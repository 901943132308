import React, { forwardRef } from 'react'
import FormErrorBox from './FormErrorBox'
import Select from './Select'

const FieldSelect = forwardRef(({ form, field, options, placeholder = null, ...rest }, ref) => {

  let selectOptions = [...options]
  if (placeholder) selectOptions = [{ label: placeholder, value: '' }, ...selectOptions]

  return (
    <>
      <Select {...field} {...rest} ref={ref} invalid={form.touched[field.name] && form.errors[field.name]}>
        {selectOptions.map(item => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </Select>
      <FormErrorBox fieldName={field.name} errors={form.errors} touched={form.touched} />
    </>
  )
})

export default FieldSelect
