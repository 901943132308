import { gql } from '@apollo/client'

export const LIST = gql`
  query {
    clinics {
      id
      name
      nickname
      shortname
      uf
      city
      defaultEquipment
      defaultEquipmentCT
      defaultEquipmentMR
      groupsSettings
      docSettings
      createdAt
      isDefault
    }
  }
`

export const GET = gql`
  query ($id: ID!) {
    clinic(id: $id) {
      id
      name
      nickname
      shortname
      uf
      city
      defaultEquipment
      defaultEquipmentCT
      defaultEquipmentMR
      groupsSettings
      docSettings
      illustrationSettings
      createdAt
    }
  }
`

export const CREATE = gql`
  mutation ($name: String!, $nickname: String, $shortname: String, $uf: String, $city: String, $defaultEquipment: String, $defaultEquipmentCT: String, $defaultEquipmentMR: String) {
    createClinic(name: $name, nickname: $nickname, shortname: $shortname, uf: $uf, city: $city, defaultEquipment: $defaultEquipment, defaultEquipmentCT: $defaultEquipmentCT, defaultEquipmentMR: $defaultEquipmentMR) {
      id
      name
      nickname
      shortname
      uf
      city
      defaultEquipment
      defaultEquipmentCT
      defaultEquipmentMR
      createdAt
    }
  }
`

export const UPDATE = gql`
  mutation ($id: ID!, $name: String, $nickname: String, $shortname: String, $uf: String, $city: String, $defaultEquipment: String, $groupsSettings: JSON, $docSettings: JSON, $illustrationSettings: JSON, $defaultEquipmentCT: String, $defaultEquipmentMR: String) {
    updateClinic(id: $id, name: $name, nickname: $nickname, shortname: $shortname, uf: $uf, city: $city, defaultEquipment: $defaultEquipment, groupsSettings: $groupsSettings, docSettings: $docSettings, illustrationSettings: $illustrationSettings, defaultEquipmentCT: $defaultEquipmentCT, defaultEquipmentMR: $defaultEquipmentMR) {
      id
      name
      nickname
      shortname
      uf
      city
      defaultEquipment
      defaultEquipmentCT
      defaultEquipmentMR
      groupsSettings
      docSettings
      createdAt
    }
  }
`

export const DELETE = gql`
  mutation ($id: ID!) {
    deleteClinic(id: $id)
  }
`

export const SET_DEFAULT = gql`
  mutation ($id: ID!) {
    setDefaultClinic(id: $id)
  }
`
