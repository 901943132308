import React, { memo } from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import Form from './FormTable'
import validationSchema from './validationSchema'

import { useMutation } from '@apollo/client'
import { CREATE } from './Gqls'



const New = ({ onRequestClose, onCompleted, onError, me }) => {

  const { t } = useTranslation()

  const [createExamination] = useMutation(CREATE)

  const initialValues = {
    tree: '',
    PatientName: '',
    PatientGender: '',
    PatientAge: '',
    PatientWeight: '',
    PatientHeight: '',
    PhysicianName: '',
    PhysicianCrm: '',
    examinedAt: '',
    previousExam1At: '',
    previousExam2At: '',
    ClinicId: '',
    uid: ''
  }

  const handleSubmit = async (variables, actions) => {

    const newVariables = { ...variables, workType: 'T' }

    if (!newVariables.examinedAt) newVariables.examinedAt = null
    if (!newVariables.previousExam1At) newVariables.previousExam1At = null
    if (!newVariables.previousExam2At) newVariables.previousExam2At = null
    newVariables.PatientAge = newVariables.PatientAge ? parseInt(newVariables.PatientAge) : null
    newVariables.PatientWeight = newVariables.PatientWeight ? parseInt(newVariables.PatientWeight) : null
    newVariables.PatientHeight = newVariables.PatientHeight ? parseInt(newVariables.PatientHeight) : null

    try {
      await createExamination({ variables: newVariables })
      onCompleted()
    } catch(err) {
      console.error(err)
      actions.setSubmitting(false)
      const errorMessage = err.message.replace('GraphQL error: ', '')
      onError(errorMessage)
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Nova Tabela')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} isCreating={true} me={me} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default memo(New)
