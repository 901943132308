import React, { memo, useEffect, useState } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import md5 from 'md5'
import { CloseIcon } from '../../components/Icons'

// function getBase64Image(img) {
//   var canvas = document.createElement("canvas");
//   canvas.width = img.width;
//   canvas.height = img.height;
//   var ctx = canvas.getContext("2d");
//   ctx.drawImage(img, 0, 0);
//   var dataURL = canvas.toDataURL("image/png");
//   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
// }

async function replaceAsync(str, regex, asyncFn) {
  const promises = []
  str.replace(regex, (match, ...args) => {
    const promise = asyncFn(match, ...args)
    promises.push(promise)
  })
  const data = await Promise.all(promises)
  return str.replace(regex, () => data.shift())
}

const doctorName = (name, gender = null) => {
  let newName = name
  if (gender === 'M') return `Dr. ${newName}`
  if (gender === 'F') return `Dra. ${newName}`
  return `Dr(a). ${newName}`
}

const CleanHtml = ({ html, examination, onRequestClose }) => {

  html = html.replace(/src="https:\/\/p\.telerison\.com\/pup\?c=(.+?)"\s/g, (match, group) => {
    const h = decodeURIComponent(group.replace('&amp;h=', ''))
    const hash = md5(`${h}2`)
    return `src="https://p.telerison.com/images/${hash}.png" `
  })

  const [htmlState, setHtmlState] = useState(html)

  useEffect(() => {

    const getB64 = url => {
      return new Promise(resolve => {
        fetch(url)
          .then( response => response.blob() )
          .then( blob =>{
            const reader = new FileReader()
            reader.onload = function(){ resolve(this.result) }
            reader.readAsDataURL(blob)
          })
      })
    }

    async function fetchImage() {
      const newHtml = await replaceAsync(htmlState, /src="https:\/\/p\.telerison\.com\/images\/(.+?)\.png"\s/g, async (match, group) => {
        const hash = group
        const b64 = await getB64(`https://p.telerison.com/images/${hash}.png`)
        return `src="${b64}" `
      })

      setHtmlState(newHtml)
    }

    fetchImage()

}, [htmlState])

  let crm =  examination.user && examination.user.crm ? examination.user.crm : ''
  crm = examination.user.crmUF ? `${crm} ${examination.user.crmUF}` : crm

  const crmLabel = examination && examination.user && examination.user.crm === '29259' ? 'CRO' : 'CRM'

  return (
    <Flex flexDirection='column' h='100%'>
      <Flex bg='grey100' minHeight={64} ai='center'>
        <Box w={64} />
        <Box flex={1} ta='center' css='white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'></Box>
        <Box w={64} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
      </Flex>
      <Box p={4} overflow='auto'>
        {htmlState &&
          <Box id='tocopy' color='black' fontFamily='Arial' dangerouslySetInnerHTML={{__html: htmlState.replace(/\s+/g, ' ').replace(/\s+,/g, ',').replace(/❗/g, '').replace(/❌/g, '').replace(/lightseagreen/g, '').replace(/rgb\(13, 13, 242\)/g, '').replace(/rgb\(242, 82, 13\)/g, '').replace(/(<img\s+?alt=""\s+?src="\S+?"\s+style=")(width:\s+?100%;)/g, "$1width: 648px; height: auto;")}} />
        }
        <Box id='tocopy2' color='black' fontFamily='Arial' style={{ marginTop: 32, textAlign: 'center' }}>
          {examination && examination.user && examination.user.signatureImage && <img src={examination.user.signatureImage} style={{ height: 60, marginBottom: 2 }} />}
          {examination && examination.user && examination.user.name && <p>{doctorName(examination.user.name, examination.user.gender)}</p>}
          {crm && <p>{`${crmLabel}: ${crm}`}</p>}
        </Box>
      </Box>
    </Flex>
  )
}

export default memo(CleanHtml)
