import React from 'react'
import { Flex } from '@alobato/flex-box'

import WrapperLayout from './WrapperLayout'

const ResultLayout = ({ children }) => {

  return (
    <WrapperLayout>
      <Flex bg='gray100' flexDirection='column'>
        {children}
      </Flex>
    </WrapperLayout>
  )
}

export default ResultLayout
