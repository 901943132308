import React, { memo } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Checkbox from '@alobato/checkbox'
import Hr from '../../components/Hr'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import Select from '../../components/Select'
import Switch from '../../components/Switch'

import { readFile } from '../../utils'

import Label from '../../components/Label'
import FormErrorBox from '../../components/FormErrorBox'

import useScrollToError from '@alobato/use-scroll-to-error'
import { useTranslation } from 'react-i18next'

// import { useMutation } from '@apollo/client'
// import { SINGLE_UPLOAD } from './Gqls'

const DocLayoutSettingsForm = ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {

  const { t } = useTranslation()

  useScrollToError({ errors, isValid, isSubmitting })

  // const [singleUpload] = useCallback(useMutation(SINGLE_UPLOAD), [SINGLE_UPLOAD])

  const papers = ['A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10', 'B0', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10', 'C0', 'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10']
  const fonts = ['Arial', 'Calibri']
  const fontSizes = ['8', '9', '10', '11', '12', '13', '14', '15', '16']

  const handleChangeImage = async (e, fieldName) => {
    // setLoading(true)
    const file = e.target.files[0]
    // const src = URL.createObjectURL(file)
    // const img = await readImage(src)
    // const { width, height } = img
    // TODO: Validate width and height
    // console.log('width', width)
    // console.log('height', height)
    // const result = await singleUpload({ variables: { file } })
    // const url = result.data.singleUpload.url
    const b64 = await readFile(file)
    setFieldValue(fieldName, b64)

    // setLoading(false)
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <h2>Formatação</h2>
      <Box mb={5}>
        <Flex>
          <Box mr={4}>
            <Label>{t('Papel')}</Label>
            <Select style={{ minWidth: 200 }} placeholder={t('Selecione...')} name='paper' value={values.paper} onChange={handleChange} onBlur={handleBlur}>
              {papers.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Select>
            <FormErrorBox fieldName='paper' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Cor texto')}</Label>
              <Flex>
                <Box mr={1}><Input type='color' width='40px' name='color' value={values.color} onChange={handleChange} onBlur={handleBlur} withError={touched.color && errors.color} /></Box>
                <Box><Input width='100px' name='color' value={values.color} onChange={handleChange} onBlur={handleBlur} withError={touched.color && errors.color} /></Box>
              </Flex>
              <FormErrorBox fieldName='color' errors={errors} touched={touched} />
          </Box>
        </Flex>

        <Flex>
          <Box mr={4}>
            <Label>{t('Fonte')}</Label>
            <Select style={{ minWidth: 200 }} placeholder={t('Selecione...')} name='fontFamily' value={values.fontFamily} onChange={handleChange} onBlur={handleBlur}>
              {fonts.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Select>
            <FormErrorBox fieldName='fontFamily' errors={errors} touched={touched} />
          </Box>
          <Box mr={4}>
            <Label>{t('Tamanho da Fonte')}</Label>
            <Select style={{ width: 160 }} placeholder={t('Selecione...')} name='fontSize' value={values.fontSize} onChange={handleChange} onBlur={handleBlur}>
              {fontSizes.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Select>
            <FormErrorBox fieldName='fontSize' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Altura da linha')}</Label>
            <Input type='number' width='160px' name='lineHeight' value={values.lineHeight} onChange={handleChange} onBlur={handleBlur} withError={touched.lineHeight && errors.lineHeight} />
            <FormErrorBox fieldName='lineHeight' errors={errors} touched={touched} />
          </Box>
        </Flex>

        <Flex>
          <Box mr={4}>
            <Label>{t('Margem superior (mm)')}</Label>
            <Input type='number' width='160px' name='topMargin' value={values.topMargin} onChange={handleChange} onBlur={handleBlur} withError={touched.topMargin && errors.topMargin} />
            <FormErrorBox fieldName='topMargin' errors={errors} touched={touched} />
          </Box>
          <Box mr={4}>
            <Label>{t('Margem direita (mm)')}</Label>
            <Input  type='number' width='160px' name='rightMargin' value={values.rightMargin} onChange={handleChange} onBlur={handleBlur} withError={touched.rightMargin && errors.rightMargin} />
            <FormErrorBox fieldName='rightMargin' errors={errors} touched={touched} />
          </Box>
          <Box mr={4}>
            <Label>{t('Margem inferior (mm)')}</Label>
            <Input type='number' width='160px' name='bottomMargin' value={values.bottomMargin} onChange={handleChange} onBlur={handleBlur} withError={touched.bottomMargin && errors.bottomMargin} />
            <FormErrorBox fieldName='bottomMargin' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Margem esquerda (mm)')}</Label>
            <Input type='number' width='160px' name='leftMargin' value={values.leftMargin} onChange={handleChange} onBlur={handleBlur} withError={touched.leftMargin && errors.leftMargin} />
            <FormErrorBox fieldName='leftMargin' errors={errors} touched={touched} />
          </Box>
        </Flex>
      </Box>

      <h2>Cabeçalho</h2>
      <Box mb={5}>
        <Flex>
          <Box mr={4}>
            <Switch checked={values.showTop} onChange={checked => setFieldValue('showTop', checked)} />
            <FormErrorBox fieldName='showTop' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Altura (mm)')}</Label>
            <Input type='number' width='160px' name='topHeight' value={values.topHeight} onChange={handleChange} onBlur={handleBlur} withError={touched.topHeight && errors.topHeight} />
            <FormErrorBox fieldName='topHeight' errors={errors} touched={touched} />
          </Box>
        </Flex>
        <Box mb={2}>
          <input type='file' id='topImage' className='inputfile' onChange={e => handleChangeImage(e, 'topImageUrl')} multiple={false} accept='image/png' />
          <label htmlFor='topImage'>Escolher imagem...</label>
        </Box>
        {values.topImageUrl &&
          <Box>
            <img src={values.topImageUrl} alt='' style={{ maxHeight: 100, maxWidth: 100 }} />
            <Flex cursor='pointer' onClick={() => setFieldValue('topImageUrl', '')}>Remover</Flex >
          </Box>
        }
      </Box>

      <h2>Rodapé</h2>
      <Box mb={5}>
        <Flex>
          <Box mr={4}>
            <Switch checked={values.showBottom} onChange={checked => setFieldValue('showBottom', checked)} />
            <FormErrorBox fieldName='showBottom' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Altura (mm)')}</Label>
            <Input type='number' width='160px' name='bottomHeight' value={values.bottomHeight} onChange={handleChange} onBlur={handleBlur} withError={touched.bottomHeight && errors.bottomHeight} />
            <FormErrorBox fieldName='bottomHeight' errors={errors} touched={touched} />
          </Box>
        </Flex>
        <Box mb={2}>
          <input type='file' id='bottomImage' className='inputfile' onChange={e => handleChangeImage(e, 'bottomImageUrl')} multiple={false} accept='image/png' />
          <label htmlFor='bottomImage'>Escolher imagem...</label>
        </Box>
        {values.bottomImageUrl &&
          <Box>
            <img src={values.bottomImageUrl} alt='' style={{ maxHeight: 100, maxWidth: 100 }} />
            <Flex cursor='pointer' onClick={() => setFieldValue('bottomImageUrl', '')}>Remover</Flex>
          </Box>
        }
      </Box>

      <h2>Número da Página</h2>
      <Box mb={5}>
        <Flex>
          <Box mr={4}>
            <Switch checked={values.showPageNumber} onChange={checked => setFieldValue('showPageNumber', checked)} />
            <FormErrorBox fieldName='showPageNumber' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Distância da base (mm)')}</Label>
            <Input type='number' width='160px' name='pageNumberDistanceFromBottom' value={values.pageNumberDistanceFromBottom} onChange={handleChange} onBlur={handleBlur} withError={touched.pageNumberDistanceFromBottom && errors.pageNumberDistanceFromBottom} />
            <FormErrorBox fieldName='pageNumberDistanceFromBottom' errors={errors} touched={touched} />
          </Box>
        </Flex>
      </Box>

      <h2>Área de Dados</h2>
      <Box>
        <Box>
          <Switch checked={values.showData} onChange={checked => setFieldValue('showData', checked)} />
          <FormErrorBox fieldName='showData' errors={errors} touched={touched} />
        </Box>
        <Box>
          <Label>{t('Altura (mm)')}</Label>
          <Input type='number' width='160px' name='dataHeight' value={values.dataHeight} onChange={handleChange} onBlur={handleBlur} withError={touched.dataHeight && errors.dataHeight} />
          <FormErrorBox fieldName='dataHeight' errors={errors} touched={touched} />
        </Box>
        <Flex>
          <Box mr={4}>
            <Label>{t('Cor do texto')}</Label>
            <Flex>
              <Box mr={1}><Input type='color' width='40px' name='dataColor' value={values.dataColor} onChange={handleChange} onBlur={handleBlur} withError={touched.dataColor && errors.dataColor} /></Box>
              <Box><Input width='100px' name='dataColor' value={values.dataColor} onChange={handleChange} onBlur={handleBlur} withError={touched.dataColor && errors.dataColor} /></Box>
            </Flex>
          </Box>
          <Box mr={4}>
            <Label>{t('Fonte')}</Label>
            <Select style={{ width: 160 }} placeholder={t('Selecione...')} name='dataFontFamily' value={values.dataFontFamily} onChange={handleChange} onBlur={handleBlur} withError={touched.dataFontFamily && errors.dataFontFamily}>
              {fonts.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Select>
            <FormErrorBox fieldName='dataFontFamily' errors={errors} touched={touched} />
          </Box>
          <Box mr={4}>
            <Label>{t('Tamanho da Fonte')}</Label>
            <Select style={{ width: 160 }} placeholder={t('Selecione...')} name='dataFontSize' value={values.dataFontSize} onChange={handleChange} onBlur={handleBlur} withError={touched.dataFontSize && errors.dataFontSize}>
              {fontSizes.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Select>
            <FormErrorBox fieldName='dataFontSize' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Altura da linha')}</Label>
            <Input type='number' width='160px' name='dataLineHeight' value={values.dataLineHeight} onChange={handleChange} onBlur={handleBlur} withError={touched.dataLineHeight && errors.dataLineHeight} />
            <FormErrorBox fieldName='dataLineHeight' errors={errors} touched={touched} />
          </Box>
        </Flex>
        <Flex>
          <Box mr={4}>
            <Checkbox label={'Tem Borda?'} checked={values.dataHasBorder} onChange={checked => setFieldValue('dataHasBorder', checked)} />
            <FormErrorBox fieldName='dataHasBorder' errors={errors} touched={touched} />
          </Box>
          <Box mr={4}>
            <Label>{t('Espessura da borda (px)')}</Label>
            <Input type='number' width='160px' name='dataBorderWidth' value={values.dataBorderWidth} onChange={handleChange} onBlur={handleBlur} withError={touched.dataBorderWidth && errors.dataBorderWidth} />
            <FormErrorBox fieldName='dataBorderWidth' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Cor da borda')}</Label>
            <Flex>
              <Box mr={1}><Input type='color' width='40px' name='dataBorderColor' value={values.dataBorderColor} onChange={handleChange} onBlur={handleBlur} withError={touched.dataBorderColor && errors.dataBorderColor} /></Box>
              <Box><Input width='100px' name='dataBorderColor' value={values.dataBorderColor} onChange={handleChange} onBlur={handleBlur} withError={touched.dataBorderColor && errors.dataBorderColor} /></Box>
            </Flex>
            <FormErrorBox fieldName='dataBorderColor' errors={errors} touched={touched} />
          </Box>
        </Flex>
        <Flex>
          <Box mr={4}>
            <Checkbox label={'Tem Fundo?'} checked={values.dataHasBg} onChange={checked => setFieldValue('dataHasBg', checked)} />
            <FormErrorBox fieldName='dataHasBg' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Cor do fundo')}</Label>
            <Flex>
              <Box mr={1}><Input type='color' width='40px' name='dataBgColor' value={values.dataBgColor} onChange={handleChange} onBlur={handleBlur} withError={touched.dataBgColor && errors.dataBgColor} /></Box>
              <Box><Input width='100px' name='dataBgColor' value={values.dataBgColor} onChange={handleChange} onBlur={handleBlur} withError={touched.dataBgColor && errors.dataBgColor} /></Box>
            </Flex>
            <FormErrorBox fieldName='dataBgColor' errors={errors} touched={touched} />
          </Box>
        </Flex>
      </Box>
      <Box>
        <Label>{t('Template')}</Label>
        <Textarea withoutVoice style={{ width: 600 }} name='dataHtml' value={values.dataHtml} onChange={value => setFieldValue('dataHtml', value)} onBlur={handleBlur} withError={touched.dataHtml && errors.dataHtml} />
        <FormErrorBox fieldName='dataHtml' errors={errors} touched={touched} />
      </Box>


      <h2>Título</h2>

      <Box>
        <Switch checked={values.showTitle} onChange={checked => setFieldValue('showTitle', checked)} />
        <FormErrorBox fieldName='showTitle' errors={errors} touched={touched} />
      </Box>
      <Flex>
        <Box mr={4}>
          <Checkbox label={'É Negrito?'} checked={values.titleIsBold} onChange={checked => setFieldValue('titleIsBold', checked)} />
          <FormErrorBox fieldName='titleIsBold' errors={errors} touched={touched} />
        </Box>
        <Box mr={4}>
          <Label>{t('Alinhamento')}</Label>
          <Select style={{ width: 160 }} placeholder={t('Selecione...')} name='titleAlign' value={values.titleAlign} onChange={handleChange} onBlur={handleBlur}>
            <option value='left'>{t('Esqueda')}</option>
            <option value='center'>{t('Centro')}</option>
            <option value='right'>{t('Direita')}</option>
            <option value='justify'>{t('Justificado')}</option>
          </Select>
          <FormErrorBox fieldName='titleAlign' errors={errors} touched={touched} />
        </Box>
      </Flex>
      <Flex>
        <Box mr={4}>
          <Label>{t('Fonte')}</Label>
          <Select style={{ width: 160 }} placeholder={t('Selecione...')} name='titleFontFamily' value={values.titleFontFamily} onChange={handleChange} onBlur={handleBlur}>
            {fonts.map(item => (
              <option key={item}>{item}</option>
            ))}
          </Select>
          <FormErrorBox fieldName='titleFontFamily' errors={errors} touched={touched} />
        </Box>
        <Box mr={4}>
          <Label>{t('Tamanho da Fonte')}</Label>
          <Select style={{ width: 160 }} placeholder={t('Selecione...')} name='titleFontSize' value={values.titleFontSize} onChange={handleChange} onBlur={handleBlur}>
            {fontSizes.map(item => (
              <option key={item}>{item}</option>
            ))}
          </Select>
          <FormErrorBox fieldName='titleFontSize' errors={errors} touched={touched} />
        </Box>
        <Box>
          <Label>{t('Altura da linha')}</Label>
          <Input type='number' width='160px' name='titleLineHeight' value={values.titleLineHeight} onChange={handleChange} onBlur={handleBlur} withError={touched.titleLineHeight && errors.titleLineHeight} />
          <FormErrorBox fieldName='titleLineHeight' errors={errors} touched={touched} />
        </Box>
      </Flex>

      <h2>Assinatura</h2>
      <Box>
        <Box>
          <Switch checked={values.showSignature} onChange={checked => setFieldValue('showSignature', checked)} />
          <FormErrorBox fieldName='showSignature' errors={errors} touched={touched} />
        </Box>

        <Flex>
          <Box mr={4}>
            <Label>{t('Margem superior')}</Label>
            <Input width='160px' name='signatureMarginTop' value={values.signatureMarginTop} onChange={handleChange} onBlur={handleBlur} withError={touched.signatureMarginTop && errors.signatureMarginTop} />
            <FormErrorBox fieldName='signatureMarginTop' errors={errors} touched={touched} />
          </Box>

          <Box mr={4}>
            <Label>{t('Alinhamento')}</Label>
            <Select style={{ width: 160 }} placeholder={t('Selecione...')} name='signatureAlign' value={values.signatureAlign} onChange={handleChange} onBlur={handleBlur}>
              <option value='center'>{t('Centro')}</option>
              <option value='left'>{t('Esqueda')}</option>
              <option value='right'>{t('Direita')}</option>
            </Select>
            <FormErrorBox fieldName='signatureAlign' errors={errors} touched={touched} />
          </Box>

          <Box>
            <Label>{t('Data e cidade')}</Label>
            <Select placeholder={t('Com data e cidade')} name='signatureSettings' value={values.signatureSettings} onChange={handleChange} onBlur={handleBlur}>
              <option value='WITHOUT_DATE'>{t('Sem data')}</option>
              <option value='WITHOUT_CITY'>{t('Sem cidade')}</option>
              <option value='WITHOUT_DATE_AND_CITY'>{t('Sem data e sem cidade')}</option>
            </Select>
            <FormErrorBox fieldName='signatureSettings' errors={errors} touched={touched} />
          </Box>
        </Flex>

        <Box>
          <Label>{t('Texto adicional')}</Label>
          <Textarea withoutVoice style={{ width: 600 }} name='signatureText' value={values.signatureText} onChange={value => setFieldValue('signatureText', value)} onBlur={handleBlur} withError={touched.signatureText && errors.signatureText} />
          <FormErrorBox fieldName='signatureText' errors={errors} touched={touched} />
        </Box>

        <Box>
          <Label>{t('Texto da segunda assinatura')}</Label>
          <Textarea withoutVoice style={{ width: 600 }} name='signatureAdditionalText' value={values.signatureAdditionalText} onChange={value => setFieldValue('signatureAdditionalText', value)} onBlur={handleBlur} withError={touched.signatureAdditionalText && errors.signatureAdditionalText} />
          <FormErrorBox fieldName='signatureAdditionalText' errors={errors} touched={touched} />
        </Box>

        <Box mb={2}>
          <Label>{t('Imagem da segunda assinatura')}</Label>
          <input type='file' id='signatureAdditionalImage' className='inputfile' onChange={e => handleChangeImage(e, 'signatureAdditionalImage')} multiple={false} accept='image/png' />
          <label htmlFor='signatureAdditionalImage'>Escolher imagem...</label>
        </Box>
        {values.signatureAdditionalImage &&
          <Box>
            <img src={values.signatureAdditionalImage} alt='' style={{ maxHeight: 100, maxWidth: 100 }} />
            <Flex cursor='pointer' onClick={() => setFieldValue('signatureAdditionalImage', '')}>Remover</Flex >
          </Box>
        }

      </Box>

      <Box my={4}><Hr /></Box>

      <Box>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
}

export default memo(DocLayoutSettingsForm)
