import * as Yup from 'yup'

Yup.setLocale({
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório'
  },
  string: {
    min: params => `Deve ter pelo menos ${params.min} caracteres`
  }
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().required(),
  // specialty: Yup.string().required(),
  // role: Yup.string().required(),
  // isDoctor: Yup.boolean(),
  // specialty: Yup.string().when('isDoctor', { is: true, then: Yup.string().required() }),
  // crm: Yup.string().when('isDoctor', { is: true, then: Yup.string().required() }),
  // crmUF: Yup.string().when('isDoctor', { is: true, then: Yup.string().required() })
})

export default validationSchema
