import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation, gql } from '@apollo/client'

import { Box } from '../../components/_new/FlexBox'
import { useMessageContext } from '../../contexts/MessageContext'

import WelcomeAMultiStepForm from './WelcomeAMultiStepForm'

function WelcomeAMultiStep({ onRequestClose, onCreate, me }) {

  const { message } = useMessageContext()

  const validationSchemas = [
    yup.object().shape({}),
    yup.object().shape({}),
    yup.object().shape({}),
    yup.object().shape({})
  ]

  // const [completeIndividual] = useMutation(gql`mutation ($name: String!, $students: [ID]) { createClass(name: $name, students: $students) { id, createdAt, name, students { id, name } } }`)
  const [completeMultiple] = useMutation(gql`mutation ($adminAsUser: Boolean, $cellPhoneNumber: String, $crm: String, $crmUF: String, $specialty: String, $license: String, $modalities: JSON, $users: JSON, $clinics: JSON) { completeMultiple(adminAsUser: $adminAsUser, cellPhoneNumber: $cellPhoneNumber, crm: $crm, crmUF: $crmUF, specialty: $specialty, license: $license, modalities: $modalities, users: $users, clinics: $clinics) }`)

  const [activeStep, setActiveStep] = React.useState(0)
  const [adminAsUser, setAdminAsUser] = React.useState(null)
  const steps = ['1', '2', '3', '4', '5']
  // const steps = ['1', '2', '3', '4']

  let isLastStep = false
  if (adminAsUser && activeStep === steps.length - 1) isLastStep = true
  if (adminAsUser === false && activeStep === steps.length - 3) isLastStep = true
  // let isLastStep = activeStep === steps.length - 1
  // if (adminAsUser)
  // adminAsUser


  const currentValidationSchema = validationSchemas[activeStep]
  const initialClinics = [
    { name: '', city: '', uf: 'SP' },
  ]

  const initialValues = { adminAsUser: true, cellPhoneNumber: '', crm: '', crmUF: 'SP', specialty: '', license: '', modalities: [], users: [], clinics: initialClinics }

  async function handleSubmit(variables, actions) {
    setAdminAsUser(variables.adminAsUser)

    if (!isLastStep) return setActiveStep(activeStep + 1)

    // try {
    //   // await complete({ variables: { ...variables, crm: '123' } })
    //   await complete({ variables })
    // //   onCreate()
    //   onRequestClose()
    //   message({ level: 'success', message: 'Salvo com sucesso' })
    // } catch(error) {
    //   console.error(error)
    //   actions.setSubmitting(false)
    //   const errorMessage = error.message.replace('GraphQL error: ', '')
    //   message(errorMessage)
    // }

    try {
      await completeMultiple({ variables })
      // onCreate()
      // onRequestClose()
      // message({ level: 'success', message: 'Criado com sucesso' })
      window.location.href = '/laudos'
    } catch(error) {
      console.error(error)
      actions.setSubmitting(false)
      const errorMessage = error.message.replace('GraphQL error: ', '')
      message(errorMessage)
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1)
  }

  return (
    <Box width={1}>
      <Box p={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {props => (
            <WelcomeAMultiStepForm
              {...props}
              steps={steps}
              activeStep={activeStep}
              isLastStep={isLastStep}
              onBack={handleBack}
              isCreating
              me={me}
            />
          )}
        </Formik>
      </Box>
    </Box>
  )

}

export default WelcomeAMultiStep
