import React, { memo, useState } from 'react'
import { Box } from '@alobato/flex-box'
import Button from '../../../../components/Button'
import Label from '../../../../components/Label'
import InputWithOptions from '../../../../components/InputWithOptions'
import InputWithAutoSize from '../../../../components/InputWithAutoSize'

const CaptureVars = memo(({ capturedVars, vars, onSubmit }) => {

  const [inputVars, setInputVars] = useState(vars)

  const handleChangeInpuVars = (name, value) => {
    setInputVars(currentInputVars => {
      return { ...currentInputVars, [name]: value }
    })
  }

  const handleUpdateVars = () => {
    onSubmit(inputVars)
  }

  return (
    <Box p={2}>
      <Box mb={3}>
        {capturedVars.map(item => (
          <Box key={item.name} maxWidth={300} mb={2}>
            <Label uppercase small>{item.name.replace(/_/g, ' ')}</Label>
            {item.options.length > 0 ? (
              <Box><InputWithOptions options={item.options} value={(inputVars[item.name] || '')} onChange={value => handleChangeInpuVars(item.name, value)} /></Box>
            ) : (
              <Box><InputWithAutoSize defaultValue={(inputVars[item.name] || '')} onChange={value => handleChangeInpuVars(item.name, value)} /></Box>
            )}
          </Box>
        ))}
      </Box>
      <Box>
        <Button onClick={handleUpdateVars} small>Salvar</Button>
      </Box>
    </Box>
  )
})

export default CaptureVars
