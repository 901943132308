import React, { memo, useCallback } from 'react'
import CircularProgress from '@alobato/circular-progress'
// import { Link } from '@reach/router'
import { Box, css } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Table, TableContainer, TableRow, TableCell, TableHeaderRow, TableHeaderCell } from '@alobato/table'

import PopOver from '../../components/PopOver'
import { Menu, MenuItem } from '../../components/Menu'
import { MoreIcon } from '../../components/Icons'
import { orderBy } from '../../utils'
import Pagination from '../../components/Pagination'
import SortLabel from '../../components/SortLabel'
import { FormatDateTime } from '../../components/Format'

import { useTranslation } from 'react-i18next'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'

import { useQuery, useMutation } from '@apollo/client'
import { LIST, DELETE } from './Gqls'

const PAGE_SIZE = 100
const DEFAULT_ORDER_FIELD = 'name'

const List = memo(({ data: { plans: items }, navigate, page, setPage, order, setOrder, notifier }) => {

  const { t } = useTranslation()

  const [deletePlan] = useMutation(DELETE)

  const pageCount = Math.ceil(items.length > 0 ? items.length / PAGE_SIZE : 0)
  const offset = (page ? (page - 1) : 0) * PAGE_SIZE

  let [field, ascOrDesc] = order ? order.split(' ') : ['', '']
  if (!field) field = DEFAULT_ORDER_FIELD
  if (!ascOrDesc) ascOrDesc = 'asc'
  const orderType = field === 'createdAt' ? 'date' : 'string'
  const paginatedItems = orderBy(items, field, ascOrDesc, orderType).slice(offset, offset + PAGE_SIZE)

  const handleDelete = async id => {
    if (!window.confirm(t('Você tem certeza que deseja excluir?'))) return false

    const update = (cache, { data: { deletePlan } }) => {
      if (!deletePlan) return false
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, plans: cacheList.plans.filter(item => item.id !== id) } })
    }

    try {
      await deletePlan({ variables: { id }, update })
      notifier.success(t('Excluído com sucesso!'))
    } catch(error) {
      console.error(error)
      notifier.error(error.message.replace('GraphQL error: ', ''))
    }
  }

  const handlePageChange = useCallback(
    ({ selected }) => { if (!selected) return setPage(null); setPage(selected + 1) }, [setPage]
  )

  return (
    <Box mb={5}>

      {items.length > 0 &&
        <>
          <Box>{`${t('Total')}:  ${items.length}`}</Box>
          <TableContainer>
            <Table>
              <TableHeaderRow>
                <TableHeaderCell width={160}>
                  <SortLabel label={t('Criado em')} headerField='createdAt' currentField={field} currentAscOrDesc={ascOrDesc} setFunction={setOrder} />
                </TableHeaderCell>
                <TableHeaderCell>
                  <SortLabel label={t('Nome')} headerField='name' currentField={field} currentAscOrDesc={ascOrDesc} setFunction={setOrder} />
                </TableHeaderCell>
                {/* <TableHeaderCell>{t('Categorias')}</TableHeaderCell> */}
                {/* <TableHeaderCell>{t('Funcionalidades')}</TableHeaderCell> */}
                {/* <TableHeaderCell>{t('Lim. Usuários')}</TableHeaderCell> */}
                {/* <TableHeaderCell>{t('Usar TelerisOn')}</TableHeaderCell> */}
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {paginatedItems.map(item => (
                <TableRow key={item.id}>
                  <TableCell data-title={t('Criado em')}><FormatDateTime>{item.createdAt}</FormatDateTime></TableCell>
                  <TableCell data-title={t('Nome')}>{item.name}</TableCell>
                  {/* <TableCell data-title={t('Categorias')}>{item.categories ? item.categories.map(item => item.label).join(', ') : ''}</TableCell> */}
                  {/* <TableCell data-title={t('Funcionalidades')}>{item.features ? item.features.map(item => item.label).join(', ') : ''}</TableCell> */}
                  {/* <TableCell data-title={t('Lim. Usuários')}>{item.usersLimit}</TableCell> */}
                  {/* <TableCell data-title={t('Usar TelerisOn')}>{item.useMaster ? t('Sim') : t('Não')}</TableCell> */}
                  <TableCell width={40} lineHeight={0}>
                    <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                      <Menu>
                        <MenuItem onClick={() => navigate(item.id)}>{t('Detalhes')}</MenuItem>
                        <MenuItem onClick={() => navigate(`${item.id}/editar`)}>{t('Editar')}</MenuItem>
                        <MenuItem onClick={() => handleDelete(item.id)}>{t('Excluir')}</MenuItem>
                      </Menu>
                    )}>
                      <MoreIcon style={{cursor: 'pointer'}} />
                    </PopOver>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </>
      }

      {items.length === 0 &&
        <Box my={5} ta='center' css={css`@keyframes fadein { 0% { opacity: 0 } 66% { opacity: 0 } 100% { opacity: 1 } } & { animation: 0.5s ease 0s normal forwards 1 fadein; }`}>
          <Text medium fw={300} color='grey400'>{t('Nenhum registro encontrado')}</Text>
        </Box>
      }

      <Box ta='center'>
        <Pagination onPageChange={handlePageChange} currentPage={page} pageCount={pageCount} />
      </Box>

    </Box>
  )
})



const ListQuery = ({ notifier, navigate }) => {
  const [search, setSearch] = useQueryParam('search', StringParam)
  const [order, setOrder] = useQueryParam('order', StringParam)
  const [page, setPage] = useQueryParam('page', NumberParam)

  const { loading, error, data } = useQuery(LIST)
  if (loading) return <CircularProgress />
  if (error) return error.message
  return <List notifier={notifier} navigate={navigate} data={data} page={page} setPage={setPage} search={search} setSearch={setSearch} order={order} setOrder={setOrder} />
}

export default memo(ListQuery)
