import React from 'react'
import Spin from '../../components/Spin'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { CloseIcon } from '../../components/Icons'
import Checkbox from '@alobato/checkbox'
import Button from '../../components/Button'
import { TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '@alobato/table'
import { css } from 'styled-components/macro'

import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from '@apollo/client'
import { CONNECT_EXAMINATIONS, ASSOCIATE_EXAMINATIONS, LIST_EXAMINATIONS_TO_SECONDARY } from './Gqls'

function stepExamination(item) {
  if (item.signedAt) return ' - ASSINADO'
  if (item.step3At) return ' - ETAPA 3'
  if (item.step2At) return ' - ETAPA 2'
  if (item.step1At) return ' - ETAPA 1'
  return ''
}

const Associations = ({ code, data: { examinationsToSecondary, examination }, onRequestClose, onCompleted, me }) => {

  const { t } = useTranslation()

  // const [connectExaminations] = useMutation(CONNECT_EXAMINATIONS)
  const [associateExaminations] = useMutation(ASSOCIATE_EXAMINATIONS)

  const secondariesCodes = examination.secondaryExaminations ? examination.secondaryExaminations.map(item => item.code) : []

  const [secondaries, setSecondaries] = React.useState(secondariesCodes)

  // const handleConnect = async mainExaminationCode => {

  //   try {
  //     const result = await connectExaminations({ variables: { mainExaminationCode, secondaryExaminationCode: code } })
  //     onCompleted()
  //   } catch(error) {
  //     console.error(error)
  //   }

  // }

  const handleChange = (code, checked) => {

    if (checked) {
      setSecondaries(current => {
        if (!current.includes(code)) {
          return [...current, code]
        }
      })
    } else {
      setSecondaries(current => {
        if (current.includes(code)) {
          return current.filter(item => item !== code)
        }
      })
    }

  }

  const handleSave = async () => {
    try {
      const result = await associateExaminations({ variables: { code, secondariesCodes: secondaries } })
      onCompleted()
    } catch(error) {
      console.error(error)
    }
  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Associações')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Box mb={1}>{examination.PatientName}</Box>
        <Box mb={4}>{`${examination.orderDetails.ExamModality} - ${examination.orderDetails.ExamName}`}</Box>

        {examinationsToSecondary && examinationsToSecondary.length > 0 &&
          <>
            <Box mb={3}>
              {examinationsToSecondary.map(item => (
                <Flex alignItems='center' key={item.code}>
                  <Flex mr={2}><Checkbox checked={secondaries.includes(item.code)} onChange={checked => handleChange(item.code, checked)} /></Flex>
                  <Box>{`${item.orderDetails.ExamModality} - ${item.orderDetails.ExamName}${stepExamination(item)}`}</Box>
                </Flex>
              ))}
            </Box>
            <Button small onClick={handleSave}>Salvar</Button>
          </>
        }


        {/* <TableContainer>
          <Table>
            <TableHeaderRow>
              <TableHeaderCell>{t('Paciente')}</TableHeaderCell>
              <TableHeaderCell>{t('Modalidade')}</TableHeaderCell>
              <TableHeaderCell>{t('ExamName')}</TableHeaderCell>
            </TableHeaderRow>
            {examinationsToSecondary.map(item => (
              <TableRow key={item.code} css={css`cursor: pointer;`} onClick={() => handleConnect(item.code)}>
                <TableCell data-title='Paciente' style={{ whiteSpace: 'nowrap'}}>{item.PatientName}</TableCell>
                <TableCell data-title='Modalidade' style={{ whiteSpace: 'nowrap'}}>{item.orderDetails.ExamModality}</TableCell>
                <TableCell data-title='ExamName' style={{ whiteSpace: 'nowrap'}}>{item.orderDetails.ExamName}</TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer> */}

      </Box>
    </Flex>
  )
}

const AssociationsQuery = ({ code, onRequestClose, onCompleted, me }) => {
  const { loading, error, data } = useQuery(LIST_EXAMINATIONS_TO_SECONDARY, { variables: { code }, fetchPolicy: 'network-only' })
  if (loading) return <Spin />
  if (error) return error.message
  return <Associations code={code} data={data} onRequestClose={onRequestClose} onCompleted={onCompleted} me={me} />
}

export default AssociationsQuery
