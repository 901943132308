import { css, createGlobalStyle } from 'styled-components'

const simpleReactNotificationStyle = css`
.simple-react-notifier {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none;
  padding: 16px;
}
.simple-react-notifier > * {
  pointer-events: initial;
  animation-fill-mode: forwards;
}
.simple-react-notifier.top-left {
  left: 0;
  right: auto;
}
.simple-react-notifier.top-center {
  left: 50%;
  transform: translate(-50%, 0);
  right: auto;
}
.simple-react-notifier.bottom-left {
  top: auto;
  right: auto;
  left: 0;
  bottom: 0;
}
.simple-react-notifier.bottom-center {
  top: auto;
  left: 50%;
  transform: translate(-50%, 0);
  right: auto;
  bottom: 0;
}
.simple-react-notifier.bottom-right {
  top: auto;
  bottom: 0;
}
.simple-react-notifier .item {
  position: relative;
  min-height: 48px;
  margin-bottom: 16px;
  padding: 14px 6px;
  border-radius: 4px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  cursor: default;
  font-size: 14px;
  line-height: 1.3;
  color: white;
}
.simple-react-notifier .item span {
  margin: 0 20px 0 10px;
}
.simple-react-notifier .item button {
  cursor: pointer;
  color: white;
  background: transparent;
  border: 0;
  position: relative;
  top: -5px;
}
.simple-react-notifier .item.success {
  background: #28a745;
  border-left: 8px solid #1e7532;
}
.simple-react-notifier .item.info {
  background: #077bf7;
  border-left: 8px solid #055fbe;
}
.simple-react-notifier .item.error {
  background: #e23849;
  border-left: 8px solid #ac1f2d;
}
.simple-react-notifier .item.rtl {
  direction: rtl;
  border-left: 0;
}
.simple-react-notifier .item.rtl.success {
  border-right: 8px solid #1e7532;
}
.simple-react-notifier .item.rtl.info {
  border-right: 8px solid #055fbe;
}
.simple-react-notifier .item.rtl.error {
  border-right: 8px solid #ac1f2d;
}
.simple-react-notifier .item button {
  display: none !important;
}
`

const draftStyle = css`
.font-size-10 {
  font-size: calc(10 * 1.3px) !important;
}
.font-size-11 {
  font-size: calc(11 * 1.3px);
}
.font-size-12 {
  font-size: 16px;
}
.font-size-14 {
  font-size: calc(14 * 1.3px);
}
.font-size-18 {
  font-size: calc(18 * 1.3px);
}
.font-size-24 {
  font-size: calc(24 * 1.3px);
}
.font-size-32 {
  font-size: calc(32 * 1.3px);
}

.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-justify {
  text-align: justify;
}

.DraftEditor-root {
  height: calc(100vh - 298px);
  overflow: scroll;
  font-family: Arial;
}

.DraftEditor-editorContainer {
  line-height: 1.4;
}

.DraftEditor-editorContainer > div {
  height: 100%; padding: 2rem 1rem;
}

span[data-offset-key="b9870-0-0"] {
  font-size: 13px;
}
`

const reachComboboxStyle = css`
[data-reach-combobox-popover] {
  z-index: 1001;
}

:root {
  --reach-combobox: 1;
}

[data-reach-combobox-popover] {
  border: solid 1px hsla(0, 0%, 0%, 0.25);
  background: hsla(0, 100%, 100%, 0.99);
  font-size: 85%;
}

[data-reach-combobox-list] {
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;
}

[data-reach-combobox-option] {
  cursor: pointer;
  margin: 0;
  padding: 0.25rem 0.5rem;
}

[data-reach-combobox-option][aria-selected="true"] {
  background: hsl(211, 10%, 95%);
}

[data-reach-combobox-option]:hover {
  background: hsl(211, 10%, 92%);
}

[data-reach-combobox-option][aria-selected="true"]:hover {
  background: hsl(211, 10%, 90%);
}

[data-suggested-value] {
  font-weight: bold;
}
`

const dayPickerStyle = css`
.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;

  -webkit-flex-direction: row;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-Months {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 8px;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4A90E2;
  color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled)
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
`

const tippy = css`
/* import 'tippy.js/dist/tippy.css' */
.tippy-tooltip[data-animation=fade][data-state=hidden]{opacity:0}
.tippy-iOS{cursor:pointer!important;-webkit-tap-highlight-color:transparent}
.tippy-popper{pointer-events:none;max-width:calc(100vw - 10px);transition-timing-function:cubic-bezier(.165,.84,.44,1);transition-property:transform}
.tippy-tooltip{position:relative;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;background-color:#333;transition-property:visibility,opacity,transform;outline:0}
.tippy-tooltip[data-placement^=top]>.tippy-arrow{border-width:8px 8px 0;border-top-color:#333;margin:0 3px;transform-origin:50% 0;bottom:-7px}
.tippy-tooltip[data-placement^=bottom]>.tippy-arrow{border-width:0 8px 8px;border-bottom-color:#333;margin:0 3px;transform-origin:50% 7px;top:-7px}
.tippy-tooltip[data-placement^=left]>.tippy-arrow{border-width:8px 0 8px 8px;border-left-color:#333;margin:3px 0;transform-origin:0 50%;right:-7px}
.tippy-tooltip[data-placement^=right]>.tippy-arrow{border-width:8px 8px 8px 0;border-right-color:#333;margin:3px 0;transform-origin:7px 50%;left:-7px}
.tippy-tooltip[data-interactive][data-state=visible]{pointer-events:auto}
.tippy-tooltip[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}
.tippy-arrow{position:absolute;border-color:transparent;border-style:solid}
.tippy-content{padding:5px 9px}
/* import 'tippy.js/themes/light.css' */
.tippy-tooltip.light-theme{color:#26323d;box-shadow:0 0 20px 4px rgba(154,161,177,.15),0 4px 80px -8px rgba(36,40,47,.25),0 4px 4px -2px rgba(91,94,105,.15);background-color:#fff}
.tippy-tooltip.light-theme[data-placement^=top]>.tippy-arrow{border-width:8px 8px 0;border-top-color:#fff}
.tippy-tooltip.light-theme[data-placement^=bottom]>.tippy-arrow{border-width:0 8px 8px;border-bottom-color:#fff}
.tippy-tooltip.light-theme[data-placement^=left]>.tippy-arrow{border-width:8px 0 8px 8px;border-left-color:#fff}
.tippy-tooltip.light-theme[data-placement^=right]>.tippy-arrow{border-width:8px 8px 8px 0;border-right-color:#fff}
.tippy-tooltip.light-theme>.tippy-backdrop{background-color:#fff}
.tippy-tooltip.light-theme>.tippy-svg-arrow{fill:#fff}
/* import 'tippy.js/animations/perspective.css' */
.tippy-tooltip[data-animation=perspective][data-placement^=top]{transform-origin:bottom}
.tippy-tooltip[data-animation=perspective][data-placement^=top][data-state=visible]{transform:perspective(700px)}
.tippy-tooltip[data-animation=perspective][data-placement^=top][data-state=hidden]{transform:perspective(700px) translateY(8px) rotateX(60deg)}
.tippy-tooltip[data-animation=perspective][data-placement^=bottom]{transform-origin:top}
.tippy-tooltip[data-animation=perspective][data-placement^=bottom][data-state=visible]{transform:perspective(700px)}
.tippy-tooltip[data-animation=perspective][data-placement^=bottom][data-state=hidden]{transform:perspective(700px) translateY(-8px) rotateX(-60deg)}
.tippy-tooltip[data-animation=perspective][data-placement^=left]{transform-origin:right}
.tippy-tooltip[data-animation=perspective][data-placement^=left][data-state=visible]{transform:perspective(700px)}
.tippy-tooltip[data-animation=perspective][data-placement^=left][data-state=hidden]{transform:perspective(700px) translateX(8px) rotateY(-60deg)}
.tippy-tooltip[data-animation=perspective][data-placement^=right]{transform-origin:left}
.tippy-tooltip[data-animation=perspective][data-placement^=right][data-state=visible]{transform:perspective(700px)}
.tippy-tooltip[data-animation=perspective][data-placement^=right][data-state=hidden]{transform:perspective(700px) translateX(-8px) rotateY(60deg)}
.tippy-tooltip[data-animation=perspective][data-state=hidden]{opacity:0}

.tippy-content {
  overflow: auto;
  max-height: calc(100vh - 64px);
}
`

const resizer = css`
.Resizer {
  background: hsla(216, 19%, 0%, 1);
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}
`



const tbl = css`
.tbl .center {
  text-align: center;
}
.tbl .left {
  text-align: left;
}
.tbl .right {
  text-align: right;
}
.tbl .justify {
  text-align: justify;
}
.tbl table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.tbl td, .tbl th {
  padding: 4px;
  border: 1px solid hsl(216, 20%, 80%);
  background: white;
}
.tbl th {
  text-align: center;
  font-weight: bold;
}
.tbl tr:nth-child(even) td {
  background: white;
}
.tbl .small {
  font-size: 9px;
  text-align: justify;
}
`



// #canvas-root {
//   width: 648px;
//   margin-left: -648px;
// }

// #canvas-root table {
//   font-size: 9pt;
// }

export default createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  html, body {
    width: 100%;
    height: 100%;
  }
  body {
    margin: 0;
    font-family: ${props => `"${props.theme.primaryFontFamily}", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`};
    font-size: ${props => props.theme.primaryFontSize};
    font-weight: ${props => props.theme.primaryFontWeight};
    line-height: ${props => props.theme.primaryLineHeight};
    color: ${props => props.theme.colors.grey700};
    background-color: ${props => props.theme.colors.bodyBgColor};
  }
  div {
    -webkit-overflow-scrolling: touch;
  }
  a {
    text-decoration: underline;
    color: ${props => props.theme.colors.primary700};
  }
  svg {
    display: block;
  }



  [hidden] {
  display: none !important;
  }
  mark {
    padding: 0.2em;
    background-color: #feffe6;
  }
  ::-moz-selection {
    color: #fff;
    background: #1890ff;
  }
  ::selection {
    color: #fff;
    background: #1890ff;
  }



  ${tbl}

  ${simpleReactNotificationStyle}

  ${draftStyle}

  ${reachComboboxStyle}

  ${dayPickerStyle}

  ${tippy}

  ${resizer}





/* TODO */
@keyframes flash {
  0% { opacity: 1.0 }
  50% { opacity: 0.3 }
  100% { opacity: 1.0 }
}
@-webkit-keyframes flash {
  0% { opacity: 1.0 }
  50% { opacity: 0.3 }
  100% { opacity: 1.0 }
}
@-moz-keyframes flash {
  0% { opacity: 1.0 }
  50% { opacity: 0.3 }
  100% { opacity: 1.0 }
}

.recording {
  color:red;
  animation-name: flash;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: flash;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: flash;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite
}


.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	/* position: absolute; BUG */
	z-index: -1;
}

.inputfile + label {
  font-weight: 400;
  padding: 8px;
  color: white;
  background-color: hsla(199,75%,57%,1);
  display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: hsla(199,56%,41%,1);
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}







.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    /* Fix of Webkit flickering */
    z-index: 1;
  }

  .swiper-container-no-flexbox .swiper-slide {
    float: left;
  }

  .swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }

  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }

  .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }

  .swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
  }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }

  .swiper-slide-invisible-blank {
    visibility: hidden;
  }

  /* Auto Height */

  .swiper-container-autoheight {
    height: auto;
    .swiper-slide {
      height: auto;
    }
    .swiper-wrapper {
      align-items: flex-start;
      transition-property: transform, height;
    }
  }

  /* 3D Effects */

  /* IE10 Windows Phone 8 Fixes */

  .swiper-container-wp8-horizontal {
    touch-action: pan-y;
    > .swiper-wrapper {
      touch-action: pan-y;
    }
  }

  .swiper-container-wp8-vertical {
    touch-action: pan-x;
    > .swiper-wrapper {
      touch-action: pan-x;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-top: -22px !important;
    z-index: 10;
    cursor: pointer;
    background-size: 50px 50px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23ffffff'/></svg>");
    left: 10px;
    right: auto;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23ffffff'/></svg>");
    right: 10px;
    left: auto;
  }

  .swiper-button-prev.swiper-button-white,
  .swiper-container-rtl .swiper-button-next.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  }

  .swiper-button-next.swiper-button-white,
  .swiper-container-rtl .swiper-button-prev.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  }

  .swiper-button-prev.swiper-button-black,
  .swiper-container-rtl .swiper-button-next.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
  }

  .swiper-button-next.swiper-button-black,
  .swiper-container-rtl .swiper-button-prev.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
  }

  .swiper-button-lock {
    display: none;
  }

  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    &.swiper-pagination-hidden {
      opacity: 0;
    }
  }

  /* Common Styles */

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 0;
    width: 100%;
  }

  /* Bullets */

  .swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
    .swiper-pagination-bullet {
      transform: scale(0.33);
      position: relative;
    }
    .swiper-pagination-bullet-active,
    .swiper-pagination-bullet-active-main {
      transform: scale(1);
    }
    .swiper-pagination-bullet-active-prev {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-prev-prev {
      transform: scale(0.33);
    }
    .swiper-pagination-bullet-active-next {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-next-next {
      transform: scale(0.33);
    }
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
  }

  button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-appearance: none;
    appearance: none;
  }

  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff;
  }

  .swiper-container-vertical > .swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
    .swiper-pagination-bullet {
      margin: 6px 0;
      display: block;
    }
    &.swiper-pagination-bullets-dynamic {
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      .swiper-pagination-bullet {
        display: inline-block;
        transition: 200ms transform, 200ms top;
      }
    }
  }

  .swiper-container-horizontal {
    > .swiper-pagination-bullets {
      .swiper-pagination-bullet {
        margin: 0 4px;
      }
      &.swiper-pagination-bullets-dynamic {
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        .swiper-pagination-bullet {
          transition: 200ms transform, 200ms left;
        }
      }
    }
    &.swiper-container-rtl
      > .swiper-pagination-bullets-dynamic
      .swiper-pagination-bullet {
      transition: 200ms transform, 200ms right;
    }
  }

  /* Progress */

  .swiper-pagination-progressbar {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    .swiper-pagination-progressbar-fill {
      background: #007aff;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: scale(0);
      transform-origin: left top;
    }
  }

  .swiper-container-rtl
    .swiper-pagination-progressbar
    .swiper-pagination-progressbar-fill {
    transform-origin: right top;
  }

  .swiper-container-horizontal > .swiper-pagination-progressbar {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
  }

  .swiper-container-vertical > .swiper-pagination-progressbar {
    &.swiper-pagination-progressbar-opposite {
      width: 100%;
      height: 4px;
      left: 0;
      top: 0;
    }
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }

  .swiper-container-horizontal
    > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }

  .swiper-pagination-white .swiper-pagination-bullet-active {
    background: black;
  }

  .swiper-pagination-progressbar.swiper-pagination-white {
    background: rgba(255, 255, 255, 0.25);
    .swiper-pagination-progressbar-fill {
      background: #ffffff;
    }
  }

  .swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000000;
  }

  .swiper-pagination-progressbar.swiper-pagination-black {
    background: rgba(0, 0, 0, 0.25);
    .swiper-pagination-progressbar-fill {
      background: #000000;
    }
  }

  .swiper-pagination-lock {
    display: none;
  }

  /* Scrollbar */

  .swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    background: rgba(0, 0, 0, 0.1);
  }

  .swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 2%;
    bottom: 16px;
    z-index: 50;
    height: 8px;
    width: 96%;
  }

  .swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 10px;
    top: 5%;
    z-index: 50;
    width: 8px;
    height: 90%;
  }

  .swiper-scrollbar-drag {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  .swiper-scrollbar-cursor-drag {
    cursor: move;
  }

  .swiper-scrollbar-lock {
    display: none;
  }

  .swiper-zoom-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    > {
      img,
      svg,
      canvas {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  .swiper-slide-zoomed {
    cursor: move;
  }

  /* Preloader */

  .swiper-lazy-preloader {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 42px;
    height: 42px;
    margin-top: -21px;
    margin-left: -21px;
    animation: swiper-preloader-spin 1s steps(12, end) infinite;
    transform-origin: 50%;
    &:after {
      display: block;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 100%;
      content: "";
    }
  }

  .swiper-lazy-preloader-white:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  }

  @keyframes swiper-preloader-spin {
    100% {
      transform: rotate(360deg);
    }
  }

  /* a11y */
  .swiper-container .swiper-notification {
    position: absolute;
    z-index: -1000;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }

  .swiper-container-fade {
    &.swiper-container-free-mode .swiper-slide {
      transition-timing-function: ease-out;
    }
    .swiper-slide {
      pointer-events: none;
      transition-property: opacity;
      .swiper-slide {
        pointer-events: none;
      }
    }
    .swiper-slide-active {
      pointer-events: auto;
      .swiper-slide-active {
        pointer-events: auto;
      }
    }
  }
`
