import { useEffect, useCallback } from 'react'

const useDidMount = callback => {
  const fn = useCallback(callback, [])
  useEffect(() => {
    fn()
  }, [fn])
}

export default useDidMount
