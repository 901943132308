import md5 from 'md5'

export const shortKey = name => {
  return md5(name).substring(0, 5)
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const normalizeGender = gender => {
  if (!gender) return ''
  if (gender.toString().toUpperCase() === 'M') return 'MASCULINO'
  if (gender.toString().toUpperCase() === 'F') return 'FEMININO'
  return gender
}

export const orderBy = (items, field, ascOrDesc, type = 'string') => {
  if (type.toLowerCase() === 'string') {
    if (ascOrDesc.toLowerCase() === 'asc') {
      return items.slice().sort((a, b) => a[field].toString().localeCompare(b[field].toString()))
    }
    if (ascOrDesc.toLowerCase() === 'desc') {
      return items.slice().sort((a, b) => b[field].toString().localeCompare(a[field].toString()))
    }
  } else if (type.toLowerCase() === 'date') {
    if (ascOrDesc.toLowerCase() === 'asc') {
      return items.slice().sort((a, b) => new Date(a[field]) - new Date(b[field]))
    }
    if (ascOrDesc.toLowerCase() === 'desc') {
      return items.slice().sort((a, b) => new Date(b[field]) - new Date(a[field]))
    }
  }
  return items
}

export const asyncReduce = async (arr, fn, val, pure) => {
  for (let i = 0; i < arr.length; i++) {
    let v = await fn(val, arr[i], i, arr)
    if (pure !== false) val = v
  }
  return val
}

export const readFile = file => {
  return new Promise(resolve => {
    const fr = new FileReader()
    fr.onload = () => resolve(fr.result)
    fr.readAsDataURL(file)
  })
}

export const readImage = src => {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = function () {
      resolve(this)
    }
    img.src = src
  })
}

export const camelize = text => text.toLowerCase().replace(/\s/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const mobileDetect = userAgent => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i))

  const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows())
  const isDesktop = () => !isMobile()
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos
  }
}
