import React from 'react'
import { css } from 'styled-components/macro'

import { usePopper } from 'react-popper-next'
import { useSpring, animated } from 'react-spring-next'
import { useHover } from 'use-events'
import { useMergeRefs } from 'use-callback-ref'

const Tooltip = ({ children, tooltip, placement }) => {
  const ref = React.useRef()
  const [reference, setReference] = React.useState(null)
  const [popper, setPopper] = React.useState(null)
  // const [arrow, setArrow] = React.useState(null)

  const { styles, attributes } = usePopper(reference, popper, {
    // modifiers: [{ name: 'arrow', options: { element: arrow } }],
    placement
  })

  const [active, bind] = useHover()
  const springStyle = useSpring(active ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 })

  return (
    <>
    <div ref={useMergeRefs([ref, setReference])} {...bind}>{children}</div>
    <div ref={setPopper} {...attributes.popper} style={styles.popper}>
      <animated.div style={{ transformOrigin: 'top right', ...springStyle }}>
        <div css={css`background-color: hsla(216, 20%, 95%, 1); color: hsla(211,20%,43%,1); border-radius: 2px; padding: 4px; font-size: 12px; line-height: 1.2; transform: translateX(-8px); text-align: center;`}>{tooltip}</div>
        {/* <div ref={setArrow} style={{ border: '1px solid black', ...styles.arrow }}  /> */}
      </animated.div>
    </div>
  </>
  )

}

export default Tooltip
