import { gql } from '@apollo/client'

export const LIST = gql`
  query ($accountId: ID!) {
    account(id: $accountId) {
      id
      name
    }
    accountUsers(accountId: $accountId) {
      id
      name
      email
      cellPhoneNumber
      role
      isDoctor
      crm
      crmUF
      specialty
      createdAt
    }
  }
`

export const LIST_USERS = gql`
  query ($accountId: ID!) {
    accountUsers(accountId: $accountId) {
      id
      name
      email
      cellPhoneNumber
      role
      isDoctor
      crm
      crmUF
      specialty
      createdAt
    }
  }
`

export const GET = gql`
  query ($accountId: ID!, $id: ID!) {
    accountUser(accountId: $accountId, id: $id) {
      id
      name
      email
      cellPhoneNumber
      role
      signatureImage
      isDoctor
      crm
      crmUF
      specialty
      createdAt
    }
  }
`

export const CREATE = gql`
  mutation ($AccountId: ID!, $name: String!, $email: String!, $cellPhoneNumber: String, $role: String, $isDoctor: Boolean, $crm: String, $crmUF: String, $signatureImage: String) {
    createAccountUser(AccountId: $AccountId, name: $name, email: $email, cellPhoneNumber: $cellPhoneNumber, role: $role, isDoctor: $isDoctor, crm: $crm, crmUF: $crmUF, signatureImage: $signatureImage) {
      id
      name
      email
      cellPhoneNumber
      role
      signatureImage
      isDoctor
      crm
      crmUF
      specialty
      createdAt
    }
  }
`

export const UPDATE = gql`
  mutation ($id: ID!, $name: String!, $email: String!, $cellPhoneNumber: String, $role: String, $isDoctor: Boolean, $crm: String, $crmUF: String, $signatureImage: String) {
    updateUser(id: $id, name: $name, email: $email, cellPhoneNumber: $cellPhoneNumber, role: $role, isDoctor: $isDoctor, crm: $crm, crmUF: $crmUF, signatureImage: $signatureImage) {
      id
      name
      email
      cellPhoneNumber
      role
      signatureImage
      isDoctor
      crm
      crmUF
      specialty
      createdAt
    }
  }
`

export const DELETE = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id)
  }
`
