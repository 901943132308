import React, { useState, useEffect, useRef } from 'react'

export const eventManager = {
  ids: [],
  add: function(id, callback) {
    this.ids.push({ id, callback })
  },
  remove: function(id) {
    if (id) {
      const { callback } = this.ids.find(it => it.id === id)
      callback()
      this.ids = this.ids.filter(it => it.id !== id)
    } else {
      this.ids.forEach(it => it.callback())
      this.ids = []
    }
  }
}

const filter = (ar, id) => ar.filter(it => it.key.toString() !== id.toString())

const Notification = ({ message, onClose, type = 'info', width = '300px', closeOnClick = true, onMouseEnter, onMouseLeave }) => (
  <div className={`item ${type}`} style={{ width }} onClick={() => closeOnClick && onClose()} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}><span>{message}</span> <button onClick={onClose}>✖</button></div>
)

const timers = {}

class Timer {

  constructor(callback, delay) {
    let timerId = -1, start = 0
    this.remaining = delay

    this.pause = () => {
      clearTimeout(timerId)
      this.remaining -= Date.now() - start
    }

    this.resume = () => {
      start = Date.now()
      clearTimeout(timerId)
      timerId = setTimeout(callback, this.remaining)
    }

    this.resume()
  }
}

export default props => {

  const [, setItems] = useState([])
  const [hovered, setHovered] = useState(false)
  const [dismissedByClick, setDismissedByClick] = useState(false)
  const items = useRef([])

  const { autoClose = 3000, delay = 0, id, animation = {} } = props

  const animationDuration = animation.duration || 300
  const closeTime = autoClose > 0 ? autoClose : 0


  useEffect(() => {
    const index = items.current.findIndex(it => it.key === id)
    const el = items.current[index]
    if (!el) return
    const t = timers[id].remaining

    const style = hovered && !dismissedByClick ? {} : { animationName: `${animation.out}`, animationDelay: `${t - animationDuration}ms`, animationDuration: `${animationDuration}ms` }

    const cloned = React.cloneElement(el, { ...el.props, style })

    items.current.splice(index, 1, cloned)
    setItems([...items.current])
  }, [hovered, dismissedByClick, animation.out, animationDuration, id])


  useEffect(() => {

    const removeItemById = id => {
      items.current = filter(items.current, id)
      setItems(items.current)
      items.current.length === 0 && props.cleared()
    }

    const handleClose = () => {
      setTimeout(() => removeItemById(id), animationDuration)
      timers[id].remaining = animationDuration
      setDismissedByClick(true)
    }

    const params = { id, onClose: handleClose, onMouseEnter: () => f("pause"), onMouseLeave: () => f("resume") }

    const f = action => {
      props.pauseOnHover && timers[id] && timers[id][action]()
      setHovered(action === "pause")
    }

    let newItem = props.render ? (props.render(params)) : (<Notification {...props} {...params} />)

    if (animationDuration) {
      newItem = (<div key={id} style={{animationName: `${animation.in}, ${animation.out}`, animationDelay: `0ms, ${closeTime}ms`, animationDuration: `${animationDuration}ms, ${animationDuration}ms`}}>{newItem}</div>)
    }

    const rest = props.onlyLast ? [] : items.current
    const { newestOnTop = true } = props
    items.current = newestOnTop ? [newItem, ...rest] : [...rest, newItem]
    eventManager.add(id, () => removeItemById(id))

    setTimeout(() => setItems(items.current), delay)

    timers[id] = new Timer(() => autoClose && removeItemById(id), delay + closeTime + animationDuration)
  }, [props, animation.in, animation.out, animationDuration, delay, autoClose, closeTime, id])

  return <>{items.current}</>
}
