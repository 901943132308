import React, { memo, useMemo } from 'react'
import CircularProgress from '@alobato/circular-progress'
import { Link } from '@reach/router'
import { Box, css } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Table, TableContainer, TableRow, TableCell, TableHeaderRow, TableHeaderCell } from '@alobato/table'

import PopOver from '../../components/PopOver'
import { Menu, MenuItem } from '../../components/Menu'
import { MoreIcon } from '../../components/Icons'
import Pagination from '../../components/Pagination'
import SortLabel from '../../components/SortLabel'
import { FormatDateTime } from '../../components/Format'

import { orderBy } from '../../utils'

import { useTranslation } from 'react-i18next'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'

import { useQuery, useMutation } from '@apollo/client'
import { LIST, DELETE, REMOVE_LICENSE } from './Gqls'
import { ME } from '../../authorization'

const PAGE_SIZE = 100
const DEFAULT_ORDER_FIELD = 'name'

function LicenseText({ children }) {
  if (!children) return ''

  if (children.includes('-IR')) {
    return 'BASIC'
  } else if (children.includes('-I')) {
    return ''
  } else if (children.includes('-L1')) {
    return 'PRO'
  } else if (children.includes('-L2')) {
    return ''
  } else if (children.includes('-T')) {
    return 'PLUS'
  }

  return ''
}

const List = ({ refetch, data: { users: items }, navigate, page, setPage, order, setOrder, notifier }) => {

  const { t } = useTranslation()



  // const items = useMemo(() => users.map(item => console.log('map') || ({
  //   ...item,
  //   UnitName: item.unit ? item.unit.name : ''
  // })), [users])

  const [deleteUser] = useMutation(DELETE)
  const [removeLicense] = useMutation(REMOVE_LICENSE)



  const pageCount = Math.ceil(items.length > 0 ? items.length / PAGE_SIZE : 0)
  const offset = (page ? (page - 1) : 0) * PAGE_SIZE



  let [field, ascOrDesc] = order ? order.split(' ') : ['', '']
  if (!field) field = DEFAULT_ORDER_FIELD
  if (!ascOrDesc) ascOrDesc = 'asc'
  const orderType = field === 'createdAt' ? 'date' : 'string'
  const paginatedItems = orderBy(items, field, ascOrDesc, orderType).slice(offset, offset + PAGE_SIZE)



  const handleRemoveLicense = async id => {
    if (!window.confirm(t('Você tem certeza que deseja remover a licença?'))) return false

    // const update = (cache, { data: { removeLicense } }) => {
    //   if (!removeLicense) return false
    //   const cacheList = cache.readQuery({ query: LIST })
    //   cache.writeQuery({ query: LIST, data: { ...cacheList, users: cacheList.users.filter(item => item.id !== id) } })
    // }

    // const result = await createLicense({ refetchQueries: [{ query: ME }], variables: { licenses: [] } })


    try {
      await removeLicense({ refetchQueries: [{ query: ME }], variables: { UserId: id } })
      refetch()
      notifier.success(t('Licença removida com sucesso!'))
    } catch(error) {
      console.error(error)
    }
  }

  const handleDelete = async id => {
    if (!window.confirm(t('Você tem certeza que deseja excluir?'))) return false

    const update = (cache, { data: { deleteUser } }) => {
      if (!deleteUser) return false
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, users: cacheList.users.filter(item => item.id !== id) } })
    }

    try {
      await deleteUser({ refetchQueries: [{ query: ME }], variables: { id }, update })
      notifier.success(t('Excluído com sucesso!'))
    } catch(error) {
      notifier.error(error.message.replace('GraphQL error: ', ''))
      console.error(error)
    }
  }

  return (
    <Box mb={5}>

      {items.length > 0 &&
        <>
          <Box>{`${t('Total')}:  ${items.length}`}</Box>

          <TableContainer>
            <Table>
              <TableHeaderRow>
                <TableHeaderCell width={160}>
                  {/* <SortLabel label={t('Criado em')} headerField='createdAt' currentField={field} currentAscOrDesc={ascOrDesc} setFunction={setOrder} /> */}
                </TableHeaderCell>
                <TableHeaderCell>
                  <SortLabel label={t('Nome')} headerField='name' currentField={field} currentAscOrDesc={ascOrDesc} setFunction={setOrder} />
                </TableHeaderCell>
                <TableHeaderCell>
                  <SortLabel label={t('Email')} headerField='email' currentField={field} currentAscOrDesc={ascOrDesc} setFunction={setOrder} />
                </TableHeaderCell>
                <TableHeaderCell>Especialidade</TableHeaderCell>
                <TableHeaderCell>Modalidades</TableHeaderCell>
                <TableHeaderCell>
                  <SortLabel label={t('É administrador?')} headerField='role' currentField={field} currentAscOrDesc={ascOrDesc} setFunction={setOrder} />
                </TableHeaderCell>
                <TableHeaderCell>
                  Licença
                </TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {paginatedItems.map(item => (
                <TableRow key={item.id}>
                  <TableCell data-title={t('Criado em')}><FormatDateTime>{item.createdAt}</FormatDateTime></TableCell>
                  <TableCell data-title={t('Nome')}>{item.name}</TableCell>
                  <TableCell data-title={t('Email')}>{item.email}</TableCell>
                  <TableCell data-title={t('Especialidade')}>
                    <Box css={css`max-width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;`}>{item.specialty}</Box>
                  </TableCell>
                  <TableCell data-title={t('Modalidades')}>
                    <Box css={css`max-width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;`}>{item?.preferences?.modalities ? item.preferences.modalities.join(', ') : ''}</Box>
                  </TableCell>
                  <TableCell data-title={t('É administrador?')}>{item.license && item.license.includes('A') ? 'Sim' : ''}</TableCell>
                  <TableCell data-title={t('Licença')}><LicenseText>{item.license}</LicenseText></TableCell>
                  <TableCell width={40} lineHeight={0}>
                    <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                      <Menu>
                        {/* <MenuItem onClick={() => navigate(item.id)}>{t('Detalhes')}</MenuItem> */}
                        <MenuItem onClick={() => navigate(`${item.id}/editar`)}>{t('Editar')}</MenuItem>
                        <MenuItem onClick={() => handleDelete(item.id)}>{t('Excluir')}</MenuItem>
                        {(item.license === 'A' || item.license === 'M' || !item.license) && <MenuItem onClick={() => navigate(`${item.id}/license`)}>{t('Associar Licença')}</MenuItem>}
                        {/*item.license === 'A' || item.license === 'M' || !item.license && <MenuItem onClick={() => navigate(`${item.id}/license`)}>{t('Associar Licença')}</MenuItem>*/}
                        {item.license && item.license.includes('-') && <MenuItem onClick={() => handleRemoveLicense(item.id)}>{t('Remover Licença')}</MenuItem>}
                      </Menu>
                    )}>
                      <MoreIcon style={{cursor: 'pointer'}} />
                    </PopOver>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </>
      }

      {items.length === 0 &&
        <Box my={5} ta='center' css={css`@keyframes fadein { 0% { opacity: 0 } 66% { opacity: 0 } 100% { opacity: 1 } } & { animation: 0.5s ease 0s normal forwards 1 fadein; }`}>
          <Text medium fw={300} color='grey400'>{t('Nenhum registro encontrado')}</Text>
        </Box>
      }

      <Box ta='center'>
        {/* <Pagination onPageChange={({ selected }) => { if (!selected) return setPage(null); setPage(selected + 1) }} currentPage={page} pageCount={pageCount} /> */}
      </Box>

    </Box>
  )
}

const ListQuery = props => {
  const [search, setSearch] = useQueryParam('search', StringParam)
  const [order, setOrder] = useQueryParam('order', StringParam)
  const [page, setPage] = useQueryParam('page', NumberParam)

  const { loading, error, data, refetch } = useQuery(LIST)
  if (loading) return <CircularProgress />
  if (error) return error.message
  return <List {...props} data={data} refetch={refetch} page={page} setPage={setPage} search={search} setSearch={setSearch} order={order} setOrder={setOrder} />
}

export default ListQuery
