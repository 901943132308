import React from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation, gql } from '@apollo/client'

import { Box } from '../../components/_new/FlexBox'
import { useMessageContext } from '../../contexts/MessageContext'

import WelcomeMultiStepForm from './WelcomeCMultiStepForm'

function WelcomeMultiStep({ onRequestClose, onCreate, clinics }) {
  const { message } = useMessageContext()

  const validationSchemas = [
    yup.object().shape({
      crm: yup.string().required('Campo obrigatório'),
      crmUF: yup.string().required('Campo obrigatório'),
      specialty: yup.string().required('Campo obrigatório'),
      password: yup.string().required('Campo obrigatório'),
    }),
    yup.object().shape({}),
    yup.object().shape({}),
    yup.object().shape({})
  ]

  const [completeInvite] = useMutation(gql`mutation ($crm: String, $crmUF: String, $specialty: String, $modalities: JSON, $clinics: JSON, $password: String) { completeInvite(crm: $crm, crmUF: $crmUF, specialty: $specialty, modalities: $modalities, clinics: $clinics, password: $password) }`)

  const [activeStep, setActiveStep] = React.useState(0)
  const steps = ['1', '2', '3']
  const lastStep = clinics && clinics.length > 1 ? steps.length - 1 : steps.length - 2
  const isLastStep = activeStep === lastStep
  const currentValidationSchema = validationSchemas[activeStep]

  const initialValues = { crm: '', crmUF: 'SP', specialty: '', password: '', modalities: [], clinics: clinics && clinics.length === 1 ? [clinics[0].id] : [] }

  async function handleSubmit(variables, actions) {
    if (!isLastStep) return setActiveStep(activeStep + 1)
    try {
      await completeInvite({ variables })
      window.location.href = '/laudos'
    } catch(err) {
      console.error(err)
      actions.setSubmitting(false)
      const errorMessage = err.message.replace('GraphQL error: ', '')
      message({ level: 'error', message: errorMessage })
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1)
  }

  return (
    <Box width={1}>
      <Box p={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {props => (
            <WelcomeMultiStepForm
              {...props}
              steps={steps}
              activeStep={activeStep}
              isLastStep={isLastStep}
              onBack={handleBack}
              clinics={clinics}
              isCreating
            />
          )}
        </Formik>
      </Box>
    </Box>
  )

}

export default WelcomeMultiStep
