import React, { memo } from 'react'

import { Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Select from '../../components/Select'

import useScrollToError from '@alobato/use-scroll-to-error'
import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

import FormErrorBox from '../../components/FormErrorBox'
import Label from '../../components/Label'

import states from '../../utils/states'

const Form = ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {

  const { t } = useTranslation()

  useFocusOnLoad('name')
  useScrollToError({errors, isValid, isSubmitting})

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>{t('Nome')}<span>*</span></Label>
        <Input width='100%' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} withError={touched.name && errors.name} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>

      <Box mb={2}>
        <Label>{t('UF')}<span>*</span></Label>
        <Select placeholder='Selecione...' name='uf' value={values.uf} onChange={handleChange} onBlur={handleBlur}>
          {states.map(item => (
            <option key={item}>{item}</option>
          ))}
        </Select>
        <FormErrorBox fieldName='uf' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Cidade')}<span>*</span></Label>
        <Input width='100%' name='city' value={values.city} onChange={handleChange} onBlur={handleBlur} withError={touched.city && errors.city} />
        <FormErrorBox fieldName='city' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Nome OCR')}</Label>
        <Input width='100%' name='nickname' value={values.nickname} onChange={handleChange} onBlur={handleBlur} withError={touched.nickname && errors.nickname} />
        <FormErrorBox fieldName='nickname' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Código')}</Label>
        <Input width='100%' name='shortname' value={values.shortname} onChange={handleChange} onBlur={handleBlur} withError={touched.shortname && errors.shortname} />
        <FormErrorBox fieldName='shortname' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Equipamento padrão')}</Label>
        <Input width='100%' name='defaultEquipment' value={values.defaultEquipment} onChange={handleChange} onBlur={handleBlur} withError={touched.defaultEquipment && errors.defaultEquipment} />
        <FormErrorBox fieldName='defaultEquipment' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Equipamento padrão TC')}</Label>
        <Input width='100%' name='defaultEquipmentCT' value={values.defaultEquipmentCT} onChange={handleChange} onBlur={handleBlur} withError={touched.defaultEquipmentCT && errors.defaultEquipmentCT} />
        <FormErrorBox fieldName='defaultEquipmentCT' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Equipamento padrão RM')}</Label>
        <Input width='100%' name='defaultEquipmentMR' value={values.defaultEquipmentMR} onChange={handleChange} onBlur={handleBlur} withError={touched.defaultEquipmentMR && errors.defaultEquipmentMR} />
        <FormErrorBox fieldName='defaultEquipmentMR' errors={errors} touched={touched} />
      </Box>

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
}

export default memo(Form)

