import React, { memo, useState, useEffect, useRef, useCallback } from 'react'
import isEqual from 'lodash.isequal'
import styled from 'styled-components/macro'
import Checkbox from '@alobato/checkbox'
import Text from '@alobato/text'
import Spin from '../../components/Spin'
import { Flex, Box } from '@alobato/flex-box'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PortalAwareItem from '../../components/PortalAwareItem'


import Input from '../../components/Input'
import Select from '../../components/Select'
import Switch from '../../components/Switch'

import { CloseIcon, DragVerticalIcon } from '../../components/Icons'

import useDebounce from '@alobato/use-debounce'
import usePrevious from '../../hooks/usePrevious'
import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from '@apollo/client'
import { GET, UPDATE } from './Gqls'

const Handle = styled.div`
  position: absolute;
  top: 16px;
  left: 4px;
`

const DocContentSettings = memo(({ data: { clinic }, onRequestClose, onError, notifier }) => {

  const { t } = useTranslation()

  const [updateClinic] = useMutation(UPDATE)

  const defaultInitialValues = {
    'INDICAÇÃO CLÍNICA': {
      active: true,
      title: 'Indicação Clínica:',
      titleIsBold: true,
      formatting: 'SAMELINE',
      order: 0,
    },
    'INTRODUÇÃO': {
      active: true,
      title: 'Introdução:',
      titleIsBold: true,
      formatting: 'SAMELINE',
      order: 1,
    },
    'TABELAS E ILUSTRAÇÕES': {
      active: true,
      title: 'Tabelas e Ilustrações',
      titleIsBold: true,
      formatting: 'WITHBREAK',
      order: 2,
    },
    'ANÁLISE': {
      active: true,
      title: 'Análise',
      titleIsBold: true,
      formatting: 'WITHBREAK',
      order: 3,
    },
    'IMPRESSÃO': {
      active: true,
      title: 'Impressão:',
      titleIsBold: true,
      formatting: 'SAMELINE',
      order: 4,
    },
    'OBSERVAÇÃO': {
      active: true,
      title: 'Achados adicionais:',
      titleIsBold: true,
      formatting: 'SAMELINE',
      order: 5,
    },
    'NOTA': {
      active: true,
      title: '',
      titleIsBold: false,
      formatting: 'WITHBREAK',
      order: 6,
    }
  }

  const initialValues = clinic.groupsSettings ? clinic.groupsSettings : defaultInitialValues

  const initialOrderedItems = Object.keys(initialValues).sort((a, b) => initialValues[a].order - initialValues[b].order)

  const [orderedItems, setOrderedItems] = useState(initialOrderedItems)

  const [groups, setGroups] = useState(initialValues)
  const debouncedGroups = useDebounce(groups, 600)

  useEffect(() => {
    setGroups(previousGroups => {
      return Object.keys(previousGroups).reduce((acc, key) => {
        acc[key] = { ...previousGroups[key], order: orderedItems.indexOf(key) }
        return acc
      }, {})
    })
  }, [orderedItems])

  const save = useCallback(async values => {
    try {
      await updateClinic({ variables: { id: clinic.id, groupsSettings: values } })
      notifier.success(t('Salvo com sucesso!'))
    } catch(err) {
      console.error(err)
      const errorMessage = err.message.replace('GraphQL error: ', '')
      onError(errorMessage)
    }
  // eslint-disable-next-line
  }, [clinic, updateClinic, notifier, t])



  const previousGroups = usePrevious(debouncedGroups)
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current && !isEqual(previousGroups, debouncedGroups)) {
      save(debouncedGroups)
    } else {
      didMountRef.current = true
    }
  }, [debouncedGroups, previousGroups, save])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = result => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    const reordered = reorder(
      orderedItems,
      result.source.index,
      result.destination.index
    )
    setOrderedItems(reordered)
  }

  const handleChange = (groupName, field, value) => {
    setGroups(previousGroups => {
      return { ...previousGroups, [groupName]: { ...previousGroups[groupName], [field]: value } }
    })
  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Configurar conteúdo')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box maxWidth={700}>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='list'>
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {orderedItems.map((item, index) => (
                    <Draggable draggableId={item} index={index} key={item}>
                      {(provided, snapshot) => (
                        <PortalAwareItem innerRef={provided.innerRef} provided={provided} snapshot={snapshot}>
                          <Box p={3} position='relative'>
                            <Handle {...provided.dragHandleProps}><DragVerticalIcon height={24} /></Handle>
                            <Box mb={3}><Box w={200} pl={4}><Text fw={700}>{item}</Text></Box></Box>
                            <Box mb={3} pl={4}>
                              <Flex ai='center' jc='space-between'>
                                <Flex ai='center'>
                                  <Box mr={5}>
                                    <Switch checked={groups[item].active} onChange={checked => handleChange(item, 'active', checked)} />
                                  </Box>
                                  <Box mr={2}>
                                    <Input placeholder={t('Texto do título')} value={groups[item].title} onChange={e => handleChange(item, 'title', e.target.value)} />
                                  </Box>
                                  <Box mr={4}>
                                    <Checkbox label={t('Negrito')} checked={groups[item].titleIsBold} onChange={checked => handleChange(item, 'titleIsBold', checked)} />
                                  </Box>``
                                  <Box>
                                    <Select value={groups[item].formatting} onChange={e => handleChange(item, 'formatting', e.target.value)}>
                                      <option value='SAMELINE'>{t('Mesma linha')}</option>
                                      <option value='WITHBREAK'>{t('Com quebra de linha')}</option>
                                    </Select>
                                  </Box>
                                </Flex>
                              </Flex>
                            </Box>

                          </Box>
                        </PortalAwareItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

        </Box>

      </Box>
    </Flex>
  )

})



const DocContentSettingsQuery = ({ id, ...rest }) => {
  const { loading, error, data } = useQuery(GET, { fetchPolicy: 'network-only', variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <DocContentSettings {...rest} data={data} />
}

export default memo(DocContentSettingsQuery)
