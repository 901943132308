import React, { memo } from 'react'

import Wrapper from '../../components/Wrapper'
import Card from '../../components/Card'
import Modal from '../../components/StyledModal'

import { useTranslation } from 'react-i18next'
import { useHotkeys } from 'react-hotkeys-hook'

import List from './List'
import Show from './Show'
import New from './New'
import Edit from './Edit'

const Home = ({ id, accountId, path, navigate, notifier }) => {

  useHotkeys('n', () => navigate('novo'))

  const { t } = useTranslation()

  return (
    <>
      <Wrapper>

        <Card>


          <List accountId={accountId} navigate={navigate} notifier={notifier} />

        </Card>

      </Wrapper>

      {path === '/:id' && id &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../')}
          render={
            ({onRequestClose}) => (
              <Show
                id={id}
                accountId={accountId}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      }

      {path === '/:id/editar' &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../../')}
          render={
            ({onRequestClose}) => (
              <Edit
                id={id}
                accountId={accountId}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
                notifier={notifier}
              />
            )
          }
        />
      }

      {path === '/novo' &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../')}
          render={
            ({onRequestClose}) => (
              <New
                accountId={accountId}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
                notifier={notifier}
              />
            )
          }
        />
      }
    </>
  )
}

export default memo(Home)
