import React from 'react'
import { useQuery, gql } from '@apollo/client'
import Spin from '../../components/Spin'
import Illustrations from '../Step3/Illustrations'

function Illustration({ data: { myClinic } }) {

  const defaultValues = {
    titleBackgroundColor: '#6b6b6b',
    titleTextColor: '#ffffff',
    titleBorderColor: '#878787'
  }

  const titleBackgroundColor = myClinic && myClinic.illustrationSettings && myClinic.illustrationSettings.titleBackgroundColor ? myClinic.illustrationSettings.titleBackgroundColor : defaultValues.titleBackgroundColor
  const titleTextColor = myClinic && myClinic.illustrationSettings && myClinic.illustrationSettings.titleTextColor ? myClinic.illustrationSettings.titleTextColor : defaultValues.titleTextColor
  const titleBorderColor = myClinic && myClinic.illustrationSettings && myClinic.illustrationSettings.titleBorderColor ? myClinic.illustrationSettings.titleBorderColor : defaultValues.titleBorderColor
  const zoom = myClinic && myClinic.illustrationSettings && myClinic.illustrationSettings.zoom ? Number(myClinic.illustrationSettings.zoom) : 2

  return (
    <div style={{ margin: '0 auto', width: 990 }}>
      <Illustrations
        gallery={[]}
        images={[]}
        onRequestClose={() => {}}
        onCompleted={src => { console.log('src', src) }}
        hideHeader
        hideAdd
        titleBackgroundColor={titleBackgroundColor}
        titleTextColor={titleTextColor}
        titleBorderColor={titleBorderColor}
        zoom={zoom}
      />
    </div>
  )
}

const IllustrationQuery = ({ id, ...rest }) => {
  const { loading, error, data } = useQuery(gql`query { myClinic { illustrationSettings } }`, { fetchPolicy: 'network-only', variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  console.log('data', data)
  return <Illustration data={data} />
}

export default IllustrationQuery
