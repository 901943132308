import { getMarkdownStyles } from '../../utils/draft'
import { normalizeGender, shortKey } from '../../utils'

import { getVariables, replaceFields, trimValues } from '../../utils/variables'
import { preparePhrases, getGroups, getSuppress } from '../../utils/steps'

import { EditorState, ContentState, ContentBlock, SelectionState, Modifier } from 'draft-js'

import { Map } from 'immutable'

const clearDisplay = text => {
  text = text.replace(/#.+?#/, '')
  text = text.replace(/<.+?>/, '')
  return text
}



const mount = (decorator, examination, docSettings, groupsSettings, zoom = 2) => {

  let { indication, selectedProtocol, filters, selectedModel, hiddenGroups, groupsCollapse, vars, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsIgnoringSelections, fields, complementFields, afterGroup, PatientName, PatientGender, PatientAge, PatientHeight, PatientWeight } = examination

  const defaultEquipment = examination.clinic && examination.clinic.defaultEquipment ? examination.clinic.defaultEquipment : ''

  if (PatientGender && (!filters || !filters['SEXO'] || (filters['SEXO'].toUpperCase() !== 'MASCULINO' && filters['SEXO'].toUpperCase() !== 'FEMININO'))) {
    filters = { ...filters, SEXO: normalizeGender(PatientGender) }
  } else if (filters && filters['SEXO']) {
    PatientGender = normalizeGender(filters['SEXO'])
  }

  const patient = {
    equipamento_padrao: defaultEquipment,
    paciente_sexo: PatientGender ? normalizeGender(PatientGender) : '',
    paciente_nome: PatientName ? PatientName : '',
    paciente_idade: PatientAge,
    paciente_altura: PatientHeight,
    paciente_peso: PatientWeight
  }

  let formulas = {}

  const initialFormulas = {
    imc: '(paciente_peso / Math.pow((paciente_altura/100), 2)).toFixed(2)',
    bsa: '(0.007184 * Math.pow(paciente_altura, 0.725) * Math.pow(paciente_peso, 0.425)).toFixed(2)'
  }

  const aftersToInsert = []

  const content = examination.list.content


  // #region declaration
  const groupsFieldsRange = []
  const cGroupsFieldsRange = []
  const nullsRanges = []
  const blanksRanges = []
  const cNullsRanges = []
  const cBlanksRanges = []
  const afterRanges = []
  let inlineStyleRanges = []
  // #endregion

  let phrases = preparePhrases(content)
  phrases = getSuppress(phrases, selectedPhrases, selectedComplementPhrases)

  const groups = getGroups(phrases, selectedProtocol, filters, selectedModel, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields)

  // groupsWithText são array de objetos com os campos group (nome do grupo) e text.
  // Nesse método ele também cria
  // afterRanges, nullsRanges, blanksRanges, cNullsRanges, cBlanksRanges
  // com os dados key (md5 do nome do grupo), anchorOffset e focusOffset
  // Aqui tb vai criando groupsFieldsRange para usar lá embaixo
  let groupsWithText = groups.reduce((acc, group) => {
    let groupText = ''

    if (group.selectedPhrase && group.selectedPhrase.text && group.selectedPhrase.text.includes('<table')) {
      groupText = group.selectedPhrase.text

      if (groupText.includes('~')) {
        formulas = groupText.split('~')[0].match(/\[#\S+?#[^\]]*/g).reduce((acc, formulaItem) => {
          const name = formulaItem.match(/\[#(\S+?)#/)[1].toLowerCase()
          if (/\[#\S+?#\s*?\(.+\)/.test(formulaItem)) {
            const formulaText = formulaItem.match(/\[#\S+?#(.+)/)[1]
            acc = { ...acc, [name]: formulaText }
          }
          return acc
        }, initialFormulas)
      }

      let variables = getVariables({ fields, patient, examination, filters, vars: trimValues(vars), formulas })
      variables = Object.entries(variables).reduce((acc, [key, value]) => ({ ...acc, [key]: (value === null || value === undefined) ? '' : value}), {})

      if (groupText.includes('~')) {
        groupText = groupText.split('~')[1]
      }

      if (groupText.includes('$$')) {
        groupText = groupText.split('$$')[1]
      }

      groupText = replaceFields(groupText, variables)

    } else if (group.selectedPhrase && group.selectedPhrase.text) {
      groupText = group.selectedPhrase.text

      if (groupText.includes('~')) {
        formulas = groupText.split('~')[0].match(/\[#\S+?#[^\]]*/g).reduce((acc, formulaItem) => {
          const name = formulaItem.match(/\[#(\S+?)#/)[1].toLowerCase()
          if (/\[#\S+?#\s*?\(.+\)/.test(formulaItem)) {
            const formulaText = formulaItem.match(/\[#\S+?#(.+)/)[1]
            acc = { ...acc, [name]: formulaText }
          }
          return acc
        }, initialFormulas)
      }

      let groupTextAfterTil = ''
      let groupTextArray = []
      if (groupText.includes('~')) {
        groupTextAfterTil = groupText.split('~')[1]
      } else {
        groupTextAfterTil = groupText
      }

      if (groupTextAfterTil.includes('$$')) {
        groupTextAfterTil = groupTextAfterTil.split('$$')[1]
      }

      groupTextArray = groupTextAfterTil.replace(/\[.*?\]/g, '^^').split('^')

      if (groupTextArray.length > 1 && group.fields) {
        Object.entries(group.fields).forEach(([key, value]) => {
          groupTextArray[key] = clearDisplay(value)
        })
      }

      const fieldsIndexes = group.fields ? Object.keys(group.fields).map(item => Number(item)) : []

      const reducedGroupTextArray = groupTextArray.reduce((acc, item, index) => {

        let variables = getVariables({ fields, patient, examination, filters, vars: trimValues(vars), formulas })
        variables = Object.entries(variables).reduce((acc, [key, value]) => ({ ...acc, [key]: (value === null || value === undefined) ? '' : value}), {})

        item = replaceFields(item, variables)

        if (fieldsIndexes.includes(index)) {
          if (item === 'NULO') {
            item = ''
            nullsRanges.push({ key: shortKey(group.name), anchorOffset: (acc.joined.length), focusOffset: acc.joined.length })
          } else if (!item || item === '' || item === null || item === 'null') {
            item = ''
            blanksRanges.push({ key: shortKey(group.name), anchorOffset: acc.joined.length, focusOffset: (acc.joined.length + item.length) })
          } else if (!!item) {
            acc.fieldsRange.push({ anchorOffset: acc.joined.length, focusOffset: (acc.joined.length + item.length) })
          }
        }

        const joined = `${acc.joined}${item}`

        return { ...acc, joined }
      }, { fieldsRange: [], joined: '' })

      groupsFieldsRange.push(...reducedGroupTextArray.fieldsRange.map(item => { return { ...item, key: shortKey(group.name) } }))

      groupText = reducedGroupTextArray.joined.trim()
    }

    // If group has complement phrases selected...
    if (!!group.selectedComplementPhrases.length) {
      // Get complement phrases, replace with Selected Inputs and return lines
      let complementText = group.selectedComplementPhrases.map((item, cIndex) => {
        const cFields = group.complementFields && group.complementFields[item.name] ? group.complementFields[item.name] : null

        let cGroupText = item.text

        if (cGroupText.includes('~')) {
          formulas = cGroupText.split('~')[0].match(/\[#\S+?#[^\]]*/g).reduce((acc, formulaItem) => {
            const name = formulaItem.match(/\[#(\S+?)#/)[1].toLowerCase()
            if (/\[#\S+?#\s*?\(.+\)/.test(formulaItem)) {
              const formulaText = formulaItem.match(/\[#\S+?#(.+)/)[1]
              acc = { ...acc, [name]: formulaText }
            }
            return acc
          }, initialFormulas)
        }

        if (cGroupText.includes('<table')) {

          let variables = getVariables({ fields, patient, examination, filters, vars: trimValues(vars), formulas })
          variables = Object.entries(variables).reduce((acc, [key, value]) => ({ ...acc, [key]: (value === null || value === undefined) ? '' : value}), {})

          if (cGroupText.includes('~')) {
            cGroupText = cGroupText.split('~')[1]
          }

          if (cGroupText.includes('$$')) {
            cGroupText = cGroupText.split('$$')[1]
          }

          cGroupText = replaceFields(cGroupText, variables)

        } else {

          let groupTextAfterTil = ''
          let cGroupTextArray = []
          if (cGroupText.includes('~')) {
            groupTextAfterTil = cGroupText.split('~')[1]
          } else {
            groupTextAfterTil = cGroupText
          }

          if (groupTextAfterTil.includes('$$')) {
            groupTextAfterTil = groupTextAfterTil.split('$$')[1]
          }

          cGroupTextArray = groupTextAfterTil.replace(/\[.*?\]/g, '^^').split('^')

          if (cGroupTextArray.length > 1 && cFields) {
            Object.entries(cFields).forEach(([key, value]) => {
              cGroupTextArray[key] = clearDisplay(value)
            })
          }

          const cFieldsIndexes = cFields ? Object.keys(cFields).map(item => Number(item)) : []

          const reducedCGroupTextArray = cGroupTextArray.reduce((acc, item, index) => {
            let variables = getVariables({ fields, patient, examination, filters, vars: trimValues(vars), formulas })
            variables = Object.entries(variables).reduce((acc, [key, value]) => ({ ...acc, [key]: (value === null || value === undefined) ? '' : value}), {})

            item = replaceFields(item, variables)

            if (cFieldsIndexes.includes(index)) {
              if (item === 'NULO') {
                cNullsRanges.push({ key: shortKey(group.name), anchorOffset: acc.joined.length, focusOffset: (acc.joined.length + item.length) })
              }
              if (!item || item === '' || item === null || item === 'null') {
                cBlanksRanges.push({ key: shortKey(group.name), anchorOffset: groupText.length + acc.joined.length + cIndex, focusOffset: (groupText.length + acc.joined.length + item.length + cIndex) })
              }
              acc.fieldsRange.push({ anchorOffset: groupText.length + acc.joined.length + cIndex, focusOffset: (groupText.length + acc.joined.length + item.length + cIndex) })
            }

            const joined = `${acc.joined}${item}`

            return { ...acc, joined }
          }, { fieldsRange: [], joined: '' })

          cGroupsFieldsRange.push(...reducedCGroupTextArray.fieldsRange.map(item => { return { ...item, key: shortKey(group.name) } }))

          cGroupText = reducedCGroupTextArray.joined
        }

        return cGroupText
        // TODO \n to block
      }).join(`\n`)

      complementText = complementText.replace(/\s?NULO/g, '')

      // TODO \n to block
      groupText = groupText ? `${groupText}\n${complementText}` : complementText
    }

    if (afterGroup && afterGroup[group.name]) {

      afterGroup[group.name].split(`\n`).forEach(line => {
        aftersToInsert.push({ name: group.name, text: line })
      })

    }

    return [...acc, { group: group.name, text: groupText }]
  }, [])


  const inserts = []

  const selectedGroupsNames = groupsWithText.map(item => item.group)

  const titleFound = groupsWithText.find(item => item.group === 'TÍTULO')
  const titleText = titleFound ? titleFound.text : ''
  let titleKey = shortKey('TÍTULO')

  let titleStyle = {}
  if (docSettings.titleAlign) titleStyle = { ...titleStyle, textAlign: docSettings.titleAlign }
  if (docSettings.titleFontSize) titleStyle = { ...titleStyle, fontSize: docSettings.titleFontSize }

  const initialBlockArray = titleText ? [
    new ContentBlock({ key: titleKey, type: 'unstyled', text: titleFound.text, data: Map(titleStyle) }), // 'fontSize': '18'
    new ContentBlock({ key: `${titleKey}--B`, type: 'unstyled', text: '', data: Map({}) })
  ] : []

  const groupsNames = Object.keys(groupsSettings)
  const orderedGroupsNames = groupsNames.sort((a, b) => groupsSettings[a].order - groupsSettings[b].order)

  let organizedGroups = orderedGroupsNames.map(name => {

    const foundGroup = groupsWithText.find(item => item.group === name)

    let text = foundGroup && foundGroup.text ? foundGroup.text : ''

    if (!foundGroup && name === 'INDICAÇÃO CLÍNICA' && indication) {
      text = indication
    }

    // if (name === 'INDICAÇÃO CLÍNICA' && indication) text += ` ${indication}`

    return {
      key: shortKey(name),
      name,
      text,
      ...groupsSettings[name]
    }
  })

  // organizedGroups = organizedGroups.filter(item => (item.active && (item.name === 'ANÁLISE' || item.text)))
  organizedGroups = organizedGroups.filter(item => (item.active && (item.name === 'ANÁLISE' || selectedGroupsNames.includes(item.name)  || item.name === 'INDICAÇÃO CLÍNICA')))

  const formatedBlockArray = organizedGroups.reduce((acc, item) => {

    const { text, styles } = getMarkdownStyles(item.text)
    item.text = text

    if (item.formatting === 'WITHBREAK') {

      if (item.title) {
        acc = [...acc, new ContentBlock({ key: `${item.key}--T`, type: 'unstyled', text: item.title, data: Map({}) })]
        if (item.titleIsBold) inlineStyleRanges.push({ style: 'BOLD', key: `${item.key}--T`, anchorOffset: 0, focusOffset: item.title.length })
      }

      styles.forEach(style => {
        inlineStyleRanges.push({ style: style.style, key: shortKey(item.name), anchorOffset: style.start, focusOffset: style.end })
      })

    } else if (item.formatting === 'SAMELINE') {

      inserts.push({ key: item.key, title: item.title })
      if (item.titleIsBold) inlineStyleRanges.push({ style: 'BOLD', key: item.key, anchorOffset: 0, focusOffset: item.title.length })

      styles.forEach(style => {
        inlineStyleRanges.push({ style: style.style, key: shortKey(item.name), anchorOffset: style.start + item.title.length, focusOffset: style.end + item.title.length })
      })

    }

    if (['INDICAÇÃO CLÍNICA', 'INTRODUÇÃO', 'IMPRESSÃO', 'OBSERVAÇÃO', 'NOTA', 'TABELAS E ILUSTRAÇÕES'].includes(item.name)) {

      if (item.text.includes('<table')) {
        acc = [...acc, new ContentBlock({ key: item.key, type: 'image', data: Map({ src: `https://p.telerison.com/pup?z=${zoom}&c=.left%20%7B%0A%20%20text-align%3A%20left%3B%0A%7D%0A.right%20%7B%0A%20%20text-align%3A%20right%3B%0A%7D%0A.justify%20%7B%0A%20%20text-align%3A%20justify%3B%0A%7D%0A.center%20%7B%0A%20%20text-align%3A%20center%3B%0A%7D%0A.small%20%7B%0A%20%20font-size%3A%209px%3B%20text-align%3A%20justify%3B%0A%7D%0Atable%20%7B%0A%20%20color%3A%20black%3B%0A%20%20font-family%3A%20Arial%2C%20sans-serif%3B%0A%20%20font-size%3A%2011px%3B%0A%20%20width%3A%20648px%3B%0A%20%20border-collapse%3A%20collapse%3B%0A%20%20border-spacing%3A%200%3B%0A%7D%0Atd%2C%20th%20%7B%0A%20%20padding%3A%204px%3B%0A%20%20border%3A%201px%20solid%20black%3B%0A%20%20background%3A%20white%3B%0A%7D%0Ath%20%7B%0A%20%20text-align%3A%20center%3B%0A%20%20font-weight%3A%20bold%3B%0A%7D%0Atr%3Anth-child(even)%20td%20%7B%0A%20%20background%3A%20white%3B%0A%7D%0A.bmk%20%7B%0A%20%20padding%3A%203px%3B%0A%20%20background-color%3A%20%23476A91%3B%0A%20%20border-top%3A%204px%20solid%20%23D1783D%3B%0A%20%20border-bottom%3A%204px%20solid%20%23D1783D%3B%0A%20%20color%3A%20white%3B%0A%20%20border-right%3A%201px%20solid%20%23476A91%3B%0A%20%20border-left%3A%201px%20solid%20%23476A91%3B%0A%20%20text-align%3A%20center%3B%0A%7D&h=${encodeURIComponent(item.text)}` }) })]
        acc = [...acc, new ContentBlock({ key: `${item.key}--B`, type: 'unstyled', text: '', data: Map({}) })]
      } else if (item.text && item.text.trim()) {
        acc = [...acc, new ContentBlock({ key: item.key, type: 'unstyled', text: item.text, data: Map({}) })]

        if (!aftersToInsert.find(after => after.name === item.name)) {
          acc = [...acc, new ContentBlock({ key: `${item.key}--B`, type: 'unstyled', text: '', data: Map({}) })]
        }
      }

      aftersToInsert.filter(after => (after.name === item.name)).forEach((after, index) => {
        const afterKey = `${item.key}--A-${index + 1}`
        acc = [...acc, new ContentBlock({ key: afterKey, type: 'unstyled', text: after.text, data: Map({}) })]
        afterRanges.push({ key: afterKey, anchorOffset: 0, focusOffset: after.text.length })
      })
      if (aftersToInsert.filter(after => (after.name === item.name)).length > 0) {
        acc = [...acc, new ContentBlock({ key: `${item.key}--AB`, type: 'unstyled', text: '', data: Map({}) })]
      }

    } else if (item.name === 'ANÁLISE') {
      // const newBlocksFiltered = groupsWithText.filter(filterItem => (!['TÍTULO', 'INDICAÇÃO CLÍNICA', 'INTRODUÇÃO', 'IMPRESSÃO', 'OBSERVAÇÃO', 'TABELAS E ILUSTRAÇÕES', 'NOTA'].includes(filterItem.group) && (filterItem.text && filterItem.text.trim() !== '') ))
      const newBlocksFiltered = groupsWithText.filter(filterItem => (!['TÍTULO', 'INDICAÇÃO CLÍNICA', 'INTRODUÇÃO', 'IMPRESSÃO', 'OBSERVAÇÃO', 'TABELAS E ILUSTRAÇÕES', 'NOTA'].includes(filterItem.group) ))

      const newBlocks = newBlocksFiltered.reduce((acc, filteredItem) => {
        const key = shortKey(filteredItem.group)
        const { text, styles } = getMarkdownStyles(filteredItem.text)
        styles.forEach(style => {
          inlineStyleRanges.push({ style: style.style, key, anchorOffset: style.start, focusOffset: style.end })
        })
        if (text.includes('<table')) {

          if (text.match(/<table>.+?<\/table>/g)) {
            text.match(/<table>.+?<\/table>/g).forEach((item, index) => {
              acc = [...acc, new ContentBlock({ key: `${key}--T${index}`, type: 'image', data: Map({ src: `https://p.telerison.com/pup?z=${zoom}&c=.left%20%7B%0A%20%20text-align%3A%20left%3B%0A%7D%0A.right%20%7B%0A%20%20text-align%3A%20right%3B%0A%7D%0A.justify%20%7B%0A%20%20text-align%3A%20justify%3B%0A%7D%0A.center%20%7B%0A%20%20text-align%3A%20center%3B%0A%7D%0A.small%20%7B%0A%20%20font-size%3A%209px%3B%20text-align%3A%20justify%3B%0A%7D%0Atable%20%7B%0A%20%20color%3A%20black%3B%0A%20%20font-family%3A%20Arial%2C%20sans-serif%3B%0A%20%20font-size%3A%2011px%3B%0A%20%20width%3A%20648px%3B%0A%20%20border-collapse%3A%20collapse%3B%0A%20%20border-spacing%3A%200%3B%0A%7D%0Atd%2C%20th%20%7B%0A%20%20padding%3A%204px%3B%0A%20%20border%3A%201px%20solid%20black%3B%0A%20%20background%3A%20white%3B%0A%7D%0Ath%20%7B%0A%20%20text-align%3A%20center%3B%0A%20%20font-weight%3A%20bold%3B%0A%7D%0Atr%3Anth-child(even)%20td%20%7B%0A%20%20background%3A%20white%3B%0A%7D%0A.bmk%20%7B%0A%20%20padding%3A%203px%3B%0A%20%20background-color%3A%20%23476A91%3B%0A%20%20border-top%3A%204px%20solid%20%23D1783D%3B%0A%20%20border-bottom%3A%204px%20solid%20%23D1783D%3B%0A%20%20color%3A%20white%3B%0A%20%20border-right%3A%201px%20solid%20%23476A91%3B%0A%20%20border-left%3A%201px%20solid%20%23476A91%3B%0A%20%20text-align%3A%20center%3B%0A%7D&h=${encodeURIComponent(item)}` }) })]
              acc = [...acc, new ContentBlock({ key: `${key}--B`, type: 'unstyled', text: '', data: Map({}) })]
            })
          } else {
            acc = [...acc, new ContentBlock({ key, type: 'image', data: Map({ src: `https://p.telerison.com/pup?z=${zoom}&c=.left%20%7B%0A%20%20text-align%3A%20left%3B%0A%7D%0A.right%20%7B%0A%20%20text-align%3A%20right%3B%0A%7D%0A.justify%20%7B%0A%20%20text-align%3A%20justify%3B%0A%7D%0A.center%20%7B%0A%20%20text-align%3A%20center%3B%0A%7D%0A.small%20%7B%0A%20%20font-size%3A%209px%3B%20text-align%3A%20justify%3B%0A%7D%0Atable%20%7B%0A%20%20color%3A%20black%3B%0A%20%20font-family%3A%20Arial%2C%20sans-serif%3B%0A%20%20font-size%3A%2011px%3B%0A%20%20width%3A%20648px%3B%0A%20%20border-collapse%3A%20collapse%3B%0A%20%20border-spacing%3A%200%3B%0A%7D%0Atd%2C%20th%20%7B%0A%20%20padding%3A%204px%3B%0A%20%20border%3A%201px%20solid%20black%3B%0A%20%20background%3A%20white%3B%0A%7D%0Ath%20%7B%0A%20%20text-align%3A%20center%3B%0A%20%20font-weight%3A%20bold%3B%0A%7D%0Atr%3Anth-child(even)%20td%20%7B%0A%20%20background%3A%20white%3B%0A%7D%0A.bmk%20%7B%0A%20%20padding%3A%203px%3B%0A%20%20background-color%3A%20%23476A91%3B%0A%20%20border-top%3A%204px%20solid%20%23D1783D%3B%0A%20%20border-bottom%3A%204px%20solid%20%23D1783D%3B%0A%20%20color%3A%20white%3B%0A%20%20border-right%3A%201px%20solid%20%23476A91%3B%0A%20%20border-left%3A%201px%20solid%20%23476A91%3B%0A%20%20text-align%3A%20center%3B%0A%7D&h=${encodeURIComponent(text)}` }) })]
            acc = [...acc, new ContentBlock({ key: `${key}--B`, type: 'unstyled', text: '', data: Map({}) })]
          }

        } else {
          if (text && text.trim()) {
            acc = [...acc, new ContentBlock({ key, type: 'unstyled', text, data: Map({}) })]
          }
        }

        aftersToInsert.filter(after => (after.name === filteredItem.group)).forEach((after, index) => {
          acc = [...acc, new ContentBlock({ key: `${key}--A-${index + 1}`, type: 'unstyled', text: after.text, data: Map({}) })]
          afterRanges.push({ key: `${key}--A-${index + 1}`, anchorOffset: 0, focusOffset: after.text.length })
        })

        return acc
      }, [])

      acc = [...acc, ...newBlocks, new ContentBlock({ key: `${shortKey('ANÁLISE')}--B`, type: 'unstyled', text: '', data: Map({}) })]
    }

    return acc

  }, initialBlockArray)



  let newContentState = ContentState.createFromBlockArray(formatedBlockArray)

  newContentState = newContentState.createEntity('FIELD', 'MUTABLE')
  const lastCreatedFieldEntityKey = newContentState.getLastCreatedEntityKey()
  newContentState = newContentState.createEntity('NULO', 'IMMUTABLE')
  const lastCreatedNuloEntityKey = newContentState.getLastCreatedEntityKey()
  newContentState = newContentState.createEntity('BLANK', 'IMMUTABLE')
  const lastCreatedBlankEntityKey = newContentState.getLastCreatedEntityKey()
  newContentState = newContentState.createEntity('AFTER', 'MUTABLE')
  const lastCreatedAfterEntityKey = newContentState.getLastCreatedEntityKey()

  afterRanges.forEach(item => {
    const selectionState = SelectionState.createEmpty(item.key).merge({ focusKey: item.key, anchorOffset: item.anchorOffset, focusOffset: item.focusOffset })
    try {
      newContentState = Modifier.applyEntity(newContentState, selectionState, lastCreatedAfterEntityKey)
    } catch(err) {
      console.error(err)
    }
  })

  groupsFieldsRange.forEach(item => {
    const selectionState = SelectionState.createEmpty(item.key).merge({ focusKey: item.key, anchorOffset: item.anchorOffset, focusOffset: item.focusOffset })
    try {
      newContentState = Modifier.applyEntity(newContentState, selectionState, lastCreatedFieldEntityKey)
    } catch(err) {
      console.error(err)
    }
  })

  nullsRanges.forEach(item => {
    const rangeToAdd = SelectionState.createEmpty(item.key).merge({ focusKey: item.key, anchorOffset: item.anchorOffset, focusOffset: item.anchorOffset })
    newContentState = Modifier.insertText(newContentState, rangeToAdd, String.fromCharCode(8203), null, lastCreatedNuloEntityKey)
  })

  blanksRanges.forEach(item => {
    const rangeToAdd = SelectionState.createEmpty(item.key).merge({ focusKey: item.key, anchorOffset: item.anchorOffset, focusOffset: item.anchorOffset })
    newContentState = Modifier.insertText(newContentState, rangeToAdd, String.fromCharCode(8203), null, lastCreatedBlankEntityKey)
  })

  inserts.forEach((item) => {
    if (newContentState.getBlockForKey(item.key)) {
      const titleKey ='dea7b'
      const rangeToAdd = SelectionState.createEmpty(item.key).merge({ focusKey: item.key, anchorOffset: 0, focusOffset: 0 })
      if (docSettings.titleIsBold && item.key === titleKey) {
        newContentState = Modifier.insertText(newContentState, rangeToAdd, item.title)
        inlineStyleRanges.push({ style: 'BOLD', key: titleKey, anchorOffset: 0, focusOffset: (titleText.length + item.title.length) })
      } else {
        newContentState = Modifier.insertText(newContentState, rangeToAdd, item.title)
      }
    } else if (newContentState.getBlockForKey(`${item.key}--A-1`)) {
      const rangeToAdd = SelectionState.createEmpty(`${item.key}--A-1`).merge({ focusKey: `${item.key}--A-1`, anchorOffset: 0, focusOffset: 0 })
      newContentState = Modifier.insertText(newContentState, rangeToAdd, item.title)
    }
  })

  // inlineStyleRanges.filter(item => !item.key.includes('--T')).forEach(item => {
  inlineStyleRanges.forEach(item => {
    if (newContentState.getBlockForKey(item.key)) {
      const selectionState = SelectionState.createEmpty(item.key).merge({ focusKey: item.key, anchorOffset: item.anchorOffset, focusOffset: item.focusOffset })
      try {
        newContentState = Modifier.applyInlineStyle(newContentState, selectionState, item.style)
      } catch(err) {
        console.error(err)
      }
    } else if (newContentState.getBlockForKey(`${item.key}--A-1`)) {
      const selectionState = SelectionState.createEmpty(`${item.key}--A-1`).merge({ focusKey: `${item.key}--A-1`, anchorOffset: item.anchorOffset, focusOffset: item.focusOffset })
      try {
        newContentState = Modifier.applyInlineStyle(newContentState, selectionState, item.style)
      } catch(err) {
        console.error(err)
      }
    }
  })

  return EditorState.createWithContent(newContentState, decorator)
}

export default mount
