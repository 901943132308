import React, { memo, useState, useCallback } from 'react'
import { Link } from '@reach/router'
import { useSpring, animated } from 'react-spring'
import { css } from 'styled-components/macro'
import { Flex, Box } from '@alobato/flex-box'
import omitBy from 'lodash.omitby'
import isEqual from 'lodash.isequal'
import uniq from 'lodash.uniq'
import Text from '@alobato/text'
import Checkbox from '@alobato/checkbox'
import Viewer from 'react-viewer'

import { FormatUtcDate } from '../../components/Format'
import Spin from '../../components/Spin'
import PopOver from '../../components/PopOver'
import { LockOutlineIcon, CloseIcon, PlaylistEditIcon, SettingsIcon, CloseIcon2, FileEyeOutlineIcon, AttachFileIcon, HistoryIcon, NoteTextOutline } from '../../components/Icons'
import Wrapper from '../../components/Wrapper'
import Card from '../../components/Card'
import Title from '../../components/Title'
import Label from '../../components/Label'
import Hr from '../../components/Hr'
import Select from '../../components/Select'
import SplitPane from '../../components/SplitPane'
import SelectBlocksAndPhrases, { Option } from '../../components/SelectBlocksAndPhrases'
import SelectPhrases from '../../components/SelectPhrases'
import SelectComplementPhrases from '../../components/SelectComplementPhrases'
import Modal from '../../components/StyledModal'
import MultiCheck from '../../components/_new/MultiCheck'

import { camelize, mobileDetect, normalizeGender } from '../../utils'
import { getVariables, trimValues } from '../../utils/variables'
import { preparePhrases, getModels, getGroups, getGroups2, getBlocksByGroup, getBlocksByGroup2, getPhrasesByGroup, getPhrasesByGroup2, getSuppress } from '../../utils/steps'

import useDidMount from '../../hooks/useDidMount'
import useIgnoreMount from '../../hooks/useIgnoreMount'
import useOnScreen from '../../hooks/useOnScreen'

import Fields from './Fields'

import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from '@apollo/client'
import { GET, UPDATE } from './Gqls'

// const cleanPhraseItem = item => ({ id: item._id, text: item.text, name: item.name, block: item.block, group: item.group, fields: {}, complementFields: {}, color: '' })

function cleanPhraseItem(item) {
  let text = item.text
  if (text && !text.includes('$$') && !text.includes('^') && item.textCodes && item.textCodes.length > 0) {
    text = `${item.textCodes.join(',')}$$${item.text}`
  }
  return { id: item._id, text: text, name: item.name, block: item.block, group: item.group, fields: {}, complementFields: {}, color: '' }
}


const getPhraseByCode = (phrases, code) => {
  const phrase = phrases.find(p => p._id === code)
  return phrase ? phrase : null
}

function replaceAttachmentUrl(url) {
  if (!url) return ''
  if (url.includes('https://s3.amazonaws.com/telerison.a/')) return url
  let fileName
  if (url.includes('https://telerison.a.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.a.s3.amazonaws.com/', '')
  } else if (url.includes('https://telerison.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.s3.amazonaws.com/', '')
  }
  return `https://s3.amazonaws.com/telerison.a/${fileName}`
}

const Home = memo(({ data: { examination, previousExaminations }, code, me }) => {

  const { t } = useTranslation()

  const visibleRef = React.useRef()
  const isVisible = useOnScreen(visibleRef)

  const substitutions = me.preferences && me.preferences.speechRecognition && me.preferences.speechRecognition.substitutions ? me.preferences.speechRecognition.substitutions : []

  const [drawerOpen, setDrawerOpen] = useState(false)
  const props = useSpring({ right: drawerOpen ? 0 : -280 })

  const [viewerDrawerOpen, setViewerDrawerOpen] = useState(false)
  const viewerTabProps = useSpring({ right: viewerDrawerOpen ? 0 : -780 })

  const [modelsDrawerOpen, setModelsDrawerOpen] = useState(false)
  const modelsTabProps = useSpring({ left: modelsDrawerOpen ? 0 : -350 })

  const [currentExaminationView, setCurrentExaminationView] = useState(null)
  const [previousExaminationsDrawerOpen, setPreviousExaminationsDrawerOpen] = useState(false)
  const previousExaminationsDrawerProps = useSpring({ right: previousExaminationsDrawerOpen ? 0 : -780 })

  let signed = (examination.signedAt || (examination.status && examination.status.signed)) ? true : false
  // if (!examination.correctedAt && examination.underCorrectionAt) signed = false

  const { isMobile } = mobileDetect(navigator.userAgent)

  const [autoTabEnabled, setAutoTabEnabled] = useState(false)
  const [showSubtitlesEnabled, setShowSubtitlesEnabled] = useState(false)

  const [fieldsModalOpen, setFieldsModalOpen] = useState(false)

  const viewerContainerRef = React.useRef(null)

  const [updateExamination] = useCallback(useMutation(UPDATE), [UPDATE])

  const save = useCallback(({ variables }) => {
    console.log('---save', variables)
    if (signed || !me.isDoctor) return false
    const steps = (examination.status && examination.status.steps) ? examination.status.steps : []
    const newSteps = steps.includes(2) ? steps : [...steps, 2]
    const newStatus = {...(examination.status || {}), steps: newSteps }
    updateExamination({ variables: {...variables, status: newStatus }})
  }, [updateExamination, examination.status, signed, me.isDoctor])

  let { selectedProtocol, selectedProtocols, filters, selectedModel: propsSelectedModel, selectedModels: propsSelectedModels, hiddenGroups: propsHiddenGroups, groupsCollapse: propsGroupsCollapse, vars: propsVars, selectedBlocks: propsSelectedBlocks, selectedPhrases: propsSelectedPhrases, selectedComplementPhrases: propsSelectedComplementPhrases, groupsIgnoringSelections: propsGroupsIgnoringSelections, fields: propsFields, afterGroup: propsAfterGroup, complementFields: propsComplementFields, PatientName, PatientGender, PatientAge, PatientHeight, PatientWeight } = examination

  const steps = (examination.status && examination.status.steps) ? examination.status.steps : []

  if (PatientGender && (!filters || !filters['SEXO'] || (filters['SEXO'].toUpperCase() !== 'MASCULINO' && filters['SEXO'].toUpperCase() !== 'FEMININO'))) {
    filters = { ...filters, SEXO: normalizeGender(PatientGender) }
  } else if (filters && filters['SEXO']) {
    PatientGender = normalizeGender(filters['SEXO'])
  }

  const patient = {
    equipamento_padrao: examination.clinic && examination.clinic.defaultEquipment ? examination.clinic.defaultEquipment : '',
    paciente_sexo: PatientGender ? normalizeGender(PatientGender) : '',
    paciente_nome: PatientName ? PatientName : '',
    paciente_idade: PatientAge,
    paciente_altura: PatientHeight,
    paciente_peso: PatientWeight
  }

  let phrases = preparePhrases(examination.list.content)

  const [previewAttachments, setPreviewAttachments] = useState((examination.orderDetails && examination.orderDetails.attachments) ? (examination.orderDetails.attachments || []).map(attachment => ({ src: replaceAttachmentUrl(attachment.url) })) : [])

  const [selectedModel, setSelectedModel] = useState(propsSelectedModel || '')
  const [selectedModels, setSelectedModels] = useState(propsSelectedModels || '')
  const [hiddenGroups, setHiddenGroups] = useState(propsHiddenGroups || [])
  const [groupsCollapse, setGroupsCollapse] = useState(propsGroupsCollapse || {})
  const [vars, setVars] = useState(propsVars || {})
  const [selectedBlocks, setSelectedBlocks] = useState(propsSelectedBlocks || {})
  const [selectedPhrases, setSelectedPhrases] = useState(propsSelectedPhrases || {})
  const [selectedComplementPhrases, setSelectedComplementPhrases] = useState(propsSelectedComplementPhrases || {})
  const [groupsIgnoringSelections, setGroupsIgnoringSelections] = useState(propsGroupsIgnoringSelections || [])
  const [fields, setFields] = useState(propsFields || {})
  const [complementFields, setComplementFields] = useState(propsComplementFields || {})
  const [currentFocusedGroup, setCurrentFocusedGroup] = useState('')
  const [complementGroupsBlurred, setComplementGroupsBlurred] = useState([])
  const [afterGroup, setAfterGroup] = useState(propsAfterGroup || {})

  phrases = getSuppress(phrases, selectedPhrases, selectedComplementPhrases)

  // if (examination.tree === 'MODALIDADE DE TESTE;GERAL;GERAL;TESTE') {
  //   selectedProtocol = selectedProtocols
  // }
  // const models = selectedProtocols && selectedProtocols.length > 0 ? getModels(phrases, selectedProtocols, filters) : getModels(phrases, selectedProtocol, filters)
  const models = getModels(phrases, selectedProtocols, filters)
  // const groups = selectedProtocols && selectedProtocols.length > 0 ? getGroups2(phrases, selectedProtocols, filters, selectedModels, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields) : getGroups(phrases, selectedProtocol, filters, selectedModel, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields)
  let groups = getGroups2(phrases, selectedProtocols, filters, selectedModels, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields)

  const defaultModels = models.filter(model => !model.includes('@'))
  const exclusiveModels = models.filter(model => model.includes('@'))

  useDidMount(() => {
    if (!steps.includes(2)) {

      let variablesToSave = { code }

      let newSelectedPhrases = { ...selectedPhrases }
      const newSelectedBlocks = { ...selectedBlocks }

      // const groups = selectedProtocols && selectedProtocols.length > 0 ? getGroups2(phrases, selectedProtocols, filters, selectedModels, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields) : getGroups(phrases, selectedProtocol, filters, selectedModel, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields)
      const groups = getGroups2(phrases, selectedProtocols, filters, selectedModels, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields)

      for (const group of groups) {
        // const blocks = selectedProtocols && selectedProtocols.length > 0 ? getBlocksByGroup2(phrases, group.name, selectedProtocols, filters, selectedModels) : getBlocksByGroup(phrases, group.name, selectedProtocol, filters, selectedModel)
        const blocks = getBlocksByGroup2(phrases, group.name, selectedProtocols, filters, selectedModels)

        if (blocks.length === 1) {
          newSelectedBlocks[group.name] = blocks[0]

          // const phrasesByGroup = selectedProtocols && selectedProtocols.length > 0 ? getPhrasesByGroup2(phrases, group.name, blocks[0], selectedProtocols, filters, selectedModel) : getPhrasesByGroup(phrases, group.name, blocks[0], selectedProtocol, filters, selectedModel)
          const phrasesByGroup = getPhrasesByGroup2(phrases, group.name, blocks[0], selectedProtocols, filters, selectedModels)

          if (phrasesByGroup.length === 1) {

            // -----------------
            let newSelectedComplementPhrases = { ...selectedComplementPhrases }
            const value = phrasesByGroup[0]
            let hasComplementPhraseBind = false
            if (value.bind && value.bind.length > 0) {
              for (const code of value.bind) {
                const phrase = getPhraseByCode(phrases, code)
                if (phrase) {

                  const isComplement = phrase.block && phrase.block.split(' ').includes('COMPLEMENTO')
                  if (isComplement) {
                    hasComplementPhraseBind = true
                    let groupSelectedComplementPhrases

                    if (newSelectedComplementPhrases[phrase.group]) {

                      if (!newSelectedComplementPhrases[phrase.group].map((item) => item.id).includes(phrase._id)) {
                        // adiciona
                        groupSelectedComplementPhrases = [...newSelectedComplementPhrases[phrase.group], cleanPhraseItem(phrase)]

                        newSelectedComplementPhrases = {...newSelectedComplementPhrases, [phrase.group]: groupSelectedComplementPhrases}
                        newSelectedComplementPhrases = omitBy(newSelectedComplementPhrases, v => (v === null || v === undefined || ((v instanceof Array) && v.length === 0) ))
                      }

                    } else {
                      groupSelectedComplementPhrases = [cleanPhraseItem(phrase)]
                      newSelectedComplementPhrases = {...newSelectedComplementPhrases, [phrase.group]: groupSelectedComplementPhrases}
                      newSelectedComplementPhrases = omitBy(newSelectedComplementPhrases, v => (v === null || v === undefined || ((v instanceof Array) && v.length === 0) ))
                    }
                  } else {
                    newSelectedPhrases = selectPhrase(phrase, newSelectedPhrases)
                  }

                }
              }
            }

            if (hasComplementPhraseBind) {
              setSelectedComplementPhrases(newSelectedComplementPhrases)
              variablesToSave = { ...variablesToSave, selectedComplementPhrases: newSelectedComplementPhrases }
            }
            // -----------------

            newSelectedPhrases[group.name] = cleanPhraseItem(phrasesByGroup[0])
          }
        }
      }

      setSelectedBlocks(newSelectedBlocks)
      setSelectedPhrases(newSelectedPhrases)

      variablesToSave = { ...variablesToSave, selectedBlocks: newSelectedBlocks, selectedPhrases: newSelectedPhrases }

      // save({ variables: { code, selectedBlocks: newSelectedBlocks, selectedPhrases: newSelectedPhrases } })
      save({ variables: variablesToSave })

    }
  })

  // const didMountComplementFieldsRef = useRef(false)
	// useEffect(() => {
  //   if (didMountComplementFieldsRef.current)
  //     save({ variables: { code, complementFields } })
  //   else
  //   didMountComplementFieldsRef.current = true
  // }, [code, complementFields, save])

  // const didMountFieldsRef = useRef(false)
  // useEffect(() => {
  //   if (didMountFieldsRef.current)
  //     save({ variables: { code, fields } })
  //   else
  //   didMountFieldsRef.current = true
  // }, [code, fields, save])

  // const didMountAfterGroupRef = useRef(false)
  // useEffect(() => {
  //   if (didMountAfterGroupRef.current)
  //     save({ variables: { code, afterGroup } })
  //   else
  //     didMountAfterGroupRef.current = true
  // }, [code, afterGroup, save])

  useIgnoreMount(complementFields, newComplementFields => {
    save({ variables: { code, complementFields: newComplementFields }})
  })

  useIgnoreMount(fields, newFields => {
    save({ variables: { code, fields: newFields }})
  })

  useIgnoreMount(afterGroup, newAfterGroup => {
    save({ variables: { code, afterGroup: newAfterGroup }})
  })

  useIgnoreMount(vars, (newVars) => {
    save({ variables: { code, vars: newVars }})
  })

  const handleSelectModel = element => {
    if (signed) return false

    const newSelectedModel = element.target.value
    const newSelectedPhrases = {}
    const newSelectedBlocks = {}

    const groups = selectedProtocols && selectedProtocols.length > 0 ? getGroups2(phrases, selectedProtocols, filters, newSelectedModel, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields) : getGroups(phrases, selectedProtocol, filters, newSelectedModel, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields)

    groups.forEach(group => {
      const blocks = selectedProtocols && selectedProtocols.length > 0 ? getBlocksByGroup2(phrases, group.name, selectedProtocols, filters, newSelectedModel) : getBlocksByGroup(phrases, group.name, selectedProtocol, filters, newSelectedModel)
      if (blocks.length === 1) {
        newSelectedBlocks[group.name] = blocks[0]
        const phrasesByGroup = selectedProtocols && selectedProtocols.length > 0 ? getPhrasesByGroup2(phrases, group.name, blocks[0], selectedProtocols, filters, newSelectedModel) : getPhrasesByGroup(phrases, group.name, blocks[0], selectedProtocol, filters, newSelectedModel)
        if (phrasesByGroup.length === 1)
          newSelectedPhrases[group.name] = cleanPhraseItem(phrasesByGroup[0])
      }
    })

    setSelectedModel(newSelectedModel)
    setHiddenGroups([])
    setGroupsCollapse({})
    setSelectedBlocks(newSelectedBlocks)
    setSelectedPhrases(newSelectedPhrases)
    setSelectedComplementPhrases({})
    setGroupsIgnoringSelections([])
    setFields({})

    save({ variables: { code, selectedModel: newSelectedModel, hiddenGroups: [], groupsCollapse: {}, selectedBlocks: newSelectedBlocks, selectedPhrases: newSelectedPhrases, selectedComplementPhrases: {}, groupsIgnoringSelections: [], fields: {}, complementFields: {} } })
  }

  const handleSelectModels = value => {
    if (signed) return false

    const newSelectedModels = value
    let newSelectedPhrases = {}
    const newSelectedBlocks = {}
    const newFields = {}
    let newSelectedComplementPhrases = {}

    const groups = getGroups2(phrases, selectedProtocols, filters, newSelectedModels, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields)

    groups.forEach(group => {
      const blocks = getBlocksByGroup2(phrases, group.name, selectedProtocols, filters, newSelectedModels)
      if (blocks.length === 1) {
        newSelectedBlocks[group.name] = blocks[0]
        const phrasesByGroup = getPhrasesByGroup2(phrases, group.name, blocks[0], selectedProtocols, filters, newSelectedModels)
        if (phrasesByGroup.length === 1) {

          // -----------------
          const value = phrasesByGroup[0]
          let hasComplementPhraseBind = false
          if (value.bind && value.bind.length > 0) {
            for (const code of value.bind) {
              const phrase = getPhraseByCode(phrases, code)
              if (phrase) {

                const isComplement = phrase.block && phrase.block.split(' ').includes('COMPLEMENTO')
                if (isComplement) {
                  hasComplementPhraseBind = true
                  let groupSelectedComplementPhrases

                  if (newSelectedComplementPhrases[phrase.group]) {

                    if (!newSelectedComplementPhrases[phrase.group].map((item) => item.id).includes(phrase._id)) {
                      // adiciona
                      groupSelectedComplementPhrases = [...newSelectedComplementPhrases[phrase.group], cleanPhraseItem(phrase)]

                      newSelectedComplementPhrases = {...newSelectedComplementPhrases, [phrase.group]: groupSelectedComplementPhrases}
                      newSelectedComplementPhrases = omitBy(newSelectedComplementPhrases, v => (v === null || v === undefined || ((v instanceof Array) && v.length === 0) ))
                    }

                  } else {
                    groupSelectedComplementPhrases = [cleanPhraseItem(phrase)]
                    newSelectedComplementPhrases = {...newSelectedComplementPhrases, [phrase.group]: groupSelectedComplementPhrases}
                    newSelectedComplementPhrases = omitBy(newSelectedComplementPhrases, v => (v === null || v === undefined || ((v instanceof Array) && v.length === 0) ))
                  }
                } else {
                  newSelectedPhrases = selectPhrase(phrase, newSelectedPhrases)
                }

              }
            }
          }

          if (hasComplementPhraseBind) {
            setSelectedComplementPhrases(newSelectedComplementPhrases)
          }
          // -----------------

          newSelectedPhrases[group.name] = cleanPhraseItem(phrasesByGroup[0])
        } else if (selectedPhrases[group.name]) {
          newSelectedPhrases[group.name] = selectedPhrases[group.name]
        }
        if (fields[group.name]) {
          newFields[group.name] = fields[group.name]
        }
      } else if (blocks.length > 1 && selectedPhrases[group.name]) {
        newSelectedPhrases[group.name] = selectedPhrases[group.name]
        if (fields[group.name]) {
          newFields[group.name] = fields[group.name]
        }
      }
    })

    setSelectedModels(newSelectedModels)
    setHiddenGroups([])
    setGroupsCollapse({})
    setSelectedBlocks(newSelectedBlocks)
    setSelectedPhrases(newSelectedPhrases)
    setSelectedComplementPhrases(newSelectedComplementPhrases)
    setGroupsIgnoringSelections([])
    setFields(newFields)

    save({ variables: { code, selectedModels: newSelectedModels, hiddenGroups: [], groupsCollapse: {}, selectedBlocks: newSelectedBlocks, selectedPhrases: newSelectedPhrases, selectedComplementPhrases: {}, groupsIgnoringSelections: [], fields: newFields, complementFields: {} } })
  }

  const handleHideGroup = group => {
    let newGroupsCollapse = groupsCollapse
    delete newGroupsCollapse[group]

    let newSelectedBlocks = selectedBlocks
    delete newSelectedBlocks[group]

    let newSelectedPhrases = selectedPhrases
    delete newSelectedPhrases[group]

    let newSelectedComplementPhrases = selectedComplementPhrases
    delete newSelectedComplementPhrases[group]

    let newFields = fields
    delete newFields[group]

    let newComplementFields = complementFields
    delete newComplementFields[group]

    const newHiddenGroups = [...hiddenGroups, group]

    setHiddenGroups(newHiddenGroups)
    setGroupsCollapse(newGroupsCollapse)
    setSelectedBlocks(newSelectedBlocks)
    setSelectedPhrases(newSelectedPhrases)
    setSelectedComplementPhrases(newSelectedComplementPhrases)

    save({ variables: { code, hiddenGroups: newHiddenGroups, groupsCollapse: newGroupsCollapse, selectedBlocks: newSelectedBlocks, selectedPhrases: newSelectedPhrases, selectedComplementPhrases: newSelectedComplementPhrases, fields: newFields, complementFields: newComplementFields } })
  }

  const handleShowGroup = group => {
    const newHiddenGroups = hiddenGroups.filter(item => item !== group)
    setHiddenGroups(newHiddenGroups)

    save({ variables: { code, hiddenGroups: newHiddenGroups } })
  }

  const handleIgnoreGroup = group => {
    let newGroupsIgnoringSelections = [...groupsIgnoringSelections, group]
    newGroupsIgnoringSelections = uniq(newGroupsIgnoringSelections)
    setGroupsIgnoringSelections(newGroupsIgnoringSelections)

    save({ variables: { code, groupsIgnoringSelections: newGroupsIgnoringSelections } })
  }

  const handleUndoIgnoreGroup = group => {
    const newGroupsIgnoringSelections = groupsIgnoringSelections.filter(item => item !== group)
    setGroupsIgnoringSelections(newGroupsIgnoringSelections)

    save({ variables: { code, groupsIgnoringSelections: newGroupsIgnoringSelections } })
  }

  const handleChangeAccordion = (group, open) => {
    let newGroupsCollapse = { ...groupsCollapse }
    if (open) Object.keys(newGroupsCollapse).forEach(key => { newGroupsCollapse[key] = false })
    newGroupsCollapse[group] = open
    newGroupsCollapse = omitBy(newGroupsCollapse, value => !value)
    setGroupsCollapse(newGroupsCollapse)

    save({ variables: { code, groupsCollapse: newGroupsCollapse } })
  }

  const handleSelectBlock = (element, value, group) => {
    if (signed) return false

    let newSelectedBlocks
    let newSelectedPhrases
    const selectedBlock = selectedBlocks[group]
    const selectedBlockValue = selectedBlock === value ? null : value
    newSelectedBlocks = {...selectedBlocks, [group]: selectedBlockValue}

    if (selectedBlockValue) {
      const newPhrases = selectedProtocols && selectedProtocols.length > 0 ? getPhrasesByGroup2(phrases, group, selectedBlockValue, selectedProtocols, filters, selectedModel) : getPhrasesByGroup(phrases, group, selectedBlockValue, selectedProtocol, filters, selectedModel)

      // newSelectedPhrases = newPhrases.length === 1 ? {...selectedPhrases, [group]: cleanPhraseItem(newPhrases[0])} : {...selectedPhrases, [group]: null}
      if (newPhrases.length === 1) {
        newSelectedPhrases = { ...selectedPhrases, [group]: cleanPhraseItem(newPhrases[0]) }
      } else {
        newSelectedPhrases = { ...selectedPhrases, [group]: null }
      }

    } else {
      newSelectedPhrases = {...selectedPhrases, [group]: null}
    }

    newSelectedPhrases = omitBy(newSelectedPhrases, value => (value === null || value === undefined))
    newSelectedBlocks = omitBy(newSelectedBlocks, value => (value === null || value === undefined))

    setSelectedBlocks(newSelectedBlocks)
    setSelectedPhrases(newSelectedPhrases)

    save({ variables: { code, selectedBlocks: newSelectedBlocks, selectedPhrases: newSelectedPhrases } })

    if (autoTabEnabled)
      setTimeout(() => {
        const nodeList = document.querySelectorAll('[tabindex="0"]')
        const elements = [...nodeList]
        let index = elements.indexOf(element)
        if (index === -1) index = 0
        const newElement = elements[index + 1]
        if (newElement) newElement.focus()
      }, 500)
  }

  const selectPhrase = (phrase, selected) => {
    setFields(fields => {
      let newFields = { ...fields, [phrase.group]: null }
      newFields = omitBy(newFields, value => (value === null || value === undefined || isEqual(value, {})))
      return newFields
    })
    const newSelectedPhrases = {...selected, [phrase.group]: cleanPhraseItem(phrase)}
    return newSelectedPhrases
  }

  const handleSelectPhrase = (element, value, group) => {
    console.log('handleSelectPhrase', group, value)
    if (signed) return false

    let variablesToSave = { code }

    setFields(fields => {
      let newFields = { ...fields, [group]: null }
      newFields = omitBy(newFields, value => (value === null || value === undefined || isEqual(value, {})))
      return newFields
    })

    let newSelectedPhrases = {...selectedPhrases }
    let newSelectedComplementPhrases = {...selectedComplementPhrases}

    // -----------------
    let hasComplementPhraseBind = false
    if (value.bind && value.bind.length > 0) {
      for (const code of value.bind) {
        const phrase = getPhraseByCode(phrases, code)
        if (phrase) {

          const isComplement = phrase.block && phrase.block.split(' ').includes('COMPLEMENTO')
          if (isComplement) {
            hasComplementPhraseBind = true
            let groupSelectedComplementPhrases

            if (newSelectedComplementPhrases[phrase.group]) {

              if (!newSelectedComplementPhrases[phrase.group].map((item) => item.id).includes(phrase._id)) {
                // adiciona
                groupSelectedComplementPhrases = [...newSelectedComplementPhrases[phrase.group], cleanPhraseItem(phrase)]

                newSelectedComplementPhrases = {...newSelectedComplementPhrases, [phrase.group]: groupSelectedComplementPhrases}
                newSelectedComplementPhrases = omitBy(newSelectedComplementPhrases, v => (v === null || v === undefined || ((v instanceof Array) && v.length === 0) ))
              }

            } else {
              groupSelectedComplementPhrases = [cleanPhraseItem(phrase)]
              newSelectedComplementPhrases = {...newSelectedComplementPhrases, [phrase.group]: groupSelectedComplementPhrases}
              newSelectedComplementPhrases = omitBy(newSelectedComplementPhrases, v => (v === null || v === undefined || ((v instanceof Array) && v.length === 0) ))
            }
          } else {
            newSelectedPhrases = selectPhrase(phrase, newSelectedPhrases)
          }

        }
      }
    }

    if (hasComplementPhraseBind) {
      setSelectedComplementPhrases(newSelectedComplementPhrases)
      variablesToSave = { ...variablesToSave, selectedComplementPhrases: newSelectedComplementPhrases }
    }
    // -----------------

    newSelectedPhrases = {...newSelectedPhrases, [group]: cleanPhraseItem(value)}
    setSelectedPhrases(newSelectedPhrases)

    variablesToSave = { ...variablesToSave, selectedPhrases: newSelectedPhrases }

    save({ variables: variablesToSave })

    if (autoTabEnabled)
      setTimeout(() => {
        const nodeList = document.querySelectorAll('[tabindex="0"]')
        const elements = [...nodeList]
        let index = elements.indexOf(element)
        if (index === -1) index = 0
        const newElement = elements[index + 1]
        if (newElement) newElement.focus()
      }, 500)
  }

  const handleSelectComplementPhrase = (element, value, group) => {
    if (signed) return false

    let variablesToSave = { code }

    let groupSelectedComplementPhrases

    let isSelecting = true
    // se já existe selectedComplementPhrases nesse grupo
    if (selectedComplementPhrases[group]) {
      // se o value selecionado já está no selectedComplementPhrases[group]
      if (selectedComplementPhrases[group].map((item) => item.id).includes(value._id)) {
        // remove
        isSelecting = false
        groupSelectedComplementPhrases = selectedComplementPhrases[group].filter((phrase) => { return phrase.id !== value._id })
      } else {
        // adiciona
        groupSelectedComplementPhrases = [...selectedComplementPhrases[group], cleanPhraseItem(value)]
      }
    } else {
      // não existe selectedComplementPhrases com esse grupo. Adiciona a frase
      groupSelectedComplementPhrases = [cleanPhraseItem(value)]
    }

    let newSelectedComplementPhrases = {...selectedComplementPhrases, [group]: groupSelectedComplementPhrases}
    newSelectedComplementPhrases = omitBy(newSelectedComplementPhrases, v => (v === null || v === undefined || ((v instanceof Array) && v.length === 0) ))

    if (isSelecting) {

      let newSelectedPhrases = {...selectedPhrases }

      let groupSelectedComplementPhrases2
      let hasPhraseBind = false
      if (value.bind && value.bind.length > 0) {
        for (const code of value.bind) {
          const phrase = getPhraseByCode(phrases, code)
          if (phrase) {
            const isComplement = phrase.block && phrase.block.split(' ').includes('COMPLEMENTO')
            if (isComplement) {
              if (newSelectedComplementPhrases[phrase.group]) {

                if (!newSelectedComplementPhrases[phrase.group].map((item) => item.id).includes(phrase._id)) {
                  // adiciona
                  groupSelectedComplementPhrases2 = [...newSelectedComplementPhrases[phrase.group], cleanPhraseItem(phrase)]

                  newSelectedComplementPhrases = {...newSelectedComplementPhrases, [phrase.group]: groupSelectedComplementPhrases2}
                  newSelectedComplementPhrases = omitBy(newSelectedComplementPhrases, v => (v === null || v === undefined || ((v instanceof Array) && v.length === 0) ))
                }

              } else {
                groupSelectedComplementPhrases2 = [cleanPhraseItem(phrase)]
                newSelectedComplementPhrases = {...newSelectedComplementPhrases, [phrase.group]: groupSelectedComplementPhrases2}
                newSelectedComplementPhrases = omitBy(newSelectedComplementPhrases, v => (v === null || v === undefined || ((v instanceof Array) && v.length === 0) ))
              }
            } else {
              hasPhraseBind = true
              newSelectedPhrases = selectPhrase(phrase, newSelectedPhrases)
            }
          }
        }
      }

      if (hasPhraseBind) {
        setSelectedPhrases(newSelectedPhrases)
        variablesToSave = { ...variablesToSave, selectedPhrases: newSelectedPhrases }
      }

    }

    setSelectedComplementPhrases(newSelectedComplementPhrases)
    variablesToSave = { ...variablesToSave, selectedComplementPhrases: newSelectedComplementPhrases }

    save({ variables: variablesToSave })
  }

  const handleChangeField = ({ key, value, index, group, phraseType = 'phrase', complementPhraseName = null, fieldName = null }) => {

    if (signed) return false

    if (!value) value = ''

    if (key) {

      return setFields(fields => {
        const groupFields = fields[group.name] ? fields[group.name] : {}
        let newFields = {...fields, [group.name]: omitBy({...groupFields, [key]: value }, value => (value === null || value === undefined))}
        newFields = omitBy(newFields, value => (value === null || value === undefined || isEqual(value, {})))
        return newFields
      })

    }

    if (phraseType === 'complementPhrase') {

      setComplementFields(complementFields => {
        const groupComplementFields = complementFields[group.name] ? complementFields[group.name] : {}
        const phraseGroupComplementFields = complementFields[group.name] && complementFields[group.name][complementPhraseName] ? complementFields[group.name][complementPhraseName] : {}
        let newGroupComplementFields = {...groupComplementFields, [complementPhraseName]: {...phraseGroupComplementFields, [index]: value}}
        const newComplementFields = {...complementFields, [group.name]: newGroupComplementFields}
        // newGroupComplementFields = omitBy({...newGroupComplementFields}, value => (value === null || value === undefined))
        return newComplementFields
      })

      // setComplementFields(newComplementFields)
      // save({ variables: { code, complementFields: newComplementFields } })
    } else {

      if (fieldName && !/^#\S+?#/.test(value)) value = `#${fieldName}#${value}`

      setFields(fields => {
        const groupFields = fields[group.name] ? fields[group.name] : {}
        let newFields = {...fields, [group.name]: {...groupFields, [index]: value }}

        newFields = {...fields, [group.name]: omitBy({...groupFields, [index]: value }, value => (value === null || value === undefined))}
        newFields = omitBy(newFields, value => (value === null || value === undefined || isEqual(value, {})))
        return newFields
      })

    }

  }

  const handleAfterChange = (groupName, value) => {
    setAfterGroup(prevAfterGroup => ({...prevAfterGroup, [groupName]: value}))
  }

  const handleAfterAdd = groupName => {
    setAfterGroup(prevAfterGroup => ({...prevAfterGroup, [groupName]: ''}))
  }

  const handleAfterRemove = groupName => {
    setAfterGroup(prevAfterGroup => omitBy({...prevAfterGroup, [groupName]: null}, value => (value === null || value === undefined)))
  }

  const handleGroupFocus = group => {
    setCurrentFocusedGroup(group)
    const groupName = camelize(group)

    const element = document.getElementById(groupName)
    const elementBoundingClientRect = element.getBoundingClientRect()
    const elementHeight = elementBoundingClientRect.height
    // const elementTop = elementBoundingClientRect.top
    const elementY = elementBoundingClientRect.y
    // const elementOffsetTop = element.offsetTop

    const scrollElement = document.getElementById('rightSide')
    const scrollHeight = scrollElement.getBoundingClientRect().height
    const scrollTop = scrollElement.scrollTop

    const top = scrollTop + elementY

    let newScrollPosition = (top - 80) - (scrollHeight / 2) + (elementHeight / 2)
    if (newScrollPosition < 0) newScrollPosition = 0

    scrollElement.scrollTo({ top: newScrollPosition, behavior: 'smooth' })
  }

  const handleGroupBlur = group => {
    setCurrentFocusedGroup('')
  }

  const handleComplementGroupBlur = group => {
    setComplementGroupsBlurred([...complementGroupsBlurred, group])
    setCurrentFocusedGroup('')
  }

  const handleChangeVars = (vars) => {
    setVars((currentVars) => omitBy({ ...currentVars, ...vars }, (value) => (value === null || value === undefined)))
  }

  const handleVariablesSubmit = (vars) => {
    console.log('---handleVariablesSubmit')
    const newVariables = omitBy(vars, (value) => (value === null || value === undefined))
    setVars((currentVars) => ({ ...currentVars, ...newVariables }))
  }

  const handleClickField = group => {
    try {
      const groupName = camelize(group)
      const element = document.getElementById(`l-${groupName}`)

      if (element) {
        const elementBoundingClientRect = element.getBoundingClientRect()
        const elementHeight = elementBoundingClientRect.height
        const elementY = elementBoundingClientRect.y
        const scrollElement = document.getElementsByClassName('Pane1')[0]
        const scrollHeight = scrollElement.getBoundingClientRect().height
        const scrollTop = scrollElement.scrollTop
        const t = scrollTop + elementY
        let newScrollPosition = (t - 80) - (scrollHeight / 2) + (elementHeight / 2)
        if (newScrollPosition < 0) newScrollPosition = 0
        scrollElement.scrollTo({ top: newScrollPosition, behavior: 'smooth' })

        element.animate([{ backgroundColor: 'hsla(55, 74%, 94%, 1)' }, { backgroundColor: 'hsla(55, 74%, 94%, 0)' }], { duration: 2000, easing: 'ease' })
          .onfinish = () => {
            element.style.backgroundColor = 'transparent'
          }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeselectPhrase = groupName => {

    let newSelectedComplementPhrases = { ...selectedComplementPhrases }
    delete newSelectedComplementPhrases[groupName]
    setSelectedComplementPhrases(newSelectedComplementPhrases)

    let newSelectedPhrases = {...selectedPhrases, [groupName]: null}
    newSelectedPhrases = omitBy(newSelectedPhrases, value => (value === null || value === undefined))
    setSelectedPhrases(newSelectedPhrases)
    save({ variables: { code, selectedPhrases: newSelectedPhrases, selectedComplementPhrases: newSelectedComplementPhrases } })
  }

  const handleGetResult = (code) => {
    setCurrentExaminationView({ code })
  }

  // groups = groups.filter((item) => item.name !== 'LAUDO ESTRUTURADO')

  let allVariables = getVariables({ fields, patient, examination, filters, vars: trimValues(vars) })
  allVariables = Object.entries(allVariables).reduce((acc, [key, value]) => ({ ...acc, [key]: (value === null || value === undefined) ? '' : value}), {})

  function v2() {
    // const modality = examination.tree.split(';')[0]
    // if (me.account.name === 'DASA') return true
    // if (examination.tree === 'MODALIDADE DE TESTE;GERAL;GERAL;TESTE') return true
    // if (examination.tree === 'MODALIDADE DE TESTE;GERAL;GERAL;GERAL') return true
    // if (me.email.includes('admin@admin')) return true
    // if (modality === 'TOMOGRAFIA' && (me.email.includes('iugiro') || me.email.includes('izabela'))) return true
    // return false
    return true
  }

  const popper = onRequestClose => (
    <Box bg='white' css={css`box-shadow: hsla(0, 0%, 0%, 0.15) 0 2px 8px; padding: 32px 16px 16px 16px;`}>
      <Box position='absolute' top={4} right={4} cursor='pointer' onClick={onRequestClose}><CloseIcon2 height={16} /></Box>
      <Flex mb={3}><Box lh='18px' pr={2}><Checkbox checked={autoTabEnabled} onChange={setAutoTabEnabled} /></Box><Box cursor='pointer' style={{userSelect: 'none'}} onClick={() => setAutoTabEnabled(current => !current)} lh='18px' fontSize={14} fontWeight={500}>Habilitar avanço automático</Box></Flex>
      <Flex><Box lh='18px' pr={2}><Checkbox checked={showSubtitlesEnabled} onChange={setShowSubtitlesEnabled} /></Box><Box cursor='pointer' style={{userSelect: 'none'}} onClick={() => setShowSubtitlesEnabled(current => !current)} lh='18px' fontSize={14} fontWeight={500}>Exibir subtítulos</Box></Flex>
    </Box>
  )

  const GroupsArea = (
    <Box bg='white'>

      <Box p={[3, 2]}>

        <Box position='absolute' top='8px' right={16} w={16} h={16}>
          <PopOver placement='bottom' render={({onRequestClose}) => popper(onRequestClose)}>
            <Flex cursor='pointer' color='hsla(0, 0%, 0%, 0.65)'><SettingsIcon height={16} /></Flex>
          </PopOver>
        </Box>

        <Flex jc='space-between' ai='center' mb={3}>
          <Box px={2}>
            <Title>{t('Etapa 2')}</Title>
          </Box>
          {isMobile() && <Box><Box cursor='pointer' onClick={() => setFieldsModalOpen(true)}><PlaylistEditIcon /></Box></Box>}
        </Flex>

        <Box px={2}>
          <Box mb={2}>
            <Label>{t('Exame')}</Label>
            {examination.orderDetails && examination.orderDetails.ExamName && (
              <Box>[{examination.orderDetails.ExamName}]</Box>
            )}
            <Box>{examination.tree.split(';')[examination.tree.split(';').length - 1]}</Box>
          </Box>
          <Box mb={2}>
            <Label>{t('Nome do Paciente')}</Label>
            <Box>{examination.PatientName}</Box>
          </Box>
        </Box>

        <Box mb={3} p={3} bg='grey100' borderRadius={4} ref={visibleRef}>
          {v2() && (
            <Box mb={3}>
              <Label>{t('Modelos exclusivos')}</Label>
              <MultiCheck withAll={false} options={exclusiveModels} value={selectedModels} onChange={handleSelectModels} />
            </Box>
          )}
          {v2() && (
            <Box>
              <Label>{t('Modelos')}</Label>
              <MultiCheck options={defaultModels} value={selectedModels} onChange={handleSelectModels} />
            </Box>
          )}
          {!v2() && (
            <Box>
              <Label>{t('Modelos')}</Label>
              <Select value={selectedModel} onChange={handleSelectModel} disabled={signed}>
              {models.map(item => <option key={item} value={item}>{item}</option>)}
            </Select>
            </Box>
          )}
        </Box>

      </Box>

      <Box>
        {groups.map(({ name, color, phrases, complementPhrases, selectedBlock, selectedPhrase, selectedComplementPhrases, anyBlockSelected, blocks, showUndoIgnore, showIgnore, blocksOpen, collapsed }, index) => {

          if (!showSubtitlesEnabled && name.includes('SUBTÍTULO')) return null

          let groupStyle = {}
          if (currentFocusedGroup === name) groupStyle = {...groupStyle, border: '1px solid hsla(207, 100%, 63%, 1)', boxShadow: '0 0 0 3px rgba(24, 144, 255, 0.2)', backgroundColor: 'hsla(54, 77%, 94%, 1)'}

          return (
            <div key={name} style={groupStyle} id={`l-${camelize(name)}`}>

              {index > 0 && (<hr style={{border: 'none', borderBottom: '1px solid hsla(216, 19%, 80%, 1)', margin: 0}} />)}

              <SelectBlocksAndPhrases
                disabled={signed}
                title={name}
                value={selectedBlock}
                collapsed={collapsed ? true : false}
                onChange={(e, value) => handleSelectBlock(e, value, name)}
                onCollapse={status => handleChangeAccordion(name, status)}
                color={color}
                onHideClick={handleHideGroup}
                showIgnore={showIgnore}
                showUndoIgnore={showUndoIgnore}
                onIgnoreClick={handleIgnoreGroup}
                onUndoIgnoreClick={handleUndoIgnoreGroup}
                onFocus={handleGroupFocus}
                onBlur={handleGroupBlur}
                tabIndex={(selectedBlock || selectedPhrase) ? -1 : 0 }
              >
                {blocks.map(block => (
                  <Option key={block} value={block}>{block}</Option>
                ))}
              </SelectBlocksAndPhrases>

              {(true || anyBlockSelected) &&
                <SelectPhrases
                  disabled={signed}
                  title={name}
                  value={selectedPhrase}
                  onChange={(e, value) => handleSelectPhrase(e, value, name)}
                  onFocus={handleGroupFocus}
                  onBlur={handleGroupBlur}
                >
                  {phrases.map(phrase => (
                    <Option key={phrase._id} value={phrase}>{phrase.name}</Option>
                  ))}
                </SelectPhrases>
              }

              {(!phrases.length || selectedPhrase) &&
                <SelectComplementPhrases
                  disabled={signed}
                  title={name}
                  values={selectedComplementPhrases}
                  onChange={(e, value) => handleSelectComplementPhrase(e, value, name)}
                  onFocus={handleGroupFocus}
                  onBlur={handleComplementGroupBlur}
                  tabIndex={complementGroupsBlurred.includes(name) ? -1 : 0 }
                >
                  {complementPhrases.map(phrase => (
                    <Option key={phrase._id} value={phrase}>{phrase.name}</Option>
                  ))}
                </SelectComplementPhrases>
              }

            </div>
          )}
        )}
      </Box>

      {!!hiddenGroups.length &&
        <>
          <Hr />
          <Box>
            <Box><Text fs={13} fw={600}>REATIVAR GRUPOS</Text></Box>
            <Box p={2}>
              {hiddenGroups.map(group => (
                <div key={group} style={{display: 'inline-block', cursor: 'pointer', padding: '2px 6px', margin: '0 5px', background: 'hsla(0, 0%, 90%, 1)', borderRadius: 10, fontSize: 11, fontWeight: 400}} onClick={() => handleShowGroup(group)}>
                  {group}
                </div>
              ))}
            </Box>
          </Box>
        </>
      }

    </Box>
  )

  const FieldsArea = (
    <div id='rightSide' style={{overflow: 'auto', height: `calc(100vh - 48px - ${signed ? 32 : 0}px)`, padding: 24, paddingBottom: 80 }}>
      <Fields
        patient={patient}
        examination={examination}
        filters={filters}
        allFields={fields}
        disabled={signed}
        groups={groups}
        afterGroup={afterGroup}
        onChange={handleChangeField}
        onAfterChange={handleAfterChange}
        onAddAfter={handleAfterAdd}
        onRemoveAfter={handleAfterRemove}
        onChangeVars={handleChangeVars}
        onVariablesSubmit={handleVariablesSubmit}
        vars={vars}
        onClick={handleClickField}
        onDeselectPhrase={handleDeselectPhrase}
        substitutions={substitutions}
      />
    </div>
  )

  if (!me.isDoctor) {
    return (
      <Wrapper>
        <Card>

          <Box mb={4}>
            <Title>{t('Etapa 2')}</Title>
          </Box>

          <Box>
            Somente usuários médicos podem acessar a Etapa 2.
          </Box>
        </Card>
      </Wrapper>
    )
  }

  if (isMobile()) return (
    <Box>
      {GroupsArea}
      {fieldsModalOpen &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => setFieldsModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <Flex flexDirection='column' h='100%'>
                <Flex bg='grey100' minHeight={48} ai='center'>
                  <Box w={48} />
                  <Box flex={1} ta='center' css='white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'><Text small>Edição</Text></Box>
                  <Box w={48} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
                </Flex>
                <Box p={4} overflow='auto'>
                  <Fields
                    patient={patient}
                    filters={filters}
                    groups={groups}
                    afterGroup={afterGroup}
                    onChange={handleChangeField}
                    onVariablesSubmit={handleVariablesSubmit}
                    onAfterChange={handleAfterChange}
                    onAddAfter={handleAfterAdd}
                    onRemoveAfter={handleAfterRemove}
                    onRequestClose={onRequestClose}
                  />
                </Box>
              </Flex>
            )
          }
        />
      }
    </Box>
  )

  if (!v2() && !selectedProtocol) return (
    <Wrapper>
      <Card>

        <Box mb={4}>
          <Title>{t('Etapa 2')}</Title>
        </Box>

        <Box>
          O Protocolo não foi selecionado. Vá para a <Link to={`/etapa1/${code}`}>Etapa 1</Link> e selecione um Protocolo.
        </Box>
      </Card>
    </Wrapper>
  )

  return (
    <Box>
      {signed &&
        <Box boxShadow='0px 1px 6px 0px hsla(0, 0%, 20%, 0.1)' h={32} ta='center' bg='hsla(54, 77%, 94%, 1)' p={1} zIndex={2} position='fixed' width={1}><Text display='inline-block' lineHeight='16px' verticalAlign='-2px' mr={1}><LockOutlineIcon height={16} /></Text><Text display='inline-block' lineHeight='16px'>Este Laudo está assinado e não pode ser editado.</Text></Box>
      }
      <SplitPane split='vertical' style={{height: `calc(100vh - 48px - ${signed ? 32 : 0}px)`, marginTop: (signed ? 32 : 0)}} pane1Style={{overflowY: 'auto'}} minSize={250} defaultSize={400}>
        <Box>{GroupsArea}</Box>
        <Box>{FieldsArea}</Box>
      </SplitPane>


      <animated.div style={modelsTabProps} tabIndex='-1' css={css`background-color: hsla(201, 20%, 90%, 1); position: fixed; top: 48px; bottom: 0px; z-index: 1001; outline: none; width: 350px; display: ${isVisible ? 'none' : 'block'};`}>
        <Flex cursor='pointer' ai='center' jc='center' css={css`width: 40px; height: 40px; background: hsla(201,20%,90%,1); position: absolute; right: -40px; top: 60px;`} onClick={() => setModelsDrawerOpen(currentModelsDrawerOpen => !currentModelsDrawerOpen)}><NoteTextOutline /></Flex>
        <Box overflow='auto' h='100%'>
          <Box p={3}>

            <Box mb={3} p={3} bg='grey100' borderRadius={4}>
              {v2() && (
                <Box mb={3}>
                  <Label>{t('Modelos exclusivos')}</Label>
                  <MultiCheck withAll={false} options={exclusiveModels} value={selectedModels} onChange={handleSelectModels} />
                </Box>
              )}
              {v2() && (
                <Box>
                  <Label>{t('Modelos')}</Label>
                  <MultiCheck options={defaultModels} value={selectedModels} onChange={handleSelectModels} />
                </Box>
              )}
              {!v2() && (
                <Box>
                  <Label>{t('Modelos')}</Label>
                  <Select value={selectedModel} onChange={handleSelectModel} disabled={signed}>
                  {models.map(item => <option key={item} value={item}>{item}</option>)}
                </Select>
                </Box>
              )}
            </Box>

          </Box>
        </Box>
      </animated.div>

      <animated.div style={props} tabIndex='-1' css={css`background-color: hsla(201, 20%, 90%, 1); position: fixed; top: 48px; bottom: 0px; z-index: 1001; outline: none; width: 280px;`}>
        <Flex cursor='pointer' ai='center' jc='center' css={css`width: 40px; height: 40px; background: hsla(201,20%,90%,1); position: absolute; left: -40px; top: 40px;`} onClick={() => setDrawerOpen(currentDrawerOpen => !currentDrawerOpen)}><FileEyeOutlineIcon /></Flex>
        <Box overflow='auto' h='100%'>
          <Box p={3}>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Exame')}</Label>
              {examination.orderDetails && examination.orderDetails.ExamName && (
                <Box>[{examination.orderDetails.ExamName}]</Box>
              )}
              <Box>{examination.tree.split(';')[examination.tree.split(';').length - 1]}</Box>
            </Box>

            {examination.examinedAt &&
              <Box mb={3} css={css`white-space: nowrap;`}>
                <Label>{t('Data do Exame')}</Label>
                <Box><FormatUtcDate>{examination.examinedAt}</FormatUtcDate></Box>
              </Box>
            }
            {examination.previousExam1At &&
              <Box mb={3} css={css`white-space: nowrap;`}>
                <Label>{t('Dt. Exame Anterior 1')}</Label>
                <Box><FormatUtcDate>{examination.previousExam1At}</FormatUtcDate></Box>
              </Box>
            }
            {examination.previousExam2At &&
              <Box mb={3} css={css`white-space: nowrap;`}>
                <Label>{t('Dt. Exame Anterior 2')}</Label>
                <Box><FormatUtcDate>{examination.previousExam2At}</FormatUtcDate></Box>
              </Box>
            }

            <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Indicação Clínica')}</Label>
              <Box>{examination.indication}</Box>
            </Box>
            <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Nome')}</Label>
              <Box>{examination.PatientName}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Idade')}</Label>
              <Box>{examination.PatientAge}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Peso')}</Label>
              <Box>{examination.PatientWeight}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Altura')}</Label>
              <Box>{examination.PatientHeight}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Sexo')}</Label>
              <Box>{normalizeGender(examination.PatientGender)}</Box>
            </Box>
            <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Clínica')}</Label>
              <Box>{examination.clinic && examination.clinic.name}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Equipamento padrão da clínica')}</Label>
              <Box>{examination.clinic && examination.clinic.defaultEquipment}</Box>
            </Box>
            <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Variáveis')}</Label>
              <Box>{Object.entries(allVariables).map(item => {
                return (
                  <Box key={item[0]} css={css`white-space: nowrap;`}>
                    <Text fw={600}>{`${item[0]}:`}</Text>
                    <Text>{` `}</Text>
                    <Text>{item[1] !== null && <>{item[1]}</>}</Text>
                  </Box>
                )
              })}</Box>
            </Box>
          </Box>
        </Box>
      </animated.div>

      <animated.div style={viewerTabProps} tabIndex='-1' css={css`background-color: hsla(201, 20%, 90%, 1); position: fixed; top: 48px; bottom: 0px; z-index: 1006; outline: none; width: 780px;`}>
        <Flex cursor='pointer' ai='center' jc='center' css={css`width: 40px; height: 40px; background: hsla(201,20%,90%,1); position: absolute; left: -40px; top: 90px;`} onClick={() => setViewerDrawerOpen(currentViewerDrawerOpen => !currentViewerDrawerOpen)}><AttachFileIcon /></Flex>
        <Box overflow='auto' h='100%'>
          <Box p={3} h='100%' ref={viewerContainerRef} />
        </Box>
      </animated.div>

      {viewerDrawerOpen && (
        <Viewer noClose={true} container={viewerContainerRef.current} defaultScale={2.5} scalable={true} disableMouseZoom={true} showTotal={false} zoomSpeed={1} visible={previewAttachments && previewAttachments.length > 0} onClose={() => setPreviewAttachments([]) } images={previewAttachments} />
      )}

      {previousExaminations.length > 0 && (
        <animated.div style={previousExaminationsDrawerProps} tabIndex='-1' css={css`background-color: hsla(201, 20%, 90%, 1); position: fixed; top: 48px; bottom: 0px; z-index: 1005; outline: none; width: 780px;`}>
          <Flex cursor='pointer' ai='center' jc='center' css={css`width: 40px; height: 40px; background: hsla(201,20%,90%,1); position: absolute; left: -40px; top: 140px;`} onClick={() => setPreviousExaminationsDrawerOpen(current => !current)}><HistoryIcon /></Flex>
          <Box overflow='auto' h='100%'>
            {previousExaminations.map(item => (
              <Box p={3} key={item.code}>
                <span css={css`cursor: pointer; text-decoration: underline;`} onClick={() => handleGetResult(item.code)}>
                  <FormatUtcDate>{item.examinedAt}</FormatUtcDate>
                  {item.PatientAge && ` (${item.PatientAge})`}
                  {item.orderDetails && item.orderDetails.ExamName && ` - ${item.orderDetails.ExamName}`}
                  {item.orderDetails && item.orderDetails.UnitShortname && ` - ${item.orderDetails.UnitShortname}`}
                </span>
              </Box>
            ))}
            {currentExaminationView && (
              <Box p={3}>
                <iframe css={css`width: 100%; height: calc(100vh - 140px); border: none;`} title={currentExaminationView.code} src={`https://app.telerison.com/resultado/${currentExaminationView.code}?format=html`} />
              </Box>
            )}
          </Box>
        </animated.div>
      )}

    </Box>
  )
})

const HomeQuery = ({ code, me }) => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET, { fetchPolicy: 'network-only', variables: { code } })
  if (loading) return <Spin />
  if (error) return error.message
  if (!data.examination) return t('Não encontrado')
  return <Home me={me} code={code} data={data} />
}

export default memo(HomeQuery)
