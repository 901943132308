import React, { useState, useRef, lazy, Suspense, useEffect, useCallback } from 'react'
import { useSpring, animated } from 'react-spring'
import { css } from 'styled-components/macro'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import Radio from '@alobato/radio'
import Button from '../../components/Button'
import Viewer from 'react-viewer'

import InputWithOptions from '../../components/InputWithOptions'
import InputWithAutoSize from '../../components/InputWithAutoSize'

import TreeCascader from '../../shared/TreeCascader'

import { FormatUtcDate } from '../../components/Format'
import Spin from '../../components/Spin'
import { LockOutlineIcon, FileEyeOutlineIcon } from '../../components/Icons'
import Card from '../../components/Card'
import Title from '../../components/Title'
import Label from '../../components/Label'
import Hr from '../../components/Hr'
import Select from '../../components/Select'
import SelectBlocks, { Option } from '../../components/SelectBlocks'

import omitBy from 'lodash.omitby'
import { preparePhrases, getProtocols, getExtras, getModels, getGroups, getBlocksByGroup, getPhrasesByGroup, getSuppress } from '../../utils/steps'

import { useTranslation } from 'react-i18next'
import { usePermission } from 'react-use'
import useDidMount from '../../hooks/useDidMount'
import useIgnoreMount from '../../hooks/useIgnoreMount'

import { useQuery, useMutation } from '@apollo/client'
import { GET, UPDATE } from './Gqls'

function replaceAttachmentUrl(url) {
  if (!url) return ''
  if (url.includes('https://s3.amazonaws.com/telerison.a/')) return url
  let fileName
  if (url.includes('https://telerison.a.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.a.s3.amazonaws.com/', '')
  } else if (url.includes('https://telerison.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.s3.amazonaws.com/', '')
  }
  return `https://s3.amazonaws.com/telerison.a/${fileName}`
}

const Textarea = lazy(() => import('../../components/Textarea'))

const cleanPhraseItem = item => ({ id: item._id, text: item.text, name: item.name, block: item.block, group: item.group, fields: [], color: '' })


const Step1Home = ({ data: { examination }, code, navigate, me }) => {

  const { t } = useTranslation()

  const substitutions = me.preferences && me.preferences.speechRecognition && me.preferences.speechRecognition.substitutions ? me.preferences.speechRecognition.substitutions : []

  // const rvStopRef = useRef(null)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const props = useSpring({ right: drawerOpen ? 0 : -280 })

  // const permissionState =
  usePermission({ name: 'microphone' })
  // if (permissionState) {
  //   console.log('permissionState', permissionState)
  // }

  let signed = (examination.signedAt || (examination.status && examination.status.signed)) ? true : false
  // if (!examination.correctedAt && examination.underCorrectionAt) signed = false

  const [updateExamination] = useCallback(useMutation(UPDATE), [UPDATE])

  let {
    tree,
    PatientName,
    selectedProtocol: propsSelectedProtocol,
    filters: propsFilters,
    selectedModel: propsSelectedModel,
    hiddenGroups: propsHiddenGroups,
    groupsCollapse: propsGroupsCollapse,
    vars: propsVars,
    selectedBlocks: propsSelectedBlocks,
    selectedPhrases: propsSelectedPhrases,
    selectedComplementPhrases: propsSelectedComplementPhrases,
    groupsIgnoringSelections: propsGroupsIgnoringSelections,
    indication: propsIndication,
    fields: propsFields
  } = examination

  let phrases = examination && examination.list && examination.list.content ? preparePhrases(examination.list.content) : []

  const [previewAttachments, setPreviewAttachments] = useState([])

  const [indication, setIndication] = useState(propsIndication || '')

  const [selectedProtocol, setSelectedProtocol] = useState(propsSelectedProtocol || '')
  const [filters, setFilters] = useState(propsFilters || {})
  const [selectedModel, setSelectedModel] = useState(propsSelectedModel || '')
  const [hiddenGroups, setHiddenGroups] = useState(propsHiddenGroups || [])
  const [groupsCollapse, setGroupsCollapse] = useState(propsGroupsCollapse || {})
  const [vars, setVars] = useState(propsVars || {})
  const [selectedBlocks, setSelectedBlocks] = useState(propsSelectedBlocks || {})
  const [selectedPhrases, setSelectedPhrases] = useState(propsSelectedPhrases || {})
  const [selectedComplementPhrases, setSelectedComplementPhrases] = useState(propsSelectedComplementPhrases || {})
  const [groupsIgnoringSelections, setGroupsIgnoringSelections] = useState(propsGroupsIgnoringSelections || [])
  const [fields, setFields] = useState(propsFields || {})

  phrases = phrases.filter(item => item.text.includes('<table'))

  phrases = getSuppress(phrases, selectedPhrases, selectedComplementPhrases)

  const protocols = getProtocols(phrases)
  const extras = getExtras(phrases, selectedProtocol)
  const models = getModels(phrases, selectedProtocol, filters)
  const groups = getGroups(phrases, selectedProtocol, filters, selectedModel, hiddenGroups, selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields)

  const save = useCallback(({ variables }) => {
    if (signed) return false
    const steps = (examination.status && examination.status.steps) ? examination.status.steps : []
    const newSteps = steps.includes(1) ? steps : [...steps, 1]
    const newStatus = { ...(examination.status || {}), steps: newSteps }
    updateExamination({ variables: {...variables, status: newStatus } })
  }, [updateExamination, examination.status, signed])

  const textPhrases = groups.reduce((acc, group) => {
    const groupTextPhrases = group.phrases.map(phrase => (phrase.text))
    acc = [...acc, ...groupTextPhrases]
    return acc
  }, [])

  const textPhrasesWithVars = textPhrases.filter(item => (/\[#\S+?#(.*?)\]/.test(item)))

  const capturedVars = textPhrasesWithVars.reduce((acc, item) => {
    const options = item.match(/\[.*?\]/g)
    options.forEach(option => {
      const match = option.match(/\[#(\S+?)#/)
      if (match && match.length > 1) {
        const name = option.match(/\[#(\S+?)#/)[1].toLowerCase()
        if (!/\[#\S+?#\s*?\(.+\)/.test(option)) {
          let fieldsOptions = []
          if (/#.+?]/.test(option) && option.includes('|')) {
            fieldsOptions = option.match(/#\S+?#(.+?)\]/)[1]
            fieldsOptions = fieldsOptions.split('|')
          }
          if (!acc.find(element => (element.name === name))) acc.push({ name, options: fieldsOptions })
        }

      }
    })
    return acc
  }, [])

  useDidMount(() => {
    if (protocols.length === 1 && !selectedProtocol) handleSelectProtocol(protocols[0])
  })

  const handleSelectModel = useCallback(value => {
    if (signed) return false

    const newSelectedModel = value
    const newSelectedPhrases = {}
    const newSelectedBlocks = {}

    const groups = getGroups(phrases, selectedProtocol, filters, newSelectedModel, hiddenGroups, newSelectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields)

    groups.forEach(group => {
      const blocks = getBlocksByGroup(phrases, group.name, selectedProtocol, filters, newSelectedModel)
      if (blocks.length === 1) {
        newSelectedBlocks[group.name] = blocks[0]
        const phrasesByGroup = getPhrasesByGroup(phrases, group.name, blocks[0], selectedProtocol, filters, newSelectedModel)
        if (phrasesByGroup.length === 1)
          newSelectedPhrases[group.name] = cleanPhraseItem(phrasesByGroup[0])
      }
    })

    setSelectedModel(newSelectedModel)
    setHiddenGroups([])
    setGroupsCollapse({})
    setSelectedBlocks(newSelectedBlocks)
    setSelectedPhrases(newSelectedPhrases)
    setSelectedComplementPhrases({})
    setGroupsIgnoringSelections([])
    setFields({})

    save({ variables: { code, selectedModel: newSelectedModel, hiddenGroups: [], groupsCollapse: {}, selectedBlocks: newSelectedBlocks, selectedPhrases: newSelectedPhrases, selectedComplementPhrases: {}, groupsIgnoringSelections: [], fields: {}, complementFields: {} } })
  }, [code, fields, filters, groupsCollapse, groupsIgnoringSelections, hiddenGroups, phrases, save, selectedComplementPhrases, selectedPhrases, selectedProtocol, signed])

  useIgnoreMount(selectedProtocol, () => {
    if (models.length === 1) handleSelectModel(models[0])
  })

  useIgnoreMount(vars, newVars => {
    save({ variables: { code, vars: newVars }})
  })

  useIgnoreMount(groupsCollapse, newGroupsCollapse => {
    save({ variables: { code, groupsCollapse: newGroupsCollapse }})
  })



	const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current) {

      const foundEquipmentExtras = extras.reduce((acc, item) => {
        if (item.name === 'EQUIPAMENTO' && item.items.length > 0) acc = true
        return acc
      }, false)

      const equipments = extras.reduce((acc, item) => {
        if (item.name === 'EQUIPAMENTO' && item.items.length > 0) acc = [...acc, ...item.items]
        return acc
      }, [])

      const foundGenderExtras = extras.reduce((acc, item) => {
        if (item.name === 'SEXO' && item.items.length > 0) acc = true
        return acc
      }, false)

      const genders = extras.reduce((acc, item) => {
        if (item.name === 'SEXO' && item.items.length > 0) acc = [...acc, ...item.items]
        return acc
      }, [])

      let newFilters = {}
      if (examination.clinic && examination.clinic.defaultEquipment && foundEquipmentExtras && equipments.includes(examination.clinic.defaultEquipment)) {
        newFilters = { ...newFilters, 'EQUIPAMENTO': examination.clinic.defaultEquipment }
      }

      const gendersObj =  { 'M': 'MASCULINO', 'F': 'FEMININO' }
      if (examination.PatientGender && foundGenderExtras && genders.includes(gendersObj[examination.PatientGender])) {
        newFilters = { ...newFilters, 'SEXO': gendersObj[examination.PatientGender] }
      }

      setFilters(newFilters)

      save({ variables: { code, filters: newFilters } })
    } else {
      didMountRef.current = true
    }
  // eslint-disable-next-line
  }, [selectedProtocol])

  const handleChangeIndication = indication => {
    if (signed) return false
    setIndication(indication)
    save({ variables: { code, indication } })
  }

  const handleSelectProtocol = value => {
    console.log('handleSelectProtocol')
    if (signed) return false

    const newSelectedProtocol = value
    const newSelectedPhrases = {}
    const newSelectedBlocks = {}

    const groups = getGroups(phrases, newSelectedProtocol, {}, '', hiddenGroups, newSelectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields)

    groups.forEach(group => {
      const blocks = getBlocksByGroup(phrases, group.name, newSelectedProtocol, filters, selectedModel)
      if (blocks.length === 1) {
        newSelectedBlocks[group.name] = blocks[0]
        const phrasesByGroup = getPhrasesByGroup(phrases, group.name, blocks[0], newSelectedProtocol, filters, selectedModel)
        if (phrasesByGroup.length === 1)
          newSelectedPhrases[group.name] = cleanPhraseItem(phrasesByGroup[0])
      }
    })

    setSelectedProtocol(newSelectedProtocol)
    setFilters({})
    setSelectedModel('')
    setHiddenGroups([])
    setGroupsCollapse({})
    setSelectedBlocks(newSelectedBlocks)
    setSelectedPhrases(newSelectedPhrases)
    setSelectedComplementPhrases({})
    setGroupsIgnoringSelections([])
    setFields({})

    save({ variables: { code, selectedProtocol: newSelectedProtocol, filters: {}, selectedModel: '', hiddenGroups: [], groupsCollapse: {}, selectedBlocks: newSelectedBlocks, selectedPhrases: newSelectedPhrases, selectedComplementPhrases: {}, groupsIgnoringSelections: [], fields: {} }})
  }

  const handleChangeFilters = (name, value) => {
    if (signed) return false

    let newFilters = {...filters, [name]: value}
    if (filters[name] === value) newFilters = {...filters, [name]: null}
    newFilters = omitBy(newFilters, value => (value === null || value === undefined))

    setFilters(newFilters)
    setSelectedModel('')
    setHiddenGroups([])
    setGroupsCollapse({})
    setSelectedBlocks({})
    setSelectedPhrases({})
    setSelectedComplementPhrases({})
    setGroupsIgnoringSelections([])
    setFields({})

    save({ variables: { code, filters: newFilters, selectedModel: '', hiddenGroups: [], groupsCollapse: {}, selectedBlocks: {}, selectedPhrases: {}, selectedComplementPhrases: {}, groupsIgnoringSelections: [], fields: {} } })
  }

  const handleChangeAccordion = (group, open) => {
    setGroupsCollapse(currentGroupsCollapse => {
      let newGroupsCollapse = {...currentGroupsCollapse}
      if (open) for (const key of Object.keys(newGroupsCollapse)) {
        newGroupsCollapse[key] = false
      }
      newGroupsCollapse[group] = open
      newGroupsCollapse = omitBy(newGroupsCollapse, value => !value)
      return newGroupsCollapse
    })
  }

  const handleSelectBlock = (element, value, group) => {
    if (signed) return false

    let newSelectedBlocks
    let newSelectedPhrases
    const selectedBlock = selectedBlocks[group]
    const selectedBlockValue = selectedBlock === value ? null : value
    newSelectedBlocks = {...selectedBlocks, [group]: selectedBlockValue}

    if (selectedBlockValue) {
      const newPhrases = getPhrasesByGroup(phrases, group, selectedBlockValue, selectedProtocol, filters, selectedModel)
      newSelectedPhrases = newPhrases.length === 1 ? {...selectedPhrases, [group]: cleanPhraseItem(newPhrases[0])} : {...selectedPhrases, [group]: null}
    } else {
      newSelectedPhrases = {...selectedPhrases, [group]: null}
    }

    newSelectedPhrases = omitBy(newSelectedPhrases, value => (value === null || value === undefined))
    newSelectedBlocks = omitBy(newSelectedBlocks, value => (value === null || value === undefined))

    setSelectedBlocks(newSelectedBlocks)
    setSelectedPhrases(newSelectedPhrases)

    save({ variables: { code, selectedBlocks: newSelectedBlocks, selectedPhrases: newSelectedPhrases }})

    if (element) {
      setTimeout(() => {
        const nodeList = document.querySelectorAll('[tabindex="0"]')
        const elements = [...nodeList]
        const index = elements.indexOf(element)
        const newElement = elements[index + 1]
        if (newElement) newElement.focus()
      }, 500)
    }

  }

  const handleChangeVars = (name, value) => {
    setVars(currentVars => omitBy({ ...currentVars, [name]: value }, value => (value === null || value === undefined)))
  }

  return (
    <Box>

      {signed &&
        <Box boxShadow='0px 1px 6px 0px hsla(0, 0%, 20%, 0.1)' h={32} ta='center' bg='hsla(54, 77%, 94%, 1)' p={1} zIndex={2} position='fixed' width={1}><Text display='inline-block' lineHeight='16px' verticalAlign='-2px' mr={1}><LockOutlineIcon height={16} /></Text><Text display='inline-block' lineHeight='16px'>Este Laudo está assinado e não pode ser editado.</Text></Box>
      }

      <Box p={[3, 4]} pt={signed ? [5, 5] : ''}>

        <Card>

          <Box mb={4}>
            <Title>{t('Tabela - Etapa 1')}</Title>
          </Box>

          <Flex>
            <Box>
              <Box mb={3}>
                <Label>{t('Exame')}</Label>
                <Box>{tree.split(';').join(' → ')}</Box>
              </Box>
              {PatientName &&
                <Box mb={3}>
                  <Label>{t('Nome do Paciente')}</Label>
                  <Box>{PatientName}</Box>
                </Box>
              }
              {examination.orderDetails && examination.orderDetails.attachments && examination.orderDetails.attachments.length > 0 &&
                <Box mb={3}>
                  <Label>{t('Pedido Médico')}</Label>
                  <Box display='inline-block' cursor='pointer' onClick={() => setPreviewAttachments((examination.orderDetails.attachments || []).map(attachment => ({ src: replaceAttachmentUrl(attachment.url) })))}>
                    <Text underline>{examination.orderDetails.attachments.length} imagens</Text>
                  </Box>
                </Box>
              }

              {/* <Box mb={3}>
                <Label>{t('Indicação Clínica')}</Label>
                <Box>
                  <Suspense fallback={<div>Carregando...</div>}>
                    <Textarea stopAction={stop => rvStopRef.current = stop } maxLength={255} disabled={signed} value={indication} onChange={handleChangeIndication} substitutions={substitutions} />
                  </Suspense>
                </Box>
              </Box> */}

              {examination.anamnesis &&
                <Box mb={4}>
                  <Label>{t('Anamnese')}</Label>
                  <Box>
                    {examination.anamnesis}
                  </Box>
                </Box>
              }

            </Box>
          </Flex>

          <Box mb={4} p={3} bg='grey100' borderRadius={4}>

            <Box mb={3}>
              <Label>{t('Protocolo')}</Label>
              <Select disabled={signed} placeholder={t('Selecione...')} value={selectedProtocol} onChange={e => handleSelectProtocol(e.target.value)}>
                {protocols.map(item => <option key={item} value={item}>{item}</option>)}
              </Select>
            </Box>

            {selectedProtocol &&
              <>
                <Box>
                  {extras.map(extra => (
                    <Box key={extra.name} mb={3}>
                      {!!extra.items.length && (
                        <Box mb={1}><Label>{extra.name}</Label></Box>
                      )}
                      {extra.items.map(item => (
                        <Radio disabled={signed} key={`${extra.name}${item}`} checked={filters[extra.name] === item} onChange={() => handleChangeFilters(extra.name, item)} label={item} />
                      ))}
                    </Box>
                  ))}
                </Box>

                <Box>
                  <Label>Modelo</Label>
                  <Select disabled={signed} placeholder={t('Selecione...')} value={selectedModel} onChange={e => handleSelectModel(e.target.value)}>
                    {models.map(item => <option key={item} value={item}>{item}</option>)}
                  </Select>
                </Box>

                {false && (
                  <Box>
                    {capturedVars.map(item => (
                      <Box key={item.name} maxWidth={300} mb={2}>
                        <Label>{item.name.replace(/_/g, ' ')}</Label>
                        {item.options.length > 0 ? (
                          <Box><InputWithOptions options={item.options} value={(vars[item.name] || '')} onChange={value => handleChangeVars(item.name, value)} /></Box>
                        ) : (
                          <Box><InputWithAutoSize defaultValue={(vars[item.name] || '')} onChange={value => handleChangeVars(item.name, value)} /></Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            }

          </Box>

          {false && (
            <Box mb={4}>
              {!!groups.length && <Hr />}
              {groups.map(({ name, selectedBlock, blocks, collapsed }, index) => (
                <Box key={name}>
                  {index > 0 && (<Hr />)}
                  <SelectBlocks
                    disabled={signed}
                    data-id={name}
                    tabIndex={(blocks.length === 1 && blocks[0] === selectedBlock) ? -1 : 0}
                    title={name}
                    value={selectedBlock}
                    collapsed={collapsed ? true : false}
                    onChange={(element, value) => handleSelectBlock(element, value, name)}
                    onCollapse={status => handleChangeAccordion(name, status)}
                  >
                    {blocks.map(block => (
                      <Option key={block} value={block}>{block}</Option>
                    ))}
                  </SelectBlocks>

                </Box>
              ))}
              {!!groups.length && <Hr />}
            </Box>
          )}

          <Box p={4} pl={3} ta='center'>
            <Button tabIndex={0} onClick={() => navigate(`/tabela-etapa2/${code}`)}>Ir para a Etapa 2</Button>
          </Box>

        </Card>

      </Box>

      {/*previewAttachments && previewAttachments.length > 0 &&
        <Viewer files={previewAttachments} onRequestClose={() => setPreviewAttachments([])} />
      */}

      <Viewer scalable={false} disableMouseZoom={true} showTotal={false} zoomSpeed={1} visible={previewAttachments && previewAttachments.length > 0} onClose={() => setPreviewAttachments([]) } images={previewAttachments} />

      <animated.div style={props} tabIndex='-1' css={css`background-color: hsla(201, 20%, 90%, 1); position: fixed; top: 48px; bottom: 0px; z-index: 1001; outline: none; width: 280px;`}>
        <Flex cursor='pointer' ai='center' jc='center' css={css`width: 40px; height: 40px; background: hsla(201,20%,90%,1); position: absolute; left: -40px; top: 40px;`} onClick={() => setDrawerOpen(currentDrawerOpen => !currentDrawerOpen)}><FileEyeOutlineIcon /></Flex>
        <Box overflow='auto' h='100%'>
          <Box p={3}>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Exame')}</Label>
              <Box>{examination.tree.split(';')[examination.tree.split(';').length - 1]}</Box>
            </Box>

            {examination.examinedAt &&
              <Box mb={3} css={css`white-space: nowrap;`}>
                <Label>{t('Data do Exame')}</Label>
                <Box><FormatUtcDate>{examination.examinedAt}</FormatUtcDate></Box>
              </Box>
            }
            {examination.previousExam1At &&
              <Box mb={3} css={css`white-space: nowrap;`}>
                <Label>{t('Dt. Exame Anterior 1')}</Label>
                <Box><FormatUtcDate>{examination.previousExam1At}</FormatUtcDate></Box>
              </Box>
            }
            {examination.previousExam2At &&
              <Box mb={3} css={css`white-space: nowrap;`}>
                <Label>{t('Dt. Exame Anterior 2')}</Label>
                <Box><FormatUtcDate>{examination.previousExam2At}</FormatUtcDate></Box>
              </Box>
            }

            <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Nome')}</Label>
              <Box>{examination.PatientName}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Idade')}</Label>
              <Box>{examination.PatientAge}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Peso')}</Label>
              <Box>{examination.PatientWeight}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Altura')}</Label>
              <Box>{examination.PatientHeight}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Sexo')}</Label>
              <Box>{examination.PatientGender}</Box>
            </Box>
            <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Clínica')}</Label>
              <Box>{examination.clinic && examination.clinic.name}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Equipamento padrão da clínica')}</Label>
              <Box>{examination.clinic && examination.clinic.defaultEquipment}</Box>
            </Box>
          </Box>
        </Box>
      </animated.div>

    </Box>
  )

}

function Step1HomeWithoutTree({ code, refetch }) {

  const { t } = useTranslation()

  const [updateExamination] = useCallback(useMutation(UPDATE), [UPDATE])

  const treeCascaderFilter = []

  async function handleChange(e) {
    console.log('handleChange', e.target.value)
    const result = await updateExamination({ variables: { code, tree: e.target.value } })
    console.log('result', result)
    refetch()
  }

  return (
    <Box p={[3, 4]}>
      <Card>

        <Box mb={4}>
          <Title>{t('Etapa 1')}</Title>
        </Box>

        <Flex>
          <Box>
            <Box mb={3}>
              <Label>{t('Selecione um modelo de laudo para continuar')}</Label>
              <TreeCascader
                name='tree'
                defaultValue=''
                onChange={handleChange}
                style={{ width: '100%' }}
                filters={treeCascaderFilter}
              />
            </Box>
          </Box>
        </Flex>

      </Card>
    </Box>
  )
}

const Step1HomeQuery = ({ code, navigate, me }) => {
  const { t } = useTranslation()
  const { loading, error, data, refetch } = useQuery(GET, { fetchPolicy: 'network-only', variables: { code } })
  if (loading) return <Spin />
  if (error) return error.message
  if (!data.examination) return t('Não encontrado')
  if (!data.examination.tree) return <Step1HomeWithoutTree code={code} refetch={refetch} />
  return <Step1Home code={code} data={data} navigate={navigate} me={me} />
}

export default Step1HomeQuery
