import React, { memo } from 'react'
import { Router } from '@reach/router'

import MainLayout from '../../layouts/MainLayout'
import Home from './Home'
import AccountUsersHome from '../AccountUsers/Home'

 const Index = ({ me, notifier }) => {
  return (
    <Router>
      <MainLayout me={me} path='/'>
        <AccountUsersHome me={me} notifier={notifier} path='/:id/usuarios/novo' />
        <AccountUsersHome me={me} notifier={notifier} path='/:id/usuarios/:id/editar' />
        <AccountUsersHome me={me} notifier={notifier} path='/:id/usuarios/:id' />
        <AccountUsersHome me={me} notifier={notifier} path='/:id/usuarios' />

        <Home me={me} notifier={notifier} path='/novo' />
        <Home me={me} notifier={notifier} path='/:id/editar' />
        <Home me={me} notifier={notifier} path='/:id' />
        <Home me={me} notifier={notifier} path='/' />
      </MainLayout>
    </Router>
  )
}

export default memo(Index)
