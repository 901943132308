import React, { Component } from 'react'

export default class ContentEditable extends Component {

  shouldComponentUpdate(nextProps) {
    // We need not rerender if the change of props simply reflects the user's
    // edits. Rerendering in this case would make the cursor/caret jump.
    return (
      // Rerender if there is no element yet... (somehow?)
      !this.htmlEl
      // ...or if html really changed... (programmatically, not by user edit)
      || ( nextProps.html !== this.htmlEl.innerHTML
        && nextProps.html !== this.props.html )
      // ...or if editing is enabled or disabled.
      || this.props.disabled !== nextProps.disabled
      // ...or if className changed
      || this.props.className !== nextProps.className
    );
  }

  componentDidUpdate() {
    if ( this.htmlEl && this.props.html !== this.htmlEl.innerHTML ) {
      // Perhaps React (whose VDOM gets outdated because we often prevent
      // rerendering) did not update the DOM. So we update it manually now.
      this.htmlEl.innerHTML = this.props.html;
    }
  }

  emitChange = e => {
    if (!this.htmlEl) return
    const html = this.htmlEl.innerHTML
    console.log('this.htmlEl', this.htmlEl.attributes)
    if (this.props.onChange && html !== this.lastHtml) {
      let attributes = {}
      Array.prototype.slice.call(this.htmlEl.attributes).forEach((item) => {
        attributes[item.name] = item.value
      })
      e.target = { ...attributes, value: html }
      this.props.onChange(e, this)
    }
    this.lastHtml = html
  }

  render() {
    const { tagName, html, ...props } = this.props
    // return React.createElement(tagName || 'span', { ...props, ref: (e) => this.htmlEl = e, onInput: this.emitChange, onBlur: this.props.onBlur || this.emitChange, contentEditable: !this.props.disabled, dangerouslySetInnerHTML: {__html: html} }, this.props.children)
    return React.createElement(tagName || 'span', { ...props, ref: (e) => this.htmlEl = e, onInput: this.emitChange, onBlur: this.props.onBlur || this.emitChange, contentEditable: !this.props.disabled, dangerouslySetInnerHTML: {__html: html} })
  }

}
