import React, { memo } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Checkbox from '@alobato/checkbox'
import Hr from '../../components/Hr'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import Select from '../../components/Select'
import Switch from '../../components/Switch'

import { readFile } from '../../utils'

import Label from '../../components/Label'
import FormErrorBox from '../../components/FormErrorBox'

import useScrollToError from '@alobato/use-scroll-to-error'
import { useTranslation } from 'react-i18next'

// import { useMutation } from '@apollo/client'
// import { SINGLE_UPLOAD } from './Gqls'

const DocLayoutSettingsForm = ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {

  const { t } = useTranslation()

  useScrollToError({ errors, isValid, isSubmitting })

  // const [singleUpload] = useCallback(useMutation(SINGLE_UPLOAD), [SINGLE_UPLOAD])

  const formats = ['png', 'jpg', 'bmp']

  const handleChangeImage = async (e, fieldName) => {
    // setLoading(true)
    const file = e.target.files[0]
    // const src = URL.createObjectURL(file)
    // const img = await readImage(src)
    // const { width, height } = img
    // TODO: Validate width and height
    // console.log('width', width)
    // console.log('height', height)
    // const result = await singleUpload({ variables: { file } })
    // const url = result.data.singleUpload.url
    const b64 = await readFile(file)
    setFieldValue(fieldName, b64)

    // setLoading(false)
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <h2>Ilustração</h2>
      <Box mb={5}>
        <Flex>
          {/* <Box mr={4}>
            <Label>{t('Papel')}</Label>
            <Select style={{ minWidth: 200 }} placeholder={t('Selecione...')} name='paper' value={values.paper} onChange={handleChange} onBlur={handleBlur}>
              {papers.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Select>
            <FormErrorBox fieldName='paper' errors={errors} touched={touched} />
          </Box> */}

          <Box mr={4}>
            <Label>{t('Fundo do título')}</Label>
              <Flex>
                <Box mr={1}><Input type='color' width='40px' name='titleBackgroundColor' value={values.titleBackgroundColor} onChange={handleChange} onBlur={handleBlur} withError={touched.titleBackgroundColor && errors.titleBackgroundColor} /></Box>
                <Box><Input width='100px' name='titleBackgroundColor' value={values.titleBackgroundColor} onChange={handleChange} onBlur={handleBlur} withError={touched.titleBackgroundColor && errors.titleBackgroundColor} /></Box>
              </Flex>
              <FormErrorBox fieldName='titleBackgroundColor' errors={errors} touched={touched} />
          </Box>

          <Box mr={4}>
            <Label>{t('Borda do título')}</Label>
              <Flex>
                <Box mr={1}><Input type='color' width='40px' name='titleBorderColor' value={values.titleBorderColor} onChange={handleChange} onBlur={handleBlur} withError={touched.titleBorderColor && errors.titleBorderColor} /></Box>
                <Box><Input width='100px' name='titleBorderColor' value={values.titleBorderColor} onChange={handleChange} onBlur={handleBlur} withError={touched.titleBorderColor && errors.titleBorderColor} /></Box>
              </Flex>
              <FormErrorBox fieldName='titleBorderColor' errors={errors} touched={touched} />
          </Box>

          <Box>
            <Label>{t('Texto do título')}</Label>
              <Flex>
                <Box mr={1}><Input type='color' width='40px' name='titleTextColor' value={values.titleTextColor} onChange={handleChange} onBlur={handleBlur} withError={touched.titleTextColor && errors.titleTextColor} /></Box>
                <Box><Input width='100px' name='titleTextColor' value={values.titleTextColor} onChange={handleChange} onBlur={handleBlur} withError={touched.titleTextColor && errors.titleTextColor} /></Box>
              </Flex>
              <FormErrorBox fieldName='titleTextColor' errors={errors} touched={touched} />
          </Box>
        </Flex>

        <Flex>
          {/* <Box mr={4}>
            <Label>{t('Fonte do título')}</Label>
            <Select style={{ minWidth: 200 }} placeholder={t('Selecione...')} name='fontFamily' value={values.fontFamily} onChange={handleChange} onBlur={handleBlur}>
              {fonts.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Select>
            <FormErrorBox fieldName='fontFamily' errors={errors} touched={touched} />
          </Box> */}
          <Box mr={4}>
            <Label>{t('Formato da imagem')}</Label>
            <Select style={{ width: 160 }} placeholder={t('Selecione...')} name='format' value={values.format} onChange={handleChange} onBlur={handleBlur}>
              {formats.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Select>
            <FormErrorBox fieldName='fontSize' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Tamanho da imagem')}</Label>
            <Select style={{ width: 160 }} placeholder={t('Selecione...')} name='zoom' value={values.zoom} onChange={handleChange} onBlur={handleBlur}>
              <option value='1'>17cm</option>
              <option value='1.5'>25cm</option>
              <option value='2'>34cm</option>
            </Select>
            {/* <Input type='number' width='160px' name='lineHeight' value={values.lineHeight} onChange={handleChange} onBlur={handleBlur} withError={touched.lineHeight && errors.lineHeight} /> */}
            <FormErrorBox fieldName='lineHeight' errors={errors} touched={touched} />
          </Box>
        </Flex>

        {/* <Flex>
          <Box mr={4}>
            <Label>{t('Margem superior (mm)')}</Label>
            <Input type='number' width='160px' name='topMargin' value={values.topMargin} onChange={handleChange} onBlur={handleBlur} withError={touched.topMargin && errors.topMargin} />
            <FormErrorBox fieldName='topMargin' errors={errors} touched={touched} />
          </Box>
          <Box mr={4}>
            <Label>{t('Margem direita (mm)')}</Label>
            <Input  type='number' width='160px' name='rightMargin' value={values.rightMargin} onChange={handleChange} onBlur={handleBlur} withError={touched.rightMargin && errors.rightMargin} />
            <FormErrorBox fieldName='rightMargin' errors={errors} touched={touched} />
          </Box>
          <Box mr={4}>
            <Label>{t('Margem inferior (mm)')}</Label>
            <Input type='number' width='160px' name='bottomMargin' value={values.bottomMargin} onChange={handleChange} onBlur={handleBlur} withError={touched.bottomMargin && errors.bottomMargin} />
            <FormErrorBox fieldName='bottomMargin' errors={errors} touched={touched} />
          </Box>
          <Box>
            <Label>{t('Margem esquerda (mm)')}</Label>
            <Input type='number' width='160px' name='leftMargin' value={values.leftMargin} onChange={handleChange} onBlur={handleBlur} withError={touched.leftMargin && errors.leftMargin} />
            <FormErrorBox fieldName='leftMargin' errors={errors} touched={touched} />
          </Box>
        </Flex> */}
      </Box>

      <Box my={4}><Hr /></Box>

      <Box>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
}

export default memo(DocLayoutSettingsForm)
