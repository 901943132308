import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components/macro'
import { Field } from 'formik'

import Input from '../../components/_new/Input'
import Select from '../../components/_new/Select'
import FieldInput from '../../components/_new/FieldInput'
import FieldSelect from '../../components/_new/FieldSelect'
import Label from '../../components/_new/Label'
import Text from '../../components/_new/Text'
import Button from '../../components/_new/ButtonWithLoading'
import { Flex, Box } from '../../components/_new/FlexBox'
import Checkbox from '../../components/_new/Checkbox'
import BigCheckbox from '../../components/_new/BigCheckbox'

const states = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']

function MultiStepForm({ me, activeStep, values, setFieldValue, isLastStep, onBack, isSubmitting, handleSubmit, students, isCreating = false }) {
  const { t } = useTranslation()

  const selectedIds = []

  const handleChangeLicense = e => {
    const value = e.target.value
    let deleted = false
    const pplans = me.account.settings.plans.filter(item => {
      if (!deleted && item === value) {
        deleted = true
        return false
      }
      return true
    })
    const users = pplans.map(item => {
      return [item, '', '']
    })
    setFieldValue('license', value)
    setFieldValue('users', users)
  }

  const handleCheckboxChange = (id, checked) => {
    const student = students.find(item => item.id === id)
    if (checked) {
      setFieldValue('students', [...values.students, { id: student.id, name: student.name } ])
    } else {
      setFieldValue('students', [...values.students.filter(item => item.id !== student.id) ])
    }
  }

  const handleModalityCheckboxChange = (value, checked) => {
    if (checked) {

      if (!values.modalities.includes(value)) {
        setFieldValue('modalities', [...values.modalities, value])
      }

    } else {
      if (values.modalities.includes(value)) {
        setFieldValue('modalities', [...values.modalities.filter(item => item !== value) ])
      }
    }
  }

  const handleUserChange = (field, index, value) => {
    let newUsers = [...values.users]
    if (field === 'name') {
      newUsers[index][1] = value
    } else if (field === 'email') {
      newUsers[index][2] = value
    }
    setFieldValue('users', newUsers)
  }

  const handleClinicChange = (field, index, value) => {
    let newClinics = [...values.clinics]
    newClinics[index][field] = value
    setFieldValue('clinics', newClinics)

    // if (field === 'name') {
    //   newUsers[index][1] = value
    // } else if (field === 'email') {
    //   newUsers[index][2] = value
    // }
    // setFieldValue('users', newUsers)
  }

  const handleAddClinic = e => {
    e.preventDefault()
    const newClinics = [...values.clinics, { name: '', city: '', uf: '' }]
    setFieldValue('clinics', newClinics)
  }

  const handleChangePlanMode = (isAdmin) => {
    setFieldValue('adminAsUser', isAdmin)
    if (isAdmin === false) {
      // const value = e.target.value
      // let deleted = false
      // const pplans = me.account.settings.plans.filter(item => {
      //   if (!deleted && item === Number(value)) {
      //     deleted = true
      //     return false
      //   }
      //   return true
      // })
      const users = me.account.settings.plans.map(item => {
        return [item, '', '']
      })
      // setFieldValue('license', value)
      setFieldValue('users', users)
    }
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>
      <div>
        {activeStep === 0 && (
          <Box>
            <Box textAlign='center' mb={5}>
              <Text large>Seja bem-vindo! Você também é um usuário médico?</Text>
            </Box>

            <Flex justifyContent='center' mb={5}>
              <BigCheckbox mr={2} checked={values.adminAsUser === true} onClick={() => handleChangePlanMode(true)}>Sim, sou médico e vou utilizar o TelerisOn</BigCheckbox>
              <BigCheckbox ml={2} checked={values.adminAsUser === false} onClick={() => handleChangePlanMode(false)}>Não, vou apenas administrar</BigCheckbox>
            </Flex>

          </Box>
        )}
        {values.adminAsUser === true && activeStep === 1 && (
          <Box>
            <Box textAlign='center' mb={4}>
              <Text large>Complete seus dados</Text>
            </Box>

            <Box>
              <Box mr={3}>
                <Label required>Seu celular</Label>
                <Field width='100%' name='cellPhoneNumber' autoComplete='nope' component={FieldInput} />
              </Box>
            </Box>

            <Flex>
              <Box mr={3}>
                <Label required>Seu CRM</Label>
                <Field width='100%' name='crm' autoComplete='nope' component={FieldInput} />
              </Box>
              <Box>
                <Label required>UF</Label>
                <Field width='100%' name='crmUF' autoComplete='nope' component={FieldSelect} options={states.map(item => ({ label: item, value: item }))} />
              </Box>
            </Flex>

            <Box>
              <Label required>Sua especialidade</Label>
              <Field width='100%' name='specialty' autoComplete='nope' list='specialties' component={FieldInput} />
              <datalist id='specialties'>
                <option value='Cabeça e Pescoço' />
                <option value='Cardiologia' />
                <option value='Coluna' />
                <option value='Medicina Interna' />
                <option value='Músculo Esquelético' />
                <option value='Neuro' />
                <option value='Saúde da Mulher' />
                <option value='Radiologia Dentária' />
                <option value='Vascular' />
              </datalist>
            </Box>

            <Box>
              <Label required>Sua licença</Label>
              {/* <Field width='100%' name='license' autoComplete='nope' component={FieldSelect} options={me.account.settings.plans.map(item => ({ label: plans[item], value: item }))} /> */}
              <Select value={values.license} width='100%' placeholder='Selecione...' onChange={handleChangeLicense}>
                {me.account.settings.plans.map(item => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Select>
            </Box>

          </Box>
        )}

        {values.adminAsUser === true && activeStep === 2 && (
          <Box>
            <Box textAlign='center' mb={3}>
              <Text large>Você trabalha com quais modalidades?</Text>
            </Box>

            <Box textAlign='center' mb={5}>
              <Text>Esta informação será importante para melhorar a sua experiência de navegação</Text>
            </Box>

            <Flex mb={6}>
              <Box mr={4}><Checkbox checked={values.modalities.includes('TC')} onChange={checked => handleModalityCheckboxChange('TC', checked)} label='TOMOGRAFIA' /></Box>
              <Box mr={4}><Checkbox checked={values.modalities.includes('RM')} onChange={checked => handleModalityCheckboxChange('RM', checked)} label='RESSONÂNCIA' /></Box>
              <Box mr={4}><Checkbox checked={values.modalities.includes('US')} onChange={checked => handleModalityCheckboxChange('US', checked)} label='ULTRASSONOGRAFIA' /></Box>
              <Box mr={4}><Checkbox checked={values.modalities.includes('MG')} onChange={checked => handleModalityCheckboxChange('MG', checked)} label='MAMOGRAFIA' /></Box>
            </Flex>
          </Box>
        )}

        {((values.adminAsUser === false && activeStep === 1) || (values.adminAsUser === true && activeStep === 3)) && (
          <Box>
            <Box textAlign='center' mb={3}>
              <Text large>Cadastre os usuários</Text>
            </Box>

            <Box textAlign='center' mb={5}>
              <Text>Preencha o nome e email de cada usuário. Eles receberão o convite por email.</Text>
            </Box>

            <Box mb={4}>
              {values.users.map((item, index) => (
                <Flex key={index} justifyContent='center'>
                  <Box width={60} mr={4}>{item[0]}</Box>
                  <Box mr={4} mb={2}><Input value={item[1]} width='100%' name='name' autoComplete='nope' placeholder='Nome' onChange={e => handleUserChange('name', index, e.target.value)} /></Box>
                  <Box mr={4} mb={2}><Input value={item[2]} width='100%' name='email' autoComplete='nope' placeholder='Email' onChange={e => handleUserChange('email', index, e.target.value)} /></Box>
                </Flex>
              ))}
            </Box>
            <Box>
              <Text>Você também pode deixar em branco e cadastrar depois</Text>
            </Box>

          </Box>
        )}

        {((values.adminAsUser === false && activeStep === 2) || (values.adminAsUser === true && activeStep === 4)) && (
          <Box>

            <Box textAlign='center' mb={5}>
              <Text large>Cadastre as clínicas</Text>
            </Box>

            <Box>
              {values.clinics.map((item, index) => (
                <Flex justifyContent='center' key={index}>
                  <Box mr={4} mb={2}><Input value={item.name} width='100%' name='name' autoComplete='nope' placeholder='Nome' onChange={e => handleClinicChange('name', index, e.target.value)} /></Box>
                  <Box mr={4} mb={2}><Input value={item.city} width='100%' name='city' autoComplete='nope' placeholder='Cidade' onChange={e => handleClinicChange('city', index, e.target.value)} /></Box>
                  <Box mr={4}>
                    <Select value={item.uf} width='100%' name='uf' autoComplete='nope' options={states.map(item => ({ label: item, value: item }))} onChange={e => handleClinicChange('uf', index, e.target.value)}>
                    {states.map(item => (
                      <option key={item} value={item}>{item}</option>
                    ))}
                    </Select>
                  </Box>
                  {/* <Box mr={4}><Field width='100%' name='name' autoComplete='nope' placeholder='Nome' component={FieldInput} /></Box> */}
                  {/* <Box mr={4}><Field width='100%' name='city' autoComplete='nope' placeholder='Cidade' component={FieldInput} /></Box> */}
                  {/* <Box mr={4}><Field width='100%' name='uf' autoComplete='nope' component={FieldSelect} options={states.map(item => ({ label: item, value: item }))} /></Box> */}
                </Flex>
              ))}
              <Box css={css`text-align: right; margin-right: 117px; margin-top: 20px;`}><Button onClick={handleAddClinic}>+</Button></Box>
            </Box>

            <Box>
              <Text>Você também pode deixar em branco e cadastrar depois</Text>
            </Box>

          </Box>
        )}




        {activeStep === 33 && (
          <Box>
            <Box>Cadastre sua primeira clínica</Box>
            <Box></Box>

            <Box>
              <Label required>Nome da clínica</Label>
              <Field width='100%' name='clinicName' autoComplete='nope' component={FieldInput} />
            </Box>

            <Box>
              <Label required>Cidade</Label>
              <Field width='100%' name='clinicCity' autoComplete='nope' component={FieldInput} />
            </Box>

            <Box>
              <Label required>Estado</Label>
              <Field width='100%' name='clinicUF' autoComplete='nope' component={FieldInput} />
            </Box>

          </Box>
        )}




        {activeStep === 11 && (
          <Box>
            <Box mb={2}><Label>{t('Students')}</Label></Box>
            <Box p={2} css={css`overflow: scroll; max-height: calc(70vh - 150px); min-height: 40px; border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'grey'};`}>
            {students.map(item => (
              <Flex ai='center' key={item.id}>
                <Box mr={2} mb={1}><Checkbox label={item.name} checked={selectedIds.includes(item.id)} onChange={checked => handleCheckboxChange(item.id, checked)} /></Box>
                {/* <Box>{item.name}</Box> */}
              </Flex>
            ))}
            </Box>
          </Box>
        )}
        {activeStep === 33 && (
          <Box>
            <Box mb={4}>{t('Revise as informações')}</Box>
            <Box mb={3}>
              <Label>{t(`Class name`)}:</Label>
              <Box>{values.name}</Box>
            </Box>
            <Box mb={1}><Label>{t('Students')}</Label></Box>
            <Box css={css`overflow: scroll; max-height: calc(70vh - 150px); min-height: 40px;`}>
              {values.students.map(item => (
                <Box key={item.id} mb={1}>- {item.name}</Box>
              ))}
            </Box>
          </Box>
        )}

      </div>

      <Flex alignItems='center' mt={4}>
        {activeStep !== 0 && (<Box cursor='pointer' onClick={onBack} mr={4}>Voltar</Box>)}
        <Box><Button type='submit' loading={isSubmitting}>{isLastStep ? (isCreating ? 'Concluir' : 'Salvar') : 'Próximo'}</Button></Box>
      </Flex>

    </form>
  )
}

export default MultiStepForm
