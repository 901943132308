import { useState, useEffect, useRef } from 'react'

const useSpeechRecognition = ({ continuous = true, lang = 'pt-BR', maxAlternatives = 1, interimResults = true }) => {
  const [isRecording, setIsRecording] = useState(false)
  const [interim, setInterim] = useState('')
  const [finalTranscript, setFinalTranscript] = useState('')

  const recognitionRef = useRef(null)

  const onResult = e => {
    const recognition = recognitionRef.current

    let finalTranscript = ''
    let interim = ''

    setFinalTranscript('')

    if (typeof(e.results) === 'undefined') {
      recognition.onend = null
      recognition.stop()
      return
    }

    for (var i = e.resultIndex; i < e.results.length; ++i) {
      if (e.results[i].isFinal) {
        finalTranscript += e.results[i][0].transcript
        finalTranscript = finalTranscript.replace(/\sponto e vírgula/ig, ';')
        finalTranscript = finalTranscript.replace(/\svírgula/ig, ',')
        finalTranscript = finalTranscript.replace(/\spausa/ig, ',')
        finalTranscript = finalTranscript.replace(/\scomma/ig, ',')
        finalTranscript = finalTranscript.replace(/\scoma/ig, ',')
        finalTranscript = finalTranscript.replace(/\sponto final/ig, '.')
        finalTranscript = finalTranscript.replace(/\sponto de exclamação/ig, '!')
        finalTranscript = finalTranscript.replace(/\sponto de interrogação/ig, '?')
        finalTranscript = finalTranscript.replace(/\sponto/ig, '.')
        finalTranscript = finalTranscript.replace(/\sabre parênteses/ig, ' (')
        finalTranscript = finalTranscript.replace(/\sabre parentes/ig, '(')
        finalTranscript = finalTranscript.replace(/\sfecha parênteses/ig, ')')
        finalTranscript = finalTranscript.replace(/\sfecha parentes/ig, ')')
        finalTranscript = finalTranscript.replace(/\(\s/ig, '(')
        finalTranscript = finalTranscript.replace(/\sdois pontos/ig, ':')
        finalTranscript = finalTranscript.replace(/\s2 pontos/ig, ':')
        finalTranscript = finalTranscript.replace(/\spor cento/ig, '%')
        finalTranscript = finalTranscript.replace(/\saspas/ig, '"')
        finalTranscript = finalTranscript.replace(/\smilímetros/ig, 'mm')
        finalTranscript = finalTranscript.replace(/\scentímetros/ig, 'cm')
        finalTranscript = finalTranscript.replace(/\squebra de linha/ig, '↵')
        finalTranscript = finalTranscript.replace(/\sparágrafo/ig, '↵')
      } else {
        interim += e.results[i][0].transcript
        interim = interim.replace(/\sponto e vírgula/ig, '; ')
        interim = interim.replace(/\svírgula/ig, ', ')
        interim = interim.replace(/\spausa/ig, ', ')
        interim = interim.replace(/\scomma/ig, ', ')
        interim = interim.replace(/\scoma/ig, ', ')
        interim = interim.replace(/\sponto final/ig, '. ')
        interim = interim.replace(/\sponto de exclamação/ig, '!')
        interim = interim.replace(/\sponto de interrogação/ig, '?')
        interim = interim.replace(/\sponto/ig, '.')
        interim = interim.replace(/\sabre parentes/ig, ' (')
        interim = interim.replace(/\sabre parênteses/ig, '(')
        interim = interim.replace(/\sfecha parênteses/ig, ')')
        interim = interim.replace(/\sfecha parentes/ig, ')')
        interim = interim.replace(/\(\s/ig, '(')
        interim = interim.replace(/\sdois pontos/ig, ': ')
        interim = interim.replace(/\2 pontos/ig, ': ')
        interim = interim.replace(/\spor cento/ig, '% ')
        interim = interim.replace(/\saspas/ig, '"')
        interim = interim.replace(/\smilímetros/ig, 'mm')
        interim = interim.replace(/\scentímetros/ig, 'cm')
        interim = interim.replace(/\squebra de linha/ig, '↵')
        interim = interim.replace(/\sparágrafo/ig, '↵')
      }
    }

    setInterim(interim)

    if (finalTranscript && finalTranscript !== '')
      setFinalTranscript(finalTranscript)
  }

  function toggle() {
    try {
      const recognition = recognitionRef.current
      if (!isRecording) {
        setIsRecording(true)
        recognition.start()
      } else {
        setIsRecording(false)
        recognition.stop()
      }
    } catch(e) {
      setIsRecording(false)
      console.error(e.message)
    }
  }

  const stop = () => {
    try {
      setIsRecording(false)
      recognitionRef.current.stop()
    } catch(e) {
      setIsRecording(false)
      console.error(e.message)
    }
  }

  useEffect(() => {
    try {
      const speechRecognition = window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition || window.oSpeechRecognition || window.SpeechRecognition
      if (!speechRecognition) return null
      const recognition = new speechRecognition()
      recognition.continuous = continuous
      recognition.interimResults = interimResults
      recognition.lang = lang
      recognition.maxAlternatives = maxAlternatives
      recognition.onresult = onResult
      recognition.onend = () => setIsRecording(false)
      recognitionRef.current = recognition
    } catch(e) {
      setIsRecording(false)
      console.error(e.message)
    }
  }, [continuous, interimResults, lang, maxAlternatives])

  return [isRecording, interim, finalTranscript, toggle, stop]
}

export default useSpeechRecognition
