export const weekdays = 'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split('_')
export const months = 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')

export const offsetDate = text => {
  const date = new Date(text)
  return new Date(date.valueOf() + (date.getTimezoneOffset() * 60000))
}


export const lastXDays = days => {
  const date = new Date()
  date.setDate(date.getDate() - days)
  date.setHours(0, 0, 0, 0)
  return date
}

export const today = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

export const yesterday = () => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  yesterday.setHours(0, 0, 0, 0)
  return yesterday
}

export const tomorrow = () => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)
  return tomorrow
}

export const lastWeek = () => {
  const lastWeek = new Date()
  lastWeek.setDate(lastWeek.getDate() - 7)
  lastWeek.setHours(0, 0, 0, 0)
  return lastWeek
}

export const beginningOfDay = date => {
  if (!date) return undefined
  const day = date
  day.setHours(0, 0, 0, 0)
  return day
}

export const endOfDay = date => {
  if (!date) return undefined
  const day = date
  day.setHours(23, 59, 59, 999)
  return day
}

export const beginningOfThisMonth = () => {
  const thisMonth = new Date()
  thisMonth.setDate(1)
  thisMonth.setHours(0, 0, 0, 0)
  return thisMonth
}

export const beginningOfPastMonth = (monthAgo = 1) => {
  const lastMonth = new Date()
  lastMonth.setMonth(lastMonth.getMonth() - monthAgo)
  lastMonth.setDate(1)
  lastMonth.setHours(0, 0, 0, 0)
  return lastMonth
}

export const padStart = (string, length, pad) => {
  const s = String(string)
  if (!s || s.length >= length) return string
  return `${Array((length + 1) - s.length).join(pad)}${string}`
}

export const arrayDateTimeFromIsoString = text => text.slice(0, 16).replace('T', '-').replace(/:/g, '-').split('-')

export const arrayIntDateFromIsoString = text => {
  const ar = arrayDateTimeFromIsoString(text)
  return [Number(ar[0]), (Number(ar[1]) - 1), Number(ar[2]), Number(ar[3]), Number(ar[4])]
}

export const neutralDateDateFromIsoString = text => {
  const d = arrayIntDateFromIsoString(text)
  return (new Date(...d))
}

export const formatDateTimeFromIsoString = text => {
  const d = arrayDateTimeFromIsoString(text)
  return `${d[2]}/${d[1]}/${d[0]} ${d[3]}:${d[4]}`
}

export const formatDateFromIsoString = text => {
  if (!text) return ''
  const d = arrayDateTimeFromIsoString(text)
  return `${d[2]}/${d[1]}/${d[0]}`
}

export const formatTimeFromIsoString = text => {
  const d = arrayDateTimeFromIsoString(text)
  return `${d[3]}:${d[4]}`
}
