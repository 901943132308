import React from 'react'
import styled from 'styled-components'

function Button({ children, className, ...rest }) {
  return (
    <button className={className} {...rest}>
      {children}
    </button>
  )
}

const StyledButton = styled(Button)`
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  user-select: none;
  white-space: nowrap;

  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  color: white;
  background-color: ${props => props.theme?.colors?.primary || 'darkgrey'};
  border-radius: 4px;
  padding: 0.6em 1.2em;

  &:hover {
    background: ${props => props.theme?.colors?.primary600 || 'black'};
  }
  &:focus {
    outline: 3px solid ${props => props.theme?.colors?.grey200 || 'grey'};
  }
  &:active {
    background: ${props => props.theme?.colors?.primary700 || 'black'};
  }
  &:disabled {
    background-color: ${props => props.theme?.colors?.primary300 || 'grey'};
    cursor: not-allowed;
  }
`

export default StyledButton
