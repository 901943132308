import React, { memo } from 'react'
import { Link } from '@reach/router'
import { Box } from '@alobato/flex-box'

import Button from '../../components/Button'
import Input from '../../components/Input'

import { useTranslation } from 'react-i18next'

import FormErrorBox from '../../components/FormErrorBox'

const Form = ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {

  const { t } = useTranslation()

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Input width='100%' type='email' autoComplete='email' placeholder={t('Email')} name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='email' errors={errors} touched={touched} />
      </Box>
      <Box mb={1}>
        <Input width='100%' type='password' autoComplete='current-password' placeholder={t('Senha')} name='password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='password' errors={errors} touched={touched} />
      </Box>
      <Box mb={4}>
        <Link to='/esqueci-a-senha'>{t('Esqueci a senha')}</Link>
      </Box>
      <Box ta='center'>
        <Button type='submit' loading={isSubmitting}>{t('Entrar')}</Button>
      </Box>
    </form>
  )
}

export default memo(Form)
