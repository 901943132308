import React, { memo } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Formik } from 'formik'

import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import Form from './Form'
import validationSchema from './validationSchema'

import { useMutation } from '@apollo/client'
import { CREATE, LIST } from './Gqls'

const New = ({ accountId, onRequestClose, onCompleted, notifier }) => {

  const { t } = useTranslation()

  const [createAccountUser] = useMutation(CREATE)

  const initialValues = {
    name: '',
    email: '',
    cellPhoneNumber: '',
    role: 'USER',
    isDoctor: false,
    specialty: '',
    crm: '',
    crmUF: '',
    signatureImage: ''
  }

  const handleSubmit = async variables => {

    const update = (cache, { data: { createAccountUser } }) => {
      const cacheList = cache.readQuery({ query: LIST, variables: { accountId } })
      cache.writeQuery({ query: LIST, variables: { accountId }, data: { ...cacheList, accountUsers: [createAccountUser, ...cacheList.accountUsers] } })
    }

    try {
      await createAccountUser({ variables: { ...variables, AccountId: accountId }, update })
      onCompleted()
    } catch(error) {
      console.error(error)
      notifier.error(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Novo')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} isCreating={true} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default memo(New)
