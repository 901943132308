import { gql } from '@apollo/client'

export const GET = gql`
  query Examination($code: ID!) {
    examination(code: $code) {
      code
      status
      indication
      anamnesis
      tree
      selectedProtocol
      selectedProtocols
      filters
      selectedModel
      selectedModels
      groupsCollapse
      hiddenGroups
      selectedBlocks
      selectedPhrases
      selectedComplementPhrases
      groupsIgnoringSelections
      fields
      vars
      accessionNumber
      PatientName
      PatientAge
      PatientWeight
      PatientHeight
      PatientGender
      PhysicianName
      PhysicianCrm
      clinic {
        name
        defaultEquipment
      }
      examinedAt
      previousExam1At
      previousExam2At
      signedAt
      underCorrectionAt
      correctedAt
      orderDetails
      createdAt

      list {
        content
      }

      order {
        id
        attachments
        anamnesis

      }
    }
    previousExaminations(code: $code) {
      code
      PatientName
      tree
      examinedAt
      orderDetails
      PatientAge
    }
  }
`

export const UPDATE = gql`
  mutation UpdateExamination($code: ID!, $tree: String, $indication: String, $selectedProtocol: String, $selectedProtocols: JSON, $filters: JSON, $selectedModel: String, $selectedModels: JSON, $groupsCollapse: JSON, $vars: JSON, $hiddenGroups: JSON, $groupsIgnoringSelections: JSON, $selectedBlocks: JSON, $selectedPhrases: JSON, $selectedComplementPhrases: JSON, $fields: JSON, $status: JSON) {
    updateExamination(code: $code, tree: $tree, indication: $indication, selectedProtocol: $selectedProtocol, selectedProtocols: $selectedProtocols, filters: $filters, selectedModel: $selectedModel, selectedModels: $selectedModels, groupsCollapse: $groupsCollapse, vars: $vars, hiddenGroups: $hiddenGroups, groupsIgnoringSelections: $groupsIgnoringSelections, selectedBlocks: $selectedBlocks, selectedPhrases: $selectedPhrases, selectedComplementPhrases: $selectedComplementPhrases, fields: $fields, status: $status) {
      code
    }
  }
`

export const FAST_UPDATE = gql`
  mutation FastUpdateExamination($code: ID!, $PatientWeight: Int, $PatientHeight: Int, $previousExam1At: Date, $previousExam2At: Date) {
    fastUpdateExamination(code: $code, PatientWeight: $PatientWeight, PatientHeight: $PatientHeight, previousExam1At: $previousExam1At, previousExam2At: $previousExam2At)
  }
`
