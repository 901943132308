import React, { memo } from 'react'
import { Flex, Box } from '@alobato/flex-box'

import Title from '../../components/Title'
import Button from '../../components/_new/Button'
import Modal from '../../components/StyledModal'

import { useTranslation } from 'react-i18next'
import { useHotkeys } from 'react-hotkeys-hook'

import List from './List'
import Show from './Show'
import New from './New'
import Edit from './Edit'
import DocContentSettings from './DocContentSettings'
import DocLayoutSettings from './DocLayoutSettings'
import IllustrationSettings from './IllustrationSettings'

const Home = ({ id, path, navigate, notifier }) => {

  const { t } = useTranslation()

  useHotkeys('n', () => {
    navigate(`novo${window.location.search}`)
  })

  return (
    <>
      <Box mb={5} p={[3, 4]}>

        <Box>

          <Flex justifyContent='space-between' alignItems='center'>
            <Box mb={4}>
              <Title>{t('Clínicas')}</Title>
            </Box>
            <Box>
              <Button onClick={() => navigate(`novo${window.location.search}`)}>Nova</Button>
            </Box>
          </Flex>

          <List
            notifier={notifier}
          />

          {/* <List navigate={navigate} notifier={notifier} /> */}

        </Box>

      </Box>

      {path === '/:id' && id &&
        <Modal
          onCloseCompleted={() => navigate('../')}
          render={
            ({onRequestClose}) => (
              <Show
                id={id}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      }

      {path === '/:id/editar' &&
        <Modal
          onCloseCompleted={() => navigate('../../')}
          render={
            ({onRequestClose}) => (
              <Edit
                id={id}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
              />
            )
          }
        />
      }

      {path === '/:id/conteudo' &&
        <Modal
          onCloseCompleted={() => navigate('../../')}
          render={
            ({onRequestClose}) => (
              <DocContentSettings
                id={id}
                notifier={notifier}
                onRequestClose={onRequestClose}
                onError={errorMessage => notifier.error(t(errorMessage))}
              />
            )
          }
        />
      }

      {path === '/:id/layout' &&
        <Modal
          onCloseCompleted={() => navigate('../../')}
          render={
            ({onRequestClose}) => (
              <DocLayoutSettings
                id={id}
                notifier={notifier}
                onRequestClose={onRequestClose}
                onError={errorMessage => notifier.error(t(errorMessage))}
              />
            )
          }
        />
      }

      {path === '/:id/ilustracao' &&
        <Modal
          onCloseCompleted={() => navigate('../../')}
          render={
            ({onRequestClose}) => (
              <IllustrationSettings
                id={id}
                notifier={notifier}
                onRequestClose={onRequestClose}
                onError={errorMessage => notifier.error(t(errorMessage))}
              />
            )
          }
        />
      }

      {path === '/novo' &&
        <Modal
          onCloseCompleted={() => navigate('../')}
          render={
            ({onRequestClose}) => (
              <New
                onRequestClose={onRequestClose}
                onError={errorMessage => notifier.error(t(errorMessage))}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
              />
            )
          }
        />
      }
    </>
  )
}

export default memo(Home)
