import React from 'react'
import { render } from 'react-dom'

import NotificationContainer, { eventManager } from './NotificationContainer'

let id = 0

const notifier = ({ message, type }) => {
  let modalRoot = document.querySelector(`.simple-react-notifier.top-center`)
  if (!modalRoot) {
    modalRoot = document.createElement('div')
    modalRoot.classList.add('simple-react-notifier', 'top-center')
    document.body.appendChild(modalRoot)
  }
  render(<NotificationContainer message={message} type={type} id={id} cleared={() => { try { document.body.removeChild(modalRoot) } catch (e) {}}} />, modalRoot)
  id++
  return id - 1
}

notifier.info = message => notifier({ message, type: 'info' })
notifier.success = message => notifier({ message, type: 'success' })
notifier.error = message => notifier({ message, type: 'error' })
notifier.warn = message => notifier({ message, type: 'warn' })
notifier.dismiss = id => eventManager.remove(id)

export default notifier
