import React, { useState, useCallback, useRef, useEffect, forwardRef } from 'react'
import styled from 'styled-components'
import useDebounce from '@alobato/use-debounce'

const InputDebounced = forwardRef((props, ref) => {

  const { onChange, className, defaultValue, placeholder, ...rest } = props

  const onChangeCallBack = useCallback(onChange, [])

  const [value, setValue] = useState(defaultValue)

  const debouncedValue = useDebounce(value, 600)

	const didMountRef = useRef(false)
	useEffect(() => {
    if (didMountRef.current)
		  onChangeCallBack(debouncedValue)
    else
      didMountRef.current = true
  }, [debouncedValue, onChangeCallBack])

	const handleChange = e => {
		setValue(e.target.value)
	}

  const handleBlur = () => {
    onChange(value)
  }

  return (
    <input
      type='number'
      min='0'
      max='400'
      step='1'
      value={value}
      className={className}
      onChange={handleChange}
      placeholder={placeholder}
      onBlur={handleBlur}
      autoComplete='nope'
      maxLength={3}
      ref={ref}
      {...rest}
    />
  )

})

const StyledInputDebounced = styled(InputDebounced)`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: 1.6;

  padding: 0 4px;
  background-color: white;
  border: 1px solid hsla(0, 0%, 85%, 1);
  border-radius: 4px;
  outline: 0;

  width: 80px;

  font-size: 12px;

  transition: all 300ms;
  &:hover, &:focus {
    border-color: #40a9ff !important;
    background-color: white;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  &:active {
    border-color: #096dd9 !important;
  }
`

export default StyledInputDebounced
