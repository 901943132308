import React from 'react'
import { Redirect } from 'react-router-dom'
import md5 from 'md5'
import { css } from 'styled-components/macro'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'
import * as yup from 'yup'
import { Box } from '../../components/_new/FlexBox'
import Text from '../../components/_new/Text'
import { useAppContext } from '../../contexts/AppContext'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

const plans = {
  BASIC: 'Basic',
  PLUS: 'Plus',
  PRO: 'Pro',
}

const validationSchemas = [
  yup.object().shape({
    email: yup.string().email('Email inválido').required('Campo obrigatório'),
  }),


    // yup.object().shape({
    //   name: yup
    //     .string()
    //     .required(t('Required field'))
    //     .test('name', t('There is a class registered with this name'), async (value) => {
    //       try {
    //         const response = await (await fetch(`${BASE_API}/check-class-name/${value.trim()}`)).json()
    //         return !response.exists
    //       } catch(err) {
    //         console.error(err)
    //         return true
    //       }
    //     }),
    // }),


  yup.object().shape({
    validationCode: yup.string().required('Campo obrigatório').test('validationCode', 'Código inválido', function(value) {
      const md5Hash = md5(this.parent.email.trim())
      const code = md5Hash.replace(/\D/g, '').substring(0, 4)
      if (value && (value.trim() === code)) return true
      return false
    })
  }),

  yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    password: yup.string().required('Campo obrigatório').matches(/^.{8,}$/, 'Sua senha precisa ter pelo menos 8 caracteres'),
    agree: yup.boolean().oneOf([true], 'Você deve concordar com os termos para criar uma conta')
  })
]


function RegisterMultiStep() {
  const { state } = useAppContext()
  console.log('state', state)
  const { message } = useMessageContext()

  const [multiStepRegisterValidateEmail] = useMutation(gql`mutation($email: String!) { multiStepRegisterValidateEmail(email: $email) }`)
  const [register] = useMutation(gql`mutation($accountName: String, $subdomain: String, $name: String!, $email: String!, $password: String!, $plans: JSON) { register(accountName: $accountName, subdomain: $subdomain, name: $name, email: $email, password: $password, plans: $plans) { token } }`)

  const [activeStep, setActiveStep] = React.useState(0)
  const steps = ['1', '2', '3']
  const isLastStep = activeStep === steps.length - 1
  const currentValidationSchema = validationSchemas[activeStep]

  const initialValues = { email: '', validationCode: '', accountName: '', subdomain: '', name: '', password: '', agree: false }

  const showAccountName = (typeof state.plans === 'object' && state.plans !== null && !Array.isArray(state.plans))

  async function handleSubmit(variables, { setSubmitting }) {

    if (activeStep === 0) {
      try {
        const result = await multiStepRegisterValidateEmail({ variables: { email: variables.email } })
        if (result.data.multiStepRegisterValidateEmail === true) {
          return setActiveStep(activeStep + 1)
        }
      } catch(err) {
        setSubmitting(false)
        console.error(err)
        const errorMessage = err.message.replace('GraphQL error: ', '')
        message({ level: 'error', message: errorMessage })
        return false
      }

    }

    if (activeStep > 0) {

      if (isLastStep) {

        console.log('variables', variables)

        if (showAccountName && !variables.subdomain) {
          return message({ level: 'error', message: 'Preencha o endereço' })
        }

        if (showAccountName && !/^[a-z0-9][a-z0-9]+[a-z0-9]$/.test(variables.subdomain)) {
          return message({ level: 'error', message: 'Endereço inválido' })
        }

        localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)

        try {
          const result = await register({ variables: { ...variables, plans: state.plans } })

          if (variables.subdomain) {
            window.location.href = `https://${variables.subdomain}.telerison.com/laudos/?token=${result.data.register.token}`
          } else {
            localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, result.data.register.token)
            setTimeout(() => { window.location.href = '/laudos' }, 10)
          }

          // localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, result.data.register.token)
          // history.push(`/laudos`)
          // window.location.href = '/laudos'
        } catch(err) {
          setSubmitting(false)
          console.error(err)
          const errorMessage = err.message.replace('GraphQL error: ', '')
          message({ level: 'error', message: errorMessage })
        }

      } else {
        return setActiveStep(activeStep + 1)
      }

    }

  }

  function handleBack() {
    setActiveStep(activeStep - 1)
  }



  if (!state?.plans) return (<Redirect to={{ pathname: '/plans' }} />)

  return (
    <Box pt={5} pb={4}>
      <Box mx='auto' maxWidth={400}>

        <Box textAlign='center' mb={4}>
          <Text fontWeight={800} css={css`font-size: clamp(2rem, 8vw - 2.5rem, 2.5rem); color: hsl(219deg 8% 11%);`}>Crie sua conta</Text>
        </Box>

        {/* <Box textAlign='center' mb={4}>
          <Text fontWeight={600} fontSize={28} css={css`font-size: clamp(1.5rem, 8vw - 2rem, 2rem);`}>Crie sua conta</Text>
        </Box> */}

        {state?.plans?.length === 1 && (
          <Box mb={5} textAlign='center' css={css`color: hsl(219deg 8% 50%);`}>
            <Text fontWeight={500} fontSize={18}>Plano escolhido:</Text> <Text fontWeight={800} fontSize={18}>{plans[state.plans[0]]}</Text>
          </Box>
        )}

        <Box mb={5}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {props => (
              <Form
                {...props}
                steps={steps}
                activeStep={activeStep}
                isLastStep={isLastStep}
                onBack={handleBack}
                isCreating
                showAccountName={showAccountName}
              />
            )}
          </Formik>
        </Box>

      </Box>
    </Box>
  )
}


export default RegisterMultiStep
