import React, { memo } from 'react'
import { Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Checkbox from '@alobato/checkbox'

import Label from '../../components/Label'
import Select from '../../components/Select'
import InputMask from '../../components/InputMask'
import FormErrorBox from '../../components/FormErrorBox'

import useScrollToError from '@alobato/use-scroll-to-error'
import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

const Form = ({ isCreating, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {

  const { t } = useTranslation()

  useFocusOnLoad('name')
  useScrollToError({errors, isValid, isSubmitting})

  // const handleChangeIsDoctor = checked => {
  //   setFieldValue('isDoctor', checked)
  //   setFieldValue('specialty', '')
  //   setFieldValue('crm', '')
  //   setFieldValue('crmUF', '')
  // }

  const handleChangeIsAdmin = checked => {
    if (checked) {
      if (values.license && values.license.includes('M')) {
        setFieldValue('license', values.license.replace('M', 'A'))
      }
    } else {
      if (values.license && values.license.includes('A')) {
        setFieldValue('license', values.license.replace('A', 'M'))
      }
    }
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>{t('Nome')}<span>*</span></Label>
        <Input width='100%' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} withError={touched.name && errors.name} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Email')}<span>*</span></Label>
        <Input type='email' width='100%' name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} withError={touched.email && errors.email} />
        <FormErrorBox fieldName='email' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
      <Checkbox label={t('Este usuário é administrador?')} checked={values.license && values.license.includes('A')} onChange={handleChangeIsAdmin} />
      </Box>

      {/* <Box mb={3}>
        <Label>{t('Celular')}</Label>
        <InputMask mask='(99) 99999-9999' placeholder='(99) 99999-9999' name='cellPhoneNumber' value={values.cellPhoneNumber} onChange={handleChange} onBlur={handleBlur} withError={touched.cellPhoneNumber && errors.cellPhoneNumber} />
        <FormErrorBox fieldName='cellPhoneNumber' errors={errors} touched={touched} />
      </Box> */}

      {/* <Box mb={3}>
        <Label>{t('Permissão')}<span>*</span></Label>
        <Select placeholder={t('Selecione...')} name='role' value={values.role} onChange={handleChange} onBlur={handleBlur} withError={touched.role && errors.role}>
          <option value='USER'>USUÁRIO</option>
          <option value='ADMIN'>ADMIN</option>
          <option value='SUPERADMIN'>SUPERADMIN</option>
        </Select>
        <FormErrorBox fieldName='role' errors={errors} touched={touched} />
      </Box> */}

      {/* <Box mb={3}>
        <Checkbox label={t('Este usuário é um médico?')} checked={values.isDoctor} onChange={handleChangeIsDoctor} />
      </Box> */}

      {false && (
        <Box>
          <Box mb={3}>
            <Label>{t('Especialidade')}<span>*</span></Label>
            <Input width='100%' name='specialty' list='specialties' value={values.specialty} onChange={handleChange} onBlur={handleBlur} withError={touched.specialty && errors.specialty} />
            <datalist id='specialties'>
              <option value='Cabeça e Pescoço' />
              <option value='Cardiologia' />
              <option value='Coluna' />
              <option value='Medicina Interna' />
              <option value='Músculo Esquelético' />
              <option value='Neuro' />
              <option value='Saúde da Mulher' />
              <option value='Radiologia Dentária' />
              <option value='Vascular' />
            </datalist>
            <FormErrorBox fieldName='specialty' errors={errors} touched={touched} />
          </Box>

          <Box mb={3}>
            <Label>{t('CRM')}<span>*</span></Label>
            <Input width='100%' name='crm' value={values.crm} onChange={handleChange} onBlur={handleBlur} withError={touched.crm && errors.crm} />
            <FormErrorBox fieldName='crm' errors={errors} touched={touched} />
          </Box>

          <Box mb={3}>
            <Label>{t('CRM UF')}<span>*</span></Label>
            <Select placeholder={t('Selecione...')} name='crmUF' value={values.crmUF} onChange={handleChange} onBlur={handleBlur} withError={touched.crmUF && errors.crmUF}>
              <option>AC</option>
              <option>AL</option>
              <option>AP</option>
              <option>AM</option>
              <option>BA</option>
              <option>CE</option>
              <option>DF</option>
              <option>ES</option>
              <option>GO</option>
              <option>MA</option>
              <option>MT</option>
              <option>MS</option>
              <option>MG</option>
              <option>PA</option>
              <option>PB</option>
              <option>PR</option>
              <option>PE</option>
              <option>PI</option>
              <option>RJ</option>
              <option>RN</option>
              <option>RS</option>
              <option>RO</option>
              <option>RR</option>
              <option>SC</option>
              <option>SP</option>
              <option>SE</option>
              <option>TO</option>
            </Select>
            <FormErrorBox fieldName='crmUF' errors={errors} touched={touched} />
          </Box>
        </Box>
      )}

      {isCreating &&
        <Box mb={3} ta='right'>
          <em>{t('Será enviado um email com a senha inicial para o usuário')}</em>
        </Box>
      }

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
}

export default memo(Form)

