import React, { memo } from 'react'
import { Router } from '@reach/router'

import MainLayout from '../../layouts/MainLayout'
import HomeLazy from './HomeLazy'

 const Index = ({ me, notifier }) => {
  return (
    <Router>
      <MainLayout me={me} path='/'>
        <HomeLazy me={me} notifier={notifier} path='/' />
      </MainLayout>
    </Router>
  )
}

export default memo(Index)
