import React, { memo } from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import Spin from '../../components/Spin'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from '@apollo/client'
import { GET, UPDATE } from './Gqls'

import Form from './IllustrationSettingsForm'

import * as Yup from 'yup'

// Yup.setLocale({
//   mixed: {
//     default: 'Campo inválido',
//     required: 'Campo obrigatório'
//   },
//   string: {
//     min: params => `Deve ter pelo menos ${params.min} caracteres`
//   }
// })

const validationSchema = Yup.object().shape({
  // paper: Yup.string().required(),
})

const IllustrationSettings = ({ data: { clinic }, onRequestClose, onError, notifier }) => {

  const { t } = useTranslation()

  const [updateClinic] = useMutation(UPDATE)

  const defaultInitialValues = {
    titleBorderColor: '#878787',
    titleBackgroundColor: '#6b6b6b',
    titleTextColor: '#ffffff',
    boxBackgroundColor: '#444444',
    boxTextColor: '#ffffff',
    format: 'png',
    zoom: '1',

  }

  const initialValues = clinic.illustrationSettings ? clinic.illustrationSettings : defaultInitialValues

  const handleSubmit = async variables => {

    console.log('variables', variables)
    // boxBackgroundColor: "#444444"
    // boxTextColor: "#ffffff"
    // format: "png"
    // titleBackgroundColor: "#476a91"
    // titleBorderColor: "#d1783d"
    // titleTextColor: "#ffffff"
    // zoom: "1"

    try {
      await updateClinic({ variables: { id: clinic.id, illustrationSettings: variables } })
      notifier.success(t('Salvo com sucesso!'))
    } catch(err) {
      console.error(err)
      const errorMessage = err.message.replace('GraphQL error: ', '')
      onError(errorMessage)
    }

  }

  return (

    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Configurar ilustração')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box maxWidth={800}>

          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {props => <Form {...props} />}
          </Formik>

        </Box>

      </Box>

    </Flex>
  )

}

const IllustrationSettingsQuery = ({ id, ...rest }) => {
  const { loading, error, data } = useQuery(GET, { fetchPolicy: 'network-only', variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <IllustrationSettings {...rest} data={data} />
}

export default IllustrationSettingsQuery
