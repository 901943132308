import React, { memo } from 'react'
import CircularProgress from '@alobato/circular-progress'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Formik } from 'formik'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import Form from './Form'
import validationSchema from './validationSchema'

import { useQuery, useMutation } from '@apollo/client'
import { GET, LIST, UPDATE } from './Gqls'

const Edit = memo(({ user, onRequestClose, onCompleted }) => {

  const { t } = useTranslation()

  const [updateUser] = useMutation(UPDATE)

  const initialValues = {
    name: user.name,
    email: user.email,
    license: user.license
  }

  const handleSubmit = async variables => {

    const update = (cache, { data: { updateUser } }) => {
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, users: cacheList.users.map(item => { if (item.id === variables.id) return updateUser; return item }) }  })
    }

    try {
      await updateUser({ variables: { ...variables, id: user.id }, update })
      onCompleted()
    } catch(error) {
      console.error(error)
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Editar')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
})

const EditQuery = ({ id, onRequestClose, onCompleted }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <CircularProgress />
  if (error) return error.message
  return <Edit user={data.user} onRequestClose={onRequestClose} onCompleted={onCompleted} />
}

export default memo(EditQuery)
