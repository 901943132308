import React, { memo } from 'react'
import Spin from '../../components/Spin'
import { Box, css } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Table, TableContainer, TableRow, TableCell, TableHeaderRow, TableHeaderCell } from '@alobato/table'

import PopOver from '../../components/PopOver'
import { Menu, MenuItem } from '../../components/Menu'
import { MoreIcon } from '../../components/Icons'
import { FormatDateTime } from '../../components/Format'

import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from '@apollo/client'
import { LIST, DELETE, SET_DEFAULT } from './Gqls'



const List = memo(({ data: { clinics: items }, page, setPage, order, setOrder, notifier, refetch }) => {

  const { t } = useTranslation()

  const [deleteClinic] = useMutation(DELETE)
  const [setDefaultClinic] = useMutation(SET_DEFAULT)

  const handleDelete = async id => {
    if (!window.confirm(t('Você tem certeza que deseja excluir?'))) return false

    const update = (cache, { data: { deleteClinic } }) => {
      if (!deleteClinic) return false
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, clinics: cacheList.clinics.filter(item => item.id !== id) } })
    }

    try {
      await deleteClinic({ variables: { id }, update })
      notifier.success(t('Excluído com sucesso!'))
    } catch(error) {
      console.error(error)
    }
  }

  const handleDefault = async (id) => {
    console.log('id', id)
    if (!window.confirm(t('Você tem certeza que deseja definir esta clínica como padrão?'))) return false

    try {
      await setDefaultClinic({ variables: { id } })
      notifier.success(t('Alterado com sucesso!'))
      refetch()
    } catch(error) {
      console.error(error)
    }
  }

  return (
    <Box mb={5}>

      {items.length > 0 &&
        <>
          <Box>{`${t('Total')}:  ${items.length}`}</Box>
          <TableContainer>
            <Table>
              <TableHeaderRow>
                <TableHeaderCell width={160}>{t('Criado em')}</TableHeaderCell>
                <TableHeaderCell>{t('Nome')}</TableHeaderCell>
                <TableHeaderCell>{t('Nome OCR')}</TableHeaderCell>
                <TableHeaderCell>{t('Código')}</TableHeaderCell>
                <TableHeaderCell>{t('UF')}</TableHeaderCell>
                <TableHeaderCell>{t('Cidade')}</TableHeaderCell>
                <TableHeaderCell>{t('Equipamento padrão')}</TableHeaderCell>
                <TableHeaderCell>{t('Clínica padrão')}</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {items.map(item => (
                <TableRow key={item.id}>
                  <TableCell data-title={t('Criado em')}><FormatDateTime>{item.createdAt}</FormatDateTime></TableCell>
                  <TableCell data-title={t('Nome')}>{item.name}</TableCell>
                  <TableCell data-title={t('Nome OCR')}>{item.nickname}</TableCell>
                  <TableCell data-title={t('Código')}>{item.shortname}</TableCell>
                  <TableCell data-title={t('UF')}>{item.uf}</TableCell>
                  <TableCell data-title={t('Cidade')}>{item.city}</TableCell>
                  <TableCell data-title={t('Equipamento padrão')}>{item.defaultEquipment}</TableCell>
                  <TableCell data-title={t('Clínica padrão')}>{item.isDefault ? 'Sim' : ''}</TableCell>
                  <TableCell width={40} lineHeight={0}>
                    <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                      <Menu>
                        <MenuItem to={item.id}>{t('Detalhes')}</MenuItem>
                        <MenuItem to={`${item.id}/editar`}>{t('Editar')}</MenuItem>
                        <MenuItem to={`${item.id}/conteudo`}>{t('Configurar conteúdo')}</MenuItem>
                        <MenuItem to={`${item.id}/layout`}>{t('Configurar layout')}</MenuItem>
                        <MenuItem to={`${item.id}/ilustracao`}>{t('Configurar ilustração')}</MenuItem>
                        <MenuItem onClick={() => handleDelete(item.id)}>{t('Excluir')}</MenuItem>
                        <MenuItem onClick={() => handleDefault(item.id)}>{t('Definir como padrão')}</MenuItem>
                      </Menu>
                    )}>
                      <MoreIcon style={{cursor: 'pointer'}} />
                    </PopOver>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </>
      }

      {items.length === 0 &&
        <Box my={5} ta='center' css={css`@keyframes fadein { 0% { opacity: 0 } 66% { opacity: 0 } 100% { opacity: 1 } } & { animation: 0.5s ease 0s normal forwards 1 fadein; }`}>
          <Text medium fw={300} color='grey400'>{t('Nenhum registro encontrado')}</Text>
        </Box>
      }

    </Box>
  )
})

const ListQuery = props => {
  const { loading, error, data, refetch } = useQuery(LIST)
  if (loading) return <Spin />
  if (error) return error.message
  return <List notifier={props.notifier} data={data} refetch={refetch} />
}

export default memo(ListQuery)
