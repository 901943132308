import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components/macro'
import { Box } from '@alobato/flex-box'

export const Menu = styled(({ children, className, ...rest }) => (<Box bg='grey0' py={2} px={3} minWidth={100} className={className} {...rest}>{children}</Box>))`
  box-shadow: ${props => props.theme && props.theme.shadows && props.theme.shadows.menuShadow ? props.theme.shadows.menuShadow : '0 2px 8px hsla(0, 0%, 0%, 0.15)'};
  border-radius: ${props => props.theme && props.theme.radius && props.theme.radius.menuRadius && props.theme.radius.menuRadius ? props.theme.radius.menuRadius : '0'};
  border: ${props => props.theme && props.theme.borders && props.theme.borders.menuBorder ? props.theme.borders.menuBorder : 'none'};
`

export const MenuItem = ({ children, className, to, ...rest }) => {
  if (to) {
    return <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}><Box py={1} cursor='pointer' className={className}  {...rest}>{children}</Box></Link>
  }
  return <Box py={1} cursor='pointer' className={className}  {...rest}>{children}</Box>
}






