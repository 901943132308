import React, { memo } from 'react'
import styled, { withTheme } from 'styled-components'
import { Box } from '@alobato/flex-box'


const Card = withTheme(memo(({ ...props }) => {
  let padding = [3, 4]
  if (props.theme && props.theme.paddings && props.theme.paddings.cardPadding !== null) padding = props.theme.paddings.cardPadding
  if (props.p) padding = props.p
  return <Box {...props} p={padding} />
}))

const StyledCard = styled(Card)`
  background-color: ${props => props.theme && props.theme.colors && props.theme.colors.cardBgColor ? props.theme.colors.cardBgColor : 'white'};
  .desktop & {
    box-shadow: ${props => props.theme && props.theme.shadows && props.theme.shadows.cardShadow ? props.theme.shadows.cardShadow : '0px 3px 6px 0px hsla(0, 0%, 20%, 0.15)'};
    border-radius: ${props => props.theme && props.theme.radius && props.theme.radius.cardRadius ? props.theme.radius.cardRadius : '12'};
    border: ${props => props.theme && props.theme.borders && props.theme.borders.cardBorder ? props.theme.borders.cardBorder : 'none'};
  }
`

export default StyledCard
