import * as Yup from 'yup'

Yup.setLocale({
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório'
  },
  string: {
    min: params => `Deve ter pelo menos ${params.min} caracteres`
  }
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  PlanId: Yup.string().required(),
})

export default validationSchema
