import React, { memo } from 'react'
import { BlobProvider } from '@react-pdf/renderer'

// https://github.com/diegomura/react-pdf/issues/608#issuecomment-612489268
const BlobProviderWrapper = ({ document, children }) => {
  // if (!document) return null
  return (
    <BlobProvider key={Math.random()} document={document}>
      {({ blob, url, loading, error }) => {
        if (error) return null
        if (loading) return null
        if (url) return children({ blob, url })
      }}
    </BlobProvider>
  )
}

export default memo(BlobProviderWrapper)

