import React, { memo } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import Spin from '../../components/Spin'
import Label from '../../components/Label'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import { useQuery } from '@apollo/client'
import { GET } from './Gqls'

const Show = memo(({ data: { account: item }, onRequestClose }) => {

  const { t } = useTranslation()

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Detalhes')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={3}>
          <Box><Label>{t('Nome')}</Label></Box>
          <Box>{item.name}</Box>
        </Box>

        <Box mb={3}>
          <Box><Label>{t('Plano')}</Label></Box>
          <Box>{item.plan && item.plan.name}</Box>
        </Box>

      </Box>
    </Flex>
  )
})

const ShowQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  if (!data || !data.account) return <div style={{ padding: 32, textAlign: 'center' }}>Não encontrado</div>
  return <Show data={data} onRequestClose={onRequestClose} />
}

export default memo(ShowQuery)
