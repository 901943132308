import { gql } from '@apollo/client'

export const ADD_CONVERSATION = gql`
  mutation ($id: ID!, $text: String) {
    addConversation(id: $id, text: $text)
  }
`

export const CLOSE_PENDENCY = gql`
  mutation ($id: ID!, $text: String) {
    closePendency(id: $id, text: $text)
  }
`

export const LIST_PENDENCIES = gql`
  query($code: ID!) {
    pendencies(code: $code) {
      id
      description
      by
      closedAt
      closeInfo
      conversation
      createdAt
    }
  }
`

export const ADD_MESSAGE_CONVERSATION = gql`
  mutation ($id: ID!, $text: String) {
    addMessageConversation(id: $id, text: $text)
  }
`

export const LIST_MESSAGES = gql`
  query($code: ID!) {
    messages(code: $code) {
      id
      to
      by
      body
      conversation
      createdAt
    }
  }
`

export const CREATE_MESSAGE = gql`
  mutation ($code: ID!, $body: String!, $to: JSON) {
    createMessage(code: $code, body: $body, to: $to)
  }
`

export const CREATE_PENDENCY = gql`
  mutation ($code: ID!, $description: String!, $text: String) {
    createPendency(code: $code, description: $description, text: $text)
  }
`

export const LIST_EXAMINATIONS_TO_SECONDARY = gql`
  query($code: ID!) {
    examinationsToSecondary(code: $code) {
      code
      examinedAt
      PatientName
      orderDetails
      signedAt
      step1At
      step2At
      step3At
    }
    examination(code: $code, withUpdate: false) {
      PatientName
      orderDetails
      mainExaminationCode
      secondaryExaminations
    }
  }
`

export const LIST_USERS = gql`
  query {
    myAccountUsers {
      id
      name
    }
    stripe
    me {
      id
      email
      firstAccessAt
      role
      isDoctor
      isOwner
      license
      defaultClinicId
      account {
        id
        name
        settings
      }
    }
    clinics {
      id
      name
    }
  }
`

export const LIST_CLINICS = gql`
  query {
    clinics {
      id
      name
      nickname
    }
  }
`

export const GET_TREE = gql`
  query {
    account: myAccount {
      tree
    }
  }
`

export const LIST = gql`
  query($limit: Int, $offset: Int, $search: String, $createdBy: Int, $from: Date, $to: Date, $orderBy: String, $status: String) {
    examinations(limit: $limit, offset: $offset, search: $search, createdBy: $createdBy, from: $from, to: $to, orderBy: $orderBy, status: $status) {
      count
      results {
        code
        accessionNumber
        status
        tree
        PatientName
        PatientGender
        PatientAge
        PatientWeight
        PatientHeight
        uid
        PhysicianName
        PhysicianCrm
        orderDetails
        mainExaminationCode
        secondaryExaminations
        signedAt
        examinedAt
        createdAt
        orderCreatedAt
        sla
        remainingSla
        orderLimitAt
        orderAvailableAt
        underCorrectionAt
        correctedAt
        versions
        OpenPendenciesQty
        ClosedPendenciesQty
        reconstruction
        reconstructedAt
        isGroup
        lastMessageColor
        workType
        studyInstanceUID
        s3
        clinic {
          id
          name
        }
        user {
          id
          name
        }
      }
    }
  }
`

export const GET = gql`
  query ($code: ID!) {
    examination(code: $code) {
      code
      tree
      PatientName
      PatientGender
      PatientAge
      PatientWeight
      PatientHeight
      uid
      PhysicianName
      PhysicianCrm
      examinedAt
      previousExam1At
      previousExam2At
      createdAt
      ClinicId
    }
  }
`

export const CREATE = gql`
  mutation ($tree: String!, $ClinicId: ID, $PatientName: String!, $PatientGender: String, $PatientAge: Int, $PatientWeight: Int, $PatientHeight: Int, $uid: String, $PhysicianName: String, $PhysicianCrm: String, $examinedAt: Date, $previousExam1At: Date, $previousExam2At: Date, $workType: String) {
    createExamination(tree: $tree, ClinicId: $ClinicId, PatientName: $PatientName, PatientGender: $PatientGender, PatientAge: $PatientAge, PatientWeight: $PatientWeight, PatientHeight: $PatientHeight, uid: $uid, PhysicianName: $PhysicianName, PhysicianCrm: $PhysicianCrm, examinedAt: $examinedAt, previousExam1At: $previousExam1At, previousExam2At: $previousExam2At, workType: $workType) {
      code
      tree
      PatientName
      PatientGender
      PatientAge
      PatientWeight
      PatientHeight
      uid
      PhysicianName
      PhysicianCrm
      examinedAt
      previousExam1At
      previousExam2At
      workType
      createdAt
      clinic {
        id
        name
      }
      ClinicId
    }
  }
`

export const UPDATE = gql`
  mutation ($code: ID!, $tree: String!, $ClinicId: ID, $PatientName: String!, $PatientGender: String, $PatientAge: Int, $PatientWeight: Int, $PatientHeight: Int, $uid: String, $PhysicianName: String, $PhysicianCrm: String, $examinedAt: Date, $previousExam1At: Date, $previousExam2At: Date) {
    updateExamination(code: $code, reset: true, tree: $tree, ClinicId: $ClinicId, PatientName: $PatientName, PatientGender: $PatientGender, PatientAge: $PatientAge, PatientWeight: $PatientWeight, PatientHeight: $PatientHeight, uid: $uid, PhysicianName: $PhysicianName, PhysicianCrm: $PhysicianCrm, examinedAt: $examinedAt, previousExam1At: $previousExam1At, previousExam2At: $previousExam2At) {
      code
      tree
      PatientName
      PatientGender
      PatientAge
      PatientWeight
      PatientHeight
      uid
      PhysicianName
      PhysicianCrm
      examinedAt
      previousExam1At
      previousExam2At
      createdAt
      clinic {
        id
        name
      }
      ClinicId
    }
  }
`

export const CONNECT_EXAMINATIONS = gql`
  mutation ($mainExaminationCode: ID!, $secondaryExaminationCode: ID!) {
    connectExaminations(mainExaminationCode: $mainExaminationCode, secondaryExaminationCode: $secondaryExaminationCode)
  }
`

export const ASSOCIATE_EXAMINATIONS = gql`
  mutation ($code: ID!, $secondariesCodes: [ID]) {
    associateExaminations(code: $code, secondariesCodes: $secondariesCodes)
  }
`

export const DELETE = gql`
  mutation ($code: ID!) {
    deleteExamination(code: $code)
  }
`

export const REGISTER_FIRST_ACCESS = gql`
  mutation {
    registerFirstAccess
  }
`
