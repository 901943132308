import React, { memo, useMemo, useCallback } from 'react'

import { Field } from 'formik'

import { Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/FieldInput'
import MultiSelect from '../../components/MultiSelect'

import useScrollToError from '@alobato/use-scroll-to-error'
import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

import FormErrorBox from '../../components/FormErrorBox'
import Label from '../../components/Label'
import Switch from '../../components/Switch'



const Form = ({ isEditing = false, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {

  const { t } = useTranslation()

  const changeCategoriesMultiSelect = useCallback(options => {
    options ? setFieldValue('categories', options) : setFieldValue('categories', null)
  }, [setFieldValue])

  const changeFeaturesMultiSelect = useCallback(options => {
    options ? setFieldValue('features', options) : setFieldValue('features', null)
  }, [setFieldValue])

  useFocusOnLoad('name')
  useScrollToError({errors, isValid, isSubmitting})

  const categoriesOptions = useMemo(() => ([{ value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }]), [])

  const featuresOptions = useMemo(() => (['RV'].map(item => ({ value: item, label: item }))), [])

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>{t('Nome')}<span>*</span></Label>
        <Input width='100%' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} withError={touched.name && errors.name} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>

      {false && !isEditing && (
        <>

        <Box mb={3}>
          <Label>{t('Categorias')}</Label>
          <MultiSelect
            onChange={changeCategoriesMultiSelect}
            value={values.categories}
            options={categoriesOptions}
          />
          <FormErrorBox fieldName='categories' errors={errors} touched={touched} />
        </Box>

        <Box mb={3}>
          <Label>{t('Funcionalidades')}</Label>
          <MultiSelect
            onChange={changeFeaturesMultiSelect}
            value={values.features}
            options={featuresOptions}
          />
          <FormErrorBox fieldName='modalities' errors={errors} touched={touched} />
        </Box>

        <Box mb={3}>
          <Label>{t('Limite de usuários')}</Label>
          <Field name='usersLimit' type='number' component={Input} withError={touched.usersLimit && errors.usersLimit} />
          <FormErrorBox fieldName='usersLimit' errors={errors} touched={touched} />
        </Box>

        <Box mb={3}>
          <Label>{t('Dias para experimentar')}</Label>
          <Field name='trialDays' type='number' component={Input} withError={touched.trialDays && errors.trialDays} />
          <FormErrorBox fieldName='trialDays' errors={errors} touched={touched} />
        </Box>

        <Box mb={3}>
          <Label>{t('Usar frases do TelerisOn')}</Label>
          <Switch checked={values.useMaster} onChange={checked => setFieldValue('useMaster', checked)} />
        </Box>

      </>
      )}

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
}

export default memo(Form)

