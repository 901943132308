import React from 'react'
import { Router } from '@reach/router'

import MainLayout from '../../layouts/MainLayout'
import Home from './HomeLazy'

const ExaminationsIndex = ({ me, notifier }) => {
  return (
    <Router>
      <MainLayout me={me} path='/'>
        <Home me={me} notifier={notifier} path='/' />
      </MainLayout>
    </Router>
  )
}

export default ExaminationsIndex
