import React, { memo, useCallback } from 'react'
import CircularProgressInline from '../components/CircularProgressInline'
import Cascader from '../components/Cascader'

import { useTranslation } from 'react-i18next'

import { useQuery } from '@apollo/client'
import { GET_TREE } from './Gqls'



const filter = (inputValue, path) => path.some(option => (option.title).toLowerCase().indexOf(inputValue.toLowerCase()) > -1)



const TreeCascader = memo(({ data: { tree }, onChange, name, defaultValue, separator = ';', filters = [] }) => {

  const { t } = useTranslation()

  const onChangeCallBack = useCallback(onChange, [])

  const options = (filters && filters.length > 0) ? tree.filter(item => filters.includes(item.key)) : tree

  defaultValue = defaultValue ? defaultValue.split(separator) : []

  const handleTreeSelectChange = value => {
    onChangeCallBack({ target: { value: value.join(separator), name } })
  }

  return (
    <Cascader
      defaultValue={defaultValue}
      name={name}
      showSearch={{ filter }}
      options={options}
      onChange={handleTreeSelectChange}
      fieldNames={{ label: 'title' }}
      placeholder={t('Selecione...')}
      style={{ marginRight: 20, width: '100%', minWidth: 400, maxWidth: 600 }}
    />
  )
})



const TreeCascaderQuery = ({ name, defaultValue, onChange, separator }) => {
  const { loading, error, data } = useQuery(GET_TREE, { fetchPolicy: 'network-only' })
  if (loading) return <CircularProgressInline />
  if (error) return error.message
  return <TreeCascader name={name} defaultValue={defaultValue} onChange={onChange} separator={separator} data={data} />
}

export default memo(TreeCascaderQuery)
