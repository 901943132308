import React, { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
// import ReactGA from 'react-ga'
import Spin from './components/Spin'

export const ME = gql`
  query {
    stripe
    me {
      id
      name
      email
      role
      city
      gender
      crm
      crmUF
      isDoctor
      avatar
      preferences
      signatureImage
      license
      isOwner
      specialty
      defaultClinicId
      myClinics
      account {
        createdAt
        name
        settings
        stripeSubscriptionStatus
        plan {
          categories
          usersLimit
          useMaster
          features
          trialDays
        }
        licenses {
          code
          createdAt
          price
          plan {
            id
            name
          }
          user {
            id
            name
          }
        }
      }
    }
  }
`

// https://github.com/reach/router/issues/100
const Redirect = props => {
  const { navigate, to } = props
  useEffect(() => {
    const redirect = () => {
      setTimeout(() => navigate(to, { replace: true }), 0)
    }
    redirect()
  }, [navigate, to])
  return null
}

const QueryMe = ({ children, shouldRenderChildren, ...props }) => {
  const { data, error, loading } = useQuery(ME)
  if (error) return null
  if (loading || !data) return <Spin />
  console.log('data', data)
  if (data.me) {
    if (process.env.NODE_ENV === 'production') {
      try {
        // ReactGA.set({ userId: data.me.id })
        // window.splitbee.user.set({ userId: data.me.id, displayName: data.me.name, email: data.me.email })
        if (window && window.analytics && window.analytics.user && data && data.me) {
          window.analytics.user.set({ userId: data.me.id, displayName: data.me.name, email: data.me.email })
        }
      } catch (err) {
        console.error(err)
      }
    }
    if (shouldRenderChildren) return children.props.children({ ...data.me, stripe: { ...data.stripe } }, props)
    return <Redirect navigate={props.navigate} to='/' />
  } else {
    localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, '')
    return <Redirect navigate={props.navigate} to='/login' />
    // window.location.href = '/login'
  }
  // return null
}

export const OnlyAuthenticated = ({ children, ...props }) => {
  if (localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)) return <QueryMe shouldRenderChildren={true} children={children} navigate={props.navigate} />
  return <Redirect navigate={props.navigate} to='/login' />
}

export const OnlyUnauthenticated = ({ children, ...props }) => {
  if (localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)) return <QueryMe shouldRenderChildren={false} children={children} navigate={props.navigate} />
  return children.props.children(props)
}
