import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAppContext } from '../../contexts/AppContext'

const PublicRoute = ({ children, redirectTo, ...rest }) => {
  const { state } = useAppContext()
  return (
    <Route
      {...rest}
      render={() =>
        state.me ? (
          <Redirect to={{ pathname: (redirectTo || '/') }} />
        ) : (
          children
        )
      }
    />
  )
}

export default PublicRoute
