import React, { memo, useState, useRef } from 'react'
import styled from 'styled-components'

export const Accordion = styled.div`
  outline: 0;
  background-color: ${props => props.theme.colors.white};
  & > .title {
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
  }
  & > .pills {
    padding-bottom: 16px;
  }
  &:focus {
    outline: 0;
    /* box-shadow: 0 0 0 3px ${props => props.theme.colors.primary200}; */
    background-color: hsla(54, 77%, 94%, 1);
    border: 1px solid ${props => props.theme.colors.grey200};
    border-left: 2px solid ${props => props.theme.colors.grey200};
    border-right: 2px solid ${props => props.theme.colors.grey200};
  }
`

export const Pill = styled.div`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  padding: 6px 10px;
  margin: 0.5rem 0px 0px 0.5rem;
  font-size: 12px;
  border: 1px solid hsla(216, 20%, 50%, 1);
  border-radius: 50px;
  font-weight: 600;
  color: hsla(216, 20%, 45%, 1);
  background-color: white;
  outline: 0;
`

export const Option = ({children}) =>  <div>{children}</div>

export default memo(function SelectBlocks({ disabled = false, children, onChange, onCollapse, collapsed, title, value, tabIndex, onChangeStatus = (title, options, status) => {} }) {
  const [focused, setFocused] = useState(children.find(option => value && option.props.value === value) || children[0])
  const [containerIsFocused, setContainerIsFocused] = useState(false)

  const container = useRef()

  const handleClick = (option, index) => {
    if (disabled) return false
    setFocused(children[index])
    return onChange(container.current, option.props.value)
  }

  const handleFocus = () => {
    onCollapse(true)
    setContainerIsFocused(true)
    onChangeStatus(title, true, children.map(option => option.props.value))
    setTimeout(() => container.current && container.current.scrollIntoView({behavior: 'smooth', block: 'start'}), 500)
  }

  const handleBlur = () => {
    setContainerIsFocused(false)
    onChangeStatus(title, false, children.map(option => option.props.value))
  }

  const handleKeyUp = e => {
    if (disabled) return false
    e.preventDefault()

    const itemFocused = children.find(option => option.props.value === focused.props.value)
    const index = children.indexOf(itemFocused)

    if (e.which === 32 || e.which === 13) {
      const selectedItem = children[index]
      setFocused(selectedItem)
      onChange(container.current, selectedItem.props.value)
    }

    if (e.which === 37) {
      if (index > 0) setFocused(children[index - 1])
    }
    if (e.which === 39) {
      if (index < children.length - 1) setFocused(children[index + 1])
    }
  }

  return (
    <Accordion data-id={title} ref={container} tabIndex={tabIndex || 0} onFocus={handleFocus} onBlur={handleBlur} onKeyUp={handleKeyUp}>
      <div className='title'>{title}</div>
      {collapsed &&
        <div className='pills'>
          {children.map((option, i) => {
            let style = {}
            const isFocused = containerIsFocused && option.props.value === focused.props.value
            const isSelected = option.props.value === value
            if (isFocused) style = {...style, borderColor: 'hsla(207, 100%, 63%, 1)', boxShadow: '0 0 0 3px rgba(24, 144, 255, 0.2)'}
            // if (isSelected) style = {...style, color: 'white', backgroundColor: 'hsla(0, 0%, 30%, 1)'}
            if (isSelected) style = {...style, color: 'white', backgroundColor: 'hsla(216, 20%, 50%, 1)'}
            // let style = {}
            // if (containerIsFocused && option.props.value === focused.props.value) style = {...style, borderColor: 'hsla(207, 100%, 63%, 1)', boxShadow: '0 0 0 3px rgba(24, 144, 255, 0.2)'}
            // if (option.props.value === value) style = {...style, color: 'white', backgroundColor: 'hsla(0, 0%, 30%, 1)'}
            return (
              <Pill
                key={option.props.value}
                style={style}
                onClick={() => handleClick(option, i)}
                tabIndex={-1}
              >
                {option.props.children}
              </Pill>
            )
          })}
        </div>
      }
    </Accordion>
  )

})
