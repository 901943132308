import React from 'react'
import Illustrations from './Illustrations'

import { useQuery, useMutation } from '@apollo/client'
import { GET, UPDATE } from './Gqls'

import { Flex, Box } from '@alobato/flex-box'
import Spin from '../../components/Spin'
import Card from '../../components/Card'
import Title from '../../components/Title'
import useDidMount from '../../hooks/useDidMount'

function Home({ code, data }) {

  const steps = (data.examination.status && data.examination.status.steps) ? data.examination.status.steps : []
  console.log('steps', steps)

  const [updateExamination] = React.useCallback(useMutation(UPDATE), [UPDATE])

  const save = React.useCallback(({ variables }) => {
    // if (signed || !me.isDoctor) return false
    const currentSteps = (data.examination.status && data.examination.status.steps) ? data.examination.status.steps : []
    const newSteps = currentSteps.includes(2) ? currentSteps : [...currentSteps, 2]
    const newStatus = { ...(data.examination.status || {}), steps: newSteps }
    console.log('newStatus', newStatus)
    updateExamination({ variables: {...variables, status: newStatus }})
  }, [updateExamination, data])

  useDidMount(() => {
    if (!steps.includes(2)) {
      save({ variables: { code } })
    }
  })

  return (
    <Box p={[3, 4]}>

      <Card>

        <Box>
          <Title>Etapa 2 - Ilustração</Title>
        </Box>

        <div style={{ margin: '0 auto', width: 990 }}>
          <Illustrations
            gallery={data.examination.gallery}
            images={[]}
            onRequestClose={() => {}}
            onCompleted={src => { console.log('src', src) }}
            hideHeader
            hideAdd
            code={code}
            illustrationState={data.examination.illustrationState}
            illustrationSettings={data.examination.clinic.illustrationSettings}
          />
        </div>

      </Card>

    </Box>
  )
}

const HomeQuery = ({ code, navigate, me }) => {
  // const { t } = useTranslation()
  const { loading, error, data, refetch } = useQuery(GET, { fetchPolicy: 'network-only', variables: { code } })
  if (loading) return <Spin />
  if (error) return error.message
  if (!data.examination) return 'Não encontrado'
  return <Home code={code} data={data} navigate={navigate} me={me} />
}

export default HomeQuery
