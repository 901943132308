import { gql } from '@apollo/client'

export const LIST = gql`
  query ($AccountId: ID!) {
    account(id: $AccountId) {
      id
      name
    }
    accountGroups(AccountId: $AccountId) {
      id
      name
      users
      createdAt
    }
    doctors(AccountId: $AccountId) {
      id
      name
    }
  }
`

export const LIST_DOCTORS = gql`
  query ($AccountId: ID!) {
    doctors(AccountId: $AccountId) {
      id
      name
    }
  }
`

export const GET = gql`
  query ($AccountId: ID!, $id: ID!) {
    accountGroup(AccountId: $AccountId, id: $id) {
      id
      name
      users
      createdAt
    }
    doctors(AccountId: $AccountId) {
      id
      name
    }
  }
`

export const CREATE = gql`
  mutation ($AccountId: ID!, $name: String!, $users: JSON) {
    createAccountGroup(AccountId: $AccountId, name: $name, users: $users) {
      id
      name
      users
      createdAt
    }
  }
`

export const UPDATE = gql`
  mutation ($id: ID!, $name: String!, $users: JSON) {
    updateGroup(id: $id, name: $name, users: $users) {
      id
      name
      users
      createdAt
    }
  }
`

export const DELETE = gql`
  mutation ($id: ID!) {
    deleteGroup(id: $id)
  }
`
