import React from 'react'
import { Flex, Box } from '../../components/_new/FlexBox'
import { CloseIcon } from '../../components/_new/Icons'

import WelcomeAMultiStep from './WelcomeAMultiStep'



const Welcome = ({ onRequestClose, me }) => {

  return (
    <Flex flexDirection='column' h='100%'>
      {/* <Box position='relative' h={48} css={{ flexShrink: 0 }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'></Box>
      </Box> */}
      <Box p={4} pt={0} overflow='auto'>

        <WelcomeAMultiStep me={me} onRequestClose={onRequestClose} />

      </Box>
    </Flex>
  )
}

export default Welcome
