import { gql } from '@apollo/client'

export const GET = gql`
  query Examination($code: ID!) {
    examination(code: $code) {
      code
      status
      indication
      anamnesis
      tree
      selectedProtocol
      filters
      selectedModel
      groupsCollapse
      hiddenGroups
      selectedBlocks
      selectedPhrases
      selectedComplementPhrases
      groupsIgnoringSelections
      fields
      vars

      PatientName
      PatientAge
      PatientWeight
      PatientHeight
      PatientGender
      PhysicianName
      PhysicianCrm
      clinic {
        name
        defaultEquipment
      }
      examinedAt
      previousExam1At
      previousExam2At
      signedAt
      underCorrectionAt
      correctedAt
      orderDetails
      createdAt

      list {
        content
      }

      order {
        id
        attachments
        anamnesis

      }
    }
  }
`

export const UPDATE = gql`
  mutation UpdateExamination($code: ID!, $tree: String, $indication: String, $selectedProtocol: String, $filters: JSON, $selectedModel: String, $groupsCollapse: JSON, $vars: JSON, $hiddenGroups: JSON, $groupsIgnoringSelections: JSON, $selectedBlocks: JSON, $selectedPhrases: JSON, $selectedComplementPhrases: JSON, $fields: JSON, $status: JSON) {
    updateExamination(code: $code, tree: $tree, indication: $indication, selectedProtocol: $selectedProtocol, filters: $filters, selectedModel: $selectedModel, groupsCollapse: $groupsCollapse, vars: $vars, hiddenGroups: $hiddenGroups, groupsIgnoringSelections: $groupsIgnoringSelections, selectedBlocks: $selectedBlocks, selectedPhrases: $selectedPhrases, selectedComplementPhrases: $selectedComplementPhrases, fields: $fields, status: $status) {
      code
    }
  }
`
