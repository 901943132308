import React, { memo, useState, useRef, useLayoutEffect } from 'react'
import { navigate } from '@reach/router'

import { Flex, Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/Input'

import Title from '../../components/Title'
import Card from '../../components/Card'

import { useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/client'
import { PASSWORD_RESET } from './Gqls'

import LoginLayout from '../../layouts/LoginLayout'

import themes from '../../themes'

 const Index = ({ token, notifier }) => {

  useLayoutEffect(() => {
    document.body.style.backgroundColor = themes.default.colors.grey100
    return () => document.body.removeAttribute('style')
  }, [])

  const { t } = useTranslation()

  const passwordInput = useRef(null)
  const confirmPasswordInput = useRef(null)

  const [passwordReset] = useMutation(PASSWORD_RESET)

  const [loading, setLoading] = useState(false)

  const handleReset = () => {
    if (!passwordInput.current.value) return notifier.error(t('Preencha a nova senha'))
    if (passwordInput.current.value.length < 6) return notifier.error(t('A nova senha deve ter pelo menos 6 caracteres'))
    if (passwordInput.current.value !== confirmPasswordInput.current.value) return notifier.error(t('A confirmação da senha está diferente'))

    setLoading(true)

    const variables = { newPassword: passwordInput.current.value, resetPasswordToken: token }

    passwordReset({ variables })
      .then(result => {
        setLoading(false)
        if (result.data.passwordReset) notifier.success(t('Senha nova atualizada com sucesso. Acesse sua conta com se email e a nova senha.'))
        navigate('/login')
      })
      .catch(error => {
        setLoading(false)
        notifier.error(error.message.replace('GraphQL error: ', ''))
      })
  }

  return (
    <LoginLayout>
      <Flex jc='center' minWidth={['100%', 448]} maxWidth={448} mx='auto'>

        <Box width={1} p={[3, 4]}>

          <Card p={[3, 4]} mx='auto'>

            <Box ta='center' mb={4}>
              <Title>{t('Redefinir senha')}</Title>
            </Box>

            <Box mx='auto' maxWidth={300} mb={4}>
              <Input width='100%' ref={passwordInput} autoFocus type='password' placeholder={t('Digite sua nova senha')} />
            </Box>
            <Box mx='auto' maxWidth={300} mb={4}>
              <Input width='100%' ref={confirmPasswordInput} type='password' placeholder={t('Digite novamente sua nova senha')} />
            </Box>
            <Box ta='center'>
              <Button primary onClick={handleReset} loading={loading}>{t('Salvar')}</Button>
            </Box>

          </Card>

        </Box>

      </Flex>
    </LoginLayout>
  )
}

export default memo(Index)
