import React, { memo } from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'

import Title from '../../components/Title'
import Card from '../../components/Card'

import { useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/client'
import { SIGN_IN } from './Gqls'

import * as Yup from 'yup'
import '../../validators'

import Form from './Form'



const validationSchema = Yup.object().shape({ email: Yup.string().required(), password: Yup.string().required() })

const Home = ({ redirectTo, notifier }) => {

  const { t } = useTranslation()

  const initialValues = { email: '', password: '' }

  const [signIn] = useMutation(SIGN_IN)

  const handleSubmit = (variables, { setSubmitting }) => {
    const subdomain = window.location.hostname.split('.').slice(0, -2).join('.')
    // const subdomain = 'dasa'

    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)
    signIn({ variables: { ...variables, subdomain } })
      .then(result => {
        localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, result.data.signIn.token)
        window.location.href = redirectTo
        // navigate(redirectTo)
      })
      .catch(error => {
        setSubmitting(false)
        const errorMessage = error.message.replace('GraphQL error: ', '')
        notifier.error(errorMessage)
      })
  }

  return (
    <Flex jc='center' minWidth={['100%', 448]} maxWidth={448} mx='auto'>

      <Box width={1} p={[3, 4]}>

        <Card p={[3, 4]} mx='auto'>

          <Box ta='center' mb={4}>
            <Title>{t('Login')}</Title>
          </Box>

          <Box mx='auto'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {(props) => <Form {...props} />}
            </Formik>
          </Box>

        </Card>

      </Box>

    </Flex>
  )
}

export default memo(Home)
