import * as Yup from 'yup'

Yup.setLocale({
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório'
  },
  string: {
    min: params => `Deve ter pelo menos ${params.min} caracteres`
  }
})

const validationSchema = Yup.object().shape({
  tree: Yup.string().required(),
  ClinicId: Yup.string().required(),
  PatientName: Yup.string().required(),
  PatientAge: Yup.number().integer().min(0).max(140).nullable(),
  PatientWeight: Yup.number().integer().min(0).max(300).nullable(),
  PatientHeight: Yup.number().integer().min(0).max(300).nullable(),
})

export default validationSchema
