import React from 'react'
import { gql } from '@apollo/client'
import { Box } from '@alobato/flex-box'
import Spin from '../../components/Spin'
import { useQuery } from '@apollo/client'
import prettyMilliseconds from 'pretty-ms'
import { TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '@alobato/table'


function formatTime(seconds) {
  if (!seconds) return ''
  return prettyMilliseconds(seconds * 1000)
  // if (seconds < 60) return parseInt(seconds).toString() + 's'
  // return Math.floor(seconds / 60).toString() + 'min'
}

function Telemetry ({ data }) {
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHeaderRow>
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell>Hoje</TableHeaderCell>
            <TableHeaderCell>Sem.</TableHeaderCell>
            <TableHeaderCell>7d</TableHeaderCell>
            <TableHeaderCell>Mês</TableHeaderCell>
            <TableHeaderCell>30d</TableHeaderCell>
            <TableHeaderCell>Ano</TableHeaderCell>
            <TableHeaderCell>365d</TableHeaderCell>
            <TableHeaderCell>Histórico</TableHeaderCell>
          </TableHeaderRow>
          <TableRow>
            <TableCell>Quantidade</TableCell>
            <TableCell>{data.telemetry.countToday}</TableCell>
            <TableCell>{data.telemetry.countWeek}</TableCell>
            <TableCell>{data.telemetry.count7}</TableCell>
            <TableCell>{data.telemetry.countMonth}</TableCell>
            <TableCell>{data.telemetry.count30}</TableCell>
            <TableCell>{data.telemetry.countYear}</TableCell>
            <TableCell>{data.telemetry.count365}</TableCell>
            <TableCell>{data.telemetry.countAll}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Melhor Tempo</TableCell>
            <TableCell>{formatTime(data.telemetry.minToday)}</TableCell>
            <TableCell>{formatTime(data.telemetry.minWeek)}</TableCell>
            <TableCell>{formatTime(data.telemetry.min7)}</TableCell>
            <TableCell>{formatTime(data.telemetry.minMonth)}</TableCell>
            <TableCell>{formatTime(data.telemetry.min30)}</TableCell>
            <TableCell>{formatTime(data.telemetry.minYear)}</TableCell>
            <TableCell>{formatTime(data.telemetry.min365)}</TableCell>
            <TableCell>{formatTime(data.telemetry.minAll)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Média Tempo</TableCell>
            <TableCell>{formatTime(data.telemetry.avgToday)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgWeek)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avg7)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgMonth)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avg30)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgYear)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avg365)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgAll)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Média Tempo Etapa 1</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep1Today)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep1Week)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep17)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep1Month)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep130)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep1Year)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep1365)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep1All)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Média Tempo Etapa 2</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep2Today)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep2Week)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep27)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep2Month)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep230)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep2Year)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep2365)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep2All)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Média Tempo Etapa 3</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep3Today)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep3Week)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep37)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep3Month)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep330)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep3Year)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep3365)}</TableCell>
            <TableCell>{formatTime(data.telemetry.avgStep3All)}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Box>
  )
}

const TelemetryQuery = () => {
  const { loading, error, data } = useQuery(gql`{ telemetry }`, { fetchPolicy: 'cache-and-network' })
  if (loading) return <Spin />
  if (error) return error.message
  return <Telemetry data={data} />
}

export default TelemetryQuery
