import React, { memo } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Checkbox from '@alobato/checkbox'

import Select from '../../components/Select'
import Label from '../../components/Label'
import InputMask from '../../components/InputMask'
import FormErrorBox from '../../components/FormErrorBox'

import useScrollToError from '@alobato/use-scroll-to-error'
import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

import { readFile } from '../../utils'

const Form = ({ isCreating, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {

  const { t } = useTranslation()

  useFocusOnLoad('name')
  useScrollToError({ errors, isValid, isSubmitting })

  const handleChangeIsDoctor = checked => {
    setFieldValue('isDoctor', checked)
    setFieldValue('specialty', '')
    setFieldValue('crm', '')
    setFieldValue('crmUF', '')
  }

  const handleChangeSignatureImage = async e => {
    const file = e.target.files[0]
    const b64 = await readFile(file)
    setFieldValue('signatureImage', b64)
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>{t('Nome')}<span>*</span></Label>
        <Input width='100%' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} withError={touched.name && errors.name} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Email')}<span>*</span></Label>
        <Input type='email' width='100%' name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} withError={touched.email && errors.email} />
        <FormErrorBox fieldName='email' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Celular')}</Label>
        <InputMask mask='(99) 99999-9999' placeholder='(99) 99999-9999' name='cellPhoneNumber' value={values.cellPhoneNumber} onChange={handleChange} onBlur={handleBlur} withError={touched.cellPhoneNumber && errors.cellPhoneNumber} />
        <FormErrorBox fieldName='cellPhoneNumber' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Box mb={2}>
          <Box><Label>{t('Assinatura (imagem no formato PNG)')}</Label></Box>
          <input type='file' id='signatureImage' className='inputfile' onChange={handleChangeSignatureImage} multiple={false} accept='image/png' />
          <label htmlFor='signatureImage'>Escolher imagem...</label>
        </Box>
        {values.signatureImage &&
          <Box>
            <img src={values.signatureImage} alt='' style={{ maxHeight: 200, maxWidth: 200 }} />
            <Flex cursor='pointer' onClick={() => setFieldValue('signatureImage', '')}>Remover</Flex>
          </Box>
        }
      </Box>

      <Box mb={3}>
        <Label>{t('Permissão')}<span>*</span></Label>
        <Select name='role' value={values.role} onChange={handleChange} onBlur={handleBlur} withError={touched.role && errors.role}>
          <option>USER</option>
          <option>ADMIN</option>
        </Select>
        <FormErrorBox fieldName='role' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Checkbox label={t('Este usuário é um médico')} checked={values.isDoctor} onChange={handleChangeIsDoctor} />
      </Box>

      {values.isDoctor &&
        <Box>
          <Box mb={3}>
            <Label>{t('Especialidade')}<span>*</span></Label>
            <Input width='100%' name='specialty' value={values.specialty} onChange={handleChange} onBlur={handleBlur} withError={touched.specialty && errors.specialty} />
            <FormErrorBox fieldName='specialty' errors={errors} touched={touched} />
          </Box>

          <Box mb={3}>
            <Label>{t('CRM')}<span>*</span></Label>
            <Input width='100%' name='crm' value={values.crm} onChange={handleChange} onBlur={handleBlur} withError={touched.crm && errors.crm} />
            <FormErrorBox fieldName='crm' errors={errors} touched={touched} />
          </Box>

          <Box mb={3}>
            <Label>{t('CRM UF')}<span>*</span></Label>
            <Select placeholder={t('Selecione...')} name='crmUF' value={values.crmUF} onChange={handleChange} onBlur={handleBlur} withError={touched.crmUF && errors.crmUF}>
              <option>AC</option>
              <option>AL</option>
              <option>AP</option>
              <option>AM</option>
              <option>BA</option>
              <option>CE</option>
              <option>DF</option>
              <option>ES</option>
              <option>GO</option>
              <option>MA</option>
              <option>MT</option>
              <option>MS</option>
              <option>MG</option>
              <option>PA</option>
              <option>PB</option>
              <option>PR</option>
              <option>PE</option>
              <option>PI</option>
              <option>RJ</option>
              <option>RN</option>
              <option>RS</option>
              <option>RO</option>
              <option>RR</option>
              <option>SC</option>
              <option>SP</option>
              <option>SE</option>
              <option>TO</option>
            </Select>
            <FormErrorBox fieldName='crmUF' errors={errors} touched={touched} />
          </Box>
        </Box>
      }

      {isCreating &&
        <Box mb={3} ta='right'>
          <em>{t('Será enviado um email com a senha inicial para o usuário')}</em>
        </Box>
      }

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
}

export default memo(Form)

