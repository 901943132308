import React, { memo } from 'react'
import Spin from '../../components/Spin'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import Label from '../../components/Label'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import { useQuery } from '@apollo/client'
import { GET } from './Gqls'

const Show = memo(({ data: { clinic: item }, onRequestClose }) => {

  const { t } = useTranslation()

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Detalhes')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={3}>
          <Box><Label>{t('Nome')}</Label></Box>
          <Box>{item.name}</Box>
        </Box>

        <Box mb={3}>
          <Box><Label>{t('Nome OCR')}</Label></Box>
          <Box>{item.nickname}</Box>
        </Box>

        <Box mb={3}>
          <Box><Label>{t('Código')}</Label></Box>
          <Box>{item.shortname}</Box>
        </Box>

        <Box mb={3}>
          <Box><Label>{t('UF')}</Label></Box>
          <Box>{item.uf}</Box>
        </Box>

        <Box mb={3}>
          <Box><Label>{t('Cidade')}</Label></Box>
          <Box>{item.city}</Box>
        </Box>

      </Box>
    </Flex>
  )
})

const ShowQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { fetchPolicy: 'network-only', variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Show data={data} onRequestClose={onRequestClose} />
}

export default memo(ShowQuery)
