import React, { memo, useState } from 'react'
import CircularProgress from '@alobato/circular-progress'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import Spin from '../../components/Spin'
import Button from '../../components/Button'
import Input from '../../components/Input'
import InputDate from '../../components/InputDate'
import Label from '../../components/Label'
import Select from '../../components/Select'
import TreeCascader from '../../shared/TreeCascader'
import FormErrorBox from '../../components/FormErrorBox'

import usePasteImage from '../../hooks/usePasteImage'
import useScrollToError from '@alobato/use-scroll-to-error'
import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@apollo/client'

import { LIST_CLINICS } from './Gqls'

const Form = memo(({ me, data: { clinics }, isCreating, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {

  const { t } = useTranslation()

  // FIXME
  const treeCascaderFilter = me.id === '5' ? ['ULTRASSONOGRAFIA'] : []

  useFocusOnLoad('name')
  useScrollToError({ errors, isValid, isSubmitting })

  const src = usePasteImage()

  const [prevSrc, setPrevSrc] = useState('')
  const [ocrLoading, setOcrLoading] = useState(false)

  React.useEffect(() => {
    if (clinics && clinics.length === 1) {
      setFieldValue('ClinicId', clinics[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOcr = base64Image => {
    setOcrLoading(true)

    const headers = { 'Content-Type': 'application/json' }
    const method = 'POST'
    const url = 'https://vision.googleapis.com/v1/images:annotate?key=AIzaSyAlBndEolg_LlIlVst-b-fPqvjL7MDiLrE'
    const body = { requests: [ { image: { content: base64Image.replace('data:image/jpeg;base64,', '') }, features: [ { type: 'TEXT_DETECTION', maxResults: 1 } ] } ] }
    fetch(url, {method, headers, body: JSON.stringify(body)})
      .then(response => { return response.json() })
      .then(data => {
        const ocrText = data.responses[0].fullTextAnnotation.text

        const textAnnotations = data.responses[0].textAnnotations
        const vertices = textAnnotations[0].boundingPoly.vertices
        const minX = vertices[0].x
        const maxX = vertices[1].x
        const minY = vertices[0].y
        // const maxY = vertices[2].y

        console.log('textAnnotations', textAnnotations)

        const foundPhysicianName = textAnnotations.reduce((acc, item, index, array) => {
          if (index === 0) return acc
          if (item.boundingPoly.vertices[1].x > (maxX - 450) && item.boundingPoly.vertices[0].y > (minY + 90) && item.boundingPoly.vertices[0].y < (minY + 122))
            return `${acc} ${item.description}`
          return acc
        }, '').trim()

        if (foundPhysicianName) setFieldValue('PhysicianName', foundPhysicianName)

        const clinicName = textAnnotations.reduce((acc, item, index, array) => {
          if (index === 0) return acc
          if (item.boundingPoly.vertices[1].x > (maxX - 300) && item.boundingPoly.vertices[0].y < (minY + 20))
            return `${acc} ${item.description}`
          return acc
        }, '').trim()

        if (clinicName) {
          const foundClinic = clinics.find(item => {
            return item.nickname && (item.nickname.toUpperCase() === clinicName.toUpperCase())
          })
          if (foundClinic) setFieldValue('ClinicId', foundClinic.id)
        }




        const foundPatientFirstAndSecondName = textAnnotations.reduce((acc, item, index) => {
          if (index === 0) return acc
          if (acc.length === 1) {
            acc.push(item.description)
          } else if (item.boundingPoly.vertices[0].x < (minX + 20) && item.boundingPoly.vertices[0].y < (minY + 20)) {
            acc.push(item.description)
          }
          return acc
        }, []).join(' ')

        const lines = ocrText.split(`\n`)

        const foundPatientName = lines.reduce((acc, item, index) => {
          if (!acc && item.includes(foundPatientFirstAndSecondName)) acc = item
          return acc
        }, '')

        if (foundPatientName) setFieldValue('PatientName', foundPatientName)

        if (clinicName) setFieldValue('clinicName', clinicName)

        const ageMatch = ocrText.match(/(\d+)A/)
        if (ageMatch && ageMatch.length > 1) setFieldValue('PatientAge', ageMatch[1])

        const isMale = ocrText.toLowerCase().includes('masculino')
        if (isMale) setFieldValue('PatientGender', 'M')

        const isFemale = ocrText.toLowerCase().includes('feminino')
        if (isFemale) setFieldValue('PatientGender', 'F')

        const examinedAtMatch = ocrText.match(/(\d{2}\/\d{2}\/\d{4})/)
        if (examinedAtMatch && examinedAtMatch.length > 1) {
          const maskedValue = examinedAtMatch[1]
          const dateFields = maskedValue.split('/')
          const dateValue = `${dateFields[2]}-${dateFields[1]}-${dateFields[0]}`
          setFieldValue('examinedAt', dateValue)
        }

        const uidMatch = ocrText.match(/ino,\s?([0-9A-Z]+)/)
        if (uidMatch && uidMatch.length > 1) setFieldValue('uid', uidMatch[1])

        setOcrLoading(false)
      })

    }

  if (src && prevSrc !== src) {
    handleOcr(src)
    setPrevSrc(src)
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      {ocrLoading &&
        <Box m={1} ta='center'><CircularProgress height={60} /></Box>
      }

      <Flex jc='space-between' mb={3} ai='center'>
        <Box mr={5}><Text color='grey' fw={300}>Cole a imagem do exame e o sistema tentará capturar os dados automaticamente</Text></Box>
        <Box display={['none', 'block']}><Button type='submit' loading={isSubmitting}>Salvar</Button></Box>
      </Flex>

      <Box mb={3}>
        <Label>{t('Modelo de Laudo')}<span>*</span></Label>
        <TreeCascader
          name='tree'
          defaultValue={values.tree}
          onChange={handleChange}
          style={{ width: '100%' }}
          filters={treeCascaderFilter}
        />
        <FormErrorBox fieldName='tree' errors={errors} touched={touched} />
      </Box>

      <Box mb={3} mr={[0, 1]} width={[1, 1/2]}>
        <Label>{t('Clínica')}<span>*</span></Label>
        <Select placeholder={t('Selecione...')} name='ClinicId' value={values.ClinicId} onChange={handleChange} onBlur={handleBlur} withError={touched.ClinicId && errors.ClinicId}>
          {clinics.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
        </Select>
        <FormErrorBox fieldName='ClinicId' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Nome do Paciente')}<span>*</span></Label>
        <Input width='100%' autoComplete='nope' name='PatientName' value={values.PatientName} onChange={handleChange} onBlur={handleBlur} withError={touched.PatientName && errors.PatientName} />
        <FormErrorBox fieldName='PatientName' errors={errors} touched={touched} />
      </Box>

      <Flex flexDirection={['column', 'row']}>
        <Box mb={3} mr={[0, 1]} width={[1, 1/2]}>
          <Label>{t('Sexo do Paciente')}</Label>
          <Select placeholder={t('Selecione...')} name='PatientGender' value={values.PatientGender} onChange={handleChange} onBlur={handleBlur} withError={touched.PatientGender && errors.PatientGender}>
            <option value='M'>Masculino</option><option value='F'>Feminino</option>
          </Select>
          <FormErrorBox fieldName='PatientGender' errors={errors} touched={touched} />
        </Box>

        <Box mb={3} ml={[0, 1]} width={[1, 1/2]}>
          <Label>{t('Idade do Paciente')}</Label>
          <Flex ai='flex-end'><Box mr={1} flex='1' maxWidth={130}><Input width='100%' autoComplete='nope' type='number' min={0} max={130} name='PatientAge' value={values.PatientAge} onChange={handleChange} onBlur={handleBlur} withError={touched.PatientAge && errors.PatientAge} /></Box><Box>anos</Box></Flex>
          <FormErrorBox fieldName='PatientAge' errors={errors} touched={touched} />
        </Box>
      </Flex>

      <Flex flexDirection={['column', 'row']}>
        <Box mb={3} mr={[0, 1]} width={[1, 1/2]}>
          <Label>{t('Peso do Paciente')}</Label>
          <Flex ai='flex-end'><Box mr={1} flex='1' maxWidth={130}><Input width='100%' autoComplete='nope' type='number' min={0} max={300} name='PatientWeight' value={values.PatientWeight} onChange={handleChange} onBlur={handleBlur} withError={touched.PatientWeight && errors.PatientWeight} /></Box><Box>kg</Box></Flex>
          <FormErrorBox fieldName='PatientWeight' errors={errors} touched={touched} />
        </Box>

        <Box mb={3} ml={[0, 1]} width={[1, 1/2]}>
          <Label>{t('Altura do Paciente')}</Label>
          <Flex ai='flex-end'><Box mr={1} flex='1' maxWidth={130}><Input width='100%' autoComplete='nope' type='number' min={0} max={250} name='PatientHeight' value={values.PatientHeight} onChange={handleChange} onBlur={handleBlur} withError={touched.PatientHeight && errors.PatientHeight} /></Box><Box>cm</Box></Flex>
          <FormErrorBox fieldName='PatientHeight' errors={errors} touched={touched} />
        </Box>
      </Flex>

      <Box mb={3}>
        <Label>{t('Nome do Médico Solicitante')}</Label>
        <Input width='100%' autoComplete='nope' name='PhysicianName' value={values.PhysicianName} onChange={handleChange} onBlur={handleBlur} withError={touched.PhysicianName && errors.PhysicianName} />
        <FormErrorBox fieldName='PhysicianName' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('CRM do Médico Solicitante')}</Label>
        <Input width='100%' autoComplete='nope' name='PhysicianCrm' value={values.PhysicianCrm} onChange={handleChange} onBlur={handleBlur} withError={touched.PhysicianCrm && errors.PhysicianCrm} />
        <FormErrorBox fieldName='PhysicianCrm' errors={errors} touched={touched} />
      </Box>

      <Flex flexDirection={['column', 'row']}>
        <Box mb={3} mr={[0, 1]} width={[1, 1/2]}>
          <Label>{t('Data do Exame')}</Label>
          <InputDate placeholder='DD/MM/AAAA' name='examinedAt' value={values.examinedAt} onChange={(e, value) => setFieldValue('examinedAt', value)} onBlur={handleBlur} />
          <FormErrorBox fieldName='examinedAt' errors={errors} touched={touched} />
        </Box>

        <Box mb={3} ml={[0, 1]} width={[1, 1/2]}>
          <Label>{t('ID')}</Label>
          <Input width='100%' autoComplete='nope' name='uid' value={values.uid} onChange={handleChange} onBlur={handleBlur} withError={touched.uid && errors.uid} />
          <FormErrorBox fieldName='uid' errors={errors} touched={touched} />
        </Box>
      </Flex>

      <Flex flexDirection={['column', 'row']}>
        <Box mb={3} mr={[0, 1]} width={[1, 1/2]}>
          <Label>{t('Dt. Exame Anterior 1')}</Label>
          <InputDate placeholder='DD/MM/AAAA' name='previousExam1At' value={values.previousExam1At} onChange={(e, value) => setFieldValue('previousExam1At', value)} onBlur={handleBlur} />
          <FormErrorBox fieldName='previousExam1At' errors={errors} touched={touched} />
        </Box>

        <Box mb={3} mr={[0, 1]} width={[1, 1/2]}>
          <Label>{t('Dt. Exame Anterior 2')}</Label>
          <InputDate placeholder='DD/MM/AAAA' name='previousExam2At' value={values.previousExam2At} onChange={(e, value) => setFieldValue('previousExam2At', value)} onBlur={handleBlur} />
          <FormErrorBox fieldName='previousExam2At' errors={errors} touched={touched} />
        </Box>
      </Flex>

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
})

const FormQuery = props => {
  const { loading, error, data } = useQuery(LIST_CLINICS)
  if (loading) return <Spin />
  if (error) return error.message
  return <Form {...props} data={data} />
}

export default memo(FormQuery)
