import React, { memo, useCallback, useRef } from 'react'
import { Box } from '@alobato/flex-box'
import Tippy from '@tippy.js/react'

import TableGroup from './TableGroup'
import InlineSelect from './InlineSelect'
import InputField from './InputField'
import CaptureVars from './CaptureVars'

import ButtonIcon from '../../../../components/ButtonIcon'

// import 'tippy.js/dist/tippy.css'
// import 'tippy.js/themes/light.css'
// import 'tippy.js/animations/perspective.css'

import useDidMount from '../../../../hooks/useDidMount'

import { getVariables, replaceFields, sanitize, trimValues } from '../../../../utils/variables'



const getDefaultValue = (fields, index) => {
  if (!fields) return ''
  if (fields && fields[index] === null) return ''
  if (fields && fields[index]) return fields[index]
  return ''
}

const TextGroup = memo(({ vars, onChangeVars, patient, examination, filters, disabled = false, allFields, group, complementPhraseName, text, fields, onChange }) => {

  if (!text) text = ''

  const instanceRef = useRef()
  // const buttonRef = useRef()

  // const replaceFieldsMemo = React.useMemo((replaceFields, [])
  // const filtered = useMemo(() => searchBy(data.companies, ['name'], search), [data.companies, search])
  // const filtered = useMemo(() => searchBy(data.users, ['name'], search), [data.users, search])
  // const mutation = useCallback(useMutation(UPDATE), [UPDATE])

  // const isTable = text.includes('<table')

  // textWithoutCaptures = Aquisição volumétrica com [#volume#1,0|1,2|1,5|2,0|3,0] mm de espessura.
  // textFields = ['Aquisição volumétrica com ', '[#volume#1,0|1,2|1,5|2,0|3,0]', ' mm de espessura.']
  // options = ['[#volume#1,0|1,2|1,5|2,0|3,0]']
  //
  let textWithoutCaptures = text.includes('~') ? text.split('~')[1] : text
  textWithoutCaptures = textWithoutCaptures.includes('$$') ? textWithoutCaptures.split('$$')[1] : textWithoutCaptures

  const textFields = textWithoutCaptures.split(/\[.*?\]/)
  const options = textWithoutCaptures.match(/\[.*?\]/g)

  // fieldsAndOptions = ['Aquisição volumétrica com ', ["#volume#1,0", "1,2", "1,5", "2,0", "3,0"], ' mm de espessura']
  //
  const fieldsAndOptions = useCallback((textFields, options) => textFields.reduce((result, item, index) => {
    result.push(item)
    if (options && options[index] && !/\[#\S+?#\s*?\(.+\)/.test(options[index])) {
      const option = options[index]
      if (option === '[]' || option === '[ ]') {
        result.push([])
      } else {
        result.push(option.replace('[', '').replace(']', '').trim().split('|'))
      }
    }
    return result
  }, []), [])

  let capturedVars = []
  let formulas = {}

  if (text.includes('~')) {

    capturedVars = text.split('~')[0].match(/\[#\S+?#[^\]]*/g).reduce((acc, item) => {
      const name = item.match(/\[#(\S+?)#/)[1].toLowerCase()
      let fieldsOptions = []
      if (/\[#\S+?#\s*?\(.+\)/.test(item)) {
      } else if (item.includes('|') && /\[#\S+?#.+?|.+/.test(item)) {
        fieldsOptions = item.match(/\[#\S+?#(.+)/)[1]
        fieldsOptions = fieldsOptions.split('|')
        if (!acc.find(element => (element.name === name))) acc.push({ name, options: fieldsOptions })
      } else {
        if (!acc.find(element => (element.name === name))) acc.push({ name, options: [] })
      }
      return acc
    }, [])

    const initialFormulas = {
      imc: '(paciente_peso / Math.pow((paciente_altura/100), 2)).toFixed(2)',
      bsa: '(0.007184 * Math.pow(paciente_altura, 0.725) * Math.pow(paciente_peso, 0.425)).toFixed(2)'
    }

    formulas = text.split('~')[0].match(/\[#\S+?#[^\]]*/g).reduce((acc, item) => {
      const name = item.match(/\[#(\S+?)#/)[1].toLowerCase()
      if (/\[#\S+?#\s*?\(.+\)/.test(item)) {
        const formulaText = item.match(/\[#\S+?#(.+)/)[1]
        acc = { ...acc, [name]: formulaText }
      }
      return acc
    }, initialFormulas)

  }

  useDidMount(() => {
    // fields = { 1: '#volume#1,0' }
    //
    fieldsAndOptions(textFields, options).forEach((item, index) => {
      if (item instanceof Array) {
        const value = getDefaultValue(fields, index)
        if (complementPhraseName) {
          if (!disabled) onChange({ value, index, group, phraseType: 'complementPhrase', complementPhraseName })
        } else {
          if (!disabled) onChange({ value, index, group, phraseType: 'phrase' })
        }
      }
    })
  })

  // ex.: allFields = { 'INTRODUÇÃO': { 1: '#volume#1,0' } }
  let variables = getVariables({ fields: allFields, patient, examination, filters, vars: trimValues(vars), formulas })
  variables = Object.entries(variables).reduce((acc, [key, value]) => ({ ...acc, [key]: (value === null || value === undefined) ? '' : value}), {})
  // TODO variable of different tables

  // const replaceFieldsMemo = useCallback(replaceFields, [textWithoutCaptures, JSON.stringify(variables)])

  if (!text) return ''

  if (text.includes('<table')) {
    // const hash = `${textWithoutCaptures}${JSON.stringify(variables)}`
    const textWithReplaceFields = replaceFields(textWithoutCaptures, variables)
    return (
      <TableGroup vars={variables} capturedVars={capturedVars} text={textWithReplaceFields} onChangeVars={onChangeVars} />
    )
  }

  return (
    <>
      {capturedVars && capturedVars.length > 0 &&
        <Tippy
          content={<CaptureVars capturedVars={capturedVars} vars={vars} onSubmit={vars => { onChangeVars(vars); instanceRef.current.hide() }} />}
          placement='bottom'
          trigger='click'
          theme='light'
          interactive={true}
          inertia={true}
          arrow={false}
          duration={[350, 200]}
          onCreate={instance => instanceRef.current = instance}
        >
          <span>
            <ButtonIcon iconPath='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z' />
          </span>
        </Tippy>
      }
      <Box>
        {fieldsAndOptions(textFields, options).map((item, index) => {
          return (
            <span key={`fo${index}`}>
              {(item && typeof item === 'string') && (
                <span dangerouslySetInnerHTML={{ __html: sanitize(replaceFields(item, variables).replace(/\*\*(.+?)\*\*/g, `<strong>$1</strong>`).replace(/__(.+?)__/g, `<em>$1</em>`)) }} />
              )}
              {(item instanceof Array && item.length === 0) && (
                <InputField disabled={disabled} defaultValue={getDefaultValue(fields, index)} onChange={value => onChange({ value, index, group })} style={{border: 'none', backgroundColor: 'hsla(187, 52%, 85%, 1)', boxShadow: 'hsla(187, 52%, 40%, 1) 1px 1px 0px', padding: 2, lineHeight: 1, width: 60}} />
              )}
              {(item instanceof Array && item.length === 1 && item[0].includes('#')) && (
                <InputField disabled={disabled} name={item[0].replace(/#/g, '')} defaultValue={getDefaultValue(fields, index)} onChange={(value, fieldName) => onChange({ value, index, group, fieldName })} type='text' style={{border: 'none', backgroundColor: 'hsla(187, 52%, 85%, 1)', boxShadow: 'hsla(187, 52%, 40%, 1) 1px 1px 0px', padding: 2, lineHeight: 1, width: 60}} />
              )}
              {(item instanceof Array && item.length === 1 && !item[0].includes('#')) && (
                <InputField disabled={disabled} defaultValue={getDefaultValue(fields, index)} onChange={value => onChange({ value, index, group })} style={{border: 'none', backgroundColor: 'hsla(187, 52%, 85%, 1)', boxShadow: 'hsla(187, 52%, 40%, 1) 1px 1px 0px', padding: 2, lineHeight: 1, width: 60}} />
              )}
              {(item instanceof Array && item.length > 1) && (
                <InlineSelect disabled={disabled} options={item} selected={getDefaultValue(fields, index)} onChange={e => { if (disabled) return false; onChange({ value: e.value, index, group }) }} />
              )}
            </span>
          )
        })}
      </Box>
    </>
  )
})

export default TextGroup
