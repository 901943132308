import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import styled, { css } from 'styled-components/macro'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '@alobato/table'
import Checkbox from '@alobato/checkbox'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Spin from '../../components/Spin'
import PopOver from '../../components/PopOver'
import { Menu, MenuItem } from '../../components/Menu'
import { reorder } from '../../utils'
// import Viewer from '../../components/Viewer'
import Modal from '../../components/StyledModal'

import { MoreIcon, SettingsIcon, DragIndicatorIcon, SignIcon, AvailableIcon, GroupIcon, GenericIcon } from '../../components/Icons'
import Pagination from '../../components/Pagination'
import { FormatUtcDate, FormatDateTime } from '../../components/Format'
import PortalAwareItem from '../../components/PortalAwareItem'

import { useTranslation } from 'react-i18next'
import { useQueryParam, NumberParam } from 'use-query-params'

import { useQuery, useMutation } from '@apollo/client'
import { LIST, DELETE } from './Gqls'

import Associations from './Associations'

// bmk://%20

const usersMap = {
  'iugiro@bmkimagem.com.br': { username: 'iugirok', password: '0203' },
  'kellymegumi@gmail.com': { username: 'k.iwasaki', password: 'bmk12345' },
  'izabela.coreixas@bmkimagem.com.br': { username: 'izabelac', password: 'bmk123' },
  'fabio.magalhaes@bmkimagem.com.br': { username: 'fmagalhaes', password: '3872' },
  'hbferri@outlook.com': { username: 'hferri', password: '1101' },
  'gtabu@globo.com': { username: 'gtaboada', password: ']]' },
  'mapaula@me.com': { username: 'mapaula', password: 'bmk1234' },
  'carolferreirap@hotmail.com': { username: 'cpadilha', password: 'bmk1234' },
  'stuppluciane@hotmail.com': { username: 'lfreitas', password: 'bmk1234' },
  'dr.cesarnoce@gmail.com': { username: 'cnoce', password: 'bmk1234' },
  'fmramos19@gmail.com': { username: 'framos', password: '12012' },
  'fabi.brito.ferreira@gmail.com': { username: 'fferreira', password: 'Bmk1234' },
  'carlacascardo@gmail.com': { username: 'cvasconcelos', password: 'Bmk@1234' },
  'jobisaglia@gmail.com': { username: 'joana', password: 'Bmk123' },
  'gustavomarquescardoso@gmail.com': { username: 'gcardoso', password: '2010' },
  'vibrady@gmail.com': { username: 'viviane.brady', password: 'bmk1234' },
  'paulobrunotrigo@gmail.com': { username: 'ptrigo', password: 'Bmk1234' },
  'jaironb@gmail.com': { username: 'jcarmo', password: 'bmk1234' },
  'monicanagano@bmkimagem.com.br': { username: 'mnagano', password: 'mn3608' },
  'leniogavio@uol.com.br': { username: 'lsilva', password: 'bmk1234' },
}




function secondsToHour(seconds) {
  if (!seconds) return ''
  return Math.floor(seconds / 3600)
}

const PAGE_SIZE = 100

const Handle = styled.div`margin-right: 8px;`

const BadgeWrapper = styled(Flex)`
  border-radius: 12px;
  background-color: ${props => (props.active ? props.theme.colors.grey : props.theme.colors.grey200)};
  color: ${props => (props.active ? props.theme.colors.grey0 : props.theme.colors.grey600)};
`
const Badge = ({ children, active, onClick }) => <BadgeWrapper active={active} onClick={onClick} cursor='pointer' jc='center' ai='center' w={24} h={24}><Box><Text fs={11} fw={600} lh={1}>{children}</Text></Box></BadgeWrapper>

function humanMessages(item) {
  if (item.lastMessageColor) return (<Flex cursor='pointer' onClick={() => navigate(`/laudos/${item.code}/mensagens${window.location.search}`)} style={{ display: 'block' }}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill={item.lastMessageColor}><path d='M12,3C17.5,3 22,6.58 22,11C22,15.42 17.5,19 12,19C10.76,19 9.57,18.82 8.47,18.5C5.55,21 2,21 2,21C4.33,18.67 4.7,17.1 4.75,16.5C3.05,15.07 2,13.13 2,11C2,6.58 6.5,3 12,3Z' /></svg></Flex>)
  return ''
}

function humanRecon(examination) {
  const { reconstruction, reconstructedAt } = examination
  if (reconstructedAt) {
    const at = FormatDateTime({ locale: 'pt-br', children: reconstructedAt })
    return (<span title={`Reconstruído em ${at}`}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill='hsla(211, 20%, 43%, 1)'><path d='M9,7V17H11V13H11.8L13,17H15L13.76,12.85C14.5,12.55 15,11.84 15,11V9A2,2 0 0,0 13,7H9M11,9H13V11H11V9M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3Z' /></svg></span>)
  }
  if (reconstruction === 'ARECONSTRUIR') {
    return (<span title='A reconstruir'><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill='hsla(211, 20%, 43%, 0.3)'><path d='M9,7H13A2,2 0 0,1 15,9V11C15,11.84 14.5,12.55 13.76,12.85L15,17H13L11.8,13H11V17H9V7M11,9V11H13V9H11M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M5,5V19H19V5H5Z' /></svg></span>)
  } else {
    return ''
  }
}

function humanPendencies(OpenPendenciesQty, ClosedPendenciesQty) {
  const total = (OpenPendenciesQty || 0) + (ClosedPendenciesQty || 0)
  if (total > 0 && OpenPendenciesQty === 0) {
    return (<span title={`${ClosedPendenciesQty} pendência(s) encerrada(s)`}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill='hsla(211, 20%, 43%, 0.3)'><path d='M9,7H13A2,2 0 0,1 15,9V11A2,2 0 0,1 13,13H11V17H9V7M11,9V11H13V9H11M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M5,5V19H19V5H5Z' /></svg></span>)
  } else if (total > 0 && OpenPendenciesQty > 0) {
    return (<span title={`${OpenPendenciesQty} pendência(s)`}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill='hsla(211, 20%, 43%, 1)'><path d='M9,7V17H11V13H13A2,2 0 0,0 15,11V9A2,2 0 0,0 13,7H9M11,9H13V11H11V9M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3Z' /></svg></span>)
  } else {
    return ''
  }
}

function TableCellWithOptions({ item, navigate, me }) {

  if (item.workType === 'T') {
    return (
      <TableCell width={118} lineHeight={0}>
        <Flex jc='flex-end'>
          <Box mr={1}>
            <span><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={24} fill='hsla(211, 20%, 43%, 1)'><path d='M5,4H19A2,2 0 0,1 21,6V18A2,2 0 0,1 19,20H5A2,2 0 0,1 3,18V6A2,2 0 0,1 5,4M5,8V12H11V8H5M13,8V12H19V8H13M5,14V18H11V14H5M13,14V18H19V14H13Z' /></svg></span>
          </Box>
          <Box mr={1} title='Etapa 1'><Badge active={item.status && item.status.steps && item.status.steps.includes(1)} onClick={() => navigate(`/tabela-etapa1/${item.code}`)}>E1</Badge></Box>
          <Box mr={1} title='Etapa 2'><Badge active={item.status && item.status.steps && item.status.steps.includes(2)} onClick={() => navigate(`/tabela-etapa2/${item.code}`)}>E2</Badge></Box>
          <Box title='Etapa 3'><Badge active={item.status && item.status.steps && item.status.steps.includes(3)} onClick={() => navigate(`/tabela-etapa3/${item.code}`)}>E3</Badge></Box>
        </Flex>
      </TableCell>
    )
  }

  if (item.workType === 'I') {
    return (
      <TableCell width={118} lineHeight={0}>
        <Flex jc='flex-end'>
          <Box mr={28}>
            <span><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={24} fill='hsla(211, 20%, 43%, 1)'><path d='M5,3C3.89,3 3,3.89 3,5V19C3,20.11 3.89,21 5,21H11V3M13,3V11H21V5C21,3.89 20.11,3 19,3M13,13V21H19C20.11,21 21,20.11 21,19V13' /></svg></span>
          </Box>
          <Box mr={1} title='Etapa 1'><Badge active={item.status && item.status.steps && item.status.steps.includes(1)} onClick={() => navigate(`/ilustracao-etapa1/${item.code}`)}>E1</Badge></Box>
          <Box mr={1} title='Etapa 2'><Badge active={item.status && item.status.steps && item.status.steps.includes(2)} onClick={() => navigate(`/ilustracao-etapa2/${item.code}`)}>E2</Badge></Box>
          {/* <Box title='Etapa 3'><Badge active={item.status && item.status.steps && item.status.steps.includes(3)} onClick={() => navigate(`/tabela-etapa3/${item.code}`)}>E3</Badge></Box> */}
        </Flex>
      </TableCell>
    )
  }

  return (
    <TableCell width={118} lineHeight={0}>
    {item.isGroup ? (
      <Flex justifyContent='flex-end' alignItems='center'>
        <Box mr={1} title='Atribuído ao grupo'><GroupIcon /></Box>
        <Box mr={1} title='Etapa 1'><Badge active={item.status && item.status.steps && item.status.steps.includes(1)} onClick={() => {
          if (me.account && me.account.name === 'BMK' && item.accessionNumber && usersMap[me.email]) {
            window.open(`bmk://%20${usersMap[me.email].username}%20${usersMap[me.email].password}%20${item.accessionNumber}%20carestream`, 'carestream');
            setTimeout(() => { navigate(`/etapa1/${item.code}`) }, 10)
          } else {
            navigate(`/etapa1/${item.code}`)
          }
        }}>E1</Badge></Box>
        <Box mr={1} title='Etapa 2'><Badge active={item.status && item.status.steps && item.status.steps.includes(2)} onClick={() => navigate(`/etapa2/${item.code}`)}>E2</Badge></Box>
        <Box title='Etapa 3'><Badge active={item.status && item.status.steps && item.status.steps.includes(3)} onClick={() => navigate(`/etapa3/${item.code}`)}>E3</Badge></Box>
      </Flex>
    ) : (
      <>
        {!item.mainExaminationCode &&
          <Flex jc='flex-end'>
            {(item.signedAt || (item.status && item.status.signed)) &&
              <>
                {item.orderAvailableAt ? (
                  <>
                    {item.correctedAt && <Box mr={1} title='Corrigido'><GenericIcon iconPath='M17,21L14.25,18L15.41,16.84L17,18.43L20.59,14.84L21.75,16.25 M 13 9 L 18.5 9 L 13 3.5 Z M 6 2 L 14 2 L 20 8 L 20 12.187 C 19.281 11.876 18.488 11.704 17.655 11.704 C 17.008 11.704 16.385 11.808 15.802 12 L 6 12 L 6 14 L 12.974 14 C 12.518 14.588 12.17 15.265 11.961 16 L 6 16 L 6 18 L 11.748 18 C 11.847 19.583 12.569 20.996 13.67 22 L 6 22 C 4.895 22 4 21.105 4 20 L 4 4 C 4 2.89 4.89 2 6 2 Z' /></Box>}
                    {!item.correctedAt && item.underCorrectionAt && <Box mr={1} title='Em correção'><GenericIcon iconPath='M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H10V20.09L12.09,18H6V16H14.09L16.09,14H6V12H18.09L20,10.09V8L14,2H6M13,3.5L18.5,9H13V3.5M20.15,13C20,13 19.86,13.05 19.75,13.16L18.73,14.18L20.82,16.26L21.84,15.25C22.05,15.03 22.05,14.67 21.84,14.46L20.54,13.16C20.43,13.05 20.29,13 20.15,13M18.14,14.77L12,20.92V23H14.08L20.23,16.85L18.14,14.77Z' /></Box>}
                    {!item.correctedAt && !item.underCorrectionAt && item.orderAvailableAt && <Box mr={1} title='Liberado'><AvailableIcon /></Box>}
                  </>
                ) : (
                  <Box mr={1} title='Assinado'><SignIcon /></Box>
                )}
                {/* <Box mr={1}><a title='Download' href={item.documentUrl} download='laudo.pdf' css={css`display: inline-block; color: inherit;`}><GenericIcon iconPath='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z' /></a></Box> */}
              </>
            }
            <>
              <Box mr={1} title='Etapa 1'><Badge active={item.status && item.status.steps && item.status.steps.includes(1)}  onClick={() => {
                if (me.account && me.account.name === 'BMK' && item.accessionNumber && usersMap[me.email]) {
                  window.open(`bmk://%20${usersMap[me.email].username}%20${usersMap[me.email].password}%20${item.accessionNumber}%20carestream`, 'carestream');
                  setTimeout(() => { navigate(`/etapa1/${item.code}`) }, 10)
                } else {
                  navigate(`/etapa1/${item.code}`)
                }
              }}>E1</Badge></Box>
              <Box mr={1} title='Etapa 2'><Badge active={item.status && item.status.steps && item.status.steps.includes(2)} onClick={() => navigate(`/etapa2/${item.code}`)}>E2</Badge></Box>
              <Box title='Etapa 3'><Badge active={item.status && item.status.steps && item.status.steps.includes(3)} onClick={() => navigate(`/etapa3/${item.code}`)}>E3</Badge></Box>
            </>
          </Flex>
        }
      </>
    )}

  </TableCell>
  )
}

const List = ({ data: { examinations: { results: items, count } }, page, refetch, notifier, me }) => {

  const { t, i18n } = useTranslation()

  const [deleteExamination] = useMutation(DELETE)

  const setPage = useQueryParam('page', NumberParam)[1]

  let initialColumns = [
    { name: 'examinedAt', label: 'Realizado', nowrap: true, onlyBmk: false },
    { name: 'exam', label: 'Exame', nowrap: false, onlyBmk: true },
    { name: 'tree', label: 'Modelo de Laudo', nowrap: false, onlyBmk: false },
    { name: 'userName', label: 'Laudador', nowrap: true, onlyBmk: false },
    { name: 'clinicName', label: 'Clínica', nowrap: true, onlyBmk: false },
    { name: 'PatientName', label: 'Nome do Paciente', nowrap: false, onlyBmk: false },
    { name: 'PatientGender', label: 'Sexo', nowrap: true, onlyBmk: false },
    { name: 'PatientAge', label: 'Idade', nowrap: true, onlyBmk: false },
    { name: 'PatientHeight', label: 'Altura', nowrap: true, onlyBmk: false },
    { name: 'PatientWeight', label: 'Peso', nowrap: true, onlyBmk: false },
    { name: 'uid', label: 'ID', nowrap: true, onlyBmk: false },
    { name: 'pendencies', label: 'Pend.', nowrap: true, onlyBmk: true },
    { name: 'messages', label: 'Mens.', nowrap: true, onlyBmk: true },
    { name: 'reconstruction', label: 'Rec.', nowrap: true, onlyBmk: true },
    { name: 'sla', label: 'SLA', nowrap: true, onlyBmk: true },
    { name: 'orderLimitAt', label: 'Limite', nowrap: true, onlyBmk: true },
  ]

  const tds = {
    examinedAt: (item, config) => <TableCell key='examinedAt' data-title={t('Realizado')} style={{ width: 100, whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}><FormatUtcDate>{item.examinedAt}</FormatUtcDate></TableCell>,
    exam: (item, config) => <TableCell key='exam' data-title={t('Exame')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.orderDetails && item.orderDetails.ExamName ? `${item.orderDetails.ExamModality} - ${item.orderDetails.ExamName}` : ''}</TableCell>,
    tree: (item, config) => <TableCell key='tree' data-title={t('Modelo de Laudo')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.tree && item.tree.split(';').slice(-1)[0]}</TableCell>,
    userName: (item, config) => <TableCell key='userName' data-title={t('Laudador')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.user && item.user.name}</TableCell>,
    clinicName: (item, config) => <TableCell key='clinicName' data-title={t('Clínica')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.clinic && item.clinic.name}</TableCell>,
    PatientName: (item, config) => <TableCell key='PatientName' data-title={t('Nome do Paciente')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.PatientName}</TableCell>,
    PatientGender: (item, config) => <TableCell key='PatientGender' data-title={t('Sexo')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.PatientGender}</TableCell>,
    PatientAge: (item, config) => <TableCell key='PatientAge' data-title={t('Idade')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.PatientAge}</TableCell>,
    PatientHeight: (item, config) => <TableCell key='PatientHeight' data-title={t('Altura')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.PatientHeight}</TableCell>,
    PatientWeight: (item, config) => <TableCell key='PatientWeight' data-title={t('Peso')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.PatientWeight}</TableCell>,
    uid: (item, config) => <TableCell key='uid' data-title={t('ID')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.uid}</TableCell>,
    pendencies: (item, config) => <TableCell key='pendencies' data-title={t('Pendências')} style={{ textAlign: 'center', whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}><span style={{ cursor: 'pointer' }} onClick={() => navigate(`/laudos/${item.code}/pendencias${window.location.search}`)}>{humanPendencies(item.OpenPendenciesQty, item.ClosedPendenciesQty)}</span></TableCell>,
    messages: (item, config) => <TableCell key='messages' data-title={t('Mensagens')} style={{ textAlign: 'center', whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{humanMessages(item)}</TableCell>,
    reconstruction: (item, config) => <TableCell key='reconstruction' data-title={t('Reconstrução')} style={{ textAlign: 'center', whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{humanRecon(item)}</TableCell>,
    sla: (item, config) => <TableCell key='sla' data-title={t('SLA')} style={{ textAlign: 'right', whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{secondsToHour(item.remainingSla)}/{item.sla}</TableCell>,
    orderLimitAt: (item, config) => <TableCell key='orderLimitAt' data-title={t('Limite')} style={{ width: 110, whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}><FormatDateTime short locale={i18n.language}>{item.orderLimitAt}</FormatDateTime></TableCell>,
  }

  const [columns, setColumns] = useState(initialColumns.filter(item => {
    if (me && me.account && me.account.name !== 'BMK' && item.onlyBmk) return false
    return true
  }))
  const [associationModalCode, setAssociationModalCode] = useState(null)

  // const [previewAttachments, setPreviewAttachments] = useState([])

  const initialHiddenColumns = () => (window.localStorage.getItem('examinationsHiddenColumns') ? JSON.parse(window.localStorage.getItem('examinationsHiddenColumns')) : [])
  const [hiddenColumns, setHiddenColumns] = useState(initialHiddenColumns)

  useEffect(() => {
    window.localStorage.setItem('examinationsHiddenColumns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  const pageCount = Math.ceil(count > 0 ? count / PAGE_SIZE : 0)

  const handleChangeHiddenColumns = (columnName, checked) => {
    if (checked) {
      setHiddenColumns(previousHiddenColumns => {
        return previousHiddenColumns.filter(item => item !== columnName)
      })
    } else {
      setHiddenColumns(previousHiddenColumns => {
        return [...previousHiddenColumns, columnName]
      })
    }
  }

  const onDragEnd = result => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    const reorderedColumns = reorder(columns, result.source.index, result.destination.index)
    setColumns(reorderedColumns)
  }

  const handlePageChange = ({ selected }) => {
    const searchParams = new URLSearchParams(window.location.search)
    const pageParam = searchParams.get('page')

    if (selected === 0 && !pageParam) return false
    if (pageParam && Number(pageParam) === selected + 1) return false

    if (selected === 0) setPage(null)
    if (selected) setPage(selected + 1)
  }

  const handleDelete = async code => {
    if (!window.confirm(t('Você tem certeza que deseja excluir este laudo?'))) return false

    try {
      await deleteExamination({ variables: { code } })
      refetch()
      notifier.success(t('Excluído com sucesso!'))
    } catch(error) {
      console.error(error)
      notifier.error(error.message.replace('GraphQL error: ', ''))
    }
  }

  const filteredColumns = columns.filter(item => {
    if (hiddenColumns.includes(item.name)) return false
    return true
  })

  return (
    <Box mb={5}>

      {items.length > 0 &&
        <>
          <Flex jc='space-between'>
            <Box>{`${t('Total')}: ${count}`}</Box>
            <Box>
              <PopOver placement='right' modifiers={{ preventOverflow: { enabled: true }, hide: { enabled: false } }} render={() => (
                <Menu py={3} css={css`max-height: calc(100vh - 58px); overflow-y: scroll;`}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='list'>
                      {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {columns.map((item, index) => (
                            <Draggable draggableId={item.name} index={index} key={item.name}>
                              {(provided, snapshot) => (
                                <PortalAwareItem innerRef={provided.innerRef} provided={provided} snapshot={snapshot}>
                                  <Handle {...provided.dragHandleProps}><DragIndicatorIcon height={16} /></Handle>
                                  <MenuItem>
                                    <Checkbox label={t(item.label)} checked={!hiddenColumns.includes(item.name)} onChange={checked => handleChangeHiddenColumns(item.name, checked)} />
                                  </MenuItem>
                                </PortalAwareItem>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Menu>
              )}>
                <SettingsIcon height={16} style={{cursor: 'pointer'}} />
              </PopOver>
            </Box>
          </Flex>

          <TableContainer css={css`overflow-x: scroll;`}>
            <Table>
              <TableHeaderRow>
                <TableHeaderCell style={{ whiteSpace: 'nowrap', width: 140 }}>{t('Criado em')}</TableHeaderCell>
                {filteredColumns.map(item => (
                  <TableHeaderCell key={item.label} width={item.width ? item.width : null} style={{whiteSpace: item.nowrap ? 'nowrap' : null, paddingRight: item.paddingRight ? item.paddingRight : null }}>{t(item.label)}</TableHeaderCell>
                ))}
                <TableHeaderCell></TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {items.map(item => (
                <TableRow key={item.code} css={item.mainExaminationCode ? css`font-weight: 300; color: hsla(211, 20%, 70%, 1);` : (item.secondaryExaminations ? css`font-weight: 600;` : null) }>
                  <TableCell data-title='Criado em' style={{ whiteSpace: 'nowrap'}}><FormatDateTime locale={i18n.language}>{item.orderCreatedAt ? item.orderCreatedAt : item.createdAt}</FormatDateTime></TableCell>
                  {filteredColumns.map(td => tds[td.name](item, td))}
                  <TableCellWithOptions item={item} navigate={navigate} me={me} />
                  <TableCell width={40} lineHeight={0}>
                    <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={({ onRequestClose }) => (


// me && me.account && me.account.name

                      <Menu>
                        <MenuItem onClick={() => navigate(`/laudos/${item.code}${window.location.search}`)}>{t('Detalhes')}</MenuItem>
                        {me && me.isDoctor && <MenuItem onClick={() => navigate(`/laudos/${item.code}/editar${window.location.search}`)}>{t('Editar')}</MenuItem>}
                        {me && me.isDoctor && <MenuItem onClick={() => handleDelete(item.code)}>{t('Excluir')}</MenuItem>}
                        {/*me && me.isDoctor && <MenuItem onClick={() => navigate(`/laudos/${item.code}/anexar${window.location.search}`)}>{t('Associar a...')}</MenuItem>*/}
                        {me && me.isDoctor && !item.mainExaminationCode && <MenuItem onClick={() => setAssociationModalCode(item.code)}>{t('Associações')}</MenuItem>}
                        {me && me.account && me.account.name === 'BMK' && me.isDoctor && <MenuItem onClick={() => navigate(`/laudos/${item.code}/nova-pendencia${window.location.search}`)}>{t('Nova pendência')}</MenuItem>}
                        {me && me.account && me.account.name === 'BMK' && me.isDoctor && <MenuItem onClick={() => navigate(`/laudos/${item.code}/pendencias${window.location.search}`)}>{t('Pendências')}</MenuItem>}
                        {me && me.account && me.account.name === 'BMK' && me.isDoctor && <MenuItem onClick={() => navigate(`/laudos/${item.code}/mensagens${window.location.search}`)}>{t('Mensagens')}</MenuItem>}
                        {me && me.account && me.account.name === 'BMK' && me.isDoctor && item.studyInstanceUID && <MenuItem onClick={() => { window.open(`https://viewer.bmkimagem.com.br/viewer?url=https://api.bmkexterno.com.br/webviewer/${item.studyInstanceUID}`, 'viewer'); setTimeout(() => onRequestClose(), 10); }}>{t('Visualizador')}</MenuItem>}
                        {me && me.email === 'iugiro@bmkimagem.com.br' && me.account && me.account.name === 'BMK' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20iugirok%200203%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'iugiro@bmkimagem.com.br' && me.account && me.account.name === 'BMK' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20iugirok%200203%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'kellymegumi@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20k.iwasaki%20bmk12345%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'kellymegumi@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20k.iwasaki%20bmk12345%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'izabela.coreixas@bmkimagem.com.br' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20izabelac%20bmk123%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'izabela.coreixas@bmkimagem.com.br' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20izabelac%20bmk123%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'fabio.magalhaes@bmkimagem.com.br' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20fmagalhaes%203872%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'fabio.magalhaes@bmkimagem.com.br' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20fmagalhaes%203872%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'hbferri@outlook.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20hferri%201101%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'hbferri@outlook.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20hferri%201101%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'gtabu@globo.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20gtaboada%20]]%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'gtabu@globo.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20gtaboada%20]]%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'fabi.brito.ferreira@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20fferreira%20bmk1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'fabi.brito.ferreira@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20fferreira%20bmk1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'mapaula@me.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20mapaula%20bmk1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'mapaula@me.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20mapaula%20bmk1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'stuppluciane@hotmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20lfreitas%20bmk1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'stuppluciane@hotmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20lfreitas%20bmk1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'dr.cesarnoce@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20cnoce%20bmk1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'dr.cesarnoce@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20cnoce%20bmk1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'fmramos19@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20framos%20bmk12012%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'fmramos19@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20framos%20bmk12012%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'fabi.brito.ferreira@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20fferreira%20Bmk1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'fabi.brito.ferreira@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20fferreira%20Bmk1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'carlacascardo@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20cvasconcelos%20Bmk@1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'carlacascardo@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20cvasconcelos%20Bmk@1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'jobisaglia@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20joana%20Bmk123%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'jobisaglia@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20joana%20Bmk123%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'gustavomarquescardoso@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20gcardoso%202010%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'gustavomarquescardoso@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20gcardoso%202010%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'vibrady@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20viviane.brady%20bmk1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'vibrady@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20viviane.brady%20bmk1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'paulobrunotrigo@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20ptrigo%20Bmk1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'paulobrunotrigo@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20ptrigo%20Bmk1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'jaironb@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20jcarmo%20bmk1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'jaironb@gmail.com' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20jcarmo%20bmk1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'monicanagano@bmkimagem.com.br' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20mnagano%20mn3608%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'monicanagano@bmkimagem.com.br' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20mnagano%20mn3608%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {me && me.email === 'leniogavio@uol.com.br' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20lsilva%20bmk1234%20${item.accessionNumber}%20carestream`, 'carestream'); setTimeout(() => onRequestClose(), 10); }}>{t('Carestream')}</MenuItem>}
                        {me && me.email === 'leniogavio@uol.com.br' && item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20lsilva%20bmk1234%20${item.accessionNumber}%203d`, '3d'); setTimeout(() => onRequestClose(), 10); }}>{t('Synapse 3D')}</MenuItem>}
                        {item.accessionNumber && <MenuItem onClick={() => { window.open(`bmk://%20%20%20${item.accessionNumber}%20fuji`, 'fuji'); setTimeout(() => onRequestClose(), 10); }}>{t('Fuji')}</MenuItem>}
                      </Menu>
                    )}>
                      <MoreIcon style={{cursor: 'pointer'}} />
                    </PopOver>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </>
      }

      {items.length === 0 &&
        <Box my={5} ta='center' css={css`@keyframes fadein { 0% { opacity: 0 } 66% { opacity: 0 } 100% { opacity: 1 } } & { animation: 0.5s ease 0s normal forwards 1 fadein; }`}>
          <Text medium fw={300} color='grey400'>{t('Nenhum registro encontrado')}</Text>
        </Box>
      }

      <Box ta='center'>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={page}
          pageCount={pageCount}
        />
      </Box>

      {associationModalCode &&
        <Modal
          onCloseCompleted={() => { setAssociationModalCode(null)}}
          render={
            ({onRequestClose}) => (
              <Associations onCompleted={() => { refetch() }} onRequestClose={onRequestClose} code={associationModalCode} />
            )
          }
        />
      }

    </Box>
  )
}

const ListQuery = ({ page, search, from, to, createdBy, status, notifier, me }) => {
  console.log('me', me)
  const offset = page ? ((page - 1) * PAGE_SIZE) : 0
  const variables = { limit: PAGE_SIZE, offset, search, from, to, createdBy, status }
  const { loading, error, data, refetch } = useQuery(LIST, { fetchPolicy: 'cache-and-network', variables })
  if (loading) return <Spin />
  if (error) return error.message
  return <List data={data} page={page} refetch={refetch} notifier={notifier} me={me} />
}

export default ListQuery
