import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { a, useTransition } from 'react-spring-next'
import { useMessageContext } from '../contexts/MessageContext'

export const Main = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled('div')`
  position: fixed;
  z-index: 1000;
  width: 0 auto;
  top: ${props => (props.top ? '30px' : 'unset')};
  bottom: ${props => (props.top ? 'unset' : '30px')};
  margin: 0 auto;
  left: 30px;
  right: 30px;
  display: flex;
  flex-direction: ${props => (props.top ? 'column-reverse' : 'column')};
  pointer-events: none;
  align-items: ${props => (props.position === 'center' ? 'center' : `flex-${props.position || 'end'}`)};
  @media (max-width: 680px) {
    align-items: center;
  }
`

export const Message = styled(a.div)`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 300px;
  @media (max-width: 680px) {
    width: 100%;
  }
`

export const Content = styled('div')`
  color: white;
  /* background: #445159; */
  /* font-size: 1em; */
  overflow: hidden;
  height: auto;
  margin-top: ${props => (props.top ? '0' : '10px')};
  margin-bottom: ${props => (props.top ? '10px' : '0')};
  background: ${props => (props?.theme?.colors?.infoBg || 'grey')};
  ${props => (props?.level === 'error' && css`
    background: ${props?.theme?.colors?.errorBg || 'grey'};
  `)}
  ${props => (props?.level === 'success' && css`
    background: ${props?.theme?.colors?.successBg || 'grey'};
  `)}
`

export const Button = styled('button')`
  cursor: pointer;
  pointer-events: all;
  outline: 0;
  border: none;
  background: transparent;
  display: flex;
  margin: 0;
  padding: 0;
  color: inherit;
`

export const Life = styled(a.div)`
  position: absolute;
  bottom: ${props => (props.top ? '10px' : '0')};
  left: 0px;
  width: auto;
  /* background-image: linear-gradient(130deg, #00b4e6, #00f0e0); */
  height: 5px;
  background: ${props => (props?.theme?.colors?.infoBgDarken || 'black')};
  ${props => (props?.level === 'error' && css`
    background-color: ${props?.theme?.colors?.errorBgDarken || 'black'};
  `)}
  ${props => (props?.level === 'success' && css`
    background-color: ${props?.theme?.colors?.successBgDarken || 'black'};
  `)}
`

let id = 0

function MessageHub({ config = { tension: 125, friction: 20, precision: 0.1 }, timeout = 5000, children }) {
  const [refMap] = useState(() => new WeakMap())
  const [cancelMap] = useState(() => new WeakMap())
  const [items, setItems] = useState([])

  const transition = useTransition(items, {
    key: item => item.key,
    from: { opacity: 0, height: 0, life: '100%' },
    enter: item => async (next, stop) => {
      cancelMap.set(item, () => {
        stop()
        setItems(state => state.filter(i => i.key !== item.key))
      })
      console.log('refMap.get(item).offsetHeight', refMap.get(item).offsetHeight)
      await next({ opacity: 1, height: refMap.get(item).offsetHeight, config })
      await next({ life: '0%', config: { duration: timeout } })
      cancelMap.get(item)()
    },
    leave: () => async next => {
      await next({ opacity: 0, config })
      await next({ height: 0, config })
    },
  })

  useEffect(() => {
    void children(msg => setItems(state => [...state, { key: id++, msg }]))
  }, [children])

  return (
    <Container>
      {transition(({ life, ...style }, item) => (
        <Message level={item?.msg?.level} style={style}>
          <Content level={item?.msg?.level} ref={ref => ref && refMap.set(item, ref)}>
            <Life level={item?.msg?.level} style={{ right: life }} />
            <div css={css`display: flex; justify-content: space-between;`}>
              <div css={css`flex-grow: 1; padding: 16px; padding-bottom: 30px;`}>{item.msg.message ? item.msg.message : item.msg}</div>
              <div css={css`padding: 8px;`}>
                <Button onClick={e => { e.stopPropagation(); if (cancelMap.has(item)) { cancelMap.get(item)() } }}>
                  <svg viewBox='0 0 24 24' height={20} fill='currentColor'>
                    <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
                  </svg>
                </Button>
              </div>
            </div>
          </Content>
        </Message>
      ))}
    </Container>
  )
}

const WrapperLayout = ({ children }) => {

  const { refMessage } = useMessageContext()

  return (
    <>
      <MessageHub children={add => (refMessage.current = add)} />
      {children}
    </>
  )
}

export default WrapperLayout
