import React from 'react'

import { keyframes, css } from 'styled-components/macro'

const bounce = keyframes`
  0%, 100% {transform: scale(0)}
  50% {transform: scale(1.0)}
`

const fadein = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`

const Spin = ({ color = 'hsla(201, 20%, 80%, 1)', size = 40 }) => {

  const wrapperStyle = css`
    opacity: 0;
    animation: 1s ease 0.3s normal forwards 1 ${fadein};
    position: relative;
    width: ${size}px;
    height: ${size}px;
  `

  const style = i => css`
    position: absolute;
    height: ${size}px;
    width: ${size}px;
    background-color: ${color};
    border-radius: 100%;
    opacity: 0.6;
    top: 0;
    left: 0;
    animation-fill-mode: both;
    animation: ${bounce} 2.1s ${i === 1 ? "1s" : "0s"} infinite ease-in-out;
  `

  return (
    <div css={wrapperStyle}>
      <div css={style(1)} />
      <div css={style(2)} />
    </div>
  )

}

export default Spin


// import { Box, css } from '@alobato/flex-box'

// const Spin = ({ color = 'hsla(211, 61%, 43%, 1)', ...rest }) => (
//   <Box w='100%' ta='center' css={css`@keyframes fadein { 0% { opacity: 0 } 100% { opacity: 1 } } & { opacity: 0; animation: 1s ease 0.3s normal forwards 1 fadein; }`}>
//     <svg
//       height={120}
//       viewBox='0 0 100 100'
//       preserveAspectRatio='xMidYMid'
//       style={{background: 'none'}}
//       {...rest}
//     >
//       <circle
//         cx='50'
//         cy='50'
//         fill='none'
//         r='20'
//         stroke={color}
//         strokeWidth='5'
//       />
//       <circle
//         cx='50'
//         cy='50'
//         fill='none'
//         r='20'
//         stroke='#ffffff'
//         strokeWidth='4'
//         strokeLinecap='square'
//         transform='rotate(146.131 50 50)'
//       >
//         <animateTransform
//           attributeName='transform'
//           type='rotate'
//           calcMode='linear'
//           values='0 50 50;180 50 50;720 50 50'
//           keyTimes='0;0.5;1'
//           dur='2.9s'
//           begin='0s'
//           repeatCount='indefinite'
//         />
//         <animate
//           attributeName='stroke-dasharray'
//           calcMode='linear'
//           values='12.566370614359172 113.09733552923255;62.83185307179586 62.83185307179586;12.566370614359172 113.09733552923255'
//           keyTimes='0;0.5;1'
//           dur='2.9'
//           begin='0s'
//           repeatCount='indefinite'
//         />
//       </circle>
//     </svg>
//   </Box>
// )

// export default Spin
