import React from 'react'
import { Flex } from '@alobato/flex-box'
// import { ThemeProvider } from 'styled-components'

import WrapperLayout from './WrapperLayout'
// import { useAppContext } from '../AppContext'
// import themes from '../themes'
import useTawkTo from '../hooks/useTawkTo'

const LoginLayout = ({ children }) => {

  // const { state } = useAppContext()
  // const theme = themes[state.theme]
  useTawkTo('60f751b3649e0a0a5ccd2bdf', '1fb31e9b3')

  return (
    <WrapperLayout>
      <Flex bg='gray100' flexDirection='column'>
        {children}
      </Flex>
    </WrapperLayout>
  )
}

export default LoginLayout
