// import React from 'react'
// import ReactSelect from 'react-select'
// import styled from 'styled-components'

// const MultiSelect = styled(ReactSelect)`
// 	&.Select--multi {
// 		.Select-value {
// 			display: inline-flex;
// 			align-items: center;
// 		}
// 	}
// 	& .Select-placeholder {
// 		font-size: smaller;
// 	}
// `

// export default (props) => <MultiSelect isMulti {...props} />

import React, { memo } from 'react'
import Select, { components } from 'react-select'

const DropdownIndicator = memo(props => (
  <components.DropdownIndicator {...props}>
    <svg  style={{transform: `rotate(${props.selectProps.menuIsOpen ? -180 : 0 }deg)`, transition: 'all 300ms'}} viewBox='0 0 24 24' height={24} fill='currentColor'>
      <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
    </svg>
  </components.DropdownIndicator>
))

export const selectCustomStyles = {
  option: (base, state) => ({
    ...base,
    padding: '5px 12px',
    fontSize: 14,
    color: 'inherit',
    fontWeight: state.isSelected ? '600' : 'inherit',
    backgroundColor: state.isFocused ? 'hsla(199, 100%, 95%, 1)' : 'inherit',
  }),
  container: (base, state) => ({
    ...base,
    maxWidth: 400
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 32,
    lineHeight: 1.2,
    padding: 0,
    borderWidth: 1,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderRadius: 3,
    transition: 'all 300ms',
    boxShadow: state.isFocused ? '0 0 0 3px hsla(202, 81%, 86%, 1)' : null,
    borderColor: state.isFocused ? 'hsla(205, 79%, 66%, 1)' : 'hsla(203, 20%, 76%, 1)',
    cursor: 'pointer',
    '& > div': { minHeight: 33 }
  }),
  singleValue: (base, state) => ({
    ...base,
    color: 'hsla(0, 0%, 0%, 0.65)'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 2,
    fontSize: 12,
    color: 'hsla(0, 0%, 0%, 0.25)'
  }),
  placeholder: (base, state) => ({
    color: 'hsla(203, 20%, 76%, 1)',
    fontFamily: 'inherit',
    fontSize: 'inherit'
  })
}

export default memo(({ options, value, onChange, placeholder = 'Selecione...' }) => {
  return (
    <Select
      tabIndex={0}
      styles={selectCustomStyles}
      components={{ DropdownIndicator }}
      isSearchable={true}
      value={value}
      onChange={onChange}
			options={options}
			placeholder={placeholder}
			isMulti
    />
  )
})
