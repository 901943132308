import React, { memo } from 'react'
import { Router } from '@reach/router'

import MainLayout from '../../layouts/MainLayout'
import Home from './Home'

 const Index = ({ me, onLogout, ...rest }) => {
  return (
    <Router>
      <MainLayout me={me} onLogout={onLogout} path='/'>
        <Home {...rest} me={me} path='/novo' />
        <Home {...rest} me={me} path='/:id/editar' />
        <Home {...rest} me={me} path='/:id/license' />
        <Home {...rest} me={me} path='/:id' />
        <Home {...rest} me={me} path='/' />
      </MainLayout>
    </Router>
  )
}

export default memo(Index)
