import React from 'react'
import { Field } from 'formik'
import { css } from 'styled-components/macro'
import { Box, Flex } from '../../components/_new/FlexBox'
import Label from '../../components/_new/Label'
import FormErrorBox from '../../components/_new/FormErrorBox'
import Checkbox from '../../components/_new/Checkbox'
import Button from '../../components/_new/ButtonWithLoading'
import FieldInput from '../../components/_new/FieldInput'
import FieldSubdomainInput from '../../components/_new/FieldSubdomainInput'
// import useFocusOnLoad from '../../hooks/useFocusOnLoad'

function Form({ activeStep, values, setFieldValue, isLastStep, onBack, isSubmitting, handleSubmit, touched, errors, students, isCreating = false, showAccountName }) {
// function Form({ handleSubmit, isSubmitting, values, errors, touched, setFieldValue, showAccountName }) {

  React.useEffect(() => {
    const subdomain = values.accountName.toLowerCase().replace(/\s/g, '').replace(/[^a-z]/g, '').slice(0, 20)
    setFieldValue('subdomain', subdomain)
  }, [setFieldValue, values.accountName])

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      {activeStep === 0 && (
        <Box>

          <Box mb={2}>
            <Label htmlFor='email' css={css`font-size: 14px; color: hsl(219deg 8% 50%);`}>Digite Seu email</Label>
            <Field required id='email' type='email' autoComplete='nope' width='100%' name='email' component={FieldInput} />
          </Box>

        </Box>
      )}

      {activeStep === 1 && (
        <Box>

          <Box mb={4} py={1} px={2} textAlign='center' css={css`font-size: 16px; color: hsl(219deg 8% 50%); background-color: hsl(219deg 8% 96%);`}>{`Enviamos um email para ${values.email.trim()} com o código de validação`}</Box>

          <Box mb={3}>
            <Label htmlFor='validationCode' css={css`font-size: 14px; color: hsl(219deg 8% 50%);`}>Código de Validação</Label>
            <Field required id='validationCode' autoComplete='nope' width='100%' name='validationCode' component={FieldInput} />
          </Box>

        </Box>
      )}

      {activeStep === 2 && (
        <Box>

          {showAccountName && (
            <>
              <Box mb={3}>
                <Label htmlFor='accountName' css={css`font-size: 14px; color: hsl(219deg 8% 50%);`}>Nome da conta</Label>
                <Field required id='accountName' autoComplete='nope' width='100%' name='accountName' component={FieldInput} />
              </Box>
              <Box mb={3}>
                <Label htmlFor='subdomain' css={css`font-size: 14px; color: hsl(219deg 8% 50%);`}>Endereço da sua conta</Label>
                <Flex alignItems='baseline'>
                  <Box mr={1}>https://</Box>
                  <Box><Field required id='subdomain' autoComplete='nope' width='120px' maxLength={20} name='subdomain' component={FieldSubdomainInput} /></Box>
                  <Box ml={1}>.telerison.com</Box>
                </Flex>

              </Box>
            </>
          )}

          <Box mb={3}>
            <Label htmlFor='name' css={css`font-size: 14px; color: hsl(219deg 8% 50%);`}>Seu nome completo</Label>
            <Field required id='name' autoComplete='nope' width='100%' name='name' component={FieldInput} />
          </Box>

          <Box mb={3}>
            <Label htmlFor='password' css={css`font-size: 14px; color: hsl(219deg 8% 50%);`}>Crie uma senha</Label>
            <Field required id='password' type='password' autoComplete='new-password' width='100%' name='password' component={FieldInput} />
          </Box>

          <Box mb={3}>
            <Checkbox label={(<div style={{ cursor: 'pointer', userSelect: 'none' }}>Eu li e concordo com os <a href='/terms' target='_blank'>Termos de Uso</a></div>)} checked={values.agree} onChange={checked => setFieldValue('agree', checked)} />
            <FormErrorBox fieldName='agree' errors={errors} touched={touched} />
          </Box>

        </Box>
      )}



      <Flex alignItems='center' mt={4}>
        {activeStep !== 0 && (<Box cursor='pointer' onClick={onBack} mr={4}>Voltar</Box>)}
        <Box><Button type='submit' loading={isSubmitting}>{isLastStep ? (isCreating ? 'Concluir' : 'Salvar') : 'Próximo'}</Button></Box>
      </Flex>

    </form>
  )
}

export default Form
