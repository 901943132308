import React from 'react'
import { Router, globalHistory } from '@reach/router'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ThemeProvider } from 'styled-components'
// import Fingerprint2 from 'fingerprintjs2'

import notifier from './components/Notifier'
import ErrorBoundary from './components/ErrorBoundary'

// import ReactGA from 'react-ga'

import GlobalStyle from './globalStyle'
import { OnlyAuthenticated, OnlyUnauthenticated } from './authorization'

import AutoLogout from './components/AutoLogout'

import WithMe from './components/_new/WithMe'
import PrivateRoute from './components/_new/PrivateRoute'
import PublicRoute from './components/_new/PublicRoute'

import LoginLayout from './layouts/LoginLayout'

import Register from './containers/Register'
import RegisterMultiStep from './containers/RegisterMultiStep'
import RegisterPlans from './containers/Register/Plans'

import Signup from './containers/Signup'
import Login from './containers/Login'
import Dashboard from './containers/Dashboard'
import Accounts from './containers/Accounts'
import AccountUsers from './containers/AccountUsers'
import AccountLicenses from './containers/AccountLicenses'
import AccountGroups from './containers/AccountGroups'
import Profile from './containers/Profile'
import Account from './containers/Account'
import Phrases from './containers/Phrases'
import Tree from './containers/Tree'
import Examinations from './containers/Examinations'
import Clinics from './containers/Clinics'
import Plans from './containers/Plans'
import Monit from './containers/Monit'
import Lab from './containers/Lab'
import Experiments from './containers/Experiments/index3'
import Galleries from './containers/Galleries'
import Documents from './containers/Documents'
import TableMaker from './containers/TableMaker'
import Users from './containers/Users'

import Subscription from './containers/Subscription'
import MySubscription from './containers/MySubscription'

// import Users from './containers/Users'
// import Units from './containers/Units'
// import UnitUsers from './containers/UnitUsers'
// import Studies from './containers/Studies'
// import Exams from './containers/Exams'
// import Communications from './containers/Communications'
// import Orders from './containers/Orders'
// import Download from './containers/Download'
// import Templates from './containers/Templates'
// import DocSettings from './containers/DocSettings'

import PasswordResetSend from './containers/PasswordReset/Send'
import PasswordReset from './containers/PasswordReset'

import Step1 from './containers/Step1'
import Step2 from './containers/Step2'
import Step3 from './containers/Step3'

import TableStep1 from './containers/TableStep1'
import TableStep2 from './containers/TableStep2'
import TableStep3 from './containers/TableStep3'

import IllustrationStep1 from './containers/IllustrationStep1'
import IllustrationStep2 from './containers/IllustrationStep2'
import Illustration from './containers/Illustration'

import Terms from './containers/Pages/terms'

// import Patients from './containers/Patients'

// import Portfolios from './containers/Portfolios'
// import Agreements from './containers/Agreements'

import './handsontable.css'
import './antd.css'

import useMobileDetect from '@alobato/use-mobile-detect'

// import { AppProvider } from './AppContext'
import { AppProvider, useAppContext } from './contexts/AppContext'
import { MessageProvider } from './contexts/MessageContext'
import themes from './themes'

// import ttiPolyfill from 'tti-polyfill'


// if (process.env.NODE_ENV === 'production') {
  // https://github.com/iqbal125/react-hooks-google-analytics/blob/master/src/App.js
  // ReactGA.initialize('UA-169306943-1')

  // globalHistory.listen(({ location }) => {
    // https://stackoverflow.com/questions/53193975/how-to-track-page-views-in-react-using-reach-router-and-google-analytics
    // ReactGA.set({ page: window.location.pathname + window.location.search })
    // ReactGA.pageview(window.location.pathname + window.location.search)
  // })

  // ttiPolyfill.getFirstConsistentlyInteractive().then(tti => {
    // ReactGA.timing({ category: 'Load Performace', variable: 'Time to Interactive', value: tti })
  // })

  // https://www.freecodecamp.org/news/performance-and-user-tracking-in-react-with-google-analytics/
  // const observer = new PerformanceObserver(list => {
  //   list.getEntries().forEach(entry => {
  //     ReactGA.timing({
  //       category: 'First Meaningful Paint',
  //       variable: entry.name,
  //       value: entry.responseEnd
  //     })
  // })})
  // observer.observe({ entryTypes: [
    // 'navigation',
    // 'paint',
    // 'resource',
    // 'mark',
    // 'measure',
    // 'frame',
    // 'longtask'
  // ]})
// }



const App = () => {

  useMobileDetect()

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    const redirect = () => {
      setTimeout(() => {
        window.location.href = '/'
      }, 10)
    }

    if (params && params.token) {
      localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, params.token)
      redirect()
    }
  }, [])

  // React.useEffect(() => {
  //   if (window.requestIdleCallback) {
  //     requestIdleCallback(function () {
  //       Fingerprint2.get(function (components) {
  //         console.log('components1', components) // an array of components: {key: ..., value: ...}
  //       })
  //     })
  //   } else {
  //     setTimeout(function () {
  //       Fingerprint2.get(function (components) {
  //         console.log('components2', components)
  //       })
  //     }, 500)
  //   }
  // }, [])

  const { state } = useAppContext()
  const theme = themes[state.theme]

  return (
      <ErrorBoundary>

        <BrowserRouter>

        <QueryParamProvider reachHistory={globalHistory}>
          <ThemeProvider theme={theme}>
          <GlobalStyle theme={theme} />

          <AutoLogout />

          <MessageProvider>

          <Switch>
            <PrivateRoute exact path={`/`}>
              <Dashboard to={`/laudos`} />
            </PrivateRoute>
            <Route path={`/terms`}>
              <Terms />
            </Route>
            <Route path={[`/register`, `/plans`]}>
              <LoginLayout>
                <PublicRoute path={`/register1`}>
                  <Register />
                </PublicRoute>
                <PublicRoute path={`/register`}>
                  <RegisterMultiStep />
                </PublicRoute>
                <PublicRoute path={`/plans`}>
                  <RegisterPlans />
                </PublicRoute>
              </LoginLayout>
            </Route>
          </Switch>

          <Router>
            <Documents path='/resultado/*' notifier={notifier} />

            <OnlyUnauthenticated path='/criar-conta'>
              {() => <Signup notifier={notifier} />}
            </OnlyUnauthenticated>

            {/* <OnlyUnauthenticated path='/register'>
              {() => <Register notifier={notifier} />}
            </OnlyUnauthenticated> */}

            <OnlyUnauthenticated path='/login'>
              {() => <Login notifier={notifier} redirectTo='/' />}
            </OnlyUnauthenticated>

            <OnlyUnauthenticated path='/esqueci-a-senha'>
              {() => <PasswordResetSend notifier={notifier} />}
            </OnlyUnauthenticated>

            <OnlyUnauthenticated path='/r/:token'>
              {({ token }) => <PasswordReset token={token} notifier={notifier} />}
            </OnlyUnauthenticated>

            {/* <OnlyAuthenticated path='/'>
              {() => <Dashboard to='/laudos' />}
            </OnlyAuthenticated> */}

            <OnlyAuthenticated path='/lab'>
              {() => <Lab />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/experiments'>
              {() => <Experiments />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/tablemaker'>
              {me => <TableMaker notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/monit'>
              {() => <Monit />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/contas/:accountId/usuarios'>
              {me => <AccountUsers notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/contas/:accountId/licencas'>
              {me => <AccountLicenses notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/contas/:accountId/grupos'>
              {me => <AccountGroups notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/contas'>
              {me => <Accounts notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/planos'>
              {me => <Plans notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/clinicas'>
              {me => <Clinics notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/laudos'>
              {me => <Examinations notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/etapa1'>
              {me => <Step1 notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/etapa2'>
              {me => <Step2 notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/etapa3'>
              {me => <Step3 notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/tabela-etapa1'>
              {me => <TableStep1 notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/tabela-etapa2'>
              {me => <TableStep2 notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/tabela-etapa3'>
              {me => <TableStep3 notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/ilustracao-etapa1'>
              {me => <IllustrationStep1 notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/ilustracao-etapa2'>
              {me => <IllustrationStep2 notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/ilustracao'>
              {me => <Illustration notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/arvore'>
              {me => <Tree notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/galerias'>
              {me => <Galleries notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/frases'>
              {me => <Phrases notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/minha-conta'>
              {me => <Profile notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/account'>
              {me => <Account notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/account'>
              {me => <Account notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/subscription'>
              {me => <Subscription notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/my-subscription'>
              {me => <MySubscription notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            <OnlyAuthenticated path='/users'>
              {me => <Users notifier={notifier} me={me} />}
            </OnlyAuthenticated>

            {/* <Communications path='/c/:token' notifier={notifier} /> */}

            {/* <OnlyAuthenticated path='/download'>
              {(me, props) => <Download {...props} notifier={notifier} me={me} onLogout={() => onLogout(client)} />}
            </OnlyAuthenticated> */}

            {/* <OnlyUnauthenticated path='/criar-conta'>
              {props => <Signup {...props} notifier={notifier} redirectTo='/download' />}
            </OnlyUnauthenticated> */}

            {/* <OnlyAuthenticated path='/unidades/:unitId/usuarios'>
              {(me, props) => <UnitUsers {...props} notifier={notifier} me={me} onLogout={() => onLogout(client)} />}
            </OnlyAuthenticated> */}

            {/* <OnlyAuthenticated path='/unidades'>
              {(me, props) => <Units {...props} notifier={notifier} me={me} onLogout={() => onLogout(client)} />}
            </OnlyAuthenticated> */}

            {/* <OnlyAuthenticated path='/usuarios'>
              {(me, props) => <Users {...props} notifier={notifier} me={me} onLogout={() => onLogout(client)} />}
            </OnlyAuthenticated> */}

            {/* <OnlyAuthenticated path='/estudos'>
              {(me, props) => <Studies {...props} notifier={notifier} me={me} onLogout={() => onLogout(client)} />}
            </OnlyAuthenticated> */}

            {/* <OnlyAuthenticated path='/exames'>
              {(me, props) => <Exams {...props} notifier={notifier} me={me} onLogout={() => onLogout(client)} />}
            </OnlyAuthenticated> */}

            {/* <OnlyAuthenticated path='/templates'>
              {(me, props) => <Templates {...props} notifier={notifier} me={me} onLogout={() => onLogout(client)} />}
            </OnlyAuthenticated> */}

            {/* <OnlyAuthenticated path='/configuracoes'>
              {(me, props) => <DocSettings {...props} notifier={notifier} me={me} onLogout={() => onLogout(client)} />}
            </OnlyAuthenticated> */}

            {/* <OnlyAuthenticated path='/solicitacoes'>
              {me => <Orders notifier={notifier} me={me} />}
            </OnlyAuthenticated> */}

          </Router>

          </MessageProvider>
        </ThemeProvider>
        </QueryParamProvider>

        </BrowserRouter>
      </ErrorBoundary>
  )
}

// export default memo(props => {
//   // TODO: Load DB config theme preference
//   return <AppProvider defaultValue={{ theme: 'default' }}><App {...props} /></AppProvider>
// })

function AppWithProvider() {
  const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)
  return (
    token ? (
      <WithMe>{({ data }) => <AppProvider defaultValue={{ theme: 'default', me: data.me }}><App /></AppProvider>}</WithMe>
    ) : (
      <AppProvider defaultValue={{ theme: 'default' }}><App /></AppProvider>
    )
  )
}


export default AppWithProvider
