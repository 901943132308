import React, { memo, useLayoutEffect } from 'react'
import { Router } from '@reach/router'

import LoginLayout from '../../layouts/LoginLayout'
import Home from './Home'

import themes from '../../themes'

 const Index = ({ notifier, redirectTo }) => {

  useLayoutEffect(() => {
    document.body.style.backgroundColor = themes.default.colors.grey100
    return () => document.body.removeAttribute('style')
  }, [])

  return (
    <Router>
      <LoginLayout path='/'>
        <Home path='/' notifier={notifier} redirectTo={redirectTo} />
      </LoginLayout>
    </Router>
  )
}

export default memo(Index)
