import { useEffect } from 'react'
// import { navigate } from '@reach/router'

function Dashboard({ to }) {

  useEffect(() => {
    // setTimeout(() => navigate(to, { replace: true }), 0)
    setTimeout(() => window.location.href = to, 0)
  }, [to])

  return null

}

export default Dashboard
