import React from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { css } from 'styled-components/macro'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'
import * as yup from 'yup'

import { Box } from '../../components/_new/FlexBox'
import Text from '../../components/_new/Text'
// import Link from '../../components/Link'

import { useAppContext } from '../../contexts/AppContext'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

function Home() {
  // const history = useHistory()

  const { state } = useAppContext()
  const { message } = useMessageContext()

  const initialValues = { accountName: '', name: '', email: '', password: '', agree: false }
  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    email: yup.string().email('Email inválido').required('Campo obrigatório'),
    password: yup.string().required('Campo obrigatório').matches(/^.{8,}$/, 'Sua senha precisa ter pelo menos 8 caracteres'),
    agree: yup.boolean().oneOf([true], 'Você deve concordar com os termos para criar uma conta')
  })

  const [register] = useMutation(gql`mutation($accountName: String, $name: String!, $email: String!, $password: String!, $plans: JSON) { register(accountName: $accountName, name: $name, email: $email, password: $password, plans: $plans) { token } }`)

  const handleSubmit = async (variables, { setSubmitting }) => {
    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)

    try {
      const result = await register({ variables: { ...variables, plans: state.plans } })
      localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, result.data.register.token)
      // history.push(`/laudos`)
      window.location.href = '/laudos'
    } catch(err) {
      setSubmitting(false)
      console.error(err)
      const errorMessage = err.message.replace('GraphQL error: ', '')
      message({ level: 'error', message: errorMessage })
    }
  }

  if (!state?.plans) return (<Redirect to={{ pathname: '/plans' }} />)

  const showAccountName = (typeof state.plans === 'object' && state.plans !== null && !Array.isArray(state.plans))

  return (
    <Box pt={5} pb={4}>
      <Box mx='auto' maxWidth={400}>

        <Box textAlign='center' mb={4}>
          <Text fontWeight={600} fontSize={28} css={css`font-size: clamp(1.5rem, 8vw - 2rem, 2rem);`}>Crie sua conta</Text>
        </Box>

        {state?.plans?.length === 1 && (
          <Box mb={4} textAlign='center'>
            Plano escolhido: {state.plans[0].name}
          </Box>
        )}

        <Box mb={4} textAlign='center'>
          Complete os dados para criar sua conta
        </Box>

        <Box mb={5}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {props => <Form {...props} showAccountName={showAccountName} />}
          </Formik>
        </Box>


      </Box>
    </Box>
  )
}

export default Home
