import React from 'react'
import Spin from '../../components/Spin'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { CloseIcon } from '../../components/Icons'
import { TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '@alobato/table'
import { css } from 'styled-components/macro'

import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from '@apollo/client'
import { CONNECT_EXAMINATIONS, LIST_EXAMINATIONS_TO_SECONDARY } from './Gqls'

const Secondary = ({ code, data: { examinationsToSecondary }, onRequestClose, onCompleted, me }) => {

  const { t } = useTranslation()

  const [connectExaminations] = useMutation(CONNECT_EXAMINATIONS)

  const handleConnect = async mainExaminationCode => {

    try {
      const result = await connectExaminations({ variables: { mainExaminationCode, secondaryExaminationCode: code } })
      onCompleted()
    } catch(error) {
      console.error(error)
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Associar a...')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <TableContainer>
          <Table>
            <TableHeaderRow>
              <TableHeaderCell>{t('Paciente')}</TableHeaderCell>
              <TableHeaderCell>{t('Modalidade')}</TableHeaderCell>
              <TableHeaderCell>{t('ExamName')}</TableHeaderCell>
            </TableHeaderRow>
            {examinationsToSecondary.map(item => (
              <TableRow key={item.code} css={css`cursor: pointer;`} onClick={() => handleConnect(item.code)}>
                <TableCell data-title='Paciente' style={{ whiteSpace: 'nowrap'}}>{item.PatientName}</TableCell>
                <TableCell data-title='Modalidade' style={{ whiteSpace: 'nowrap'}}>{item.orderDetails.ExamModality}</TableCell>
                <TableCell data-title='ExamName' style={{ whiteSpace: 'nowrap'}}>{item.orderDetails.ExamName}</TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>

      </Box>
    </Flex>
  )
}

const SecondaryQuery = ({ code, onRequestClose, onCompleted, me }) => {
  const { loading, error, data } = useQuery(LIST_EXAMINATIONS_TO_SECONDARY, { variables: { code } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Secondary code={code} data={data} onRequestClose={onRequestClose} onCompleted={onCompleted} me={me} />
}

export default SecondaryQuery
