import React from 'react'
import { Field } from 'formik'

import { Box } from '../../components/_new/FlexBox'
import Label from '../../components/_new/Label'
import FormErrorBox from '../../components/_new/FormErrorBox'
import Checkbox from '../../components/_new/Checkbox'
import Button from '../../components/_new/ButtonWithLoading'
import FieldInput from '../../components/_new/FieldInput'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

function Form({ handleSubmit, isSubmitting, values, errors, touched, setFieldValue, showAccountName }) {

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      {showAccountName && (
        <Box mb={2}>
          <Label htmlFor='accountName'>Nome da conta</Label>
          <Field required id='accountName' autoComplete='nope' width='100%' name='accountName' component={FieldInput} />
        </Box>
      )}

      <Box mb={2}>
        <Label htmlFor='name'>Seu nome completo</Label>
        <Field required id='name' autoComplete='nope' width='100%' name='name' component={FieldInput} />
      </Box>

      <Box mb={2}>
        <Label htmlFor='email'>Seu email</Label>
        <Field required id='email' type='email' autoComplete='nope' width='100%' name='email' component={FieldInput} />
      </Box>

      <Box mb={2}>
        <Label htmlFor='password'>Crie uma senha</Label>
        <Field required id='password' type='password' autoComplete='new-password' width='100%' name='password' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Checkbox label={(<div style={{ cursor: 'pointer', userSelect: 'none' }}>Eu li e concordo com os <a href='/terms' target='_blank'>Termos de Uso</a></div>)} checked={values.agree} onChange={checked => setFieldValue('agree', checked)} />
        <FormErrorBox fieldName='agree' errors={errors} touched={touched} />
      </Box>

      <Box>
        <Button type='submit' loading={isSubmitting}>Criar conta</Button>
      </Box>

    </form>
  )
}

export default Form
