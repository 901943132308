import React from 'react'
import Spin from '../../components/Spin'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Formik } from 'formik'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import Form from './Form'
import validationSchema from './validationSchema'

import { useQuery, useMutation } from '@apollo/client'
import { GET, UPDATE } from './Gqls'

const Edit = ({ data: { examination }, onRequestClose, onCompleted, me }) => {

  const { t } = useTranslation()

  const [updateExamination] = useMutation(UPDATE)

  const initialValues = {
    ...examination,
    PatientAge: examination.PatientAge || '',
    PatientWeight: examination.PatientWeight || '',
    PatientHeight: examination.PatientHeight || '',
    examinedAt: examination.examinedAt || '',
    previousExam1At: examination.previousExam1At || '',
    previousExam2At: examination.previousExam2At || '',
  }

  const handleSubmit = async variables => {

    if (!window.confirm(t('Você tem certeza que deseja editar este laudo? As informações inseridas nas etapas 1, 2 e 3 serão perdidas.'))) return false

    const newVariables = { ...variables }

    if (!newVariables.examinedAt) newVariables.examinedAt = null
    if (!newVariables.previousExam1At) newVariables.previousExam1At = null
    if (!newVariables.previousExam2At) newVariables.previousExam2At = null
    newVariables.PatientAge = newVariables.PatientAge ? parseInt(newVariables.PatientAge) : null
    newVariables.PatientWeight = newVariables.PatientWeight ? parseInt(newVariables.PatientWeight) : null
    newVariables.PatientHeight = newVariables.PatientHeight ? parseInt(newVariables.PatientHeight) : null


    // const update = (cache, { data: { updateExamination } }) => {
    //   const cacheList = cache.readQuery({ query: LIST })
    //   cache.writeQuery({ query: LIST, data: { ...cacheList, users: cacheList.users.map(item => { if (item.id === variables.id) return updateUser; return item }) }  })
    // }

    try {
      await updateExamination({ variables: newVariables })
      onCompleted()
    } catch(error) {
      console.error(error)
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Editar')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} isCreating={false} me={me} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const EditQuery = ({ code, onRequestClose, onCompleted, me }) => {
  const { loading, error, data } = useQuery(GET, { variables: { code } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Edit data={data} onRequestClose={onRequestClose} onCompleted={onCompleted} me={me} />
}

export default EditQuery
