import React, { memo } from 'react'
import { Router } from '@reach/router'

import MainLayout from '../../layouts/MainLayout'
import Home from './Home'

const ExaminationsIndex = ({ me, notifier }) => {
  return (
    <Router>
      <MainLayout me={me} path='/'>
        <Home notifier={notifier} me={me} path='/novo' />
        <Home notifier={notifier} me={me} path='/nova-tabela' />
        <Home notifier={notifier} me={me} path='/nova-ilustracao' />
        <Home notifier={notifier} me={me} path='/:code/editar' />
        <Home notifier={notifier} me={me} path='/:code/anexar' />
        <Home notifier={notifier} me={me} path='/:code/nova-pendencia' />
        <Home notifier={notifier} me={me} path='/:code/pendencias' />
        <Home notifier={notifier} me={me} path='/:code/mensagens' />
        <Home notifier={notifier} me={me} path='/:code' />
        <Home notifier={notifier} me={me} path='/' />
      </MainLayout>
    </Router>
  )
}

export default memo(ExaminationsIndex)
