import React, { memo } from 'react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'

const Paginate = memo(({ onPageChange, currentPage, pageCount, className }) => {
  if (pageCount <= 1) return null
  return (
    <ReactPaginate
      forcePage={(currentPage || 1) - 1}
      previousLabel='Anterior'
      nextLabel='Próximo'
      breakLabel='...'
      breakClassName='break-me'
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName={className}
      subContainerClassName='pages pagination'
      activeClassName='active'
      initialPage={(currentPage || 1) - 1}
    />
  )
})

const StyledPaginate = styled(Paginate)`
  display: flex;
  justify-content: center;
  & {
    display: inline-block;
    padding-left: 0;
    border-radius: ${props => props.theme && props.theme.radius && props.theme.radius.inputRadius ? props.theme.radius.inputRadius : '0'};
    user-select: none;
  }
  & > li {
    display: inline;
  }
  & li > a {
    cursor: pointer;
  }
  & > li > a, & > li > span {
    outline: none;
    cursor: pointer;
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: inherit;
    text-decoration: none;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.grey200};
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px ${props => props.theme.colors.primary200};
      border: 1px solid ${props => props.theme.colors.primary400};
      z-index: 3;
    }
  }
  & > li:first-child > a, & ul > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: ${props => props.theme && props.theme.radius && props.theme.radius.inputRadius ? props.theme.radius.inputRadius : '0'};
    border-bottom-left-radius: ${props => props.theme && props.theme.radius && props.theme.radius.inputRadius ? props.theme.radius.inputRadius : '0'};
  }
  & > li:last-child > a, & > ul > li:last-child > span {
    border-top-right-radius: ${props => props.theme && props.theme.radius && props.theme.radius.inputRadius ? props.theme.radius.inputRadius : '0'};
    border-bottom-right-radius: ${props => props.theme && props.theme.radius && props.theme.radius.inputRadius ? props.theme.radius.inputRadius : '0'};
  }
  & > .active > a,
  & > .active > a:focus,
  & > .active > a:hover,
  & > .active > span,
  & > .active > span:focus,
  & > .active > span:hover {
    z-index: 2;
    color: ${props => props.theme.colors.white};
    cursor: default;
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
  }
  & > .disabled > a,
  & > .disabled > a:focus,
  & > .disabled > a:hover,
  & > .disabled > span,
  & > .disabled > span:focus,
  & > .disabled > span:hover {
    color: ${props => props.theme.colors.grey200};
    cursor: not-allowed;
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.grey200};
  }
`

export default StyledPaginate
