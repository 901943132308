import React from 'react'
import { Field, Form, Formik } from 'formik'

import { useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/client'
import { MULTI_STEP_SIGNUP_1 } from './Gqls'

import { object, string } from 'yup'
import '../../validators'

import styled, { css } from 'styled-components/macro'

import Button from '../../components/Button'
import Input from '../../components/FieldInput'
import Checkbox from '@alobato/checkbox'

import FormErrorBox from '../../components/FormErrorBox'


const Box = styled.div``

const Flex = styled(Box)`
  display: flex;
`

const Label = styled(Box)`
  font-weight: 600;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  ${props => props.uppercase && css`text-transform: uppercase;`}
  & > span {
    color: hsla(216, 40%, 70%, 1);
  }
`

const P = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 32px;
`

const Title = styled(Box)`
  font-family: ${props => props.theme.secondaryFontFamily};
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 32px;
`

const Card = styled(Box)`
  background-color: white;
  box-shadow: 0px 3px 6px 0px hsla(0, 0%, 20%, 0.15);
  width: 300px;
  height: 440px;
  padding: 32px;
  .desktop & {
    width: 648px;
  }
`

const validationSchema = object().shape({
  email: string().required(),
})

const Home = ({ navigate, notifier }) => {

  const [accepted, setAccepted] = React.useState(false)

  const { t } = useTranslation()

  const [signUp] = useMutation(MULTI_STEP_SIGNUP_1)

  const initialValues = {
    email: '',
  }

  const handleSubmit = async (variables, { setSubmitting }) => {
    if (!accepted) {
      return notifier.error('Você deve aceitar os termos de serviço para continuar.')
    }

    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)

    signUp({ variables })
      .then(result => {
        if (result && result.data && result.data.multiStepSignup1  && result.data.multiStepSignup1.verificationToken) {
          navigate(`/criar-conta/verificar/${result.data.multiStepSignup1.verificationToken}`)
        }
        setSubmitting(false)
      })
      .catch(error => {
        setSubmitting(false)
        console.error(error)
        const errorMessage = error.message.replace('GraphQL error: ', '')
        notifier.error(errorMessage)
      })
  }

  return (
    <Flex css={css`justify-content: center; width: 100%; margin-top: 64px;`}>

      <Card>

        <Box css={css`text-align: center;`}>
          <Title>{t('Criar conta')}</Title>
        </Box>

        <P>Experimente por 15 dias gratuitamente.<br />
        Não solicitamos cartão de crédito durante o período de teste.</P>

        <Box>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Box css={css`width: 300px; margin: 0 auto 8px auto;`}>
                  <Label>{t('Digite seu email')}</Label>
                  <Field width='100%' name='email' type='email' autoComplete='email' component={Input} withError={touched.email && errors.email} />
                  <FormErrorBox fieldName='email' errors={errors} touched={touched} />
                </Box>

                <Box css={css`width: 300px; margin: 0 auto 64px auto;`}>
                  <Checkbox checked={accepted} onChange={setAccepted} label='Aceito os termos de serviço' />
                </Box>

                <Box css={css`text-align: center;`}>
                  <Button type='submit' loading={isSubmitting}>Continuar</Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>

      </Card>

    </Flex>
  )
}

export default Home
