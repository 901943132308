const themes = {
  default: {
    space:     [0,   4,  8, 16, 32, 64, 128, 256, 512],
    fontSizes: [12, 14, 16, 20, 24, 32,  48,  64,  96],
    primaryFontSize: '14px',
    primaryLineHeight: '1.5',
    primaryFontFamily: 'Inter',
    primaryFontWeight: 400,
    secondaryFontFamily: 'Inter',
    buttonFontFamily: 'Inter',
    paddings: {
      cardPadding: 0,
      wrapperPadding: [3, 4]
    },
    radius: {
      cardRadius: '12px',
      menuRadius: '6px',
      modalRadius: '8px',
      inputRadius: '4px',
    },
    shadows: {
      cardShadow: 'none', // 0px 3px 6px 0px hsla(0, 0%, 20%, 0.15)
      menuShadow: '0 2px 8px hsla(0, 0%, 0%, 0.15)',
      modalShadow: '0 7px 8px -4px rgba(0,0,0,0.2), 0 13px 19px 2px rgba(0,0,0,0.14), 0 5px 24px 4px rgba(0,0,0,0.12)',
      buttonShadow: '0 2px 0 rgba(0, 0, 0, 0.045)',
    },
    borders: {
      cardBorder: 'none',
      menuBorder: 'none',
      modalBorder: 'none',
    },
    colors: {
      primary100:  'hsla(201, 100%, 96%, 1)',
      primary200:  'hsla(202,  81%, 86%, 1)',
      primary300:  'hsla(203,  82%, 76%, 1)',
      primary400:  'hsla(205,  79%, 66%, 1)',
      primary:     'hsla(211,  61%, 43%, 1)',
      primary600:  'hsla(209,  62%, 50%, 1)',
      primary700:  'hsla(211,  61%, 43%, 1)',
      primary800:  'hsla(213,  49%, 34%, 1)',
      primary900:  'hsla(215,  41%, 28%, 1)',
      grey0:       'white',
      grey100:     'hsla(201,  20%, 96%, 1)',
      grey200:     'hsla(202,  20%, 86%, 1)',
      grey300:     'hsla(203,  20%, 76%, 1)',
      grey400:     'hsla(205,  20%, 66%, 1)',
      grey:        'hsla(207,  20%, 57%, 1)',
      grey600:     'hsla(209,  20%, 50%, 1)',
      grey700:     'hsla(211,  20%, 43%, 1)',
      grey800:     'hsla(213,  20%, 34%, 1)',
      grey900:     'hsla(215,  20%, 28%, 1)',
      grey1000:    'black',
      error:       'hsla(  6,  66%, 47%, 1)',
      primaryButtonColor: 'white',
      primaryButtonBgColor: 'hsla(207, 73%, 57%, 1)',
      secondaryButtonColor: 'white',
      secondaryButtonBgColor: 'hsla(211,  61%, 43%, 1)',
      inputBgColor: 'white',
      bodyBgColor: 'white', // hsla(201,  20%, 96%, 1)
      topBarBgColor: 'hsla(211,  61%, 43%, 1)',
      cardBgColor: 'white',
    }
  },
  dark: {
    space:     [0,   4,  8, 16, 32, 64, 128, 256, 512],
    fontSizes: [12, 14, 16, 20, 24, 32,  48,  64,  96],
    primaryFontSize: '12px',
    primaryLineHeight: '1.5',
    primaryFontFamily: 'Inter',
    primaryFontWeight: 400,
    secondaryFontFamily: 'Inter',
    buttonFontFamily: 'Inter',
    paddings: {
      cardPadding: 0,
      wrapperPadding: [3, 4]
    },
    radius: {
      cardRadius: '0',
      menuRadius: '0',
      modalRadius: '0',
      inputRadius: '0',
    },
    shadows: {
      cardShadow: 'none',
      menuShadow: 'none',
      modalShadow: 'none',
      buttonShadow: 'none',
    },
    borders: {
      cardBorder: 'none',
      menuBorder: '1px solid hsla(0, 0%, 34%, 1)',
      modalBorder: '1px solid hsla(0, 0%, 34%, 1)',
    },
    colors: {
      primary100:  'hsla(215,  41%, 28%, 1)',
      primary200:  'hsla(213,  49%, 34%, 1)',
      primary300:  'hsla(211,  61%, 43%, 1)',
      primary400:  'hsla(209,  62%, 50%, 1)',
      primary:     'hsla(211,  61%, 43%, 1)',
      primary600:  'hsla(205,  79%, 66%, 1)',
      primary700:  'hsla(203,  82%, 76%, 1)',
      primary800:  'hsla(202,  81%, 86%, 1)',
      primary900:  'hsla(201, 100%, 96%, 1)',
      grey0:       'hsla(0,     0%, 10%, 1)',
      grey100:     'hsla(0,     0%, 20%, 1)',//  hsla(215,  20%, 28%, 1)
      grey200:     'hsla(0,     0%, 34%, 1)',
      grey300:     'hsla(0,     0%, 43%, 1)',
      grey400:     'hsla(0,     0%, 50%, 1)',
      grey:        'hsla(0,     0%, 57%, 1)',
      grey600:     'hsla(0,     0%, 66%, 1)',
      grey700:     'hsla(0,     0%, 76%, 1)',
      grey800:     'hsla(0,     0%, 86%, 1)',
      grey900:     'hsla(0,     0%, 96%, 1)',
      grey1000:    'white',
      error:       'hsla(  6,  66%, 47%, 1)',
      primaryButtonColor: 'black',
      primaryButtonBgColor: 'white',
      secondaryButtonColor: 'black',
      secondaryButtonBgColor: 'white',
      inputBgColor: 'hsla(0, 0%, 14%, 1)',
      bodyBgColor: 'hsla(0, 0%, 12%, 1)',
      topBarBgColor: 'hsla(0, 0%, 10%, 1)',
      cardBgColor: 'hsla(0, 0%, 12%, 1)',
    }
  }
}

export default themes
