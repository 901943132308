import React, { memo, useState } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { useMutation, gql } from '@apollo/client'

import Modal from '../../../components/StyledModal'

import { CloseIcon } from '../../../components/Icons'

import Home from './Home'

const Illustrations = ({ illustrationState, illustrationSettings, code, onRequestClose, onCompleted, gallery, images, hideHeader = false, hideAdd = false }) => {

  let defaultValues = {
    titleBackgroundColor: '#476a91',
    titleTextColor: '#ffffff',
    titleBorderColor: '#d1783d',
    zoom: 2,
    format: 'png',
  }

  if (illustrationSettings && illustrationSettings.format) defaultValues.format = illustrationSettings.format
  if (illustrationSettings && illustrationSettings.zoom) defaultValues.zoom = Number(illustrationSettings.zoom)
  if (illustrationSettings && illustrationSettings.titleBackgroundColor) defaultValues.titleBackgroundColor = illustrationSettings.titleBackgroundColor
  if (illustrationSettings && illustrationSettings.titleTextColor) defaultValues.titleTextColor = illustrationSettings.titleTextColor
  if (illustrationSettings && illustrationSettings.titleBorderColor) defaultValues.titleBorderColor = illustrationSettings.titleBorderColor

  const [resultModalOpen, setResultModalOpen] = useState(false)
  const [resultUrl, setResultUrl] = useState(false)

  const [updateExamination] = useMutation(gql`mutation ($code: ID!, $illustrationState: JSON) { updateExamination(code: $code, illustrationState: $illustrationState) { code } }`)
  const [log] = useMutation(gql`mutation ($name: String) { log(name: $name) }`)


  // updateExamination(
  //   code: ID!

  //   reset: Boolean

  //   status: JSON

  const handleAdd = async base64Image => {
    try { log({ variables: { name: 'illustration.add' } }) } catch(err) { console.error(err) }
    onCompleted(base64Image)
  }

  const handleGenerate = async url => {
    setResultModalOpen(true)
    setResultUrl(url)
  }

  const handleChange = async state => {
    console.log('state', state)
    const result = await updateExamination({ variables: { code, illustrationState: state } })
    console.log('result', result)
  }

  const handleNew = () => {
    try { log({ variables: { name: 'illustration.new' } }) } catch(err) { console.error(err) }
  }

  const handlePartial = () => {
    try { log({ variables: { name: 'illustration.partial' } }) } catch(err) { console.error(err) }
  }

  const handlePreview = () => {
    try { log({ variables: { name: 'illustration.generate' } }) } catch(err) { console.error(err) }
  }

  return (
    <>
      <Flex flexDirection='column' height='100%'>
        {!hideHeader && (
          <Flex bg='grey100' minHeight={64} ai='center'>
            <Box w={64} />
            <Box flex={1} ta='center' css='white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'><Text medium>Ilustrações</Text></Box>
            <Box w={64} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
          </Flex>
        )}
        <Box p={4} overflow='auto'>

          <Box minHeight={200}>

            <Home onNew={handleNew} onPartial={handlePartial} onPreview={handlePreview} illustrationState={illustrationState} onChange={handleChange} onGenerate={handleGenerate} onAdd={handleAdd} gallery={gallery} images={images} hideAdd={hideAdd} {...defaultValues} />
            {/* <IllustrationDrop
                baseUrl={baseUrl}
                token={token()}
                finalWidth={finalWidth}
                zoom={zoom}
                quality={quality}
                format={format}
                titleBackgroundColor={titleBackgroundColor}
                titleTextColor={titleTextColor}
                titleBorderColor={titleBorderColor}
                id='ilustracao'
                onGenerate={this.handleGenerate}
                arrows={arrows} /> */}

          </Box>

        </Box>

      </Flex>

      {resultModalOpen &&

        <Modal
          zIndex={1003}
          onCloseCompleted={() => setResultModalOpen(false)}
          backdropStyle={{zIndex: '1002'}}
          render={
            ({onRequestClose}) => (

              <Flex flexDirection='column' height='100%'>
              <Flex bg='grey100' minHeight={64} ai='center'>
                <Box w={64} />
                <Box flex={1} ta='center' css='white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'><Text medium>Prever</Text></Box>
                <Box w={64} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
              </Flex>
              <Box p={4} overflow='auto'>

                <Box minHeight={200} minWidth={800}>
                  {resultUrl &&
                    <div style={{width: '648px', margin: '1rem auto 5rem auto'}}><img src={resultUrl} alt='result' style={{width: `648px`, height: 'auto'}} /></div>
                  }
                </Box>

              </Box>

            </Flex>

          )}
        />

      }

    </>
  )

}

export default Illustrations
