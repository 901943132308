import React from 'react'

import { Field } from 'formik'

import { Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/FieldInput'

import useScrollToError from '@alobato/use-scroll-to-error'
import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

import FormErrorBox from '../../components/FormErrorBox'
import Label from '../../components/Label'

const Form = ({ errors, touched, handleSubmit, isSubmitting, isValid }) => {

  const { t } = useTranslation()

  useFocusOnLoad('name')
  useScrollToError({errors, isValid, isSubmitting})

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>{t('Nome')}<span>*</span></Label>
        <Field width='100%' name='name' component={Input} withError={touched.name && errors.name} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
}

export default Form

