import React, { memo } from 'react'
import { handleLogout } from '../index'
import { Link } from '@reach/router'
import { useApolloClient } from '@apollo/client'
import styled, { css } from 'styled-components/macro'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import PopOver from '../components/PopOver'
import { Menu, MenuItem } from '../components/Menu'
import { ProfileIcon, ExpandMoreIcon, MenuIcon } from '../components/Icons'
import AutoReload from '../components/AutoReload'

// import { ThemeProvider } from 'styled-components'

import { useTranslation } from 'react-i18next'

import useTawkTo from '../hooks/useTawkTo'

// import { useAppContext } from '../AppContext'
import themes from '../themes'
import Speedometer from '../components/Speedometer'
import WrapperLayout from './WrapperLayout'

const ReachNavLink = props => <Link {...props} getProps={({ isPartiallyCurrent }) => { return { className: isPartiallyCurrent ? `active ${props.className}` : props.className } }} />

const ButtonNavLinkWithoutStyles = ({ className, children, to, ...rest }) => (
  <ReachNavLink className={className} to={to} {...rest}>
    <Box>{children}</Box>
  </ReachNavLink>
)

const ButtonNavLink = styled(ButtonNavLinkWithoutStyles)`
  color: hsla(219, 72%, 70%, 1);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  margin: 9px;
  padding: 4px 16px;
  cursor: pointer;
  border: 1px solid hsla(219, 72%, 70%, 1);
  border-radius: 15px;
  text-align: center;
  height: 30px;
  white-space: nowrap;
  &.active, &:hover {
    border: 1px solid white;
    color: white;
  }
`

// https://codesandbox.io/s/issue-948-lbz3b
// let id = 0
// const MessageHub = ({ children }) => {
//   const [items, setItems] = React.useState([])
//   React.useEffect(
//     () => {
//       children(msg => {
//         console.log('msg', msg)
//         // setItems(state => [...state, { key: id++, msg }])
//       })
//     }, [])

//   return (<></>)
// }







const MainLayout = ({ me, children, uri, ...rest }) => {

  // const ref = React.useRef(null)

  const id = rest['*'] && /.+/.test(rest['*']) && ['/etapa1', '/etapa2', '/etapa3'].includes(uri) ? rest['*'] : null
  const idTable = rest['*'] && /.+/.test(rest['*']) && ['/tabela-etapa1', '/tabela-etapa2', '/tabela-etapa3'].includes(uri) ? rest['*'] : null
  const idIllustration = rest['*'] && /.+/.test(rest['*']) && ['/ilustracao-etapa1', '/ilustracao-etapa2'].includes(uri) ? rest['*'] : null

  const { t } = useTranslation()

  // const { state } = useAppContext()

  const client = useApolloClient()

  useTawkTo('60f751b3649e0a0a5ccd2bdf', '1fb31e9b3', me)

  // const theme = themes[state.theme]

  let showPhrases = true
  let showTree = true

  if (!me.role.includes('ADMIN')) {
    showTree = false
    showPhrases = false
  }
  if (me.account && me.account.plan && me.account.plan.useMaster === true ) {
    showTree = false
    showPhrases = false
  }
  if (me.account && me.account.settings && me.account.settings.useMaster === true ) {
    showTree = false
    showPhrases = false
  }

  // const handleToggleLang = () => {
  //   if (i18n.language === 'pt') {
  //     i18n.changeLanguage('en')
  //   } else {
  //     i18n.changeLanguage('pt')
  //   }
  // }

  // const handleToggleDarkMode = () => {
  //   if (state.theme === 'default') {
  //     setItemState({ theme: 'dark' })
  //   } else {
  //     setItemState({ theme: 'default' })
  //   }
  // }

  return (
      <WrapperLayout>
        <AutoReload url='/index.html' delay={30000} />

        {/* <MessageHub children={add => (ref.current = add)} /> */}

        <Flex flexDirection='column' position='fixed' t={0} b={0} r={0} l={0}>

          <Box bg='topBarBgColor' color='white' minHeight={48} overflow='auto'>
            <Flex jc='space-between' ai='center'>

              <Box ml={2} mr={3} css={css`body.mobile & { display: none; }`}>
                <Text white fs={[18, 24]} fw={700}>TelerisOn</Text>
              </Box>

              <Flex ai='center' flex={1}>
                {/* <ButtonNavLink to='/estudos' tabIndex={-1}>{t('Estudos')}</ButtonNavLink>
                <ButtonNavLink to='/solicitacoes' tabIndex={-1}>{t('Solicitações')}</ButtonNavLink> */}
                <ButtonNavLink to='/laudos' tabIndex={-1}>{t('Lista de trabalho')}</ButtonNavLink>
                {id &&
                  <Flex>
                    <ButtonNavLink to={`/etapa1/${id}`} tabIndex={-1}><Text css={css`body.mobile & { display: none; }`}>Etapa 1</Text><Text css={css`body:not(.mobile) & { display: none; }`}>E1</Text></ButtonNavLink>
                    <ButtonNavLink to={`/etapa2/${id}`} tabIndex={-1}><Text css={css`body.mobile & { display: none; }`}>Etapa 2</Text><Text css={css`body:not(.mobile) & { display: none; }`}>E2</Text></ButtonNavLink>
                    <ButtonNavLink to={`/etapa3/${id}`} tabIndex={-1}><Text css={css`body.mobile & { display: none; }`}>Etapa 3</Text><Text css={css`body:not(.mobile) & { display: none; }`}>E3</Text></ButtonNavLink>
                  </Flex>
                }
                {idTable &&
                  <Flex>
                    <ButtonNavLink to={`/tabela-etapa1/${idTable}`} tabIndex={-1}><Text css={css`body.mobile & { display: none; }`}>Etapa 1</Text><Text css={css`body:not(.mobile) & { display: none; }`}>E1</Text></ButtonNavLink>
                    <ButtonNavLink to={`/tabela-etapa2/${idTable}`} tabIndex={-1}><Text css={css`body.mobile & { display: none; }`}>Etapa 2</Text><Text css={css`body:not(.mobile) & { display: none; }`}>E2</Text></ButtonNavLink>
                    <ButtonNavLink to={`/tabela-etapa3/${idTable}`} tabIndex={-1}><Text css={css`body.mobile & { display: none; }`}>Etapa 3</Text><Text css={css`body:not(.mobile) & { display: none; }`}>E3</Text></ButtonNavLink>
                  </Flex>
                }
                {idIllustration &&
                  <Flex>
                    <ButtonNavLink to={`/ilustracao-etapa1/${idIllustration}`} tabIndex={-1}><Text css={css`body.mobile & { display: none; }`}>Etapa 1</Text><Text css={css`body:not(.mobile) & { display: none; }`}>E1</Text></ButtonNavLink>
                    <ButtonNavLink to={`/ilustracao-etapa2/${idIllustration}`} tabIndex={-1}><Text css={css`body.mobile & { display: none; }`}>Etapa 2</Text><Text css={css`body:not(.mobile) & { display: none; }`}>E2</Text></ButtonNavLink>
                  </Flex>
                }
              </Flex>

              <Box>

                <Flex h={48} ai='center'>

                  {/* <Box lh={1} mr={4} onClick={handleToggleLang} cursor='pointer'>{i18n.language === 'pt' ? 'PT' : 'EN'}</Box> */}
                  {/* <Box lh={1} mr={4} onClick={handleToggleDarkMode} cursor='pointer'>Modo {state.theme === 'default' ? 'escuro' : 'claro'}</Box> */}

                  {(me.role.includes('ADMIN')) &&
                    <Box lh={0} mx={3}>

                      <Flex h={48} ai='center'>
                        <PopOver placement='bottom' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                          <Menu>
                            {/*(me.role === 'SUPERADMIN') && <MenuItem onClick={() => navigate('/exames')}>{t('Exames')}</MenuItem>*/}
                            {/*(me.role === 'SUPERADMIN') && <MenuItem onClick={() => navigate('/usuarios')}>{t('Usuários')}</MenuItem>*/}
                            {/*(me.role === 'SUPERADMIN') && <MenuItem onClick={() => navigate('/unidades')}>{t('Unidades')}</MenuItem>*/}
                            {/*(me.role === 'SUPERADMIN') && <MenuItem onClick={() => navigate('/arvore')}>{t('Árvore')}</MenuItem>*/}
                            {/*(me.role === 'SUPERADMIN') && <MenuItem onClick={() => navigate('/frases')}>{t('Frases')}</MenuItem>*/}
                            {/*(me.role === 'SUPERADMIN') && <MenuItem onClick={() => navigate('/configuracoes/laudo')}>{t('Config. Layout Laudo')}</MenuItem>*/}
                            {/*(me.role === 'SUPERADMIN') && <MenuItem onClick={() => navigate('/configuracoes/conteudo')}>{t('Config. Conteúdo Laudo')}</MenuItem>*/}
                            {/* <MenuItem onClick={() => navigate('/estudos')}>{t('Estudos')}</MenuItem> */}
                            {/* <MenuItem onClick={() => navigate('/solicitacoes')}>{t('Solicitações')}</MenuItem> */}
                            {showTree && <MenuItem to='/arvore'>{t('Árvore')}</MenuItem>}
                            {showPhrases && <MenuItem to='/frases'>{t('Frases')}</MenuItem>}
                            <MenuItem to='/clinicas'>{t('Clínicas')}</MenuItem>
                            {me.role === 'ADMIN' && me.license?.includes('A') && <MenuItem to='/users'>{t('Usuários')}</MenuItem>}
                            {me.role.includes('SUPERADMIN') && <MenuItem to='/planos'>{t('Planos')}</MenuItem>}
                            {me.role.includes('SUPERADMIN') && <MenuItem to='/contas'>{t('Contas')}</MenuItem>}
                            {me.role.includes('SUPERADMIN') && <MenuItem to='/tablemaker'>{t('TableMaker')}</MenuItem>}
                            {me.role.includes('SUPERADMIN') && <MenuItem to='/galerias'>{t('Galerias')}</MenuItem>}
                            {/* <MenuItem to='/configuracoes/layout'>{t('Configurar layout')}</MenuItem>
                            <MenuItem to='/configuracoes/conteudo'>{t('Configurar conteúdo')}</MenuItem> */}
                          </Menu>
                        )}>
                          <Flex ai='center' cursor='pointer'>
                            <MenuIcon />
                          </Flex>
                        </PopOver>

                      </Flex>

                    </Box>
                  }

                  <Box lh={0} mx={3}>

                    <Flex h={48} ai='center'>
                      {me.email !== me.account.name ? (
                        <Box mr={2}>
                          <Box css={css`line-height: 1; font-size: 12px; font-weight: 700; text-transform: uppercase; padding-bottom: 3px;`}>{me.account.name}</Box>
                          <Box css={css`font-size: 13px; line-height: 1;}`}>{me.name}</Box>
                        </Box>
                      ) : (
                        <Box mr={2}>
                          <Box>{me.name}</Box>
                        </Box>
                      )}
                      <PopOver placement='bottom' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                        <Menu>
                          <MenuItem to='/minha-conta'>{t('Minha conta')}</MenuItem>
                          {me.role === 'ADMIN' && me.license?.includes('I-') && me.account?.name !== 'BMK' && <MenuItem to='/my-subscription'>{t('Minha assinatura')}</MenuItem>}
                          {me.role === 'ADMIN' && me.license?.includes('A') && <MenuItem to='/account'>{t('Conta')}</MenuItem>}
                          <MenuItem onClick={() => handleLogout(client)}>{t('Sair')}</MenuItem>
                        </Menu>
                      )}>
                        <Flex ai='center' cursor='pointer'>
                          {me.avatar ? (
                            <Box css={css`position: relative; box-sizing: content-box; width: 32px; height: 32px; border-radius: 50%; overflow: hidden;`}>
                              <img src={me.avatar} alt='' draggable={false} css={css`width: 100%; height: 100%;`} />
                            </Box>
                          ) : (
                            <Box><ProfileIcon height={32} /></Box>
                          )}
                          <Box><ExpandMoreIcon /></Box>
                        </Flex>
                      </PopOver>

                    </Flex>

                  </Box>

                  <Box><Speedometer /></Box>

                  {/* <Box lh={1} mx={3} opacity={0.5} onClick={() => ref.current('teste')}>Versão: {process.env.REACT_APP_VERSION}</Box> */}
                  <Box lh={1} mx={3} opacity={0.5} css={css`font-size: 12px;`}>v{process.env.REACT_APP_VERSION}</Box>

                </Flex>

              </Box>

            </Flex>

          </Box>

          <Box w='100%' minHeight='calc(100vh - 48px)' overflow='auto'>
            {children}
          </Box>

        </Flex>
      </WrapperLayout>
  )
}

export default memo(MainLayout)
