import React from 'react'
import { Box } from '@alobato/flex-box'
import Button from '../../components/Button'

import Label from '../../components/Label'
import Select from '../../components/Select'
import FormErrorBox from '../../components/FormErrorBox'

import { useTranslation } from 'react-i18next'

const FormLicense = ({ licenses, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {

  const { t } = useTranslation()

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

        <Box>
          <Box mb={3}>
            <Label>{t('Licenças disponíveis')}<span>*</span></Label>
            <Select placeholder={t('Selecione...')} name='license' value={values.license} onChange={handleChange} onBlur={handleBlur} withError={touched.license && errors.license}>
              {licenses.map(item => (
                <option key={item.code} value={item.code}>{item.code.toUpperCase()} - {item.plan.name}</option>
              ))}
            </Select>
            <FormErrorBox fieldName='license' errors={errors} touched={touched} />
          </Box>
        </Box>

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Associar')}</Button>
      </Box>

    </form>
  )
}

export default FormLicense

