import React from 'react'
import { Router } from '@reach/router'

import ResultLayout from '../../layouts/ResultLayout'
import Home from './Home'

 const Index = ({ me, notifier }) => {
  return (
    <Router>
      <ResultLayout path='/'>
        <Home notifier={notifier} path='/:code' />
      </ResultLayout>
    </Router>
  )
}

export default Index
