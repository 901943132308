import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from './FlexBox'

function BigCheckbox({ children, checked, className, ...rest }) {
  return (
    <Flex alignItems='center' p={3} className={`${className}${checked ? ' active' : ''}`} {...rest}>
      <Box mr={2}>
        <Flex justifyContent='center' alignItems='center'>
          <Box />
        </Flex>
      </Box>
      <Box>{children}</Box>
    </Flex>
  )
}

const StyledBigCheckbox = styled(BigCheckbox)`
  background-color: white;
  border: 1px solid hsl(210deg 14% 92%);
  border-radius: 8px;
  min-width: 200px;
  color: hsl(0deg 0% 11%);
  &:hover:not(.active) {
    cursor: pointer;
    border-color: hsl(210deg 14% 82%);
  }
  &.active {
    background-color: hsl(220deg 14% 96%);
  }
  & > div > div > div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  &.active > div > div > div {
    background-color: hsl(0deg 0% 11%);;
  }
  & > div > div {
    width: 16px;
    height: 16px;
    border: 1px solid hsl(210deg 14% 82%);
    border-radius: 8px;
  }
  &:hover:not(.active) > div > div {
    border: 1px solid hsl(210deg 14% 72%);
  }
`

export default StyledBigCheckbox
