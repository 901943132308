import React from 'react'
import { css } from 'styled-components/macro'
import { Box } from '@alobato/flex-box'

function Terms() {
  return (
    <Box py={4} px={[3, 5, 7]}>

      <h1>TERMOS E CONDIÇÕES DE USO E PRIVACIDADE - TELERISON</h1>

<p><span css={css`font-weight: 400;`}>Este instrumento cont&eacute;m os termos gerais e condi&ccedil;&otilde;es de uso do s&iacute;tio eletr&ocirc;nico: </span><a href="http://www.telerison.com"><span css={css`font-weight: 400;`}>www.telerison.com</span></a><span css={css`font-weight: 400;`}> , bem como dos servi&ccedil;os oferecidos por meio do website. Nestes termos tamb&eacute;m incluem alguns esclarecimentos sobre a pol&iacute;tica de privacidade, indica&ccedil;&atilde;o dos direitos e deveres legais que passar&atilde;o a vigorar ao usar os servi&ccedil;os do TelerisOn.</span></p>
<p><span css={css`font-weight: 400;`}>Ler atentamente e entender estes termos antes de utilizar os servi&ccedil;os e o website &eacute; importante, porque ao usar os servi&ccedil;os e website voc&ecirc; est&aacute; concordando com eles.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong>INTRODU&Ccedil;&Atilde;O</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>1.1 O TelerisOn &eacute; um sistema web de laudo ilustrado e estruturado, customiz&aacute;vel, capaz de gerar relat&oacute;rios diferenciados, com alta produtividade.&nbsp;</span></p>
<p><span css={css`font-weight: 400;`}>Ao se inscrever e utilizar os recursos, funcionalidades, websites, interfaces de usu&aacute;rio, aplicativos de conte&uacute;do e softwares associados, ser&aacute; celebrando um contrato com a pessoa jur&iacute;dica TelerisOn, por meio do qual adquirir&aacute; a licen&ccedil;a para uso dos servi&ccedil;os TelerisOn.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>1.2 Principais funcionalidades do TelerisOn:</span></p>
<p>&nbsp;</p>
<ul>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Reconhecimento de voz: tecnologias de reconhecimento da fala funciona com microfones comuns.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>OCR (Reconhecimento &Oacute;ptico de Caracteres): ferramenta para transi&ccedil;&atilde;o de tabelas ou textos contidas em imagens e outros documentos.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Pr&eacute;-laudo: os dados cadastrais do paciente, dados biom&eacute;tricos ou da anamnese podem ser utilizados como filtros ou mesmo como conte&uacute;do do laudo.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Diferentes formas de construir o modelo de laudo: sistema completo com v&aacute;rios modelos de laudo.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Laudo inteligente: design simples e objetivo, possibilitando a realiza&ccedil;&atilde;o de exames de forma muito r&aacute;pida.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Compatibilidade com dispositivos m&oacute;veis: projetados para se adaptarem a qualquer tipo de resolu&ccedil;&atilde;o automaticamente.</span></li>
</ul>
<p>&nbsp;</p>
<ul>
<li><strong>&nbsp;CONTRATO COM O TELERISON</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>2.1 Seu contrato com a TelerisOn inclui estes e quaisquer termos adicionais com os quais voc&ecirc; concordar, inclusive a utiliza&ccedil;&atilde;o, de </span><span css={css`font-weight: 400;`}>softwares e programas desenvolvidos por terceiros</span><span css={css`font-weight: 400;`}> para proporcionar o regular funcionamento do TelerisOn.</span></p>
<p><span css={css`font-weight: 400;`}>Conv&eacute;m esclarecer que o TelerisOn utiliza tecnologias, m&oacute;dulos e servi&ccedil;os disponibilizados por terceiros para o seu pleno funcionamento. Na hip&oacute;tese de suspens&atilde;o destes servi&ccedil;os ou de mudan&ccedil;as nas condi&ccedil;&otilde;es de uso, que de algum modo inviabilizem a continuidade da utiliza&ccedil;&atilde;o, o TelerisOn informar&aacute; aos seus clientes quais as repercuss&otilde;es em suas ferramentas, ofertando a possibilidade de substitui&ccedil;&atilde;o da ferramenta desenvolvida por terceiros.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>2.2 &Eacute; importante refor&ccedil;ar que al&eacute;m das raz&otilde;es legais ou regulamentares, pode-se, ocasionalmente, fazer altera&ccedil;&otilde;es em nossos termos, condi&ccedil;&otilde;es de uso e pol&iacute;tica de privacidade por raz&otilde;es v&aacute;lidas, tais como: melhorar as fun&ccedil;&otilde;es ou recursos existentes ou acrescentar novas fun&ccedil;&otilde;es ou recursos ao servi&ccedil;o, implementar avan&ccedil;os cient&iacute;ficos e tecnol&oacute;gicos, al&eacute;m dos ajustes t&eacute;cnicos razo&aacute;veis, com as limita&ccedil;&otilde;es de responsabilidade e privacidade, visando assegurar a operacionalidade ou a seguran&ccedil;a do servi&ccedil;o.</span></p>
<p><span css={css`font-weight: 400;`}>O TelerisOn poder&aacute; atualizar ou alterar este Termo a qualquer tempo. Ap&oacute;s a publica&ccedil;&atilde;o da altera&ccedil;&atilde;o destes Termos de Uso no Website, o Seu uso continuado dos Servi&ccedil;os ou dos Website constitui Sua expressa concord&acirc;ncia para com os Termos de Uso, conforme alterados.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>2.3 Por fim, o TelerisOn reserva-se ao direito de se utilizar de quaisquer publicidades e propagandas que entender cab&iacute;veis, tanto no que se refere &agrave; divulga&ccedil;&atilde;o de material de terceiros em seu espa&ccedil;o, quanto no que tange &agrave; propaga&ccedil;&atilde;o de sua imagem e servi&ccedil;os.</span></p>
<p><br /><br /></p>
<p>&nbsp;</p>
<ul>
<li><strong>CONDI&Ccedil;&Otilde;ES DE ACESSO&nbsp;</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>3.1 Para acessar a ferramenta corretamente, &eacute; preciso que o usu&aacute;rio utilize o navegador Google Chrome, sempre em sua vers&atilde;o mais atualizada, garantindo o funcionamento correto do servi&ccedil;o; internet banda larga de alta velocidade e computador com sistema operacional Windows, MacOS ou Linux.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>3.2 O computador dever&aacute; estar em pleno acordo com os requisitos m&iacute;nimos funcionais e de seguran&ccedil;a, necess&aacute;rios &agrave; frui&ccedil;&atilde;o dos servi&ccedil;os oferecidos pelo TelerisOn, o qual est&aacute; isento de qualquer responsabilidade decorrente de inobserv&acirc;ncia do disposto neste Termo</span><span css={css`font-weight: 400;`}>.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>3.3 &Eacute; necess&aacute;rio realizar o pr&eacute;vio cadastro requerido no site, fornecendo os dados solicitados, com precis&atilde;o e veracidade. &Eacute; dever do usu&aacute;rio mant&ecirc;-lo sempre atualizado, sendo vedada a inser&ccedil;&atilde;o de informa&ccedil;&otilde;es falsas e/ou de terceiros (voc&ecirc; pode ser penalizado por isso).</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>3.4 O acesso tamb&eacute;m poder&aacute; ser realizado atrav&eacute;s da vers&atilde;o mobile para os sistemas Android e IOS.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>3.5 A aus&ecirc;ncia de pagamento nas datas previstas acarretar&aacute; a imediata suspens&atilde;o do acesso ao servi&ccedil;o, que ser&aacute; mantida at&eacute; que a pend&ecirc;ncia financeira seja regularizada, sendo respeitado o prazo m&iacute;nimo de armazenamento das informa&ccedil;&otilde;es, conforme determinado pela lei espec&iacute;fica.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong>UTILIZA&Ccedil;&Atilde;O DO TELERISON</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>4.1 O TelerisOn &eacute; uma plataforma paga, mas tamb&eacute;m conta com </span><span css={css`font-weight: 400;`}>per&iacute;odo de teste gratuito por 30 dias</span><span css={css`font-weight: 400;`}>, para que os usu&aacute;rios tenham a oportunidade de conhecer inteiramente todas as funcionalidades oferecidas pelo programa. Ao final do prazo de teste informaremos com uma anteced&ecirc;ncia de </span><span css={css`font-weight: 400;`}>10 (dez) dias</span><span css={css`font-weight: 400;`}> quando este servi&ccedil;o passar&aacute; a ser cobrado. Nesta oportunidade, voc&ecirc; precisar&aacute; fornecer seus dados de pagamento para iniciar o uso. E ao fornecer estes detalhes, voc&ecirc; concorda que poderemos come&ccedil;ar a cobr&aacute;-lo(a) automaticamente por uma &ldquo;Assinatura Paga&rdquo; no primeiro dia seguinte ao fim da vers&atilde;o gratuita, em uma base mensal recorrente. </span><span css={css`font-weight: 400;`}>Se voc&ecirc; n&atilde;o desejar a cobran&ccedil;a recorrente, dever&aacute; cancelar a assinatura antes que seja debitada a pr&oacute;xima mensalidade.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>4.2 Em nosso site voc&ecirc; pode encontrar a descri&ccedil;&atilde;o dos nossos planos, assinaturas ou servi&ccedil;os promocionais especiais, inclusive ofertas de servi&ccedil;os e produtos de terceiros em conjunto com o TelerisOn ou atrav&eacute;s do servi&ccedil;o TelerisOn. Mas aten&ccedil;&atilde;o: n&atilde;o nos responsabilizamos pelos produtos e servi&ccedil;os prestados por terceiros.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>4.3 Os servi&ccedil;os ser&atilde;o prestados de acordo com o pacote escolhido e a ele estar&atilde;o limitados. Todos os benef&iacute;cios est&atilde;o especificados no site. Por isso, leia atentamente todas as op&ccedil;&otilde;es e escolha aquela que melhor se adequar &agrave;s suas necessidades.</span></p>
<p><span css={css`font-weight: 400;`}>Observa&ccedil;&atilde;o:</span><span css={css`font-weight: 400;`}> tendo em vista que o TelerisOn utiliza tecnologias, m&oacute;dulos e servi&ccedil;os disponibilizados por terceiros para o seu pleno funcionamento, na hip&oacute;tese de suspens&atilde;o destes servi&ccedil;os ou de mudan&ccedil;as nas condi&ccedil;&otilde;es de uso, que de algum modo inviabilizem a continuidade da utiliza&ccedil;&atilde;o, nos reservamos ao direito de modificar, encerrar ou alterar nossos planos de assinatura e ofertas promocionais a qualquer momento.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong>ARREPENDIMENTO E CANCELAMENTO</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>5.1 Se seu cadastro do per&iacute;odo de teste do TelerisOn estiver terminando, voc&ecirc; ter&aacute; um per&iacute;odo de 15 (quinze) dias ap&oacute;s para solicitar a assinatura e efetuar o pagamento para continuar utilizando os nossos servi&ccedil;os e manter seu cadastro ativo. Ap&oacute;s este per&iacute;odo seu acesso ser&aacute; suspenso.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>5.2 Ao registrar uma assinatura paga, voc&ecirc; poder&aacute;, no prazo de 07 (sete) dias, mudar de ideia por qualquer motivo e receber um reembolso total, que ser&aacute; efetuado no prazo de 10 (dez) dias, a contar da data em que voc&ecirc; solicitou o cancelamento/reembolso.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>5.3 Se voc&ecirc; n&atilde;o cancelar a assinatura antes do per&iacute;odo de 07 (sete) dias, perder&aacute; o direito de desist&ecirc;ncia e autorizar&aacute; o TelerisOn a cobr&aacute;-lo mensalmente o valor ajustado, at&eacute; que haja um cancelamento efetivo.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>5.4 No caso de cancelamento ou de arrependimento, seu acesso ao TelerisOn ser&aacute; suspenso, podendo voltar a funcionar caso realize uma das op&ccedil;&otilde;es de assinatura paga. Caso voc&ecirc; solicite justo reembolso, ele ser&aacute; efetivado atrav&eacute;s do m&eacute;todo que voc&ecirc; utilizou para realizar o pagamento.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong>PAGAMENTO</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>6.1 O pagamento da assinatura, que ocorrer&aacute; de maneira recorrente, ser&aacute; realizado atrav&eacute;s de um Gateway de Pagamento. Todos os dados relativos ao pagamento ser&atilde;o armazenados pela empresa terceirizada respons&aacute;vel, a qual possui grande atua&ccedil;&atilde;o no mercado nacional, al&eacute;m de estrutura e seguran&ccedil;a pr&oacute;prias.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>6.2 O TelerisOn n&atilde;o ter&aacute; acesso e nem armazenar&aacute; qualquer informa&ccedil;&atilde;o relativa aos dados de pagamento dos seus clientes, mas apenas e t&atilde;o somente a empresa respectiva, a qual &eacute; respons&aacute;vel de maneira &uacute;nica e exclusiva pela coleta, armazenamento, utiliza&ccedil;&atilde;o e seguran&ccedil;a destes dados.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>6.3 &Eacute; poss&iacute;vel que, de tempos em tempos, o pre&ccedil;o das assinaturas pagas seja alterado. Ser&aacute; comunicado qualquer mudan&ccedil;a para voc&ecirc; de maneira antecipada. As referidas altera&ccedil;&otilde;es surtir&atilde;o efeito no in&iacute;cio do pr&oacute;ximo per&iacute;odo de assinatura, ap&oacute;s a data da comunica&ccedil;&atilde;o. E ao continuar utilizando o servi&ccedil;o, voc&ecirc; estar&aacute; automaticamente aceitando o novo pre&ccedil;o.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>6.4 Caso n&atilde;o concorde com eventual mudan&ccedil;a que poder&aacute; ser realizada, voc&ecirc; ter&aacute; o direito de rejeit&aacute;-la por meio do cancelamento da assinatura paga antes que a altera&ccedil;&atilde;o de pre&ccedil;o entre em efeito.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong>&nbsp;DURA&Ccedil;&Atilde;O DO CONTRATO</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>7.1 A contrata&ccedil;&atilde;o dos servi&ccedil;os oferecidos pelo TelerisOn possui prazo selecionados na contrata&ccedil;&atilde;o da assinatura, entrando em vigor na data do aceite eletr&ocirc;nico e podendo ser renovado de acordo com o prazo selecionado pelo plano oferecido.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>7.2 O cancelamento da assinatura pode ser solicitado a qualquer tempo e o plano ficar&aacute; ativo at&eacute; o fim da sua compet&ecirc;ncia paga.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>7.3 Al&eacute;m desta hip&oacute;tese, sem preju&iacute;zo das san&ccedil;&otilde;es c&iacute;veis e penais eventualmente cab&iacute;veis, este contrato tamb&eacute;m poder&aacute; ser rescindido, desta feita de maneira unilateral e sem pr&eacute;vio aviso, nas seguintes hip&oacute;teses:</span></p>
<ul>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Quebra de quaisquer disposi&ccedil;&otilde;es e diretrizes constantes nos presentes Termos de Uso e na Pol&iacute;tica de Privacidade e Seguran&ccedil;a;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Comprometimento da imagem p&uacute;blica do TelerisOn e/ou de qualquer empresa a ela associada;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Utiliza&ccedil;&atilde;o de pr&aacute;ticas que desrespeitem a lei, a ordem e a sa&uacute;de p&uacute;blicas, a moral e os bons costumes.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Em quaisquer das hip&oacute;teses, contudo, ser&atilde;o mantidas suas informa&ccedil;&otilde;es do Usu&aacute;rio no banco de dados da empresa pelo prazo m&iacute;nimo determinado na legisla&ccedil;&atilde;o espec&iacute;fica.</span></li>
</ul>
<p><br /><br /><br /></p>
<p>&nbsp;</p>
<ul>
<li><strong>RESPONSABILIDADES DO USU&Aacute;RIO</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>8.1 O TelerisOn &eacute; um programa oferecido para profissionais regularmente inscritos nos respectivos conselhos, sendo inicialmente desenhado para a &aacute;rea de sa&uacute;de e principalmente para medicina, de modo que se faz necess&aacute;rio que o usu&aacute;rio possua capacidade legal para sua utiliza&ccedil;&atilde;o.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>8.2 Ao concordar com os presentes Termos de Uso, o usu&aacute;rio declara possuir as autoriza&ccedil;&otilde;es necess&aacute;rias para a inser&ccedil;&atilde;o de dados no sistema, sendo o &uacute;nico respons&aacute;vel pela sua utiliza&ccedil;&atilde;o, revis&atilde;o e expedi&ccedil;&atilde;o de textos, assim como declara observar todas as leis e demais normas relativas ao exerc&iacute;cio de sua profiss&atilde;o, &agrave; seguran&ccedil;a, privacidade e direitos de terceiros.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>8.3 O cadastro realizado no site, independentemente da modalidade de assinatura escolhida, &eacute; pessoal e intransfer&iacute;vel, sendo vedado seu compartilhamento. Caso o usu&aacute;rio compartilhe seu cadastro com terceiros, poder&aacute; ser punido, tendo seu acesso desativado de maneira imediata, sem preju&iacute;zo das san&ccedil;&otilde;es c&iacute;veis e penais cab&iacute;veis.</span></p>
<p><span css={css`font-weight: 400;`}>Deste modo, sem preju&iacute;zo das responsabilidades j&aacute; explicitadas neste Termo, &eacute; importante estar atento:</span></p>
<ul>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>O usu&aacute;rio &eacute; respons&aacute;vel pela utiliza&ccedil;&atilde;o, sigilo e ci&ecirc;ncia pessoal de sua senha, a qual dever&aacute; ser segura &ndash; d&ecirc; prefer&ecirc;ncia a uma senha que contenha, cumulativamente, caracteres mai&uacute;sculos e min&uacute;sculos, n&uacute;meros e caracteres especiais (!, @, # etc). As senhas cadastradas no sistema s&atilde;o criptografadas, pelo que a plataforma n&atilde;o possui acesso &agrave;s mesmas.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>A elabora&ccedil;&atilde;o e revis&atilde;o dos laudos s&atilde;o de total encargo do usu&aacute;rio, n&atilde;o se responsabilizando o TelerisOn pelos seus conte&uacute;dos. Assim, &eacute; dever do cliente fornecer informa&ccedil;&otilde;es ver&iacute;dicas e responsabilizar-se pela atualiza&ccedil;&atilde;o delas.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>&Eacute; absolutamente vedada a disponibiliza&ccedil;&atilde;o de qualquer conte&uacute;do il&iacute;cito, inclusive atrav&eacute;s de hyperlinks, ou que atente &agrave; moral e aos bons costumes, especialmente quando envolvendo terceiros.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>&Eacute; igualmente proibida a disponibiliza&ccedil;&atilde;o, distribui&ccedil;&atilde;o, venda ou comercializa&ccedil;&atilde;o do conte&uacute;do armazenado no TelerisOn, o qual est&aacute; estritamente restrito aos seus usu&aacute;rios e membros da Comunidade TelerisOn.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Este programa n&atilde;o se responsabiliza pelo backup das informa&ccedil;&otilde;es de m&aacute;scaras, de autotextos e quaisquer outros dados inseridos pelo usu&aacute;rio, sendo fundamental que o usu&aacute;rio tenha seu pr&oacute;prio armazenamento externo de backup.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>A viola&ccedil;&atilde;o a qualquer dos Termos de Uso ou &agrave; Pol&iacute;tica de Privacidade importar&aacute;, necessariamente, na suspens&atilde;o do acesso do usu&aacute;rio ao TelerisOn, sendo mantidas suas informa&ccedil;&otilde;es pelo prazo m&iacute;nimo determinado na legisla&ccedil;&atilde;o espec&iacute;fica, sem preju&iacute;zo das san&ccedil;&otilde;es civis e penais aplic&aacute;veis ao caso.</span></li>
</ul>
<p><span css={css`font-weight: 400;`}>Em todas as hip&oacute;teses acima exemplificadas, o usu&aacute;rio ser&aacute; o &uacute;nico respons&aacute;vel pelo uso do TelerisOn, incluindo-se aqui as informa&ccedil;&otilde;es por ele inseridas. Ao encontrar qualquer viola&ccedil;&atilde;o, seja ao ordenamento jur&iacute;dico, seja ao presente Termo, dever&aacute; imediatamente entrar em contato com o suporte, atrav&eacute;s do e-mail disponibilizado.</span></p>
<p><br /><br /></p>
<p>&nbsp;</p>
<ul>
<li><strong>SUPORTE T&Eacute;CNICO E CONTATO COM A CENTRAL</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>9.1 Havendo qualquer problema com a plataforma ou sendo necess&aacute;rio o suporte ou reparo de qualquer funcionalidade, o usu&aacute;rio dever&aacute; solicitar o atendimento, que ocorrer&aacute; exclusivamente por meio virtual, atrav&eacute;s do e-mail ou da </span><span css={css`font-weight: 400;`}>abertura de chamado, a qual poder&aacute; ser realizada no pr&oacute;prio sistema.</span></p>
<p><span css={css`font-weight: 400;`}>O prazo m&aacute;ximo para resposta do sistema &eacute; de 05 (cinco) dias &uacute;teis e o endere&ccedil;o exclusivo para contato &eacute;:&nbsp;</span><a href="mailto:contato@telerison.com"><span css={css`font-weight: 400;`}>contato@telerison.com</span></a></p>
<p><br /><br /><br /><br /></p>
<p>&nbsp;</p>
<ul>
<li><strong>DIREITOS DE PROPRIEDADE INTELECTUAL</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>10.1 Conforme disposi&ccedil;&otilde;es constantes nas Leis n&ordm; 9.279/1996, 9.609/1998 e 9.610/1998, o usu&aacute;rio est&aacute; ciente de que os direitos concernentes &agrave; integralidade das ferramentas pertencem &uacute;nica e exclusivamente &agrave; TelerisOn.</span></p>
<p><span css={css`font-weight: 400;`}>Sendo assim, o usu&aacute;rio se declara ciente de que o material disponibilizado na plataforma est&aacute; protegido por direitos de propriedade intelectual, que independem de registro, conforme legisla&ccedil;&atilde;o acima mencionada.</span></p>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>10.2 Ao TelerisOn est&aacute; reservado o direito de modificar, a qualquer tempo e sem pr&eacute;vio aviso, quaisquer aspectos de sua plataforma, incluindo-se aqui os pr&oacute;prios Termos de Uso.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong>&nbsp;HIP&Oacute;TESES DE EXCLUS&Atilde;O DE RESPONSABILIDADE</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>11.1 O TelerisOn n&atilde;o ser&aacute; responsabilizado nas seguintes hip&oacute;teses:</span></p>
<ul>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Aus&ecirc;ncia de armazenamento de conte&uacute;do do &aacute;udio ditado e dos c&aacute;lculos realizados;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Erros, interrup&ccedil;&otilde;es, mau-funcionamento, atrasos, lentid&atilde;o, ou outras imperfei&ccedil;&otilde;es que n&atilde;o estejam vinculadas ao funcionamento da plataforma;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Casos fortuito e de for&ccedil;a maior;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Mau uso do programa;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Danos e preju&iacute;zos causados pelos usu&aacute;rios a terceiros;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Danos e preju&iacute;zos causados pelos usu&aacute;rios entre si;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Danos e preju&iacute;zos de toda natureza, sejam eles decorrentes da transmiss&atilde;o, difus&atilde;o, disponibiliza&ccedil;&atilde;o, recep&ccedil;&atilde;o, obten&ccedil;&atilde;o ou acesso &agrave;s informa&ccedil;&otilde;es.</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Nas hip&oacute;teses acima elencadas, o usu&aacute;rio ser&aacute; responsabilizado individualmente, nos termos da legisla&ccedil;&atilde;o vigente, resguardado &agrave; TelerisOn o direito de regresso em qualquer dos casos.</span></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>11.2 Ambas as Partes expressamente reconhecem que o &uacute;nico v&iacute;nculo jur&iacute;dico entre elas resulta do presente Contrato ou de contratos formalmente assinados entre elas. Nenhuma disposi&ccedil;&atilde;o no presente instrumento ser&aacute; interpretada de modo a colocar as Partes como s&oacute;cias, associadas, consorciadas, comodat&aacute;rias ou para com responsabilidade solid&aacute;ria ou subsidi&aacute;ria, de qualquer esp&eacute;cie, incluindo, mas n&atilde;o se limitando, a responsabilidade civil, administrativa, trabalhista e fiscal-tribut&aacute;ria.</span></p>
<p><br /><br /></p>
<p>&nbsp;</p>
<ul>
<li><strong>&nbsp;FORO</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>12.1 Os presentes termos, em sua integralidade, s&atilde;o regidos, interpretados e executados em total conson&acirc;ncia com as leis vigentes na Rep&uacute;blica Federativa do Brasil, sendo competente </span><span css={css`font-weight: 400;`}>o Foro de:</span><span css={css`font-weight: 400;`}> para dirimir qualquer d&uacute;vida ou eventual problema decorrentes deste instrumento.</span></p>
<p><span css={css`font-weight: 400;`}>12.2 O Usu&aacute;rio consente, expressamente, com a compet&ecirc;ncia desse Ju&iacute;zo, e renuncia, na oportunidade, &agrave; compet&ecirc;ncia de qualquer outro foro, por mais privilegiado que seja ou venha a ser.</span></p>
<p><strong>TERMOS DE PRIVACIDADE</strong></p>
<p>&nbsp;</p>
<ul>
<li><strong>Informa&ccedil;&otilde;es coletadas e armazenadas:</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>O TelerisOn coleta as informa&ccedil;&otilde;es fornecidas em voz e texto pelos seus usu&aacute;rios durante a utiliza&ccedil;&atilde;o da plataforma. &Eacute; garantido o sigilo e armazenamento seguro dos seus dados pessoais de identifica&ccedil;&atilde;o e autoria.</span></p>
<p><span css={css`font-weight: 400;`}>A coleta de informa&ccedil;&otilde;es - como por exemplo: dados cadastrais de acesso &agrave; conta, data e hora do acesso realizado, quais as funcionalidades mais acessadas, cliques de navega&ccedil;&atilde;o na plataforma ser&aacute; de conhecimento apenas do TelerisOn, visando identificar as prefer&ecirc;ncias dos nossos usu&aacute;rios e para fornecer recomenda&ccedil;&otilde;es, conte&uacute;do e resultados de pesquisa personalizados.&nbsp;</span></p>
<p><span css={css`font-weight: 400;`}>Usamos as informa&ccedil;&otilde;es para garantir que nossos servi&ccedil;os funcionem como o esperado, inclusive para rastrear interrup&ccedil;&otilde;es, solucionar problemas informados e avaliar o desempenho do programa.&nbsp;</span></p>
<p><span css={css`font-weight: 400;`}>As informa&ccedil;&otilde;es coletadas ser&atilde;o utilizadas apenas para o regular funcionamento da plataforma, e somente enquanto durar a necessidade que justificou a sua coleta e tratamento, respeitados os prazos da previstos pela legisla&ccedil;&atilde;o brasileira.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong>Compartilhamento das informa&ccedil;&otilde;es coletadas e armazenadas:</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>O TelerisOn poder&aacute; compartilhar os dados coletados e armazenados de seus usu&aacute;rios, com empresas parceiras, afiliadas ou pessoas confi&aacute;veis, em conformidade com a Pol&iacute;tica de Privacidade e medidas de seguran&ccedil;a e de confidencialidade, notadamente para:</span></p>
<ul>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Garantir o regular funcionamento do programa;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>A viabiliza&ccedil;&atilde;o dos neg&oacute;cios e/ou servi&ccedil;os oferecidos pelo programa;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Para prote&ccedil;&atilde;o dos interesses do TelerisOn em qualquer tipo de conflito, incluindo a&ccedil;&otilde;es judiciais, mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham compet&ecirc;ncia legal para requisi&ccedil;&atilde;o;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Quando existir a necessidade de identificar ou revelar dados de usu&aacute;rio que esteja utilizando a plataforma com prop&oacute;sitos il&iacute;citos;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>No caso de transa&ccedil;&otilde;es e altera&ccedil;&otilde;es societ&aacute;rias envolvendo o TelerisOn.</span></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>Uma vez que o TelerisOn se prop&otilde;e a facilitar o trabalho e melhorar o desempenho dos profissionais que utilizam a plataforma, o mesmo se reserva o direito de armazenar as m&aacute;scaras, autotextos, corre&ccedil;&otilde;es e formul&aacute;rios de seus usu&aacute;rios individuais, sempre com o intuito final de promover uma melhor experi&ecirc;ncia, velocidade e qualidade de todos os usu&aacute;rios.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong>Seguran&ccedil;a no armazenamento de informa&ccedil;&otilde;es coletadas:</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>O TelerisOn foi desenvolvido com recursos de seguran&ccedil;a s&oacute;lidos que protegem continuamente suas informa&ccedil;&otilde;es. Por isso, utiliza-se os m&eacute;todos padr&otilde;es de mercado, nacional e internacional, para resguardar a seguran&ccedil;a dos dados coletados, e possu&iacute; mecanismos de prote&ccedil;&atilde;o contra acesso n&atilde;o autorizado aos sistemas, o que inclui:</span></p>
<ul>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>Utiliza&ccedil;&atilde;o de criptografia para manter os seus dados privados enquanto est&atilde;o em tr&acirc;nsito;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>A oferta de uma variedade de recursos de seguran&ccedil;a, como a navega&ccedil;&atilde;o segura e verifica&ccedil;&atilde;o de seguran&ccedil;a para ajudar voc&ecirc; a proteger sua conta;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>A an&aacute;lise de coleta de dados, pr&aacute;ticas de armazenamento e processamento de informa&ccedil;&otilde;es, incluindo medidas de seguran&ccedil;a f&iacute;sica para evitar acesso n&atilde;o autorizado aos sistemas;</span></li>
<li css={css`font-weight: 400;`}><span css={css`font-weight: 400;`}>A restri&ccedil;&atilde;o ao acesso a informa&ccedil;&otilde;es pessoais por parte de empresas, afiliadas ou pessoas autorizadas que necessitam dessas informa&ccedil;&otilde;es para process&aacute;-las.</span></li>
</ul>
<p><span css={css`font-weight: 400;`}>O compartilhamento das informa&ccedil;&otilde;es somente &eacute; feito com empresas, afiliadas e pessoas devidamente autorizadas, que se comprometem a manter o sigilo absoluto dos elementos armazenados no banco de dados da plataforma, sob pena de responsabilidade civil e criminal nos moldes da legisla&ccedil;&atilde;o brasileira.</span></p>
<p><span css={css`font-weight: 400;`}>Tais precau&ccedil;&otilde;es, no entanto, n&atilde;o afastam a possibilidade de acesso por terceiros mal-intencionados, por meio de m&eacute;todos desenvolvidos para obter informa&ccedil;&otilde;es de maneira il&iacute;cita e ilegal.</span></p>
<p><span css={css`font-weight: 400;`}>Por esta raz&atilde;o, o TelerisOn n&atilde;o se responsabiliza por acessos irregulares e indevidos, tampouco por atos de terceiros que logrem &ecirc;xito em coletar ou utilizar, por quaisquer meios, dados cadastrais e informa&ccedil;&otilde;es disponibilizadas pelo usu&aacute;rio na plataforma.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong>Altera&ccedil;&otilde;es na pol&iacute;tica de privacidade:</strong></li>
</ul>
<p>&nbsp;</p>
<p><span css={css`font-weight: 400;`}>Alteraremos esta Pol&iacute;tica de Privacidade periodicamente. N&oacute;s n&atilde;o reduziremos seus direitos sem o seu consentimento expl&iacute;cito. Ao rev&eacute;s, indicaremos sempre a data em que as &uacute;ltimas altera&ccedil;&otilde;es foram publicadas e ofereceremos acesso &agrave;s vers&otilde;es arquivadas para sua an&aacute;lise.</span></p>
<p><span css={css`font-weight: 400;`}>Se as altera&ccedil;&otilde;es forem significativas, forneceremos um aviso com mais destaque, o que inclui, no caso de alguns servi&ccedil;os, notifica&ccedil;&atilde;o por e-mail das altera&ccedil;&otilde;es da Pol&iacute;tica de Privacidade.</span></p>

    </Box>
  )
}

export default Terms
