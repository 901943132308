import React, { memo } from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import Spin from '../../components/Spin'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from '@apollo/client'
import { GET, UPDATE } from './Gqls'

import Form from './DocLayoutSettingsForm'

import * as Yup from 'yup'

// Yup.setLocale({
//   mixed: {
//     default: 'Campo inválido',
//     required: 'Campo obrigatório'
//   },
//   string: {
//     min: params => `Deve ter pelo menos ${params.min} caracteres`
//   }
// })

const validationSchema = Yup.object().shape({
  paper: Yup.string().required(),
})

const DocLayoutSettings = ({ data: { clinic }, onRequestClose, onError, notifier }) => {

  const { t } = useTranslation()

  const [updateClinic] = useMutation(UPDATE)

  const defaultInitialValues = {
    paper: 'A4',
    color: '#000000',
    fontFamily: 'Arial',
    fontSize: '12',
    lineHeight: '1.2',
    topMargin: '10',
    rightMargin: '10',
    bottomMargin: '10',
    leftMargin: '10',
    showTop: true,
    topHeight: '',
    topImageUrl: '',
    showBottom: true,
    bottomHeight: '',
    bottomImageUrl: '',
    showPageNumber: true,
    pageNumberDistanceFromBottom: '30',
    showData: true,
    dataHeight: '50',
    dataColor: '',
    dataFontFamily: '',
    dataFontSize: '',
    dataLineHeight: '1.5',
    dataHasBorder: true,
    dataBorderWidth: '',
    dataBorderColor: '',
    dataHasBg: true,
    dataBgColor: '',
    dataHtml: '',
    showSignature: true,
    signatureText: '',
    signatureSettings: '',
    signatureAlign: 'center',
    signatureAdditionalText: '',
    signatureAdditionalImage: '',
    signatureMarginTop: '40',
    showTitle: true,
    titleIsBold: true,
    titleFontFamily: 'Arial',
    titleFontSize: '12',
    titleLineHeight: '2',
    titleAlign: 'center'
  }

  const initialValues = clinic.docSettings ? clinic.docSettings : defaultInitialValues

  const handleSubmit = async variables => {

    try {
      await updateClinic({ variables: { id: clinic.id, docSettings: variables } })
      notifier.success(t('Salvo com sucesso!'))
    } catch(err) {
      console.error(err)
      const errorMessage = err.message.replace('GraphQL error: ', '')
      onError(errorMessage)
    }

  }

  return (

    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Configurar layout')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box maxWidth={800}>

          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {props => <Form {...props} />}
          </Formik>

        </Box>

      </Box>

    </Flex>
  )

}

const DocLayoutSettingsQuery = ({ id, ...rest }) => {
  const { loading, error, data } = useQuery(GET, { fetchPolicy: 'network-only', variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <DocLayoutSettings {...rest} data={data} />
}

export default memo(DocLayoutSettingsQuery)
