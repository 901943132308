import React, { memo, lazy, Suspense, Fragment } from 'react'
import { Box } from '@alobato/flex-box'
import { ContentDuplicateIcon } from '../../../components/Icons'
import Tooltip from '../../../components/Tooltip'
import { camelize } from '../../../utils'
import { mountPhrase } from '../../../utils/variables'

import TextGroup from './TextGroup'

const Textarea = lazy(() => import('../../../components/Textarea'))



const Fields = memo(({ substitutions, vars, onChangeVars, patient, examination, filters, disabled = false, allFields, groups, onChange, afterGroup, onAfterChange, onAddAfter, onRemoveAfter, onClick, onDeselectPhrase }) => {

  const handleDuplicateClick = groupName => {
    let currentAfterGroupText = ''
    if (groupName in afterGroup) {
      currentAfterGroupText = afterGroup[groupName]
    }

    const foundGroups = groups.filter(item => {
      return (item.name === groupName)
    })
    let group = null
    if (foundGroups) group = foundGroups[0]

    let text = mountPhrase({ selectedPhrase: group.selectedPhrase, vars, patient, examination, filters, allFields })
    if (currentAfterGroupText) {
      text = `${text}
${currentAfterGroupText}`
    }
    onAfterChange(groupName, text)
    onDeselectPhrase(groupName)
  }

  return (
    <Box>
      {groups.map(group => {
        let defaultStyle = { padding: '16px 8px 8px 16px', borderLeft: `4px solid ${group.color}`, fontSize: 16, lineHeight: 1.7, position: 'relative' }
        if (group.name === 'TÍTULO') defaultStyle = {...defaultStyle, fontWeight: 700, fontSize: 20}
        return (
          <Fragment key={`after${group.name}`}>
            <Box id={camelize(group.name)} key={group.name} style={{margin: '8px 0'}}>
              {group.selectedPhrase || (group.selectedComplementPhrases && group.selectedComplementPhrases.length > 0) ? (
                <Box style={defaultStyle}>
                  <Box cursor='pointer' style={{ position: 'absolute', top: 0, left: 16, fontSize: 10, fontWeight: 700, color: 'hsla(216, 20%, 75%, 1)' }} onClick={() => onClick(group.name)}>{group.name}</Box>
                  {group.selectedPhrase && group.selectedPhrase.text && !group.selectedPhrase.text.includes('<table') && (
                    <Box cursor='pointer' onClick={() => handleDuplicateClick(group.name)} style={{ position: 'absolute', top: 26, right: -8, color: 'hsla(216, 20%, 75%, 1)' }}><Tooltip placement='left' tooltip='Editar frase'><ContentDuplicateIcon height={16} /></Tooltip></Box>
                  )}
                  {group.selectedPhrase &&
                    <TextGroup vars={vars} onChangeVars={onChangeVars} patient={patient} examination={examination} filters={filters} allFields={allFields} disabled={disabled} group={group} fields={group.fields} text={group.selectedPhrase.text} onChange={({ key, value, index, fieldName }) => { if (disabled) return false; onChange({ key, value, index, group, fieldName }) }} />
                  }
                  {(group.selectedComplementPhrases && group.selectedComplementPhrases.length > 0) && (
                    <>
                      {group.selectedComplementPhrases.map(complementPhrase => {
                        const complementFields = group.complementFields && group.complementFields[complementPhrase.name] ? group.complementFields[complementPhrase.name] : null
                        return (
                          <TextGroup vars={vars} onChangeVars={onChangeVars} patient={patient} examination={examination} filters={filters} allFields={allFields} disabled={disabled} group={group} complementPhraseName={complementPhrase.name} key={complementPhrase.text} fields={complementFields} text={complementPhrase.text} onChange={({ key, value, index }) => { if (disabled) return false; onChange({ key, value, index, group, phraseType: 'complementPhrase', complementPhraseName: complementPhrase.name }) }} />
                        )
                      })}
                    </>
                  )}
                </Box>
              ) : (
                <Box cursor='pointer' style={{ padding: '8px 16px', borderLeft: `4px solid ${group.color}`, backgroundColor: 'hsla(216, 20%, 95%, 1)', fontWeight: 700, fontSize: 15 }} onClick={() => onClick(group.name)}>{group.name}</Box>
              )}
            </Box>

            {(group.name in afterGroup) ? (
              <Box>
                {!disabled &&
                  <Box title='Remover área de texto' onClick={() => onRemoveAfter(group.name)} style={{display: 'inline-block', cursor: 'pointer', lineHeight: 0, color: 'hsla(216, 20%, 75%, 1)'}}><svg style={{pointerEvents: 'none'}} fill='currentColor' viewBox='0 0 24 24' height='16'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' /></svg></Box>
                }
                <Suspense fallback={<Box>Carregando...</Box>}>
                  <Textarea substitutions={substitutions} disabled={disabled} value={afterGroup[group.name]} onChange={value => { if (disabled) return false; onAfterChange(group.name, value) }} />
                </Suspense>
              </Box>
            ) : (
              <>
                {!disabled ? (
                  <Box title='Adicionar área de texto' onClick={() => onAddAfter(group.name)} style={{marginLeft: '-6px', display: 'inline-block', cursor: 'pointer', lineHeight: 0, color: 'hsla(216, 20%, 75%, 1)'}}><svg style={{pointerEvents: 'none'}} fill='currentColor' viewBox='0 0 24 24' height='16'><path d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z ' /></svg></Box>
                ) : (
                  <Box style={{height: 16}} />
                )}
              </>
            )}
          </Fragment>
        )
      })}
    </Box>
  )
})


export default Fields
