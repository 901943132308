import React from 'react'
import Spin from '../../components/Spin'
import Button from '../../components/Button'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { FormatDateTime } from '../../components/Format'

import { CloseIcon } from '../../components/Icons'

import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { LIST_PENDENCIES, CLOSE_PENDENCY, ADD_CONVERSATION } from './Gqls'


const TextareaWithButtom = ({ onAdd }) => {
  const [text, setText] = React.useState('')

  const handleSubmit = (value) => {
    onAdd(value)
    setText('')
  }

  return (
    <Flex ai='center'>
      <Box mr={2}><textarea value={text} onChange={e => setText(e.target.value)} /></Box>
      <Box><Button extrasmall onClick={() => handleSubmit(text)}>Adicionar</Button></Box>
    </Flex>
  )
}

const ListPendencies = ({ data: { pendencies }, refetch, onRequestClose }) => {

  const { t, i18n } = useTranslation()

  const [closePendency] = useMutation(CLOSE_PENDENCY)
  const [addConversation] = useMutation(ADD_CONVERSATION)

  const handleClosePendency = async id => {
    const text = prompt('Digite como a pendência foi resolvida:')
    if (!text || !text.trim()) return false
    try {
      await closePendency({ variables: { id, text } })
      refetch()
    } catch(error) {
      console.error(error)
    }
  }

  const handleAddConversation = async id => {
    const text = prompt('Digite o comentário:')
    if (!text || !text.trim()) return false
    try {
      await addConversation({ variables: { id, text } })
      refetch()
    } catch(error) {
      console.error(error)
    }
  }

  const humanBy = (item, closed = false) => {
    if (closed) {
      if (item && item.closeInfo && item.closeInfo.by && item.closeInfo.by.name) return item.closeInfo.by.name
      return ''
    }
    if (item && item.by && item.by.name) {
      return item.by.name
    }
    return ''
  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Pendências')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        {pendencies.map(item => (
          <Box mb={5} key={item.id}>
            <Box mr={2} mb={1}><FormatDateTime locale={i18n.language}>{item.createdAt}</FormatDateTime> - <strong>Criada</strong> por {humanBy(item)}</Box>
            <Box mb={3}>Motivo: {item.description}</Box>
            {/*<Flex mb={3}>
              <Box mr={2}><FormatDateTime locale={i18n.language}>{item.createdAt}</FormatDateTime> - <strong>{item.description}</strong></Box>
              {item.closedAt ? (<Box>
                Encerrado em <FormatDateTime locale={i18n.language}>{item.closedAt}</FormatDateTime>
              </Box>) : (<Box>
                <Button extrasmall onClick={() => handleClosePendency(item.id)}>Encerrar</Button>
              </Box>)}
              </Flex>*/}
            <Box ml={3}>
              {item.conversation.map((c, index) => (
                <Box mb={3} key={index}>
                  <Box mb={1}><FormatDateTime locale={i18n.language}>{c.at}</FormatDateTime> - <strong>Comentada</strong> por {humanBy(c)}</Box>
                  <Box>{c.text}</Box>
                </Box>
              ))}
              {item.closedAt && (
                <>
                  <Box><FormatDateTime locale={i18n.language}>{item.closedAt}</FormatDateTime> - <strong>Resolvida</strong> por {humanBy(item, true)}</Box>
                  {item.closeInfo && item.closeInfo.text && (<Box mt={2}>{item.closeInfo.text}</Box>)}
                </>
              )}
            </Box>
            {!item.closedAt && (
              <Flex mt={3}>
                <Box mr={3}>
                  <Button small onClick={() => handleAddConversation(item.id)}>Adicionar comentário</Button>
                </Box>
                <Box>
                  <Button small onClick={() => handleClosePendency(item.id)}>Resolver pendência</Button>
                </Box>
              </Flex>
            )}
          </Box>
        ))}

      </Box>
    </Flex>
  )
}

const ListPendenciesQuery = ({ code, onRequestClose }) => {
  const { loading, error, data, refetch } = useQuery(LIST_PENDENCIES, { variables: { code }, fetchPolicy: 'cache-and-network' })
  if (loading) return <Spin />
  if (error) return error.message
  return <ListPendencies data={data} refetch={refetch} onRequestClose={onRequestClose} />
}

export default ListPendenciesQuery
