import React, { useState, useCallback } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'

import Spin from '../../components/Spin'
import ButtonIcon from '../../components/ButtonIcon'
import CircularProgressInline from '../../components/CircularProgressInline'
import Modal from '../../components/StyledModal'
import { formatDateFromIsoString } from '../../utils/date'

import Editor from '../../components/EditorExtended'

import { useTranslation } from 'react-i18next'

import { useQuery, useApolloClient } from '@apollo/client'
import { GET } from './Gqls'

import CleanHtml from './CleanHtml'
import PreviewPdf from './PreviewPdf'

import decorator from './decorator'


const genderText = gender => {
  if (!gender) return ''
  if (gender.toUpperCase() === 'F') return 'Feminino'
  if (gender.toUpperCase() === 'M') return 'Masculino'
  return ''
}

const defaultInitialValues = {
  'TÍTULO': {
    active: true,
    title: 'Título',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 0,
  },
  'INDICAÇÃO CLÍNICA': {
    active: true,
    title: 'Indicação Clínica:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 1,
  },
  'INTRODUÇÃO': {
    active: true,
    title: 'Introdução:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 2,
  },
  'TABELAS E ILUSTRAÇÕES': {
    active: true,
    title: 'Tabelas e Ilustrações',
    titleIsBold: true,
    formatting: 'WITHBREAK',
    order: 3,
  },
  'ANÁLISE': {
    active: true,
    title: 'Análise',
    titleIsBold: true,
    formatting: 'WITHBREAK',
    order: 4,
  },
  'IMPRESSÃO': {
    active: true,
    title: 'Impressão:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 5,
  },
  'OBSERVAÇÃO': {
    active: true,
    title: 'Achados adicionais:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 6,
  },
  'NOTA': {
    active: true,
    title: '',
    titleIsBold: false,
    formatting: 'WITHBREAK',
    order: 7,
  }
}




const Home = ({ data: { document: initialExamination }, notifier }) => {

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  console.log('params', params)

  const { t } = useTranslation()

  const groupsSettings = initialExamination.clinic.groupsSettings || defaultInitialValues
  const docSettings = initialExamination.clinic.docSettings

  const [documentTheme] = useState({
    paper: docSettings.paper,

    color: docSettings.color,

    fontSize: docSettings.fontSize || 12,
    fontFamily: docSettings.fontFamily || 'Arial',
    lineHeight: docSettings.lineHeight || 1.2,

    topMargin: docSettings.topMargin,
    bottomMargin: docSettings.bottomMargin,
    leftMargin: docSettings.leftMargin,
    rightMargin: docSettings.rightMargin,

    showPageNumber: docSettings.showPageNumber,
    pageNumberDistanceFromBottom: docSettings.pageNumberDistanceFromBottom,
    pageNumberAlign: 'right',
    pageNumberColor: 'black',
    pageNumberFontSize: docSettings.fontSize || 12,
    pageNumberFontFamily: docSettings.fontFamily || 'Arial',
    pageNumberFontWeight: 700,

    showSignature: docSettings.showSignature,
    signatureText: docSettings.signatureText,
    signatureSettings: docSettings.signatureSettings,
    signatureAlign: docSettings.signatureAlign,
    signatureAdditionalText: docSettings.signatureAdditionalText,
    signatureAdditionalImage: docSettings.signatureAdditionalImage,
    signatureMarginTop: docSettings.signatureMarginTop,

    showTop: docSettings.showTop,
    topHeight: docSettings.topHeight,
    topImageUrl: docSettings.topImageUrl,

    showBottom: docSettings.showBottom,
    bottomHeight: docSettings.bottomHeight,
    bottomImageUrl: docSettings.bottomImageUrl,

    showData: docSettings.showData,
    dataHeight: docSettings.dataHeight,
    dataBgColor: docSettings.dataBgColor,
    dataBorderColor: docSettings.dataBorderColor,
    dataBorderWidth: docSettings.dataBorderWidth,
    dataColor: docSettings.dataColor,
    dataFontFamily: docSettings.dataFontFamily,
    dataFontSize: docSettings.dataFontSize,
    dataHasBg: docSettings.dataHasBg,
    dataHasBorder: docSettings.dataHasBorder,
    dataLineHeight: docSettings.dataLineHeight,

    // titleIsBold: docSettings.titleIsBold,
    // titleFontSize: docSettings.titleFontSize,
    // titleAlign: docSettings.titleAlign,

    // pageDistanceFromBottom: 30,
    // pageAlign: 'right',
    // pageColor: 'black',
    // pageFontSize: 12,

    // headerHeight: 50,
    // headerFontSize: 12,
    // headerLineHeight: 1.5,
    // headerFontFamily: 'Arial',
    // headerColor: 'black',
    // headerBackgroundColor: '#E4E4E4',
    // headerBorderStyle: 'solid',
    // headerBorderColor: 'black',
    // headerBorderWidth: 2,
  })

  const [examination, setExamination] = useState(initialExamination)
  const client = useApolloClient()

  const signed = examination.signedAt ? true : false

  let { contentState } = examination
  const editorState = EditorState.createWithContent(convertFromRaw(contentState), decorator)

  const [loading, setLoading] = useState(false)

  const [cleanHtmlModalOpen, setCleanHtmlModalOpen] = useState(false)
  const [cleanHtml, setCleanHtml] = useState('')

  React.useEffect(() => {
    if (params && params.format && params.format === 'html') {
      let html = document.getElementsByClassName('DraftEditor-editorContainer')[0].innerHTML
      html = html.replace(/\s?❌/g, '')
      setCleanHtml(html)
    }
  }, [params])

  const handleCopyCleanHtml = () => {
    let html = document.getElementsByClassName('DraftEditor-editorContainer')[0].innerHTML
    html = html.replace(/\s?❌/g, '')

    setCleanHtml(html)
    setCleanHtmlModalOpen(true)
  }

  let headerContent = docSettings.dataHtml

  headerContent = headerContent.replace('[NOME_UNIDADE]', examination.order && examination.order.unit ? examination.order.unit.name : examination.clinicName)
  headerContent = headerContent.replace('[NOME_PACIENTE]', examination.PatientName)
  headerContent = headerContent.replace('[IDADE]', examination.PatientAge ? examination.PatientAge : '')
  headerContent = headerContent.replace('[SEXO]', genderText(examination.PatientGender))
  headerContent = headerContent.replace('[ACCESSION_NUMBER]', examination.accessionNumber ? examination.accessionNumber : '')
  headerContent = headerContent.replace('[PATIENT_ID]', examination.PatientIdTag ? examination.PatientIdTag : '')
  headerContent = headerContent.replace('[MEDICO_SOLICITANTE]', examination.PhysicianName ? examination.PhysicianName : '')
  headerContent = headerContent.replace('[DATA_EXAME]', formatDateFromIsoString(examination.examinedAt))

  return (
    <>
      <Box>

        <Box px={3} pt={3}>
          <Box>

            <Flex mb={3} ai='center'>
              <Flex>
                <Flex ai='center' ml={3}>{loading && <CircularProgressInline />}</Flex>
              </Flex>

              {(!cleanHtml || !params || !params.format || !params.format === 'html') && (
                <Box lh={0}>
                  <Flex ai='center'>
                    <Box mx={1}><ButtonIcon title='Texto Limpo' onClick={handleCopyCleanHtml} iconPath='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' /></Box>
                  </Flex>
                </Box>
              )}

            </Flex>

            <Flex>

                <Box style={{display: 'none'}}>
                <Editor
                  pasteImageEnabled={false}
                  disabled={true}
                  editorState={editorState}
                  onChange={() => {}}
                  disableFontSize
                  onAddIllustrationClick={() => {}}
                  onPreviewClick={() => {}}
                  illustrationSelected={() => {}}
                  previewSelected={() => {}}
                />
                </Box>

              {cleanHtml && params && params.format && params.format === 'html' ? (
                <Box>
                  <CleanHtml
                    onRequestClose={() => {}}
                    html={cleanHtml}
                    examination={examination}
                    hideClose={true}
                  />
                </Box>
              ) : (
                <Box width={420}>
                  <PreviewPdf
                    notifier={notifier}
                    contentState={editorState.getCurrentContent()}
                    examination={examination}
                    headerContent={headerContent}
                    documentTheme={documentTheme}
                    showSendEmail={false}
                  />
                </Box>
              )}

            </Flex>

          </Box>
        </Box>
      </Box>

      {cleanHtmlModalOpen &&
        <Modal
          onCloseCompleted={() => setCleanHtmlModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <CleanHtml
                onRequestClose={onRequestClose}
                html={cleanHtml}
                examination={examination}
              />
            )
          }
        />
      }

    </>
  )
}

const HomeQuery = ({ code, notifier }) => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET, { fetchPolicy: 'network-only', variables: { code } })
  if (loading) return <Spin />
  if (error) return error.message
  if (!data.document) return t('Não encontrado')
  return <Home notifier={notifier} code={code} data={data} />
}

export default HomeQuery
