import React from 'react'
import styled from 'styled-components/macro'

// import Modal from '@alobato/modal'
import Modal from './Modal'

const enterAnimation = (modal, backdrop, backdropOpacity) => {
  if (!modal.current) return
  if ('animate' in modal.current) {
    if (backdrop && backdrop.current) backdrop.current.animate([{opacity: 0}, {opacity: backdropOpacity}], {duration: 300, easing: 'ease'})
    modal.current.animate([{opacity: 0, transform: 'translateY(-100px)'}, {opacity: 1, transform: 'translateY(0)'}], {duration: 300, easing: 'ease'})
      .onfinish = () => {
        if (modal.current) { modal.current.style.opacity = 1 }
        if (backdrop && backdrop.current) { backdrop.current.style.opacity = backdropOpacity }
      }
  } else {
    modal.current.style.opacity = 1
    modal.current.style.transform = 'translateY(0)'
    if (backdrop && backdrop.current) backdrop.current.style.opacity = backdropOpacity
  }
}

const exitAnimation = (modal, backdrop, backdropOpacity, callback) => {
  if (!modal.current) return
  if ('animate' in modal.current) {
    if (backdrop && backdrop.current) backdrop.current.animate([{opacity: backdropOpacity}, {opacity: 0}], {duration: 300, easing: 'ease'})
    modal.current.animate([{opacity: 1, transform: 'translateY(0)'}, {opacity: 0, transform: 'translateY(-100px)'}], {duration: 300, easing: 'ease'})
      .onfinish = () => { callback() }
  } else {
    callback()
  }
}

const StyledModal = styled(Modal)`
  ${props => (props.hide ? `visibility: hidden; & + div { visibility: hidden; }` : '')}
  & > div {
    position: relative;
    background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey0 ? props.theme.colors.grey0 : 'white'};
    box-shadow: ${props => props.theme && props.theme.shadows && props.theme.shadows.modalShadow ? props.theme.shadows.modalShadow : 'none'};
    border: ${props => props.theme && props.theme.borders && props.theme.borders.modalBorder ? props.theme.borders.modalBorder : 'none'};
    pointer-events: auto;
    width: 100%;
    height: calc(100vh);
    height: calc(100vh - 64px); /* test */
    margin: 0;
    margin: 32px auto; /* test */
    overflow: hidden;
    ${props => (props.width ? `width: ${props.width} !important;` : '')}
    @media (min-width: 40em) {
      margin: 32px auto;
      width: auto;
      /* height: min-content; */
      /* height: calc(100vh - 264px); */
      max-height: 540px;
      min-width: 840px;
      max-width: 960px;
      border-radius: ${props => props.theme && props.theme.radius && props.theme.radius.modalRadius ? props.theme.radius.modalRadius : '16'};
    }
  }
`

const StyledModalWithAnimations = props => (
  <StyledModal enterAnimation={enterAnimation} exitAnimation={exitAnimation} {...props} />
)

export default StyledModalWithAnimations
