import React, { memo } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Formik } from 'formik'

import Spin from '../../components/Spin'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import Form from './Form'
import validationSchema from './validationSchema'

import { useQuery, useMutation } from '@apollo/client'
import { GET, LIST, UPDATE } from './Gqls'

const Edit = memo(({ data: { accountUser: user }, onRequestClose, onCompleted, notifier }) => {

  const { t } = useTranslation()

  const [updateUser] = useMutation(UPDATE)

  const handleSubmit = async variables => {

    const update = (cache, { data: { updateUser } }) => {
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, users: cacheList.users.map(item => { if (item.id === variables.id) return updateUser; return user }) }  })
    }

    try {
      await updateUser({ variables, update })
      onCompleted()
    } catch(error) {
      console.error(error)
      notifier.error(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Editar')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={user}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
})

const EditQuery = ({ id, accountId, onRequestClose, onCompleted }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id, accountId } })
  if (loading) return <Spin />
  if (error) return error.message
  if (!data || !data.accountUser) return <div style={{ padding: 32, textAlign: 'center' }}>Não encontrado</div>
  return <Edit data={data} onRequestClose={onRequestClose} onCompleted={onCompleted} />
}

export default memo(EditQuery)
