export const FormatUtcDate = props => {
  if (!props.children) return ''
  const [year, month, day] = props.children.replace('T00:00:00.000Z', '').split('-')
  return `${day}/${month}/${year}`
}

export const FormatDate = props => {
  if (props.locale === 'pt-br' && props.extended) {
    const date = new Date(props.children)
    // const day = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'][data.getDay()]
    const day = date.getDate()
    const month = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'][date.getMonth()]
    const year = date.getFullYear()
    return `${day} de ${month} de ${year}`
  }

  if (!props.children || props.children === '') return ''
  const locale = props.locale ? props.locale : (window.navigator.userLanguage || window.navigator.language)
  const date = new Date(props.children)
  return `${date.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' })}`
}

export const FormatDateTime = props => {
  if (!props.children || props.children === '') return ''
  const locale = props.locale ? props.locale : (window.navigator.userLanguage || window.navigator.language)
  const date = new Date(props.children)
  if (props.short) return `${date.toLocaleDateString(locale, { month: '2-digit', day: '2-digit' })} ${date.toLocaleTimeString(locale, { hour: '2-digit', minute:'2-digit' })}`
  return `${date.toLocaleDateString(locale, { year: '2-digit', month: '2-digit', day: '2-digit' })} ${date.toLocaleTimeString(locale, { hour: '2-digit', minute:'2-digit' })}`
}
