import React from 'react'
import InputField from './InputField'
import { replaceFields, replaceMultiples, sanitize } from '../../../../utils/variables'
import InlineSelect from './InlineSelect'

const getDefaultValue = (fields, index) => {
  if (!fields) return ''
  if (fields && fields[index] === null) return ''
  if (fields && fields[index]) return fields[index]
  return ''
}

function FieldsAndOptions({ fieldsAndOptions, fields, onChange, disabled, group, phrase, variables }) {
  return (
    <>
      {fieldsAndOptions.map((item, index) => {
        return (
          <span key={`fo${index}`}>
            {(item && typeof item === 'string') && (
              <span dangerouslySetInnerHTML={{ __html: sanitize(replaceFields(replaceMultiples(item, phrase.id), variables).replace(/\*\*(.+?)\*\*/g, `<strong>$1</strong>`).replace(/__(.+?)__/g, `<em>$1</em>`)) }} />
            )}
            {(item instanceof Array && item.length === 0) && (
              <InputField disabled={disabled} defaultValue={getDefaultValue(fields, index)} onChange={value => onChange({ value, index, group })} style={{border: 'none', backgroundColor: 'hsla(187, 52%, 85%, 1)', boxShadow: 'hsla(187, 52%, 40%, 1) 1px 1px 0px', padding: 2, lineHeight: 1, width: 60}} />
            )}
            {(item instanceof Array && item.length === 1 && item[0].includes('#')) && (
              <InputField disabled={disabled} name={item[0].replace(/#/g, '')} defaultValue={getDefaultValue(fields, index)} onChange={(value, fieldName) => onChange({ value, index, group, fieldName })} type='text' style={{border: 'none', backgroundColor: 'hsla(187, 52%, 85%, 1)', boxShadow: 'hsla(187, 52%, 40%, 1) 1px 1px 0px', padding: 2, lineHeight: 1, width: 60}} />
            )}
            {(item instanceof Array && item.length === 1 && !item[0].includes('#')) && (
              <InputField disabled={disabled} defaultValue={getDefaultValue(fields, index)} onChange={value => onChange({ value, index, group })} style={{border: 'none', backgroundColor: 'hsla(187, 52%, 85%, 1)', boxShadow: 'hsla(187, 52%, 40%, 1) 1px 1px 0px', padding: 2, lineHeight: 1, width: 60}} />
            )}
            {(item instanceof Array && item.length > 1) && (
              <InlineSelect disabled={disabled} options={item} selected={getDefaultValue(fields, index)} onChange={e => { if (disabled) return false; onChange({ value: e.value, index, group }) }} />
            )}
          </span>
        )
      })}
    </>
  )
}

export default FieldsAndOptions
