import React, { useState } from 'react'
import { Field } from 'formik'

import Input from '../../components/FieldInput'
import Select from '../../components/Select'
import MultiSelect from '../../components/MultiSelect'
import FormErrorBox from '../../components/FormErrorBox'
// import { useTransition, a, config } from 'react-spring-next'
import styled, { css } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import FormikStepper, { FormikStep } from './FormikStepper'


import { useMutation } from '@apollo/client'
import { MULTI_STEP_SIGNUP_3 } from './Gqls'

import { object, string, array } from 'yup'
import '../../validators'


const Box = styled.div``

const Flex = styled(Box)`
  display: flex;
`

const Label = styled(Box)`
  font-weight: 600;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  ${props => props.uppercase && css`text-transform: uppercase;`}
  & > span {
    color: hsla(216, 40%, 70%, 1);
  }
`

const P = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 32px;
`




const modalities = ['TOMOGRAFIA', 'RESSONÂNCIA MAGNÉTICA', 'ULTRASSONOGRAFIA']

const Home = ({ navigate, notifier, token }) => {

  const { t } = useTranslation()

  const [step, setStep] = useState(0)

  const [signUp] = useMutation(MULTI_STEP_SIGNUP_3)

  const handleSubmit = (variables, helpers) => {


    // resetForm: ƒ (nextState)
    // setErrors: ƒ (errors)
    // setFieldError: ƒ (field, value)
    // setFieldTouched: ƒ ()
    // setFieldValue: ƒ ()
    // setFormikState: ƒ (stateOrCb)
    // setStatus: ƒ (status)
    // setSubmitting: ƒ (isSubmitting)
    // setTouched: ƒ ()
    // setValues: ƒ ()
    // submitForm: ƒ ()
    // validateField: ƒ ()
    // validateForm: ƒ

    console.log('variables', variables)

    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)
    signUp({ variables: { ...variables, verificationToken: token } })
      .then(result => {
        // setSubmitting(false)
        localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, result.data.multiStepSignup3.token)
        navigate('/laudos')
      })
      .catch(error => {
        // setSubmitting(false)
        const errorMessage = error.message.replace('GraphQL error: ', '')
        notifier.error(errorMessage)
      })

  }

  const handleChangeStep = step => {
    console.log('handleChangeStep', step)
    setStep(step)
  }

  const modalitiesOptions = React.useMemo(() => (modalities.map(item => ({ value: item, label: item }))), [])

  const changeModalitiesMultiSelect = React.useCallback((options, setFieldValue) => {
    options ? setFieldValue('modalities', options) : setFieldValue('modalities', null)
  }, [])

  return (
    <Box>

      <FormikStepper step={step} initialValues={{ name: '', specialty: '', crm: '', crmUF: '', modalities: '', password: '' }} onSubmit={handleSubmit} onChangeStep={handleChangeStep}>

        {({ isSubmitting, values, errors, touched }) => (
          <FormikStep
            validationSchema={object().shape({
              name: string().required(),
            })}
          >
            <Box>
              <P>Seu email foi verificado com sucesso!<br />Agora precisamos concluir o seu cadastro.</P>

              <Box css={css`width: 300px; margin: 0 auto 32px auto;`}>
                <Label>{t('Digite seu nome completo')}</Label>
                <Field width='100%' name='name' autoComplete='off' component={Input} withError={touched.name && errors.name} />
                <FormErrorBox fieldName='name' errors={errors} touched={touched} />
              </Box>

            </Box>
          </FormikStep>
        )}

        {({ isSubmitting, values, errors, touched }) => (
          <FormikStep
            validationSchema={object().shape({
              specialty: string().required(),
            })}
          >
            <Box>

              <Box css={css`width: 300px; margin: 88px auto 32px auto;`}>
                <Label>{t('Qual a sua especialidade?')}</Label>
                <Field width='100%' name='specialty' list='specialties' autoComplete='off' component={Input} withError={touched.specialty && errors.specialty} />
                <datalist id='specialties'>
                  <option value='Cabeça e Pescoço' />
                  <option value='Cardiologia' />
                  <option value='Coluna' />
                  <option value='Medicina Interna' />
                  <option value='Músculo Esquelético' />
                  <option value='Neuro' />
                  <option value='Saúde da Mulher' />
                  <option value='Radiologia Dentária' />
                  <option value='Vascular' />
                </datalist>
                <FormErrorBox fieldName='specialty' errors={errors} touched={touched} />
              </Box>

            </Box>
          </FormikStep>
        )}

        {({ isSubmitting, values, errors, touched, handleChange, handleBlur }) => (
          <FormikStep
            validationSchema={object().shape({
              crm: string().required(),
              crmUF: string().required(),
            })}
          >
            <Box>

              <Box css={css`width: 400px; margin: 88px auto 32px auto;`}>
                <Label>{t('Digite seu CRM?')}</Label>
                <Flex>
                  <Box css={css`width: 220px; margin-left: 80px;`}>
                    <Field width='100%' name='crm' autoComplete='off' component={Input} withError={touched.crm && errors.crm} />
                    <FormErrorBox fieldName='crm' errors={errors} touched={touched} />
                  </Box>
                  <Box css={css`width: 180px;`}>
                    <Select placeholder={t('UF')} name='crmUF' value={values.crmUF} onChange={handleChange} onBlur={handleBlur} withError={touched.crmUF && errors.crmUF}>
                      <option>AC</option>
                      <option>AL</option>
                      <option>AP</option>
                      <option>AM</option>
                      <option>BA</option>
                      <option>CE</option>
                      <option>DF</option>
                      <option>ES</option>
                      <option>GO</option>
                      <option>MA</option>
                      <option>MT</option>
                      <option>MS</option>
                      <option>MG</option>
                      <option>PA</option>
                      <option>PB</option>
                      <option>PR</option>
                      <option>PE</option>
                      <option>PI</option>
                      <option>RJ</option>
                      <option>RN</option>
                      <option>RS</option>
                      <option>RO</option>
                      <option>RR</option>
                      <option>SC</option>
                      <option>SP</option>
                      <option>SE</option>
                      <option>TO</option>
                    </Select>
                    <FormErrorBox fieldName='crmUF' errors={errors} touched={touched} />
                  </Box>
                </Flex>
              </Box>

            </Box>
          </FormikStep>
        )}

        {({ isSubmitting, values, errors, touched, setFieldValue }) => (
          <FormikStep
            validationSchema={object().shape({
              modalities: array().nullable().required(),
            })}
          >
            <Box>

              <Box css={css`width: 400px; margin: 88px auto 32px auto;`}>
                <Label>{t('Você trabalha com quais modalidades?')}</Label>

                <MultiSelect
                  onChange={options => changeModalitiesMultiSelect(options, setFieldValue)}
                  value={values.modalities}
                  options={modalitiesOptions}
                />

                <FormErrorBox fieldName='modalities' errors={errors} touched={touched} />
              </Box>

            </Box>
          </FormikStep>
        )}

        {({ isSubmitting, values, errors, touched }) => (
          <FormikStep
            validationSchema={object().shape({
              password: string().required().min(6, 'A senha deve ter pelo menos 6 caracteres.'),
            })}
          >
            <Box>

              <Box css={css`width: 300px; margin: 88px auto 32px auto;`}>
                <Label>{t('Crie sua senha de acesso')}</Label>
                <Field width='100%' name='password' type='password' autoComplete='new-password' component={Input} withError={touched.password && errors.password} />
                <FormErrorBox fieldName='password' errors={errors} touched={touched} />
              </Box>

            </Box>
          </FormikStep>
        )}

      </FormikStepper>
    </Box>
  )
}

export default Home



