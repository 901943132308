import React, { memo, useState, useCallback } from 'react'
import { useSpring, animated } from 'react-spring'
import { css } from 'styled-components/macro'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import Viewer from 'react-viewer'

import Spin from '../../components/Spin'
import Wrapper from '../../components/Wrapper'
import Card from '../../components/Card'
import Title from '../../components/Title'
import { LockOutlineIcon, FileEyeOutlineIcon, AttachFileIcon, HistoryIcon } from '../../components/Icons'
import ButtonIcon from '../../components/ButtonIcon'
import CircularProgressInline from '../../components/CircularProgressInline'
import Modal from '../../components/StyledModal'
import { formatDateFromIsoString } from '../../utils/date'
import { getCurrentBlock } from '../../utils/draft'
import { normalizeGender } from '../../utils'

import Label from '../../components/Label'
import Hr from '../../components/Hr'
import { FormatUtcDate } from '../../components/Format'


import Editor from '../../components/EditorExtended'

import { useTranslation } from 'react-i18next'
import useDebounce from '@alobato/use-debounce'
import useLocalStorage from '../../hooks/useLocalStorage'
import useDidMount from '../../hooks/useDidMount'
import useIgnoreMount from '../../hooks/useIgnoreMount'

import { useQuery, useMutation, useApolloClient, useSubscription, gql } from '@apollo/client'
import { GET, UPDATE, SIGN, UNSIGN, CORRECT } from './Gqls'

import CleanHtml from './CleanHtml'
import CleanHtml2 from './CleanHtml2'
import Illustrations from './Illustrations'
import PreviewPdf from './PreviewPdf'

import decorator from './decorator'

import mount from './mount'


const genderText = gender => {
  if (!gender) return ''
  if (gender.toUpperCase() === 'F') return 'Feminino'
  if (gender.toUpperCase() === 'M') return 'Masculino'
  return ''
}

// const clearDisplay = text => {
//   text = text.replace(/#.+?#/, '')
//   text = text.replace(/<.+?>/, '')
//   return text
// }

const defaultInitialValues = {
  'TÍTULO': {
    active: true,
    title: 'Título',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 0,
  },
  'INDICAÇÃO CLÍNICA': {
    active: true,
    title: 'Indicação Clínica:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 1,
  },
  'INTRODUÇÃO': {
    active: true,
    title: 'Introdução:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 2,
  },
  'TABELAS E ILUSTRAÇÕES': {
    active: true,
    title: 'Tabelas e Ilustrações',
    titleIsBold: true,
    formatting: 'WITHBREAK',
    order: 3,
  },
  'ANÁLISE': {
    active: true,
    title: 'Análise',
    titleIsBold: true,
    formatting: 'WITHBREAK',
    order: 4,
  },
  'IMPRESSÃO': {
    active: true,
    title: 'Impressão:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 5,
  },
  'OBSERVAÇÃO': {
    active: true,
    title: 'Achados adicionais:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 6,
  },
  'NOTA': {
    active: true,
    title: '',
    titleIsBold: false,
    formatting: 'WITHBREAK',
    order: 7,
  }
}


function replaceAttachmentUrl(url) {
  if (!url) return ''
  if (url.includes('https://s3.amazonaws.com/telerison.a/')) return url
  let fileName
  if (url.includes('https://telerison.a.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.a.s3.amazonaws.com/', '')
  } else if (url.includes('https://telerison.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.s3.amazonaws.com/', '')
  }
  return `https://s3.amazonaws.com/telerison.a/${fileName}`
}

const Home = memo(({ data: { examination: initialExamination, previousExaminations }, code, notifier, me, navigate }) => {

  const substitutions = me.preferences && me.preferences.speechRecognition && me.preferences.speechRecognition.substitutions ? me.preferences.speechRecognition.substitutions : []

  const [drawerOpen, setDrawerOpen] = useState(false)
  const props = useSpring({ right: drawerOpen ? 0 : -280 })

  const [viewerDrawerOpen, setViewerDrawerOpen] = useState(false)
  const viewerTabProps = useSpring({ right: viewerDrawerOpen ? 0 : -780 })

  const viewerContainerRef = React.useRef(null)

  const [currentExaminationView, setCurrentExaminationView] = useState(null)
  const [previousExaminationsDrawerOpen, setPreviousExaminationsDrawerOpen] = useState(false)
  const previousExaminationsDrawerProps = useSpring({ right: previousExaminationsDrawerOpen ? 0 : -780 })

  const { t } = useTranslation()

  const [previewAttachments, setPreviewAttachments] = useState((initialExamination.orderDetails && initialExamination.orderDetails.attachments) ? (initialExamination.orderDetails.attachments || []).map(attachment => ({ src: replaceAttachmentUrl(attachment.url) })) : [])

  const groupsSettings = initialExamination.clinic.groupsSettings || defaultInitialValues
  const docSettings = initialExamination.clinic.docSettings

  const defaultIllustrationSettings = {
    titleBackgroundColor: '#6b6b6b',
    titleTextColor: '#ffffff',
    titleBorderColor: '#878787'
  }

  const titleBackgroundColor = initialExamination.clinic && initialExamination.clinic.illustrationSettings && initialExamination.clinic.illustrationSettings.titleBackgroundColor ? initialExamination.clinic.illustrationSettings.titleBackgroundColor : defaultIllustrationSettings.titleBackgroundColor
  const titleTextColor = initialExamination.clinic && initialExamination.clinic.illustrationSettings && initialExamination.clinic.illustrationSettings.titleTextColor ? initialExamination.clinic.illustrationSettings.titleTextColor : defaultIllustrationSettings.titleTextColor
  const titleBorderColor = initialExamination.clinic && initialExamination.clinic.illustrationSettings && initialExamination.clinic.illustrationSettings.titleBorderColor ? initialExamination.clinic.illustrationSettings.titleBorderColor : defaultIllustrationSettings.titleBorderColor
  const zoom = initialExamination.clinic && initialExamination.clinic.illustrationSettings && initialExamination.clinic.illustrationSettings.zoom ? Number(initialExamination.clinic.illustrationSettings.zoom) : 2

  const [documentTheme] = useState({
    paper: docSettings.paper,

    color: docSettings.color,

    fontSize: docSettings.fontSize || 12,
    fontFamily: docSettings.fontFamily || 'Arial',
    lineHeight: docSettings.lineHeight || 1.2,

    topMargin: docSettings.topMargin,
    bottomMargin: docSettings.bottomMargin,
    leftMargin: docSettings.leftMargin,
    rightMargin: docSettings.rightMargin,

    showPageNumber: docSettings.showPageNumber,
    pageNumberDistanceFromBottom: docSettings.pageNumberDistanceFromBottom,
    pageNumberAlign: 'right',
    pageNumberColor: 'black',
    pageNumberFontSize: docSettings.fontSize || 12,
    pageNumberFontFamily: docSettings.fontFamily || 'Arial',
    pageNumberFontWeight: 700,

    showSignature: docSettings.showSignature,
    signatureText: docSettings.signatureText,
    signatureSettings: docSettings.signatureSettings,
    signatureAlign: docSettings.signatureAlign,
    signatureAdditionalText: docSettings.signatureAdditionalText,
    signatureAdditionalImage: docSettings.signatureAdditionalImage,
    signatureMarginTop: docSettings.signatureMarginTop,

    showTop: docSettings.showTop,
    topHeight: docSettings.topHeight,
    topImageUrl: docSettings.topImageUrl,

    showBottom: docSettings.showBottom,
    bottomHeight: docSettings.bottomHeight,
    bottomImageUrl: docSettings.bottomImageUrl,

    showData: docSettings.showData,
    dataHeight: docSettings.dataHeight,
    dataBgColor: docSettings.dataBgColor,
    dataBorderColor: docSettings.dataBorderColor,
    dataBorderWidth: docSettings.dataBorderWidth,
    dataColor: docSettings.dataColor,
    dataFontFamily: docSettings.dataFontFamily,
    dataFontSize: docSettings.dataFontSize,
    dataHasBg: docSettings.dataHasBg,
    dataHasBorder: docSettings.dataHasBorder,
    dataLineHeight: docSettings.dataLineHeight,

    // titleIsBold: docSettings.titleIsBold,
    // titleFontSize: docSettings.titleFontSize,
    // titleAlign: docSettings.titleAlign,

    // pageDistanceFromBottom: 30,
    // pageAlign: 'right',
    // pageColor: 'black',
    // pageFontSize: 12,

    // headerHeight: 50,
    // headerFontSize: 12,
    // headerLineHeight: 1.5,
    // headerFontFamily: 'Arial',
    // headerColor: 'black',
    // headerBackgroundColor: '#E4E4E4',
    // headerBorderStyle: 'solid',
    // headerBorderColor: 'black',
    // headerBorderWidth: 2,
  })

  const [examination, setExamination] = useState(initialExamination)

  const [images, setImages] = useState(initialExamination.images)

  const client = useApolloClient()

  let examinationSigned = examination.signedAt ? true : false
  const [signed, setSigned] = useState(examinationSigned)
  // if (!examination.correctedAt && examination.underCorrectionAt) signed = false

  const [updateExamination] = useCallback(useMutation(UPDATE), [UPDATE])
  const [signExamination] = useCallback(useMutation(SIGN), [SIGN])
  const [unsignExamination] = useCallback(useMutation(UNSIGN), [UNSIGN])
  const [correctExamination] = useCallback(useMutation(CORRECT), [CORRECT])

  // const [uploadDoc] = useCallback(useMutation(UPLOAD_DOC), [UPLOAD_DOC])

  const save = useCallback(async ({ variables }) => {
    if (signed || !me.isDoctor) return false
    const steps = (examination.status && examination.status.steps) ? examination.status.steps : []
    const newSteps = steps.includes(3) ? steps : [...steps, 3]
    const newStatus = { ...(examination.status || {}), steps: newSteps }
    try {
      await updateExamination({ variables: { ...variables, status: newStatus }})
    } catch(err) {
      console.error(err)
    }
  }, [updateExamination, examination.status, signed, me.isDoctor])

  let { contentState } = examination

  const [loading, setLoading] = useState(false)

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(decorator))
  const debouncedEditorState = useDebounce(editorState, 1000)

  const [cleanHtmlModalOpen, setCleanHtmlModalOpen] = useState(false)
  const [cleanHtml, setCleanHtml] = useState('')
  const [cleanHtml2ModalOpen, setCleanHtml2ModalOpen] = useState(false)

  const [illustrationModalOpen, setIllustrationModalOpen] = useState(false)

  const [previewOpen, setPreviewOpen] = useLocalStorage('previewOpen', true)

  // const blobRef = useRef(null)

  const mountContent = useCallback(mount, [])

  useDidMount(() => {
    let newEditorState
    if (contentState) {
      newEditorState = EditorState.createWithContent(convertFromRaw(contentState), decorator)
    } else {
      newEditorState = mountContent(decorator, examination, docSettings, groupsSettings, zoom)
    }
    return setEditorState(newEditorState)
  })

  useIgnoreMount(debouncedEditorState, async newDebouncedEditorState => {
    const contentState = convertToRaw(newDebouncedEditorState.getCurrentContent())
    await save({ variables: { code, contentState } })
  })

  useSubscription(gql`subscription { galleryUpdated }`, {
    // variables: {},
    onSubscriptionData: ({ client, subscriptionData }) => {
      console.log('onSubscriptionData', subscriptionData)

      client.query({ query: gql`query Examination($code: ID!) { examination(code: $code, withContentState: false) { images { imageUrl, thumbUrl } } }`, fetchPolicy: 'network-only', variables: { code } } ).then(result => {
        setImages(result.data.examination.images)
      })
    }
  })

  // #region handles
  const handleCopyCleanHtml = () => {
    let html = document.getElementsByClassName('DraftEditor-editorContainer')[0].innerHTML
    html = html.replace(/\s?❌/g, '')

    setCleanHtml(html)
    setCleanHtmlModalOpen(true)
  }

  const handleRemount = async () => {
    if (window.confirm('Você perderá as modificações realizadas no editor. Tem certeza que deseja remontar?')) {
      setLoading(true)
      client.query({ query: GET, fetchPolicy: 'network-only', variables: { code } } ).then(result => {
        setExamination(result.data.examination)
        const newContentState = mountContent(decorator, result.data.examination, docSettings, groupsSettings, zoom)
        setEditorState(newContentState)
        setLoading(false)
      })
    }
  }

  const handleSign = async () => {

    // OpenPendenciesQty
    // ClosedPendenciesQty

    // const file = new File([blobRef.current], `${code}.pdf`)

    // try {
    //   const data = await uploadDoc({ variables: { file } })
    //   notifier.success('Laudo assinado com sucesso.')
    // } catch(err) {
    //   console.error(err)
    // }

    if (!!initialExamination.OpenPendenciesQty) {
      notifier.error('Você não pode assinar este laudo pois existe pendência.')
      return false
    }

    try {
      const result = await signExamination({ variables: { code } })
      if (result) {
        notifier.success(t('Laudo assinado com sucesso!'))
        navigate(`/laudos`)
        // setExamination(currentExamination => ({ ...currentExamination, signedAt: result.data.signExamination.signedAt }))
        // notifier.success(t('Laudo assinado com sucesso!'))
        // // setSigned(true)
        // setTimeout(() => { window.location.reload() }, 1000)
      }

    } catch(err) {
      console.error(err)
      notifier.error('Erro ao tentar assinar')
    }

  }

  const handleCorrect = async () => {
    try {
      const result = await correctExamination({ variables: { code } })
      if (result) {
        setExamination(currentExamination => ({ ...currentExamination, correctedAt: result.data.correctExamination.correctedAt }))
        notifier.success(t('Laudo salvo com sucesso!'))
      }
    } catch(err) {
      console.error(err)
      notifier.error('Erro ao tentar salvar')
    }
  }

  const handleUnsign = async () => {

    try {
      const result = await unsignExamination({ variables: { code } })
      if (result) {
        setExamination(currentExamination => ({ ...currentExamination, signedAt: null }))
        // setSigned(false)
        notifier.success(t('Laudo desassinado com sucesso!'))
        setTimeout(() => { window.location.reload() }, 1000)
      }
    } catch(err) {
      console.error(err)
      notifier.error('Erro ao tentar desassinar')
    }

  }

  const handleChangeEditor = React.useCallback(value => {
    if (signed) return false
    setEditorState(value)
  }, [signed, setEditorState])

  const handleAddIllustration = src => {
    const selectionState = editorState.getSelection()
    if (!selectionState.isCollapsed()) {
      return alert('O cursor está com seleção. Posicione o cursor em uma linha em branco.')
    }

    let contentState = editorState.getCurrentContent()
    const key = selectionState.getStartKey()
    const blockMap = contentState.getBlockMap()
    const currentBlock = getCurrentBlock(editorState)

    if (!currentBlock) {
      return alert('Bloco de texto não encontrado. Posicione o cursor em outro local.')
    }
    if (currentBlock.getLength() === 0) {
      if (currentBlock.getType() === 'image') {
        return alert('O bloco já possui uma imagem. Posicione o cursor em outro local.')
      }
      const newBlock = currentBlock.merge({type: 'image', data: {src}})
      const newContentState = contentState.merge({blockMap: blockMap.set(key, newBlock), selectionAfter: selectionState})
      setEditorState(EditorState.push(editorState, newContentState, 'change-block-type'))
    } else {
      return alert('O bloco de texto já tem conteúdo. Posicione o cursor em outro local.')
    }
  }
  // #endregion

  let headerContent = docSettings.dataHtml

  headerContent = headerContent.replace('[NOME_UNIDADE]', examination.order && examination.order.unit ? examination.order.unit.name : examination.clinicName)
  headerContent = headerContent.replace('[NOME_PACIENTE]', examination.PatientName)
  headerContent = headerContent.replace('[IDADE]', examination.PatientAge ? examination.PatientAge : '')
  headerContent = headerContent.replace('[SEXO]', genderText(examination.PatientGender))
  headerContent = headerContent.replace('[ACCESSION_NUMBER]', examination.accessionNumber ? examination.accessionNumber : '')
  headerContent = headerContent.replace('[PATIENT_ID]', examination.PatientIdTag ? examination.PatientIdTag : '')
  headerContent = headerContent.replace('[MEDICO_SOLICITANTE]', examination.PhysicianName ? examination.PhysicianName : '')
  headerContent = headerContent.replace('[DATA_EXAME]', formatDateFromIsoString(examination.examinedAt))

  const handlePreviewOpen = React.useCallback(() => {
    setPreviewOpen(currentPreviewOpen => !currentPreviewOpen)
  }, [setPreviewOpen])

  const handleIllustrationModalOpen = React.useCallback(() => {

    const selectionState = editorState.getSelection()
    const focusKey = selectionState.getFocusKey()
    console.log('focusKey', focusKey)
    const focusOffset = selectionState.getFocusOffset()
    console.log('focusOffset', focusOffset)
    const anchorKey = selectionState.getAnchorKey()
    console.log('anchorKey', anchorKey)
    const anchorOffset = selectionState.getAnchorOffset()
    console.log('anchorOffset', anchorOffset)

    if (focusKey !== anchorKey) {
      return alert('Verifique se o cursor está posicionado em uma linha onde a ilustração será adicionada.')
    }

    if (focusOffset !== 0 || anchorOffset !== 0) {
      return alert('Verifique se o cursor está posicionado em uma linha em branco.')
    }

    if (!selectionState.isCollapsed()) {
      return alert('O cursor não pode estar selecionando nenhum conteúdo.')
    }

    setIllustrationModalOpen(currentIllustrationModalOpen => !currentIllustrationModalOpen)
  }, [editorState])

  const handleTextFactorChange = async textFactor => {
    await save({ variables: { code, textFactor } })
    setExamination(currentExamination => ({ ...currentExamination, textFactor }))
  }

  const handleGetResult = (code) => {
    setCurrentExaminationView({ code })
  }

  if (!me.isDoctor) {
    return (
      <Wrapper>
        <Card>

          <Box mb={4}>
            <Title>{t('Etapa 3')}</Title>
          </Box>

          <Box>
            Somente usuários médicos podem acessar a Etapa 3.
          </Box>
        </Card>
      </Wrapper>
    )
  }

  return (
    <>
      <Box>
        {signed &&
          <Box boxShadow='0px 1px 6px 0px hsla(0, 0%, 20%, 0.1)' h={32} ta='center' bg='hsla(54, 77%, 94%, 1)' p={1} zIndex={2} position='fixed' width={1}><Text display='inline-block' lineHeight='16px' verticalAlign='-2px' mr={1}><LockOutlineIcon height={16} /></Text><Text display='inline-block' lineHeight='16px'>Este Laudo está assinado e não pode ser editado.</Text></Box>
        }

        <Box px={3} pt={signed ? 5 : 3}>
          <Box>

            <Flex mb={3} ai='center' jc='space-between'>
              <Flex>
                <Box>
                  <Title>{t('Etapa 3')}</Title>
                </Box>
                <Flex ai='center' ml={3}>{loading && <CircularProgressInline />}</Flex>
              </Flex>
              <Box lh={0}>
                <Flex ai='center'>
                  <Box mx={1}><ButtonIcon title='Texto Limpo' onClick={handleCopyCleanHtml} iconPath='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' /></Box>
                  {/*<Box mx={1}><ButtonIcon title='Texto Limpo2' onClick={() => setCleanHtml2ModalOpen(true)} iconPath='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' /></Box>*/}
                  <Box mx={1}><ButtonIcon iconProps={{ height: 28 }} title='Remontar' onClick={handleRemount} iconPath='M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z' /></Box>

                  <Box mx={1}>
                    {examination.orderAvailableAt && !examination.underCorrectionAt && !examination.correctedAt && <>Este exame já foi liberado. Para correção, solicite a operação.</>}
                    {!examination.underCorrectionAt && examination.correctedAt && <>Exame corrigido. Para corrigir novamente, solicite a operação.</>}
                    {examination.signedAt && !examination.orderAvailableAt && !examination.underCorrectionAt && !examination.correctedAt && <Text underline pointer onClick={() => { window.confirm('Você tem certeza que deseja desassinar este laudo?') && handleUnsign() }}>Desassinar</Text>}
                    {/*!examination.signedAt && !examination.orderAvailableAt && !examination.underCorrectionAt && !examination.correctedAt && <ButtonIcon iconProps={{ viewBox: '0 0 1024 1024', height: 30 }} title='Assinar' onClick={() => { window.confirm('Você tem certeza que deseja assinar este laudo?') && handleSign() }} iconPath='M799.773 119.293l-36.759-13.788-117.78 314.080 36.759 13.785 117.78-314.077zM467.314 822.453c-0.788 0.278-1.572 0.57-2.346 0.882 0.281-1.406 0.54-2.823 0.771-4.253h-39.93c-5.392 22.869-19.927 39.26-37.017 39.26-17.097 0-31.628-16.39-37.018-39.26h-2.242v-104.614c-0.234 0.169-0.471 0.33-0.702 0.501 0.237-0.491 0.466-0.991 0.702-1.483v-12.186h-37.158c-43.805 91.353-105.302 147.284-153.869 132.24-61.975-19.202-79.424-146.7-38.974-284.781 40.45-138.082 123.485-234.458 185.465-215.26 37.514 11.621 58.707 62.929 60.627 132.24h37.674c-3.43-87.567-34.365-154.388-87.035-170.703-82.635-25.598-187.463 82.807-234.14 242.132-46.673 159.325-17.522 309.236 65.113 334.834 52.901 16.387 114.893-22.158 164.903-93.812-1.211 6.848-1.868 13.956-1.868 21.26 0 54.206 35.154 98.15 78.521 98.15 14.442 0 27.967-4.884 39.593-13.388 3.118 29.588 28.14 52.649 58.555 52.649 25.638 0 47.442-16.39 55.53-39.26h-75.156v-75.15zM933.429 96.501l-34.11-12.78c-28.252-10.585-58.050 0.334-66.558 24.388l-82.128 232.283c-8.5 24.054 7.507 52.133 35.762 62.722l34.105 12.775c28.256 10.588 58.054-0.33 66.558-24.385l82.128-232.283c8.509-24.053-7.506-52.132-35.758-62.721zM805.398 426.586l-36.893-13.427c-30.564-11.126-62.796 0.346-71.995 25.621l-111.415 301.78c-9.202 25.276-36.806 182.803 0 196.301 30.121 13.561 116.414-82.003 130.885-121.765l128.098-322.605c9.201-25.277-8.121-54.785-38.679-65.907z' />*/}
                    {!examination.signedAt && (!examination.orderAvailableAt || examination.underCorrectionAt) && <ButtonIcon iconProps={{ viewBox: '0 0 1024 1024', height: 30 }} title='Assinar' onClick={() => { window.confirm('Você tem certeza que deseja assinar este laudo?') && handleSign() }} iconPath='M799.773 119.293l-36.759-13.788-117.78 314.080 36.759 13.785 117.78-314.077zM467.314 822.453c-0.788 0.278-1.572 0.57-2.346 0.882 0.281-1.406 0.54-2.823 0.771-4.253h-39.93c-5.392 22.869-19.927 39.26-37.017 39.26-17.097 0-31.628-16.39-37.018-39.26h-2.242v-104.614c-0.234 0.169-0.471 0.33-0.702 0.501 0.237-0.491 0.466-0.991 0.702-1.483v-12.186h-37.158c-43.805 91.353-105.302 147.284-153.869 132.24-61.975-19.202-79.424-146.7-38.974-284.781 40.45-138.082 123.485-234.458 185.465-215.26 37.514 11.621 58.707 62.929 60.627 132.24h37.674c-3.43-87.567-34.365-154.388-87.035-170.703-82.635-25.598-187.463 82.807-234.14 242.132-46.673 159.325-17.522 309.236 65.113 334.834 52.901 16.387 114.893-22.158 164.903-93.812-1.211 6.848-1.868 13.956-1.868 21.26 0 54.206 35.154 98.15 78.521 98.15 14.442 0 27.967-4.884 39.593-13.388 3.118 29.588 28.14 52.649 58.555 52.649 25.638 0 47.442-16.39 55.53-39.26h-75.156v-75.15zM933.429 96.501l-34.11-12.78c-28.252-10.585-58.050 0.334-66.558 24.388l-82.128 232.283c-8.5 24.054 7.507 52.133 35.762 62.722l34.105 12.775c28.256 10.588 58.054-0.33 66.558-24.385l82.128-232.283c8.509-24.053-7.506-52.132-35.758-62.721zM805.398 426.586l-36.893-13.427c-30.564-11.126-62.796 0.346-71.995 25.621l-111.415 301.78c-9.202 25.276-36.806 182.803 0 196.301 30.121 13.561 116.414-82.003 130.885-121.765l128.098-322.605c9.201-25.277-8.121-54.785-38.679-65.907z' />}
                    {/*!examination.signedAt && examination.underCorrectionAt && <ButtonIcon iconProps={{ viewBox: '0 0 1024 1024', height: 30 }} title='Assinar' onClick={() => { window.confirm('Você tem certeza que deseja assinar este laudo?') && handleSign() }} iconPath='M799.773 119.293l-36.759-13.788-117.78 314.080 36.759 13.785 117.78-314.077zM467.314 822.453c-0.788 0.278-1.572 0.57-2.346 0.882 0.281-1.406 0.54-2.823 0.771-4.253h-39.93c-5.392 22.869-19.927 39.26-37.017 39.26-17.097 0-31.628-16.39-37.018-39.26h-2.242v-104.614c-0.234 0.169-0.471 0.33-0.702 0.501 0.237-0.491 0.466-0.991 0.702-1.483v-12.186h-37.158c-43.805 91.353-105.302 147.284-153.869 132.24-61.975-19.202-79.424-146.7-38.974-284.781 40.45-138.082 123.485-234.458 185.465-215.26 37.514 11.621 58.707 62.929 60.627 132.24h37.674c-3.43-87.567-34.365-154.388-87.035-170.703-82.635-25.598-187.463 82.807-234.14 242.132-46.673 159.325-17.522 309.236 65.113 334.834 52.901 16.387 114.893-22.158 164.903-93.812-1.211 6.848-1.868 13.956-1.868 21.26 0 54.206 35.154 98.15 78.521 98.15 14.442 0 27.967-4.884 39.593-13.388 3.118 29.588 28.14 52.649 58.555 52.649 25.638 0 47.442-16.39 55.53-39.26h-75.156v-75.15zM933.429 96.501l-34.11-12.78c-28.252-10.585-58.050 0.334-66.558 24.388l-82.128 232.283c-8.5 24.054 7.507 52.133 35.762 62.722l34.105 12.775c28.256 10.588 58.054-0.33 66.558-24.385l82.128-232.283c8.509-24.053-7.506-52.132-35.758-62.721zM805.398 426.586l-36.893-13.427c-30.564-11.126-62.796 0.346-71.995 25.621l-111.415 301.78c-9.202 25.276-36.806 182.803 0 196.301 30.121 13.561 116.414-82.003 130.885-121.765l128.098-322.605c9.201-25.277-8.121-54.785-38.679-65.907z' />*/}
                    {examination.signedAt && examination.orderAvailableAt && examination.underCorrectionAt && <Text underline pointer onClick={() => { window.confirm('Você tem certeza que deseja desassinar este laudo?') && handleUnsign() }}>Desassinar</Text>}
                    {/*examination.signedAt && examination.orderAvailableAt && examination.underCorrectionAt && !examination.correctedAt && <ButtonIcon iconProps={{ viewBox: '0 0 24 24', height: 30 }} title='Salvar' onClick={() => { window.confirm('Você tem certeza que deseja confirmar a correção deste laudo?') && handleCorrect() }} iconPath='M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z' />*/}
                  </Box>

                </Flex>
              </Box>
            </Flex>

            <Flex>
              <Box css={css`& .DraftEditor-root { height: ${signed ? `calc(100vh - 169px)` : `calc(100vh - 180px)`} !important; }`} flex={1} mr={3} style={{border: '1px solid hsla(216, 20%, 85%, 1)'}}>
                <Editor
                  pasteImageEnabled={!illustrationModalOpen}
                  disabled={signed}
                  editorState={editorState}
                  onChange={handleChangeEditor}
                  disableFontSize
                  onAddIllustrationClick={handleIllustrationModalOpen}
                  onPreviewClick={handlePreviewOpen}
                  illustrationSelected={illustrationModalOpen}
                  previewSelected={previewOpen}
                  substitutions={substitutions}
                  onTextFactorChange={handleTextFactorChange}
                  textFactor={examination.textFactor}
                />
              </Box>

              {previewOpen &&
                <Box width={420}>
                  <PreviewPdf
                    me={me}
                    notifier={notifier}
                    contentState={debouncedEditorState.getCurrentContent()}
                    examination={examination}
                    headerContent={headerContent}
                    documentTheme={documentTheme}
                  />
                </Box>
              }

            </Flex>

          </Box>
        </Box>
      </Box>

      <animated.div style={props} tabIndex='-1' css={css`background-color: hsla(201, 20%, 90%, 1); position: fixed; top: 48px; bottom: 0px; z-index: 1001; outline: none; width: 280px;`}>
        <Flex cursor='pointer' ai='center' jc='center' css={css`width: 40px; height: 40px; background: hsla(201,20%,90%,1); position: absolute; left: -40px; top: 120px;`} onClick={() => setDrawerOpen(currentDrawerOpen => !currentDrawerOpen)}><FileEyeOutlineIcon /></Flex>
        <Box overflow='auto' h='100%'>
          <Box p={3}>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Exame')}</Label>
              <Box>{examination.tree.split(';')[examination.tree.split(';').length - 1]}</Box>
            </Box>

            {examination.examinedAt &&
              <Box mb={3} css={css`white-space: nowrap;`}>
                <Label>{t('Data do Exame')}</Label>
                <Box><FormatUtcDate>{examination.examinedAt}</FormatUtcDate></Box>
              </Box>
            }
            {examination.previousExam1At &&
              <Box mb={3} css={css`white-space: nowrap;`}>
                <Label>{t('Dt. Exame Anterior 1')}</Label>
                <Box><FormatUtcDate>{examination.previousExam1At}</FormatUtcDate></Box>
              </Box>
            }
            {examination.previousExam2At &&
              <Box mb={3} css={css`white-space: nowrap;`}>
                <Label>{t('Dt. Exame Anterior 2')}</Label>
                <Box><FormatUtcDate>{examination.previousExam2At}</FormatUtcDate></Box>
              </Box>
            }

            <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Indicação Clínica')}</Label>
              <Box>{examination.indication}</Box>
            </Box>
            <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Nome')}</Label>
              <Box>{examination.PatientName}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Idade')}</Label>
              <Box>{examination.PatientAge}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Peso')}</Label>
              <Box>{examination.PatientWeight}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Altura')}</Label>
              <Box>{examination.PatientHeight}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Sexo')}</Label>
              <Box>{normalizeGender(examination.PatientGender)}</Box>
            </Box>
            <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Clínica')}</Label>
              <Box>{examination.clinic && examination.clinic.name}</Box>
            </Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Equipamento padrão da clínica')}</Label>
              <Box>{examination.clinic && examination.clinic.defaultEquipment}</Box>
            </Box>
            {/* <Box my={2}><Hr /></Box>
            <Box mb={3} css={css`white-space: nowrap;`}>
              <Label>{t('Variáveis')}</Label>
              <Box>{Object.entries(allVariables).map(item => {
                return (
                  <Box key={item[0]} css={css`white-space: nowrap;`}>
                    <Text fw={600}>{`${item[0]}:`}</Text>
                    <Text>{` `}</Text>
                    <Text>{item[1] !== null && <>{item[1]}</>}</Text>
                  </Box>
                )
              })}</Box>
            </Box> */}
          </Box>
        </Box>
      </animated.div>

      {previewAttachments && previewAttachments.length > 0 && (
        <animated.div style={viewerTabProps} tabIndex='-1' css={css`background-color: hsla(201, 20%, 90%, 1); position: fixed; top: 48px; bottom: 0px; z-index: 1006; outline: none; width: 780px;`}>
          <Flex cursor='pointer' ai='center' jc='center' css={css`width: 40px; height: 40px; background: hsla(201,20%,90%,1); position: absolute; left: -40px; top: 170px;`} onClick={() => setViewerDrawerOpen(currentViewerDrawerOpen => !currentViewerDrawerOpen)}><AttachFileIcon /></Flex>
          <Box overflow='auto' h='100%'>
            <Box p={3} h='100%' ref={viewerContainerRef} />
          </Box>
        </animated.div>
      )}

      {previewAttachments && previewAttachments.length > 0 && viewerDrawerOpen && (
        <Viewer noClose={true} container={viewerContainerRef.current} defaultScale={2.5} scalable={true} disableMouseZoom={true} showTotal={false} zoomSpeed={1} visible={previewAttachments && previewAttachments.length > 0} onClose={() => setPreviewAttachments([]) } images={previewAttachments} />
      )}

      {previousExaminations.length > 0 && (
        <animated.div style={previousExaminationsDrawerProps} tabIndex='-1' css={css`background-color: hsla(201, 20%, 90%, 1); position: fixed; top: 48px; bottom: 0px; z-index: 1005; outline: none; width: 780px;`}>
          <Flex cursor='pointer' ai='center' jc='center' css={css`width: 40px; height: 40px; background: hsla(201,20%,90%,1); position: absolute; left: -40px; top: 220px;`} onClick={() => setPreviousExaminationsDrawerOpen(current => !current)}><HistoryIcon /></Flex>
          <Box overflow='auto' h='100%'>
            {previousExaminations.map(item => (
              <Box p={3} key={item.code}>
                <span css={css`cursor: pointer; text-decoration: underline;`} onClick={() => handleGetResult(item.code)}>
                  <FormatUtcDate>{item.examinedAt}</FormatUtcDate>
                  {item.PatientAge && ` (${item.PatientAge})`}
                  {item.orderDetails && item.orderDetails.ExamName && ` - ${item.orderDetails.ExamName}`}
                  {item.orderDetails && item.orderDetails.UnitShortname && ` - ${item.orderDetails.UnitShortname}`}
                </span>
              </Box>
            ))}
            {currentExaminationView && (
              <Box p={3}>
                <iframe css={css`width: 100%; height: calc(100vh - 140px); border: none;`} title={currentExaminationView.code} src={`https://app.telerison.com/resultado/${currentExaminationView.code}?format=html`} />
              </Box>
            )}
          </Box>
        </animated.div>
      )}

      {cleanHtmlModalOpen &&
        <Modal
          onCloseCompleted={() => setCleanHtmlModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <CleanHtml
                onRequestClose={onRequestClose}
                html={cleanHtml}
                examination={examination}
              />
            )
          }
        />
      }

      {cleanHtml2ModalOpen &&
        <Modal
          onCloseCompleted={() => setCleanHtml2ModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <CleanHtml2
                onRequestClose={onRequestClose}
                examination={examination}
              />
            )
          }
        />
      }

      <Modal
        displayNone={!illustrationModalOpen}
        clickOutsideDisabled
        onCloseCompleted={() => setIllustrationModalOpen(false)}
        render={
          ({onRequestClose}) => (
            <Illustrations
              gallery={initialExamination.gallery}
              captions={initialExamination.captions}
              examination={initialExamination}
              d1={initialExamination.previousExam1At ? formatDateFromIsoString(initialExamination.previousExam1At) : ''}
              d2={initialExamination.previousExam2At ? formatDateFromIsoString(initialExamination.previousExam2At) : ''}
              images={images}
              onRequestClose={onRequestClose}
              onCompleted={src => { handleAddIllustration(src); setIllustrationModalOpen(false) }}
              titleBackgroundColor={titleBackgroundColor}
              titleTextColor={titleTextColor}
              titleBorderColor={titleBorderColor}
              zoom={zoom}
              pasteImageEnabled={illustrationModalOpen}
              vars={initialExamination.vars}
            />
          )
        }
      />

    </>
  )
})

const HomeQuery = ({ code, me, notifier, navigate }) => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET, { fetchPolicy: 'network-only', variables: { code } })
  if (loading) return <Spin />
  if (error) return error.message
  if (!data.examination) return t('Não encontrado')
  return <Home me={me} notifier={notifier} code={code} data={data} navigate={navigate} />
}

export default memo(HomeQuery)
