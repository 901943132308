import React, { memo, useLayoutEffect } from 'react'
import { Router } from '@reach/router'

import LoginLayout from '../../layouts/LoginLayout'
import Home from './Home'
import Verify from './Verify'
import Finish from './Finish'

 const Index = ({ notifier }) => {

  useLayoutEffect(() => {
    document.body.style.backgroundColor = 'hsla(202,  20%, 86%, 1)'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  return (
    <Router>
      <LoginLayout path='/'>
        <Verify notifier={notifier} path='/verificar/:token' />
        <Finish notifier={notifier} path='/finalizar/:token' />
        <Home notifier={notifier} path='/' />
      </LoginLayout>
    </Router>
  )
}

export default memo(Index)
