import { gql } from '@apollo/client'

export const LIST = gql`
  query {
    users {
      id
      name
      email
      cellPhoneNumber
      role
      isDoctor
      crm
      crmUF
      specialty
      license
      licenseCode
      PlanId
      preferences
      unit {
        id
        name
      }
      createdAt
    }
  }
`

export const GET = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      name
      email
      cellPhoneNumber
      role
      isDoctor
      crm
      crmUF
      specialty
      license
      createdAt
    }
  }
`

export const GET_WITH_LICENSES = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      name
      email
      cellPhoneNumber
      role
      isDoctor
      crm
      crmUF
      specialty
      license
      createdAt
      account {
        freeLicenses {
          code
          plan {
            name
          }
        }
      }
    }
  }
`

export const CREATE = gql`
  mutation ($name: String!, $email: String!, $license: String, $cellPhoneNumber: String, $role: String, $isDoctor: Boolean, $crm: String, $crmUF: String) {
    createUser(name: $name, email: $email, license: $license, cellPhoneNumber: $cellPhoneNumber, role: $role, isDoctor: $isDoctor, crm: $crm, crmUF: $crmUF) {
      id
      name
      email
      license
      cellPhoneNumber
      role
      isDoctor
      crm
      crmUF
      specialty
      createdAt
    }
  }
`

export const UPDATE = gql`
  mutation ($id: ID!, $name: String!, $email: String!, $cellPhoneNumber: String, $role: String, $isDoctor: Boolean, $crm: String, $crmUF: String, $license: String) {
    updateUser(id: $id, name: $name, email: $email, cellPhoneNumber: $cellPhoneNumber, role: $role, isDoctor: $isDoctor, crm: $crm, crmUF: $crmUF, license: $license) {
      id
      name
      email
      license
      cellPhoneNumber
      role
      isDoctor
      crm
      crmUF
      license
      specialty
      createdAt
    }
  }
`

export const DELETE = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id)
  }
`

export const REMOVE_LICENSE = gql`
  mutation ($UserId: ID!) {
    removeLicense(UserId: $UserId)
  }
`

export const ASSOCIATE_LICENSE = gql`
  mutation ($UserId: ID!, $licenseCode: String!) {
    associateLicense(UserId: $UserId, licenseCode: $licenseCode)
  }
`

