import React from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import { CloseIcon } from '../../components/Icons'
import Text from '@alobato/text'
import Spin from '../../components/Spin'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'
import { GET, UPDATE } from './Gqls'
import { object, string } from 'yup'
import Form from './EditForm'

const validationSchema = object().shape({ name: string().required() })

function Edit({ data: { account }, onRequestClose, onCompleted, notifier }) {
  const { t } = useTranslation()
  const [updateAccount] = useMutation(UPDATE)

  const handleSubmit = async variables => {
    try {
      await updateAccount({ variables: { id: account.id, name: variables.name } })
      onCompleted()
    } catch(error) {
      console.error(error)
      notifier.error(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Editar')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Formik
          initialValues={{ name: account.name }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

function EditQuery({ id, onRequestClose, onCompleted }) {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Edit data={data} onRequestClose={onRequestClose} onCompleted={onCompleted} />
}

export default EditQuery
