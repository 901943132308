import React from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Formik } from 'formik'

import Spin from '../../components/Spin'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import Form from './Form'
import validationSchema from './validationSchema'

import { useQuery, useMutation } from '@apollo/client'
import { GET, LIST, UPDATE } from './Gqls'

// const Edit = ({ data: { accountGroup: group }, onRequestClose, onCompleted, notifier }) => {
const Edit = ({ data: { accountGroup, doctors }, onRequestClose, onCompleted, notifier }) => {

  const { t } = useTranslation()

  const [updateGroup] = useMutation(UPDATE)

  const handleSubmit = async variables => {

    try {
      await updateGroup({ variables })
      onCompleted()
    } catch(error) {
      console.error(error)
      notifier.error(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Editar')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={accountGroup}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} isCreating={false} doctors={doctors} />}
        </Formik>

      </Box>
    </Flex>
  )

}

const EditQuery = ({ id, accountId, onRequestClose, onCompleted }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id, AccountId: accountId } })
  if (loading) return <Spin />
  if (error) return error.message
  if (data) return <Edit data={data} onRequestClose={onRequestClose} onCompleted={onCompleted} />
}

export default EditQuery
