import React from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import { CloseIcon } from '../../components/Icons'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { CREATE_PENDENCY } from './Gqls'

import Form from './NewPendencyForm'
import validationSchema from './validationSchema'

const NewPendency = ({ code, onRequestClose }) => {

  const { t } = useTranslation()

  const [createPendency] = useMutation(CREATE_PENDENCY)

  const handleSubmit = async variables => {
    console.log('----handleSubmit', handleSubmit)

    console.log('variables', variables)

    try {
      const result = await createPendency({ variables: { code, ...variables } })
      console.log('result', result)
      onRequestClose()
    } catch(error) {
      console.error(error)
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Nova pendência')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={{ description: '', text:  '' }}
          onSubmit={handleSubmit}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default NewPendency
