import React, { memo } from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import Form from './Form'
import validationSchema from './validationSchema'

import { useMutation } from '@apollo/client'
import { LIST, CREATE } from './Gqls'

const New = ({ onRequestClose, onCompleted }) => {

  const { t } = useTranslation()

  const [createUser] = useMutation(CREATE)

  // const initialValues = { name: '', email: '', cellPhoneNumber: '', role: '', isDoctor: false, specialty: '', crm: '', crmUF: '' }
  const initialValues = { name: '', email: '', license: 'M' }

  const handleSubmit = async variables => {

    const update = (cache, { data: { createUser } }) => {
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, users: [createUser, ...cacheList.users] } })
    }

    try {
      await createUser({ variables, update })
      onCompleted()
    } catch(error) {
      alert(error.message.replace('GraphQL error: ', 'Erro: '))
      console.error(error)
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Novo')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} isCreating={true} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default memo(New)
