import React from 'react'
import { Form, Formik } from 'formik'
// import { useTransition, a, config } from 'react-spring-next'
import styled, { css } from 'styled-components/macro'
import Button from '../../components/Button'

const Box = styled.div``

const Flex = styled(Box)`
  display: flex;
`

const Card = styled(Box)`
  background-color: white;
  box-shadow: 0px 3px 6px 0px hsla(0, 0%, 20%, 0.15);
  width: 300px;
  height: 440px;
  padding: 32px;
  .desktop & {
    width: 648px;
  }
`

export const FormikStep = ({ children }) => {
  return <>{children}</>
}

const FormikStepper = ({ children, initialValues, onSubmit, step, onChangeStep }) => {

  const currentChild = children[step]
  const currentValidationSchema = currentChild({ isSubmitting: false, values: {}, errors: {}, touched: {}, handleChange: () => {}, handleBlur: () => {}, setFieldValue: () => {} }).props.validationSchema

  // const transition = useTransition(step, {
  //   from: { opacity: 0.5 },
  //   enter: {
  //     opacity: 1,
  //   },
  //   leave: {
  //     opacity: 0.5,
  //   },
  // })

  function isLastStep() {
    return step === children.length - 1;
  }

  const handleSubmit = async (values, helpers) => {
    console.log('handleSubmit', values)

    if (isLastStep()) {
      await onSubmit(values, helpers)
    } else {
      onChangeStep(step + 1)
    }

  }

  const handleBack = () => {
    onChangeStep(step - 1)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
        <Form>
          <Flex css={css`justify-content: center; width: 100%; margin-top: 64px;`}>

            <Card>
              {currentChild({ isSubmitting, values, errors, touched, handleBlur, handleChange, setFieldValue })}

              <Flex css={css`justify-content: center;`}>
                {step > 0 ? (
                  <Box css={css`margin-right: 8px;`}>
                    <Button
                      type='button'
                      loading={isSubmitting}
                      onClick={handleBack}
                    >Voltar</Button>
                  </Box>
                ) : null}
                <Box css={css`margin-left: 8px;`}>
                  <Button
                    loading={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting' : isLastStep() ? 'Concluir' : 'Continuar'}
                  </Button>
                </Box>
              </Flex>

            </Card>
          </Flex>

        </Form>
      )}
    </Formik>
  )
}

export default FormikStepper

// {transition((springValues, item, state) => {
//   return (
//     item !== null && (
//       <a.div style={{ opacity: springValues.opacity }}>
//         {currentChild({ isSubmitting, values, errors, touched })}
//         {/*children[item]({ isSubmitting, values, errors, touched })*/}
//       </a.div>
//     )
//   )
// })}
