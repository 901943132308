import React, { memo } from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import Spin from '../../components/Spin'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import Form from './Form'

import { useQuery, useMutation } from '@apollo/client'
import { GET, LIST, UPDATE } from './Gqls'

import { object, string } from 'yup'

const validationSchema = object().shape({ name: string().required() })


const Edit = memo(({ data: { plan }, onRequestClose, onCompleted, notifier }) => {

  const { t } = useTranslation()

  const [updatePlan] = useMutation(UPDATE)

  // const planBlank = useMemo(() => {
  //   return Object.entries(plan).reduce((acc, [key, value]) => {
  //     acc = { ...acc, [key]: value ? value : '' }
  //     return acc
  //   }, {})
  // }, [plan])



  const handleSubmit = async variables => {

    const update = (cache, { data: { updatePlan } }) => {
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, plans: cacheList.plans.map(item => { if (item.id === variables.id) return updatePlan; return item }) }  })
    }

    try {
      // variables = Object.entries(variables).reduce((acc, [key, value]) => {
      //   acc = { ...acc, [key]: value ? value : null }
      //   return acc
      // }, {})
      await updatePlan({ variables: { id: plan.id, name: variables.name }, update })
      onCompleted()
    } catch(error) {
      console.error(error)
      notifier.error(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Editar')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={{ name: plan.name }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} isEditing />}
        </Formik>

      </Box>
    </Flex>
  )
})

const EditQuery = ({ id, onRequestClose, onCompleted, notifier }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Edit data={data} onRequestClose={onRequestClose} onCompleted={onCompleted} notifier={notifier} />
}

export default EditQuery
