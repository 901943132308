import memoize from 'memoize-one'
import uniq from 'lodash.uniq'
import isEqual from 'lodash.isequal'
import compact from 'lodash.compact'
import uniqBy from 'lodash.uniqby'
import map from 'lodash.map'
import flatten from 'lodash.flatten'
import flattenDeep from 'lodash.flattendeep'
import reject from 'lodash.reject'
import pull from 'lodash.pull'

const hasIntersection = (l1, l2) => l1.some(item => l2.includes(item))

const expectedColumns = ['checked', 'locked', 'suppress', 'bind', 'hide', 'models', 'group', 'block', 'name', 'text', 'modality', 'specialty', 'region', 'examination', 'protocols', '_id', 'date', 'textCodes']

export const groupsColors = [
  'hsla(346, 85%, 49%, 1)',
  'hsla(22, 100%, 50%, 1)',
  'hsla(42, 98%, 50%, 1)',
  'hsla(156, 98%, 40%, 1)',
  'hsla(204, 96%, 45%, 1)',
  'hsla(275, 95%, 48%, 1)',
  'hsla(346, 87%, 74%, 1)',
  'hsla(208, 16%, 68%, 1)',
  'hsla(356, 73%, 45%, 1)',
  'hsla(233, 84%, 30%, 1)',
  'hsla(181, 41%, 46%, 1)',
  'hsla(22, 36%, 53%, 1)',
  'hsla(42, 58%, 75%, 1)',
  'hsla(282, 100%, 80%, 1)',
  'hsla(233, 100%, 84%, 1)',
  'hsla(56, 64%, 69%, 1)',
  'hsla(77, 60%, 51%, 1)',
  'hsla(7, 100%, 69%, 1)',
  'hsla(178, 43%, 73%, 1)',
  'hsla(219, 29%, 43%, 1)',

  'hsla(346, 85%, 49%, 1)',
  'hsla(22, 100%, 50%, 1)',
  'hsla(42, 98%, 50%, 1)',
  'hsla(156, 98%, 40%, 1)',
  'hsla(204, 96%, 45%, 1)',
  'hsla(275, 95%, 48%, 1)',
  'hsla(346, 87%, 74%, 1)',
  'hsla(208, 16%, 68%, 1)',
  'hsla(356, 73%, 45%, 1)',
  'hsla(233, 84%, 30%, 1)',
  'hsla(181, 41%, 46%, 1)',
  'hsla(22, 36%, 53%, 1)',
  'hsla(42, 58%, 75%, 1)',
  'hsla(282, 100%, 80%, 1)',
  'hsla(233, 100%, 84%, 1)',
  'hsla(56, 64%, 69%, 1)',
  'hsla(77, 60%, 51%, 1)',
  'hsla(7, 100%, 69%, 1)',
  'hsla(178, 43%, 73%, 1)',
  'hsla(219, 29%, 43%, 1)'
]

function rejectOthers(objects, filters) {
  objects = compact(objects)
  const filteredObjects = reject(objects, o => {
    let result = false
    if (!filters || isEqual(filters, {})) return result
    Object.keys(filters).forEach((item, index) => {
      if (filters[item] !== null && typeof filters[item] === 'object') {
        Object.keys(filters[item]).forEach(k => {
          if (o[item] && o[item][k] && o[item][k] !== '' && o[item][k] !== filters[item][k]) result = true
        })
      } else {
        if (o[item] && o[item] !== '' && filters[item] !== null && filters[item] !== '' && o[item] !== filters[item]) result = true
      }
    })
    return result
  })
  return filteredObjects
}

function listUniq(objects, column) {
  const uniqModelObjects = uniqBy(objects, column)
  let items = map(uniqModelObjects, column)
  items = compact(items)
  items = flattenDeep(items)
  items = uniq(compact(items))
  return items
}

function blocksByGroup(phrases, group, protocol, filters, model, ignoreSelections = false) {
  phrases = phrases.filter(item => item.protocols.includes(protocol))

  phrases = phrases.filter(item => {
    if (!ignoreSelections && model) return (item.group === group && item.models.includes(model))
    return (item.group === group)
  })

  if (filters && !isEqual(filters, {})) {
    phrases = rejectOthers(phrases, filters)
  }

  let blocks = listUniq(phrases, 'block')
  blocks = blocks.filter(item => { return !item.split(' ').includes('COMPLEMENTO') })
  return blocks
}

function blocksByGroup2(phrases, group, protocols, filters, models = [], ignoreSelections = false) {
  const joinedProtocols = protocols && !!protocols.length ? [...protocols].sort((a, b) => a.localeCompare(b)).join('+') : []
  let protocolsToFilter = uniq([...protocols, joinedProtocols])

  phrases = phrases.filter(item => (item.protocols && hasIntersection(item.protocols, protocolsToFilter)))

  if (!models || !models.length) models = []
  const joinedModels = models.filter(model => model !== 'PADRÃO').sort((a, b) => a.localeCompare(b)).join('+')
  let modelsToFilter = uniq([...models, joinedModels])
  if (!models.find(model => model.includes('@'))) {
    modelsToFilter = modelsToFilter.includes('PADRÃO') ? modelsToFilter : [...modelsToFilter, 'PADRÃO']
  } else {
    modelsToFilter = modelsToFilter.includes('PADRÃO') ? modelsToFilter.filter(model => model !== 'PADRÃO') : modelsToFilter
  }

  phrases = phrases.filter(item => {
    if (!ignoreSelections && models) return (item.group === group && item.models && hasIntersection(item.models, modelsToFilter))
    return (item.group === group)
  })

  if (filters && !isEqual(filters, {})) {
    phrases = rejectOthers(phrases, filters)
  }

  const codesToHide = phrases.filter((pitem) => {
    if (pitem.hide && pitem.hide.split(';').length > 0) {
      return true
    }
    return false
  }).reduce((acc, pitem) => {
    const hide = pitem.hide.split(';')
    return [...acc, ...hide]
  }, [])

  if (codesToHide && codesToHide.length > 0) {
    phrases = phrases.filter(pitem => {
      if (codesToHide.includes(pitem._id)) {
        return false
      }
      return true
    })
  }

  let blocks = listUniq(phrases, 'block')
  blocks = blocks.filter(item => { return !item.split(' ').includes('COMPLEMENTO') })
  return blocks
}

export const getProtocols = memoize(
  phrases => listUniq(phrases, 'protocols')
)

const extraItems = (phrases, extra) => {
  const uniqObjects = uniqBy(phrases, extra)
  let extraItems = compact(map(uniqObjects, extra))
  if (isEqual(extraItems, ['M'])) extraItems = ['M', 'F']
  if (isEqual(extraItems, ['F'])) extraItems = ['M', 'F']
  if (isEqual(extraItems, ['F', 'M'])) extraItems = ['M', 'F']
  if (isEqual(extraItems, ['SIM'])) extraItems = ['SIM', 'NÃO']
  if (isEqual(extraItems, ['NÃO'])) extraItems = ['SIM', 'NÃO']
  if (isEqual(extraItems, ['NÃO', 'SIM'])) extraItems = ['SIM', 'NÃO']
  return extraItems
}

export const getExtras = memoize(
  (phrases, protocol) => {
    if (!phrases || phrases.length === 0) return []
    phrases = phrases.filter(item => (item.protocols && item.protocols.includes(protocol)))
    let allKeys = phrases.reduce((accumulator, item) => {
      accumulator.push(...Object.keys(item))
      return accumulator
    }, [])
    allKeys = uniq(allKeys)
    let extras = pull(allKeys, ...expectedColumns)
    extras = extras.map(extra => { return {name: extra, items: extraItems(phrases, extra)} })
    return extras
  }
)

export const getExtras2 = memoize(
  (phrases, protocols) => {
    if (!phrases || phrases.length === 0) return []
    phrases = phrases.filter(item => (item.protocols && hasIntersection(item.protocols, protocols)))
    let allKeys = phrases.reduce((accumulator, item) => {
      accumulator.push(...Object.keys(item))
      return accumulator
    }, [])
    allKeys = uniq(allKeys)
    let extras = pull(allKeys, ...expectedColumns)
    extras = extras.map(extra => { return {name: extra, items: extraItems(phrases, extra)} })
    return extras
  }
)

export const getModels = memoize(
  (phrases, protocols, filters) => {
    if (!protocols) return []

    if (protocols instanceof Array) {
      phrases = phrases.filter(item => (item.protocols && hasIntersection(item.protocols, protocols)))
    } else {
      phrases = phrases.filter(item => (item.protocols && item.protocols.includes(protocols)))
    }

    if (filters && !isEqual(filters, {})) {
      phrases = rejectOthers(phrases, filters)
    }

    const result = listUniq(phrases, 'models')
    return result.filter(item => item !== 'PADRÃO')
  }
)

export const getGroups = memoize(
  (phrases, selectedProtocols, filters, selectedModel, hiddenGroups = [], selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields) => {

    if (!groupsIgnoringSelections) groupsIgnoringSelections = []

    if (!selectedProtocols) return []
    phrases = phrases.filter(item => {
      if (selectedProtocols instanceof Array) {
        if (selectedModel && !groupsIgnoringSelections.includes(item.group)) {
          return (item.protocols && hasIntersection(item.protocols, selectedProtocols) && item.models.includes(selectedModel))
        }
        return (item.protocols && hasIntersection(item.protocols, selectedProtocols))
      }

      if (selectedModel && !groupsIgnoringSelections.includes(item.group)) {
        return (item.protocols && item.protocols.includes(selectedProtocols) && item.models.includes(selectedModel))
      }
      return (item.protocols && item.protocols.includes(selectedProtocols))
    })

    if (filters && !isEqual(filters, {})) {
      phrases = rejectOthers(phrases, filters)
    }

    let groups = listUniq(phrases, 'group')
    if (hiddenGroups.length) {
      groups = groups.filter(group => {
        return !hiddenGroups.includes(group)
      })
    }

    groups = groups.map((group, index) => {

      const showUndoIgnore = selectedModel && groupsIgnoringSelections.includes(group)
      const showIgnore = selectedModel && !showUndoIgnore
      const blocks = getBlocksByGroup(phrases, group, selectedProtocols, filters, selectedModel, groupsIgnoringSelections.includes(group))
      const anyBlockSelected = !!selectedBlocks[group]
      const blocksOpen = (anyBlockSelected && blocks.length > 1) ? false : true
      const collapsed = groupsCollapse[group]

      const selectedBlock = selectedBlocks[group] ? selectedBlocks[group] : null
      const selectedPhrase = selectedPhrases[group] ? selectedPhrases[group] : null
      const selectedComplementPhrasesByGroup = (selectedComplementPhrases[group] && selectedComplementPhrases[group].length) ? selectedComplementPhrases[group] : []
      const phrasesByGroup = getPhrasesByGroup(phrases, group, selectedBlock, selectedProtocols, filters, selectedModel, groupsIgnoringSelections.includes(group))
      const complementPhrases = getComplementPhrasesByGroup(phrases, group, selectedProtocols, filters, selectedModel, groupsIgnoringSelections.includes(group))
      const fieldsByGroup = fields && fields[group] ? fields[group] : null
      const complementFieldsByGroup = complementFields && complementFields[group] ? complementFields[group] : null

      return {
        name: group,
        color: groupsColors[index],
        phrases: phrasesByGroup,
        selectedComplementPhrases: selectedComplementPhrasesByGroup,
        fields: fieldsByGroup,
        complementFields: complementFieldsByGroup,
        complementPhrases,
        selectedBlock,
        selectedPhrase,
        anyBlockSelected,
        blocks,
        showUndoIgnore,
        showIgnore,
        blocksOpen,
        collapsed
      }
    })

    return groups

  }
)

export const getGroups2 = memoize(
  (phrases, selectedProtocols, filters, selectedModels = [], hiddenGroups = [], selectedBlocks, selectedPhrases, selectedComplementPhrases, groupsCollapse, groupsIgnoringSelections, fields, complementFields) => {

    if (!selectedModels || !selectedModels.length) selectedModels = []
    const joinedModels = selectedModels && !!selectedModels.length ? selectedModels.filter(model => model !== 'PADRÃO').sort((a, b) => a.localeCompare(b)).join('+') : []
    let modelsToFilter = uniq([...selectedModels, joinedModels])
    if (!selectedModels.find(model => model.includes('@'))) {
      modelsToFilter = modelsToFilter.includes('PADRÃO') ? modelsToFilter : [...modelsToFilter, 'PADRÃO']
    } else {
      modelsToFilter = modelsToFilter.includes('PADRÃO') ? modelsToFilter.filter(model => model !== 'PADRÃO') : modelsToFilter
    }

    if (!groupsIgnoringSelections) groupsIgnoringSelections = []

    if (!selectedProtocols) return []
    const joinedProtocols = selectedProtocols && !!selectedProtocols.length ? [...selectedProtocols].sort((a, b) => a.localeCompare(b)).join('+') : []
    let protocolsToFilter = uniq([...selectedProtocols, joinedProtocols])
    phrases = phrases.filter(item => {
      if (modelsToFilter.length > 0 && !groupsIgnoringSelections.includes(item.group)) {
        return (item.protocols && hasIntersection(item.protocols, protocolsToFilter) && item.models && hasIntersection(item.models, modelsToFilter))
      }

      return (item.protocols && hasIntersection(item.protocols, protocolsToFilter))
    })

    if (filters && !isEqual(filters, {})) {
      phrases = rejectOthers(phrases, filters)
    }

    const codesToHide = phrases.filter((item) => {
      if (item.hide && item.hide.split(';').length > 0) {
        return true
      }
      return false
    }).reduce((acc, item) => {
      const hide = item.hide.split(';')
      return [...acc, ...hide]
    }, [])

    if (codesToHide && codesToHide.length > 0) {
      phrases = phrases.filter(item => {
        if (codesToHide.includes(item._id)) {
          return false
        }
        return true
      })
    }

    let groups = listUniq(phrases, 'group')
    if (hiddenGroups.length) {
      groups = groups.filter(group => {
        return !hiddenGroups.includes(group)
      })
    }

    groups = groups.map((group, index) => {

      const showUndoIgnore = modelsToFilter.length > 0 && groupsIgnoringSelections.includes(group)
      const showIgnore = modelsToFilter.length > 0 && !showUndoIgnore
      const blocks = getBlocksByGroup2(phrases, group, protocolsToFilter, filters, modelsToFilter, groupsIgnoringSelections.includes(group))
      const anyBlockSelected = !!selectedBlocks[group]
      const blocksOpen = (anyBlockSelected && blocks.length > 1) ? false : true
      const collapsed = groupsCollapse[group]

      const selectedBlock = selectedBlocks[group] ? selectedBlocks[group] : null
      const selectedPhrase = selectedPhrases[group] ? selectedPhrases[group] : null
      const selectedComplementPhrasesByGroup = (selectedComplementPhrases[group] && selectedComplementPhrases[group].length) ? selectedComplementPhrases[group] : []
      const phrasesByGroup = getPhrasesByGroup2(phrases, group, selectedBlock, protocolsToFilter, filters, modelsToFilter, groupsIgnoringSelections.includes(group))
      const complementPhrases = getComplementPhrasesByGroup2(phrases, group, protocolsToFilter, filters, modelsToFilter, groupsIgnoringSelections.includes(group))
      const fieldsByGroup = fields && fields[group] ? fields[group] : null
      const complementFieldsByGroup = complementFields && complementFields[group] ? complementFields[group] : null

      return {
        name: group,
        color: groupsColors[index],
        phrases: phrasesByGroup,
        selectedComplementPhrases: selectedComplementPhrasesByGroup,
        fields: fieldsByGroup,
        complementFields: complementFieldsByGroup,
        complementPhrases,
        selectedBlock,
        selectedPhrase,
        anyBlockSelected,
        blocks,
        showUndoIgnore,
        showIgnore,
        blocksOpen,
        collapsed
      }
    })

    return groups

  }
)

export const getBlocksByGroup = memoize(
  (phrases, group, protocol, filters, model, ignoreSelections = false) => {
    return blocksByGroup(phrases, group, protocol, filters, model, ignoreSelections)
  }
)

export const getBlocksByGroup2 = memoize(
  (phrases, group, protocols, filters, models, ignoreSelections = false) => {
    return blocksByGroup2(phrases, group, protocols, filters, models, ignoreSelections)
  }
)

export const getPhrasesByGroup = memoize(
  (phrases, group, block, protocol, filters, model, ignoreSelections = false) => {
    phrases = phrases.filter(item => {
      if (!item.protocols || !item.models || !item.group || !item.block) return false
      const isGroup = item.group === group
      const isBlock = block ? item.block === block : true
      const includeProtocol = protocol ? item.protocols.includes(protocol) : true
      const includeModel = (!ignoreSelections && model) ? item.models.includes(model) : true
      const isNotComplement = !item.block.split(' ').includes('COMPLEMENTO')
      return isGroup && isBlock && includeProtocol && includeModel && isNotComplement
    })
    if (filters && !isEqual(filters, {}))
      phrases = rejectOthers(phrases, filters)
    return phrases
  }
)

export const getPhrasesByGroup2 = memoize(
  (phrases, group, block, protocols, filters, models = [], ignoreSelections = false) => {

    const joinedProtocols = protocols && !!protocols.length ? [...protocols].sort((a, b) => a.localeCompare(b)).join('+') : []
    let protocolsToFilter = uniq([...protocols, joinedProtocols])

    phrases = phrases.filter((item) => {
      if (!item.protocols || !item.models || !item.group || !item.block) return false

      const isGroup = item.group === group
      const isBlock = block ? item.block === block : true
      const includeProtocol = protocols ? hasIntersection(item.protocols, protocolsToFilter) : true

      if (!models || !models.length) models = []
      const joinedModels = models.filter((model) => model !== 'PADRÃO').sort((a, b) => a.localeCompare(b)).join('+')
      let modelsToFilter = uniq([...models, joinedModels])

      if (!models.find((model) => model.includes('@'))) {
        modelsToFilter = modelsToFilter.includes('PADRÃO') ? modelsToFilter : [...modelsToFilter, 'PADRÃO']
      } else {
        modelsToFilter = modelsToFilter.includes('PADRÃO') ? modelsToFilter.filter((model) => model !== 'PADRÃO') : modelsToFilter
      }

      const includeModel = (!ignoreSelections && modelsToFilter) ? hasIntersection(item.models, modelsToFilter) : true
      const isNotComplement = !item.block.split(' ').includes('COMPLEMENTO')

      return isGroup && isBlock && includeProtocol && includeModel && isNotComplement
    })

    if (filters && !isEqual(filters, {})) {
      phrases = rejectOthers(phrases, filters)
    }

    const codesToHide = phrases.filter((pitem) => {
      if (pitem.hide && pitem.hide.split(';').length > 0) {
        return true
      }
      return false
    }).reduce((acc, pitem) => {
      const hide = pitem.hide.split(';')
      return [...acc, ...hide]
    }, [])

    if (codesToHide && codesToHide.length > 0) {
      phrases = phrases.filter(pitem => {
        if (codesToHide.includes(pitem._id)) {
          return false
        }
        return true
      })
    }

    return phrases
  }
)

export const getComplementPhrasesByGroup = memoize(
  (phrases, group, protocol, filters, model, ignoreSelections = false) => {
    phrases = phrases.filter(item => {
      if (!item.protocols || !item.models || !item.group || !item.block) return false
      const isGroup = item.group === group
      const includeProtocol = protocol ? item.protocols.includes(protocol) : true
      const includeModel = (!ignoreSelections && model) ? item.models.includes(model) : true
      const isComplement = item.block.split(' ').includes('COMPLEMENTO')
      return isGroup && includeProtocol && includeModel && isComplement
    })
    if (filters && !isEqual(filters, {})) {
      phrases = rejectOthers(phrases, filters)
    }
    return phrases
  }
)

export const getComplementPhrasesByGroup2 = memoize(
  (phrases, group, protocols, filters, models = [], ignoreSelections = false) => {

    if (!models || !models.length) models = []
    const joinedModels = models.filter(model => model !== 'PADRÃO').sort((a, b) => a.localeCompare(b)).join('+')
    let modelsToFilter = uniq([...models, joinedModels])
    if (!models.find(model => model.includes('@'))) {
      modelsToFilter = modelsToFilter.includes('PADRÃO') ? modelsToFilter : [...modelsToFilter, 'PADRÃO']
    } else {
      modelsToFilter = modelsToFilter.includes('PADRÃO') ? modelsToFilter.filter(model => model !== 'PADRÃO') : modelsToFilter
    }

    const joinedProtocols = protocols && !!protocols.length ? protocols.sort((a, b) => a.localeCompare(b)).join('+') : []
    let protocolsToFilter = uniq([...protocols, joinedProtocols])

    phrases = phrases.filter(item => {
      if (!item.protocols || !item.models || !item.group || !item.block) return false
      const isGroup = item.group === group
      const includeProtocol = protocols ? (item.protocols && hasIntersection(item.protocols, protocolsToFilter)) : true

      const includeModel = (!ignoreSelections && modelsToFilter.length > 0) ? (item.models && hasIntersection(item.models, modelsToFilter)) : true
      const isComplement = item.block.split(' ').includes('COMPLEMENTO')
      return isGroup && includeProtocol && includeModel && isComplement
    })
    if (filters && !isEqual(filters, {})) {
      phrases = rejectOthers(phrases, filters)
    }

    const codesToHide = phrases.filter((pitem) => {
      if (pitem.hide && pitem.hide.split(';').length > 0) {
        return true
      }
      return false
    }).reduce((acc, pitem) => {
      const hide = pitem.hide.split(';')
      return [...acc, ...hide]
    }, [])

    if (codesToHide && codesToHide.length > 0) {
      phrases = phrases.filter(pitem => {
        if (codesToHide.includes(pitem._id)) {
          return false
        }
        return true
      })
    }

    return phrases
  }
)

export const getSuppress = memoize(
  (phrases, selectedPhrases, selectedComplementPhrases) => {
    const selectedPhrasesValues = selectedPhrases ? Object.values(selectedPhrases) : []
    const selectedComplementPhrasesValues = selectedComplementPhrases ? flatten(Object.values(selectedComplementPhrases)) : []
    const selectedIds = [...selectedPhrasesValues, ...selectedComplementPhrasesValues].map(item => item.id)
    const idsToSuppress =  phrases.reduce((acc, phrase) => {
      if (phrase.suppress && selectedIds.includes(phrase._id))
        acc.push(...phrase.suppress)
      return acc
    }, [])
    phrases = phrases.filter(phrase => !idsToSuppress.includes(phrase._id))
    return phrases
  }
)

export const preparePhrases = memoize(
  (phrases) => {
    const newList = []
    for (const item of phrases) {
      const newItem = {}
      if (item.protocols && !(item.protocols instanceof Array)) newItem.protocols = item.protocols.split(';').map(item => item.trim())
      if (item.models && !(item.models instanceof Array)) newItem.models = item.models.split(';').map(item => item.trim())
      if (item.suppress && !(item.suppress instanceof Array)) newItem.suppress = item.suppress.split(';').map(item => item.trim())
      if (item.bind && !(item.bind instanceof Array)) newItem.bind = item.bind.split(';').map(item => item.trim())
      newList.push({ ...item, ...newItem })
    }
    return newList

    // return phrases.map(item => {
    //   if (item.protocols && !(item.protocols instanceof Array)) item.protocols = item.protocols.split(';').map(item => item.trim())
    //   if (item.models && !(item.models instanceof Array)) item.models = item.models.split(';').map(item => item.trim())
    //   if (item.suppress && !(item.suppress instanceof Array)) item.suppress = item.suppress.split(';').map(item => item.trim())
    //   return item
    // })

  }
)
