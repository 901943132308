import React from 'react'

// import { Field } from 'formik'

import { Box } from '@alobato/flex-box'
import Button from '../../components/Button'
// import Input from '../../components/FieldInput'

import useScrollToError from '@alobato/use-scroll-to-error'
// import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

import FormErrorBox from '../../components/FormErrorBox'
import Label from '../../components/Label'
import Select from '../../components/Select'
import Textarea from '../../components/Textarea'

const Form = ({ errors, touched, handleSubmit, isSubmitting, isValid, handleChange, handleBlur, values, setFieldValue }) => {

  const { t } = useTranslation()

  useScrollToError({ errors, isValid, isSubmitting })

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>{t('Motivo')}<span>*</span></Label>
        <Select placeholder={t('Selecione...')} name='description' value={values.description} onChange={handleChange} onBlur={handleBlur} withError={touched.description && errors.description}>
            <option value='Problemas com a aquisição das imagens'>Problemas com a aquisição das imagens</option>
            <option value='Problemas com a reconstrução ou processamento das imagens'>Problemas com a reconstrução ou processamento das imagens</option>
            <option value='Falta de imagens ou séries'>Falta de imagens ou séries</option>
            <option value='Problemas com as informações clínicas do paciente'>Problemas com as informações clínicas do paciente</option>
            <option value='Problemas com a identificação do exame ou paciente'>Problemas com a identificação do exame ou paciente</option>
            <option value='Divergência entre as informações das imagens/exame/informações'>Divergência entre as informações das imagens/exame/informações</option>
            <option value='Outro'>Outro</option>
        </Select>
        <FormErrorBox fieldName='description' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Box><Label>{t('Detalhes')}</Label></Box>
        <Textarea value={values.text} onChange={value => setFieldValue('text', value)} withError={touched.text && errors.text} />
        <FormErrorBox fieldName='text' errors={errors} touched={touched} />
      </Box>

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Criar')}</Button>
      </Box>

    </form>
  )
}

export default Form

