import React from 'react'

// import { Field } from 'formik'

import { Box } from '@alobato/flex-box'
import Button from '../../components/Button'
// import Input from '../../components/FieldInput'

import useScrollToError from '@alobato/use-scroll-to-error'
// import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

import FormErrorBox from '../../components/FormErrorBox'
import Label from '../../components/Label'
import Select from '../../components/Select'
import Textarea from '../../components/Textarea'

const Form = ({ sectors, errors, touched, handleSubmit, isSubmitting, isValid, handleChange, handleBlur, values, setFieldValue }) => {

  const { t } = useTranslation()

  useScrollToError({ errors, isValid, isSubmitting })

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>
      <Box>
        <Label>{t('Nova mensagem para')}<span>*</span></Label>
        <Select placeholder={t('Selecione...')} name='to' value={values.to} onChange={handleChange} onBlur={handleBlur} withError={touched.to && errors.to}>
          {sectors.map(item => (<option value={item.id}>{item.name}</option>))}
        </Select>
        <FormErrorBox fieldName='to' errors={errors} touched={touched} />
      </Box>

      <Box>
        <Box><Label>{t('Mensagem')}</Label></Box>
        <Textarea value={values.body} onChange={value => setFieldValue('body', value)} withError={touched.body && errors.body} />
        <FormErrorBox fieldName='body' errors={errors} touched={touched} />
      </Box>

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Adicionar')}</Button>
      </Box>

    </form>
  )
}

export default Form

