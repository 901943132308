import React from 'react'
import { convertToHTML } from 'draft-convert'

const convertContentStateToHtml = contentState => {

  return convertToHTML({
    styleToHTML: style => {
      if (style === 'BOLD') {
        return <strong />
      }
      if (style === 'ITALIC') {
        return <em />
      }
    },
    blockToHTML: block => {

      if (block.key === 'b9870') { // NOTA
        return <blockquote />
      }

      if (block.data && block.data.textAlign) {
        return <p style={{ textAlign: block.data.textAlign }} />
      }

      // block.inlineStyleRanges
      // block.data
        // text-align: "CENTER"
        // font-size: "18"
        // fontSize: "12"
        // textAlign: "center

      if (block.type === 'PARAGRAPH') {
        return <p />
      } else if (block.text) { // Bug que aparecia type image em textos
        return <p />
      } else if (block.type === 'image') {
        return <img src={block.data.src} alt='' />
      }
    },
    // entityToHTML: (entity, originalText) => {
    //   console.log('entityToHTML entity', entity)
    //   if (entity.type === 'LINK') {
    //     return <a href={entity.data.url}>{originalText}</a>;
    //   }
    //   return originalText;
    // }
  })(contentState)

}

export default convertContentStateToHtml
