import React, { useState, useEffect } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Input from '../../components/Input'
import Label from '../../components/Label'
import WebFont from 'webfontloader'
// const WebFont = lazy(() => import('webfontloader'))

// https://addpipe.com/html-media-capture-demo/
// http://anssiko.github.io/html-media-capture/


import { getVariables } from '../../utils/variables'

const Experiments = () => {

  // const formulas = {
  //   soma: '2 + 3'
  // }

  const [patient, setPatient] = useState({  })
  const [code, setCode] = useState('')
  // const [formulas, setFormulas] = useState([])

  const handleChangePatient = e => {
    const name = e.target.name
    const value = e.target.value
    setPatient(currentPatient => ({ ...currentPatient, [name]: value }))
  }

  const handleChangeCode = e => {
    const value = e.target.value
    setCode(value)
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Source Code Pro:400', 'monospace']
      }
    })
  }, [])

  // const handleAddFormula = () => {

  // }

  const initialFormulas = {
    imc: '(paciente_peso / Math.pow((paciente_altura/100), 2)).toFixed(2)',
    bsa: '(0.007184 * Math.pow(paciente_altura, 0.725) * Math.pow(paciente_peso, 0.425)).toFixed(2)'
  }


  let formulas = initialFormulas

  try {

    if (code) {
      formulas = code.match(/\[#\S+?#[^\]]*/g).reduce((acc, item) => {
        const name = item.match(/\[#(\S+?)#/)[1].toLowerCase()
        if (/\[#\S+?#\s*?\(.+\)/.test(item)) {
          const formulaText = item.match(/\[#\S+?#(.+)/)[1]
          acc = { ...acc, [name]: formulaText }
        }
        return acc
      }, initialFormulas)
    }

  } catch(e) {

  }


  // export const getVariables = memoize(({ fields = {}, patient = {}, filters = {}, vars = {}, formulas = {} }) => {
  const variables = getVariables({ patient, formulas })

  return (
    <Flex>
      <Box mr={3} p={3}>
        <Box mb={2}>
          <Label style={{ textTransform: 'lowercase' }}>paciente_idade</Label>
          <Input name='paciente_idade' value={patient.paciente_idade} onChange={handleChangePatient} />
        </Box>
        <Box mb={2}>
          <Label style={{ textTransform: 'lowercase' }}>paciente_altura</Label>
          <Input name='paciente_altura' value={patient.paciente_altura} onChange={handleChangePatient} />
        </Box>
        <Box mb={2}>
          <Label style={{ textTransform: 'lowercase' }}>paciente_peso</Label>
          <Input name='paciente_peso' value={patient.paciente_peso} onChange={handleChangePatient} />
        </Box>
        <Box mb={2}>
          <Label style={{ textTransform: 'lowercase' }}>paciente_sexo</Label>
          <Input name='paciente_sexo' value={patient.paciente_sexo} onChange={handleChangePatient} />
        </Box>
      </Box>
      <Box p={3}>
        <textarea style={{ fontFamily: 'Source Code Pro', width: 800, height: 400, fontSize: 18 }} placeholder='code' value={code} onChange={handleChangeCode} />
      </Box>
      <Box mb={3} p={3}>
        <Label>Variáveis</Label>
        <Box mb={4}>
          {Object.entries(variables).map(([key, value]) => (
            <Flex mb={1}>
              <Box mr={1}><strong>{key}</strong></Box>
              <Box><span style={{ color: (typeof value === 'string') ? 'blue' : 'green' }}>{value}</span></Box>
            </Flex>
          ))}
        </Box>

        <hr />

        <Label>Fórmulas</Label>
        <Box>
          {Object.entries(formulas).map(([key, value]) => (
            <Flex mb={1}>
              <Box mr={1}><strong>{key}</strong></Box>
              <Box>{value}</Box>
            </Flex>
          ))}
        </Box>

      </Box>

    </Flex>
  )
}

export default Experiments
