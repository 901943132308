import { gql } from '@apollo/client'

export const PLANS = gql`
  query {
    plans {
      id
      name
    }
  }
`

export const LIST = gql`
  query($limit: Int, $offset: Int, $search: String, $orderBy: String) {
    accounts(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy) {
      count
      results {
        id
        name
        settings
        modalities
        users {
          id
        }
        licenses {
          id
        }
        groups {
          id
        }
        plan {
          id
          name
        }
        createdAt
      }
    }
  }
`

export const GET = gql`
  query ($id: ID!) {
    account(id: $id) {
      id
      name
      plan {
        id
        name
      }
      settings
      createdAt
    }
  }
`

export const CREATE = gql`
  mutation ($name: String!, $modalities: JSON, $PlanId: ID) {
    createAccount(name: $name, modalities: $modalities, PlanId: $PlanId) {
      id
      name
      modalities
      plan {
        id
        name
      }
      settings
      createdAt
    }
  }
`

export const UPDATE = gql`
  mutation ($id: ID!, $name: String!) {
    updateAccount(id: $id, name: $name) {
      id
      name
      createdAt
    }
  }
`

export const DELETE = gql`
  mutation ($id: ID!) {
    deleteAccount(id: $id)
  }
`
