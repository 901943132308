// import './wdyr'

import React from 'react'
import ReactDOM from 'react-dom'

// #region Apollo Imports
import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client'
import { setContext }        from 'apollo-link-context'
import { onError }           from 'apollo-link-error'
import ApolloLinkTimeout     from 'apollo-link-timeout'
import { WebSocketLink }     from '@apollo/link-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink }  from 'apollo-upload-client'
// #endregion

import './i18n'
import App from './App'

import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
// import { Integrations } from '@sentry/apm'

const BASE_API = process.env.REACT_APP_BASE_API
const WS_BASE_API = BASE_API.replace('http', 'ws')

if (process.env.NODE_ENV === 'production') {

  Sentry.init({
    dsn: 'https://38bbc739425145fdb89e3856ff9c01a9@o234502.ingest.sentry.io/5245200',
    // release: 'telerison@' + process.env.REACT_APP_VERSION,
    // integrations: [ new Integrations.Tracing() ],
    // tracesSampleRate: 1, // Be sure to lower this in production
  })

}

export const handleLogout = client => {
  localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)
  if (client) client.resetStore()
  window.location.href = '/login'
}

const timeoutLink = new ApolloLinkTimeout(30000) // 30 seconds

const httpLink = new HttpLink({ uri: `${BASE_API}/graphql` })
const uploadLink = createUploadLink({ uri: `${BASE_API}/graphql` })
const wsLink = new WebSocketLink({
  uri: `${WS_BASE_API}/graphql`,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN),
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'Context creation failed: Your session expired. Sign in again.') {
        handleLogout()
      }
      if (message === 'Not authenticated as user.') {
        handleLogout()
      }
    })
  }
  if (networkError && networkError.statusCode === 401) {
    console.log(`[Network error]: ${networkError}`)
    handleLogout(client)
  }
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)
  return { headers: {...headers, authorization: token ? `Bearer ${token}` : ''} }
})

// const uploadAndHttpLink = ApolloLink.from([uploadLink, httpLink])
// const uploadAndHttpLink = httpLink

const splitLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink, uploadLink, httpLink
)

const client = new ApolloClient({
  link: ApolloLink.from([timeoutLink, authLink, errorLink, splitLink]),
  cache: new InMemoryCache(),
  defaultOptions: {}
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
