import { EditorState, Modifier } from 'draft-js'

export const getCurrentBlock = editorState => {
  const selectionState = editorState.getSelection()
  const contentState = editorState.getCurrentContent()
  const block = contentState.getBlockForKey(selectionState.getStartKey())
  return block
}

function exec(regex, text) {
  // function indexOfGroup(match, n) {
  //   var ix = match.index;
  //   for (var i = 1; i < n; i++)
  //     ix += match[i].length;
  //   return ix;
  // }
  let result
  let lastMatchIndex = 0
  const matches = []
  while ((result = regex.exec(text))) {
    const match = []
    lastMatchIndex = text.indexOf(result[0], lastMatchIndex)
    let relIndex = 0
    for (let i = 1; i < result.length; i++) {
      if (result[i]) {
        relIndex = text.indexOf(result[i], relIndex)
        match.push({ value: result[i], start: relIndex, end: relIndex + result[i].length })
      }
    }
    matches.push(match)
  }
  return matches
}

export const getMarkdownStyles = text => {
  const boldsRegex = /(\*\*(.+?)\*\*)/g
  const ItalicsRegex = /(__(.+?)__)/g
  const bolds = exec(boldsRegex, text)
  const italics = exec(ItalicsRegex, text)
  const formats = [...bolds, ...italics].sort((a, b) => (a[0].start - b[0].start))

  const styles = []
  let newText = text
  formats.forEach((bold, index) => {
    const item = bold[0]
    const clean = bold[1]
    newText = newText.replace(item.value, clean.value)
    const start = index === 0 ? item.start : item.start - (4 * index)
    const end = index === 0 ? item.end - 4 : item.end - ((4 * index) + 4)
    let styleName = ''
    if (/\*\*.+?\*\*/.test(item.value)) styleName = 'BOLD'
    if (/__.+?__/.test(item.value)) styleName = 'ITALIC'
    styles.push({ style: styleName, start, end })
  })
  return { text: newText, styles }
}

export function insertTextToCursor(editorState, text, entityType = null) {
  const selection = editorState.getSelection()
  const contentState = editorState.getCurrentContent()

  // let newContentState = contentState
  let newContentState = Modifier.insertText(contentState, selection, text)

  if (entityType) {
    // const entityKey = Entity.create('PLACEHOLDER', 'IMMUTABLE', {teste: 1})
    // const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {url: 'http://www.zombo.com'})
    const contentStateWithEntity = contentState.createEntity(entityType, 'MUTABLE')
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    newContentState = Modifier.insertText(contentState, selection, text, null, entityKey)
  }

  // SelectionState.createEmpty(key).merge({focusKey: key, anchorOffset: 0, focusOffset: text.length})
  // const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {url: 'http://www.zombo.com'})
  // const entityKey = contentStateWithEntity.getLastCreatedEntityKey()


  // const textWithEntity = Modifier.insertText(contentState, selection, text, null, entityKey)
  // const newContentState = Modifier.insertText(contentState, selection, text)

  // return EditorState.push(editorState, newContentState, 'insert-fragment')
  return EditorState.push(editorState, newContentState, 'insert-characters')


  // const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selectionState, entityKey)
  // const newEditorState = EditorState.push(editorState, { currentContent: contentStateWithLink });

}


