import { gql } from '@apollo/client'


export const MULTI_STEP_SIGNUP_1 = gql`
  mutation($email: String!) {
    multiStepSignup1(email: $email)
  }
`

export const MULTI_STEP_SIGNUP_2 = gql`
  mutation($verificationToken: String!, $verificationCode: String!) {
    multiStepSignup2(verificationToken: $verificationToken, verificationCode: $verificationCode)
  }
`

export const MULTI_STEP_SIGNUP_3 = gql`
  mutation($verificationToken: String!, $name: String!, $specialty: String, $crm: String, $crmUF: String, $modalities: JSON, $password: String!) {
    multiStepSignup3(verificationToken: $verificationToken, name: $name, specialty: $specialty, crm: $crm, crmUF: $crmUF, modalities: $modalities, password: $password) {
      token
    }
  }
`
