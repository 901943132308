import React, { memo } from 'react'
import { withTheme } from 'styled-components'
import { Box } from '@alobato/flex-box'

const Wrapper = withTheme(memo(({ ...props }) => {
  let padding = [0, 4]
  if (props.theme && props.theme.paddings && props.theme.paddings.wrapperPadding !== null) padding = props.theme.paddings.wrapperPadding
  if (props.p) padding = props.p
  return <Box mb={5} {...props} p={padding} />
}))

export default Wrapper
