import React from 'react'
import { Formik } from 'formik'
import Spin from '../../components/Spin'
import Button from '../../components/Button'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { FormatDateTime } from '../../components/Format'

import { CloseIcon } from '../../components/Icons'

import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { LIST_MESSAGES, CREATE_MESSAGE, ADD_MESSAGE_CONVERSATION } from './Gqls'

import Form from './MessageForm'

const sectors = [
  { id: '5', name: 'DIRETORES BMK' },
  { id: '2', name: 'OPERAÇÃO BMK' },
  { id: '4', name: 'RECONSTRUÇÃO BMK' },
  { id: '3', name: 'SUPORTE TELERISON' },
  { id: '1', name: 'SUPORTE TI BMK' }
]

const ListMessages = ({ code, data: { messages }, refetch, onRequestClose }) => {

  const { t, i18n } = useTranslation()

  const [createMessage] = useMutation(CREATE_MESSAGE)
  const [addMessageConversation] = useMutation(ADD_MESSAGE_CONVERSATION)

  const handleSubmit = async variables => {
    try {
      const to = { from: 'TELERIS', id: Number(variables.to), name: sectors.find(item => item.id === variables.to).name }
      const result = await createMessage({ variables: { code, ...variables, to } })
      console.log('result', result)
      onRequestClose()
    } catch(error) {
      console.error(error)
    }
  }

  const handleAddConversation = async id => {
    const text = prompt('Digite o comentário:')
    if (!text || !text.trim()) return false
    try {
      await addMessageConversation({ variables: { id, text } })
      refetch()
    } catch(error) {
      console.error(error)
    }
  }

  const humanBy = (item) => {
    if (item && item.by && item.by.name) {
      return item.by.name
    }
    return ''
  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{ flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)' }}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Mensagens')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        {messages.map(item => (
          <Box mb={5} key={item.id}>
            <Box mr={2} mb={1}><FormatDateTime locale={i18n.language}>{item.createdAt}</FormatDateTime> - <strong>Criada</strong> por {humanBy(item)}</Box>
            <Box mb={3}>Para: {item.to.name}</Box>
            <Box mb={3}>Mensagem: {item.body}</Box>
            <Box ml={3}>
              {item.conversation && item.conversation.map((c, index) => (
                <Box mb={3} key={index}>
                  <Box mb={1}><FormatDateTime locale={i18n.language}>{c.at}</FormatDateTime> - <strong>Comentada</strong> por {humanBy(c)}</Box>
                  <Box>{c.text}</Box>
                </Box>
              ))}
            </Box>
            <Box ml={3}>
              <Button small onClick={() => handleAddConversation(item.id)}>Adicionar comentário</Button>
            </Box>
          </Box>
        ))}

        <Box>
          <Formik
            initialValues={{ to: '', body:  '' }}
            onSubmit={handleSubmit}
          >
            {props => <Form {...props} sectors={sectors} />}
          </Formik>
        </Box>

      </Box>
    </Flex>
  )
}

const ListMessagesQuery = ({ code, onRequestClose }) => {
  const { loading, error, data, refetch } = useQuery(LIST_MESSAGES, { variables: { code }, fetchPolicy: 'cache-and-network' })
  if (loading) return <Spin />
  if (error) return error.message
  return <ListMessages data={data} code={code} refetch={refetch} onRequestClose={onRequestClose} />
}

export default ListMessagesQuery
