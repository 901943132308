import React from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation, gql } from '@apollo/client'

import { Box } from '../../components/_new/FlexBox'
import { useMessageContext } from '../../contexts/MessageContext'

import WelcomeMultiStepForm from './WelcomeBMultiStepForm'



function WelcomeMultiStep({ onRequestClose, onCreate }) {
  const { message } = useMessageContext()

  const validationSchemas = [
    yup.object().shape({
      crm: yup.string().required('Campo obrigatório'),
      crmUF: yup.string().required('Campo obrigatório'),
      specialty: yup.string().required('Campo obrigatório'),
      // email: yup.string().email('Email inválido').required('Campo obrigatório'),
      // password: yup.string().required('Campo obrigatório').matches(/^.{8,}$/, 'Sua senha precisa ter pelo menos 8 caracteres'),
      // agree: yup.boolean().oneOf([true], 'Você deve concordar com os termos para criar uma conta')
    }),
    yup.object().shape({}),
    yup.object().shape({
      clinicName: yup.string().required('Campo obrigatório'),
      clinicCity: yup.string().required('Campo obrigatório'),
      clinicUF: yup.string().required('Campo obrigatório'),
    }),
    yup.object().shape({})
  ]

  const [complete] = useMutation(gql`mutation ($cellPhoneNumber: String, $crm: String, $crmUF: String, $specialty: String, $modalities: JSON, $clinicName: String, $clinicCity: String, $clinicUF: String) { complete(cellPhoneNumber: $cellPhoneNumber, crm: $crm, crmUF: $crmUF, specialty: $specialty, modalities: $modalities, clinicName: $clinicName, clinicCity: $clinicCity, clinicUF: $clinicUF) }`)

  const [activeStep, setActiveStep] = React.useState(0)
  const steps = ['1', '2', '3']
  const isLastStep = activeStep === steps.length - 1
  const currentValidationSchema = validationSchemas[activeStep]

  const initialValues = { cellPhoneNumber: '', crm: '', crmUF: 'SP', specialty: '', modalities: [], clinicName: '', clinicCity: '', clinicUF: 'SP' }

  async function handleSubmit(variables, actions) {
    if (!isLastStep) return setActiveStep(activeStep + 1)

    try {
      await complete({ variables })
      window.location.href = '/laudos'
    } catch(error) {
      console.error(error)
      actions.setSubmitting(false)
      const errorMessage = error.message.replace('GraphQL error: ', '')
      message(errorMessage)
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1)
  }

  return (
    <Box width={1}>
      <Box p={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {props => (
            <WelcomeMultiStepForm
              {...props}
              steps={steps}
              activeStep={activeStep}
              isLastStep={isLastStep}
              onBack={handleBack}
              isCreating
            />
          )}
        </Formik>
      </Box>
    </Box>
  )

}

export default WelcomeMultiStep
