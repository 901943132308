import React, { memo } from 'react'
import CircularProgress from '@alobato/circular-progress'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Formik } from 'formik'
import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import FormLicense from './FormLicense'

import { useQuery, useMutation } from '@apollo/client'
import { GET_WITH_LICENSES, ASSOCIATE_LICENSE, LIST } from './Gqls'
import { ME } from '../../authorization'

import * as Yup from 'yup'

Yup.setLocale({
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório'
  },
  string: {
    min: params => `Deve ter pelo menos ${params.min} caracteres`
  }
})

const validationSchema = Yup.object().shape({
  license: Yup.string().required(),
})

const License = ({ data: { user }, onRequestClose, id, onCompleted }) => {

  const { t } = useTranslation()



  const licenses = user.account.freeLicenses

  const [associateLicense] = useMutation(ASSOCIATE_LICENSE)

  const handleSubmit = async variables => {

    // const update = (cache, { data: { updateUser } }) => {
    //   const cacheList = cache.readQuery({ query: LIST })
    //   cache.writeQuery({ query: LIST, data: { ...cacheList, users: cacheList.users.map(item => { if (item.id === variables.id) return updateUser; return item }) }  })
    // }

    try {
      // await updateUser({ variables, update })
      await associateLicense({ refetchQueries: [{ query: LIST }, { query: ME }], variables: { UserId: id, licenseCode: variables.license } })
      onCompleted()
    } catch(error) {
      console.error(error)
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Associar Licença')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={3}>
          Usuário: {user.name}
        </Box>

        <Formik
          initialValues={{ license: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <FormLicense licenses={licenses} {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const LicenseQuery = ({ id, onRequestClose, onCompleted }) => {
  const { loading, error, data } = useQuery(GET_WITH_LICENSES, { variables: { id } })
  if (loading) return <CircularProgress />
  if (error) return error.message
  return <License data={data} id={id} onRequestClose={onRequestClose} onCompleted={onCompleted} />
}

export default LicenseQuery
