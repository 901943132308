import React, { memo, lazy, Suspense } from 'react'
import Spin from '../../components/Spin'

const Home = lazy(() => import('./Home'))

const HomeLazy = props => (
  <Suspense fallback={<Spin />}>
    <Home {...props} />
  </Suspense>
)

export default memo(HomeLazy)
