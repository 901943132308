import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Manager, Reference, Popper } from 'react-popper'

const Portal = ({ children }) => createPortal(children, document.getElementById('portal-root'))

// modifiers = { flip: { enabled: true }, preventOverflow: { enabled: false }, hide: { enabled: false }}
const PopOver = ({ children, render, placement = 'bottom', modifiers = {} }) => {

  const [open, setOpen] = useState(false)

  const wrapperRef = useRef(null)
  const referenceRef = useRef(null)

  const handleClick = event => {
    if (referenceRef.current.contains(event.target)) return setOpen(current => !current)
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) return setOpen(false)
  }

  useEffect(() => {
    // https://codesandbox.io/s/outside-alerter-hooks-lmr2y
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  }, [])

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div style={{display: 'inline-block', userSelect: 'none', cursor: 'pointer'}} ref={ref}>
            <div ref={referenceRef}>{children}</div>
          </div>
        )}
      </Reference>

      <Popper placement={placement} modifiers={{ computeStyle: { gpuAcceleration: false }, ...modifiers }}>
        {({ ref, style }) => ( // placement, arrowProps
          <>
            {open &&
              <Portal>
                <div ref={wrapperRef}>
                  <div ref={ref} style={{ zIndex: '2', top: 0, left: 0, position: style.position, willChange: style.willChange, transform: `translate3d(${style.left}px, ${style.top}px, 0)`, transformOrigin: 'top center'}}>
                    {render({onRequestClose: () => setOpen(false)})}
                  </div>
                </div>
              </Portal>
            }
          </>
        )}
      </Popper>

    </Manager>
  )

}

export default PopOver
