import React, { memo } from 'react'
import { Box } from '@alobato/flex-box'
import Fab from '@alobato/fab'

import Wrapper from '../../components/Wrapper'
import Card from '../../components/Card'
import Title from '../../components/Title'
import Modal from '../../components/StyledModal'

import { useTranslation } from 'react-i18next'
import { useHotkeys } from 'react-hotkeys-hook'

import List from './List'
import Show from './Show'
import New from './New'
import Edit from './Edit'

const Home = ({ id, path, notifier, navigate }) => {

  const { t } = useTranslation()

  useHotkeys('n', () => navigate(`novo${window.location.search}`))

  return (
    <>
      <Wrapper>

        <Card>

          <Box mb={4}>
            <Title>{t('Contas')}</Title>
          </Box>

          <Fab onClick={() => navigate(`novo${window.location.search}`)} />

          <List navigate={navigate} notifier={notifier} />

        </Card>

      </Wrapper>

      {path === '/:id' && id &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate(`../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <Show
                id={id}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      }

      {path === '/:id/editar' &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate(`../../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <Edit
                id={id}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
                notifier={notifier}
              />
            )
          }
        />
      }

      {path === '/novo' &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate(`../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <New
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
                notifier={notifier}
              />
            )
          }
        />
      }
    </>
  )
}

export default memo(Home)
