import safeEval from 'safe-eval'
import memoize from 'lodash.memoize'
import sanitizeHtml from 'sanitize-html'

import { normalizeGender } from './index'

const diffMonths = (dt2, dt1) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= (60 * 60 * 24)
  diff /= 30
  return Math.abs(diff).toFixed(1)
}

const formatUtcDate = date => {
  if (!date) return ''
  const [year, month, day] = date.replace('T00:00:00.000Z', '').split('-')
  return `${day}/${month}/${year}`
}

export const sanitize = text => sanitizeHtml(text, {
  allowedTags: ['strong', 'em']
  // allowedTags: ['b', 'i', 'em', 'strong', 'a'],
  // allowedAttributes: { a: ['href', 'target'] }
})

const memoSafeEval = memoize(safeEval, (...args) => JSON.stringify(args))

const convertIfNumber = text => {
  if (isNaN(text)) return text
  return Number(text)
}

export const trimValues = obj => {
  if (!obj) return {}
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value && typeof value === 'string') value = value.trim()
    if (value && typeof value === 'string' && value === 'NaN') value = ''
    acc = { ...acc, [key]: value && typeof value === 'string' ? value.trim() : value }
    return acc
  }, {})
}


export const getVariables = memoize(({ fields = {}, patient = {}, examination = {}, filters = {}, vars = {}, formulas = {} }) => {

  if (!vars) vars = {}

  const adicionalVars = {}

  if (examination && examination.examinedAt) adicionalVars['data_exame'] = formatUtcDate(examination.examinedAt)
  if (examination && examination.previousExam1At) adicionalVars['data_exame_anterior_1'] = formatUtcDate(examination.previousExam1At)
  if (examination && examination.previousExam2At) adicionalVars['data_exame_anterior_2'] = formatUtcDate(examination.previousExam2At)

  if (examination && examination.examinedAt && examination.previousExam1At) {
    adicionalVars['dif_data_exame_data_exame_anterior_1'] = diffMonths(new Date(examination.examinedAt), new Date(examination.previousExam1At))
  }

  if (examination && examination.examinedAt && examination.previousExam2At) {
    adicionalVars['dif_data_exame_data_exame_anterior_2'] = diffMonths(new Date(examination.examinedAt), new Date(examination.previousExam2At))
  }

  if (examination && examination.previousExam1At && examination.previousExam2At) {
    adicionalVars['dif_data_exame_anterior_1_data_exame_anterior_2'] = diffMonths(new Date(examination.previousExam1At), new Date(examination.previousExam2At))
  }

  filters = filters ? Object.fromEntries(Object.entries(filters).map(([k, v]) => [`filtro_${k.toLowerCase()}`, v])) : {}

  const arr = Object.values(fields).reduce((acc, curr) => {
    acc = [...acc, ...Object.values(curr)]
    return acc
  }, [])

  const obj = arr.reduce((acc, curr) => {
    if (/^#\S+?#<.+?>.+?$/.test(curr)) {
      const found = curr.match(/#(\S+?)#<(.+?)>(.+?)/)
      if (found[1] && found[2] && found[3]) {
        acc = { ...acc, [found[1]]: convertIfNumber(found[2]), [`t_${found[1]}`]: convertIfNumber(found[3]) }
      }
    } else if (/^#\S+?#.+?<.+?>$/.test(curr)) {
      const found = curr.match(/#(\S+?)#(.+?)<(.+?)>$/)
      if (found[1] && found[2] && found[3]) {
        acc = { ...acc, [found[1]]: convertIfNumber(found[3]), [`t_${found[1]}`]: convertIfNumber(found[2]) }
      }
    } else if (/^#\S+?#/.test(curr)) {
      const inputFound = curr.match(/^#(\S+?)#(.+?)$/)
      if (inputFound[1] && inputFound[2]) {
        acc = {...acc, [inputFound[1]]: convertIfNumber(inputFound[2].replace('.', '').replace(',', '.'))}
      }
    }
    return acc
  }, filters)

  vars = Object.entries(vars).reduce((acc, curr) => {
    const [key, value] = curr
    if (/^.+?<.+?>$/.test(value)) {
      const found = value.match(/^(.+?)<(.+?)>$/)
      if (found[1] && found[2]) return { ...acc, [key]: found[2], [`t_${key}`]: found[1] }
    } else if (/^<.+?>.+?$/.test(value)) {
      const found = value.match(/^<(.+?)>(.+?)$/)
      if (found[1] && found[2]) return { ...acc, [key]: found[1], [`t_${key}`]: found[2] }
    }
    return { ...acc, [key]: value }
  }, {})

  const allVariables = { ...obj, ...vars, ...patient, ...adicionalVars }


  const formulaVars = Object.entries(formulas).reduce((acc, curr) => {
    const [key, value] = curr
    const originalFormula = value
    let calculatedValue = 0



    let newVariables = Object.keys({ ...allVariables, ...acc }).reduce((ac, key) => {
      let newValue = { ...allVariables, ...acc }[key]
      if (/\d+,\d+/.test(newValue)) newValue = newValue.replace(',', '.')
      if (newValue && !isNaN(newValue)) newValue = Number(newValue)
      if (key === 'paciente_sexo') newValue = normalizeGender(newValue)
      ac = { ...ac, [key]: newValue }
      return ac
    }, {})

    const vars = Object.keys(newVariables).join(', ')
    const varsStr = `({ ${vars} })`
    let formula = originalFormula.trim()

    if (/\(if.+\)/.test(formula)) {
      formula = formula.slice(1, -1)
    } else if (!formula.includes('return')) {
      formula = `return (${formula})`
    }

    const evalStr = `(${varsStr} => { ${formula} })${varsStr}`

    let result = ''
    try {
      result = memoSafeEval(evalStr, newVariables)
      calculatedValue = result

    } catch(err) {}



    return { ...acc, [key]: calculatedValue }
  }, {})

  return { ...allVariables, ...formulaVars }

}, (...args) => JSON.stringify(args))



export const replaceFields = memoize((text, variables) => {

  if (!text) return ''

  text = text.replace(/<@(.+?)@>/g, (match, originalFormula) => {

    const newVariables = Object.keys(variables).reduce((acc, key) => {
      let newValue = variables[key]
      if (/\d+,\d+/.test(newValue)) newValue = newValue.replace(',', '.')
      if (newValue && !isNaN(newValue)) newValue = Number(newValue)
      acc = {...acc, [key]: newValue}
      return acc
    }, {})

    const vars = Object.keys(newVariables).join(', ')
    const varsStr = `({ ${vars} })`
    let formula = originalFormula.trim()

    if (!formula.includes('return')) formula = `return (${formula})`
    const evalStr = `(${varsStr} => { ${formula} })${varsStr}`

    let result = ''
    try {
      result = memoSafeEval(evalStr, newVariables)
      result = result.toString()

      if (originalFormula.includes('toFixed(')) {
        result = result.toString()
      } else if (!originalFormula.includes('return') && !originalFormula.includes('if') && !originalFormula.includes('(') && !originalFormula.includes('+') && !originalFormula.includes('-') && !originalFormula.includes('/') && !originalFormula.includes('*')) {
        return result
      } else if (/^return \([A-Za-z0-9_-]+\)$/.test(formula) && !isNaN(result) && /\d+\.\d+/.test(result)) {
        result = Number(result).toFixed(2).toString()
      } else if (/\d[,.]\d+/.test(result)) {
        result = result.replace(',', '.')
        // result = Number(result).toFixed(2).toString()
        result = parseInt(result).toString()
      }
      result = result.replace(/(\d+)(\.)(\d{1,2})/g, "$1,$3")

    } catch(err) {}
    return result
  })

  if (text && /\d\.\d/.test(text)) text = text.replace('.', ',')
  return text
}, (...args) => JSON.stringify(args))

export function replaceMultiples(text, phraseId) {
  let i = 1
  let found = []
  const newText = text.replace(/<@.*?(m\d{5}).*?@>/g, (match, originalCode) => {
    if (found.includes(originalCode)) {
      i++
    }
    found.push(originalCode)
    const newCode = `${originalCode}_${phraseId}_${i}`
    const newMatch = match.replaceAll(originalCode, newCode)
    return newMatch
  })
  return newText
}

export const mountPhrase = ({ selectedPhrase, vars, patient, examination, filters, allFields }) => {

  if (!selectedPhrase || !selectedPhrase.text) return ''

  const text = selectedPhrase.text

  let textWithoutCaptures = text.includes('~') ? text.split('~')[1] : text

  textWithoutCaptures = textWithoutCaptures.includes('$$') ? textWithoutCaptures.split('$$')[1] : textWithoutCaptures

  const textFields = textWithoutCaptures.split(/\[.*?\]/)

  const fields = allFields[selectedPhrase.group]

  if (fields) {
    Object.entries(fields).forEach(([key, value]) => {
      textFields.splice(key, 0, value)
    })
    textWithoutCaptures = textFields.join('')
  }



  let formulas = {}

  if (text.includes('~')) {

    const initialFormulas = {
      imc: '(paciente_peso / Math.pow((paciente_altura/100), 2)).toFixed(2)',
      bsa: '(0.007184 * Math.pow(paciente_altura, 0.725) * Math.pow(paciente_peso, 0.425)).toFixed(2)'
    }

    formulas = text.split('~')[0].match(/\[#\S+?#[^\]]*/g).reduce((acc, item) => {
      const name = item.match(/\[#(\S+?)#/)[1].toLowerCase()
      if (/\[#\S+?#\s*?\(.+\)/.test(item)) {
        const formulaText = item.match(/\[#\S+?#(.+)/)[1]
        acc = { ...acc, [name]: formulaText }
      }
      return acc
    }, initialFormulas)

    let variables = getVariables({ fields: allFields, patient, examination, filters, vars: trimValues(vars), formulas })
    variables = Object.entries(variables).reduce((acc, [key, value]) => ({ ...acc, [key]: (value === null || value === undefined) ? '' : value}), {})

    return replaceFields(textWithoutCaptures, variables).replace(/\*\*(.+?)\*\*/g, `$1`).replace(/__(.+?)__/g, `$1`)
  }

  return textWithoutCaptures
}
