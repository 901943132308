import React, { memo, useCallback, useRef } from 'react'
// import { Box } from '@alobato/flex-box'
import Tippy from '@tippy.js/react'

import TableGroup from './TableGroup'
import TableGroup2 from './TableGroup2'
import TextGroup2 from './TextGroup2'
import CaptureVars from './CaptureVars'
import FieldsAndOptions from './FieldsAndOptions'

import ButtonIcon from '../../../../components/ButtonIcon'

// import 'tippy.js/dist/tippy.css'
// import 'tippy.js/themes/light.css'
// import 'tippy.js/animations/perspective.css'

import useDidMount from '../../../../hooks/useDidMount'

import { getVariables, replaceFields, trimValues } from '../../../../utils/variables'

const getDefaultValue = (fields, index) => {
  if (!fields) return ''
  if (fields && fields[index] === null) return ''
  if (fields && fields[index]) return fields[index]
  return ''
}

const TextGroup = memo(({ vars, onChangeVars, onVariablesSubmit, patient, examination, filters, disabled = false, allFields, group, complementPhraseName, phrase, fields, onChange }) => {

  let text = ''

  if (phrase && phrase.text) {
    text = phrase.text
  }

  // if (group.name === 'GLOBOS OCULARES') {
  //   text = 'v2!<table><tr><td><@ paciente_nome @> - <@ ((v10760 * 2) + v28362) @></td></tr></table>'
  // }

  const instanceRef = useRef()
  // const buttonRef = useRef()

  // const replaceFieldsMemo = React.useMemo((replaceFields, [])
  // const filtered = useMemo(() => searchBy(data.companies, ['name'], search), [data.companies, search])
  // const filtered = useMemo(() => searchBy(data.users, ['name'], search), [data.users, search])
  // const mutation = useCallback(useMutation(UPDATE), [UPDATE])

  // const isTable = text.includes('<table')

  // textWithoutCaptures = Aquisição volumétrica com [#volume#1,0|1,2|1,5|2,0|3,0] mm de espessura.
  // textFields = ['Aquisição volumétrica com ', '[#volume#1,0|1,2|1,5|2,0|3,0]', ' mm de espessura.']
  // options = ['[#volume#1,0|1,2|1,5|2,0|3,0]']
  //
  let textWithoutCaptures = text.includes('~') ? text.split('~')[1] : text
  textWithoutCaptures = textWithoutCaptures.includes('$$') ? textWithoutCaptures.split('$$')[1] : textWithoutCaptures

  const textFields = textWithoutCaptures.split(/\[.*?\]/)
  const options = textWithoutCaptures.match(/\[.*?\]/g)

  // fieldsAndOptions = ['Aquisição volumétrica com ', ["#volume#1,0", "1,2", "1,5", "2,0", "3,0"], ' mm de espessura']
  //
  const fieldsAndOptions = useCallback((textFields, options) => textFields.reduce((result, item, index) => {
    result.push(item)
    if (options && options[index] && !/\[#\S+?#\s*?\(.+\)/.test(options[index])) {
      const option = options[index]
      if (option === '[]' || option === '[ ]') {
        result.push([])
      } else {
        result.push(option.replace('[', '').replace(']', '').trim().split('|'))
      }
    }
    return result
  }, []), [])

  let capturedVars = []
  let formulas = {}

  if (text.includes('~')) {

    capturedVars = text.split('~')[0].match(/\[#\S+?#[^\]]*/g).reduce((acc, item) => {
      const name = item.match(/\[#(\S+?)#/)[1].toLowerCase()
      let fieldsOptions = []
      if (/\[#\S+?#\s*?\(.+\)/.test(item)) {
      } else if (item.includes('|') && /\[#\S+?#.+?|.+/.test(item)) {
        fieldsOptions = item.match(/\[#\S+?#(.+)/)[1]
        fieldsOptions = fieldsOptions.split('|')
        if (!acc.find(element => (element.name === name))) acc.push({ name, options: fieldsOptions })
      } else {
        if (!acc.find(element => (element.name === name))) acc.push({ name, options: [] })
      }
      return acc
    }, [])

    const initialFormulas = {
      imc: '(paciente_peso / Math.pow((paciente_altura/100), 2)).toFixed(2)',
      bsa: '(0.007184 * Math.pow(paciente_altura, 0.725) * Math.pow(paciente_peso, 0.425)).toFixed(2)'
    }

    formulas = text.split('~')[0].match(/\[#\S+?#[^\]]*/g).reduce((acc, item) => {
      const name = item.match(/\[#(\S+?)#/)[1].toLowerCase()
      if (/\[#\S+?#\s*?\(.+\)/.test(item)) {
        const formulaText = item.match(/\[#\S+?#(.+)/)[1]
        acc = { ...acc, [name]: formulaText }
      }
      return acc
    }, initialFormulas)

  }

  useDidMount(() => {
    // fields = { 1: '#volume#1,0' }
    //
    fieldsAndOptions(textFields, options).forEach((item, index) => {
      if (item instanceof Array) {
        const value = getDefaultValue(fields, index)
        if (complementPhraseName) {
          if (!disabled) onChange({ value, index, group, phraseType: 'complementPhrase', complementPhraseName })
        } else {
          if (!disabled) onChange({ value, index, group, phraseType: 'phrase' })
        }
      }
    })
  })

  // ex.: allFields = { 'INTRODUÇÃO': { 1: '#volume#1,0' } }
  let variables = getVariables({ fields: allFields, patient, examination, filters, vars: trimValues(vars), formulas })
  variables = Object.entries(variables).reduce((acc, [key, value]) => ({ ...acc, [key]: (value === null || value === undefined) ? '' : value}), {})
  // TODO variable of different tables

  // const replaceFieldsMemo = useCallback(replaceFields, [textWithoutCaptures, JSON.stringify(variables)])

  if (!text) return ''

  // if (text.includes('version2') || (!text.includes('~') && /<@.+?@>/g.test(text))) {
  // if (text.includes('version2')) {
  //   return <TableGroup2 variables={variables} phrase={phrase} onSubmit={onVariablesSubmit} />
  // } else

  if (text.includes('<table') && (!text.includes('version2'))) {
    // const hash = `${textWithoutCaptures}${JSON.stringify(variables)}`
    const textWithReplaceFields = replaceFields(textWithoutCaptures, variables)
    return (
      <TableGroup vars={variables} capturedVars={capturedVars} text={textWithReplaceFields} onChangeVars={onChangeVars} />
    )
  }

  const myFieldsAndOptions = fieldsAndOptions(textFields, options)

  return (
    <>
      {text.includes('<table') && text.includes('version2') && (
        <TableGroup2 examination={examination} variables={variables} phrase={phrase} onSubmit={onVariablesSubmit} />
      )}

      {!text.includes('<table') && !text.includes('[#') && (
        <TextGroup2 examination={examination} variables={variables} phrase={phrase} onSubmit={onVariablesSubmit}  disabled={disabled} fieldsAndOptions={myFieldsAndOptions} fields={fields} onChange={onChange} group={group} />
      )}

      {capturedVars && capturedVars.length > 0 && (
        <Tippy
          content={<CaptureVars capturedVars={capturedVars} vars={vars} onSubmit={vars => { onChangeVars(vars); instanceRef.current.hide() }} />}
          placement='bottom'
          trigger='click'
          theme='light'
          interactive={true}
          inertia={true}
          arrow={false}
          duration={[350, 200]}
          onCreate={instance => instanceRef.current = instance}
        >
          <span>
            <ButtonIcon iconPath='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z' />
          </span>
        </Tippy>
      )}

      {!text.includes('<table') && text.includes('[#') && (
        <>
          <FieldsAndOptions disabled={disabled} fieldsAndOptions={myFieldsAndOptions} fields={fields} onChange={onChange} group={group} phrase={phrase} variables={variables} />
        </>

      )}
    </>
  )
})

export default TextGroup
