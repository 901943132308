import { gql } from '@apollo/client'

export const GET = gql`
  query Document($code: ID!) {
    # account: myAccount {
    #   template
    #   groupsSettings
    #   docSettings
    # }
    document(code: $code) {
      code

      status

      indication
      # uid
      tree
      selectedProtocol
      filters
      selectedModel

      groupsCollapse
      hiddenGroups
      selectedBlocks
      selectedPhrases
      selectedComplementPhrases
      groupsIgnoringSelections

      afterGroup
      fields
      complementFields
      vars
      contentState
      textFactor

      accessionNumber
      PatientIdTag
      PatientName
      PatientAge
      PatientWeight
      PatientHeight
      PatientGender
      PhysicianName
      PhysicianCrm

      clinic {
        id
        name
        uf
        city
        docSettings
        groupsSettings
        defaultEquipment
      }

      # UnitName

      examinedAt
      previousExam1At
      previousExam2At

      signedAt

      # createdAt

      user {
        id
        name
        signatureImage
        crm
        crmUF
        gender
        city
      }

      # order {
      #   PhysicianName
      #   unit {
      #     id
      #     name
      #     city
      #     uf
      #   }
      # }

      # list {
      #   content
      # }

      # gallery {
      #   imageUrl
      #   thumbUrl
      # }

      # images {
      #   imageUrl
      #   thumbUrl
      # }
    }
  }
`
