import React from 'react'
import styled from 'styled-components'

const maskValue = (value = '') => {
  value = value.toString()
  value = value.replace(/\D/g, '')

  if (!value) return ''

  if (value.length === 1 && /\d/.test(value)) return `${value}`
  if (value.length === 2 && /\d{2}/.test(value)) return `${value}`
  if (value.length === 3 && /\d{3}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 1)}`
  if (value.length === 4 && /\d{4}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}`
  if (value.length === 5 && /\d{5}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 1)}`
  if (value.length === 6 && /\d{6}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 2)}`
  if (value.length === 7 && /\d{7}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 3)}`
  if (value.length === 8 && /\d{8}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 4)}`

  return value
}

const unmaskValue = (maskedValue = '') => {
  if (!maskedValue) return ''
  let arrayDate = ['', '', '']
  if (maskedValue.length === 10) {
    arrayDate = maskedValue.split('/')
    return `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`
  } else {
    return ''
  }

}

const InputDateDebounced = React.forwardRef((props, ref) => {

  const { onChange, className, value, defaultValue, placeholder, ...rest } = props

  // const onChangeCallBack = React.useCallback(onChange, [])

  const [maskedValue, setMaskedValue] = React.useState('')
  // const [value, setValue] = React.useState(defaultValue)

  React.useEffect(
    () => {
      let param = value || defaultValue
      if (param && (param.length === 8 || param.length === 10)) {
        param = param.replace(/\D/g, '')
        param = `${param.substr(6, 2)}${param.substr(4, 2)}${param.substr(0, 4)}`
      } else if (param && param.length > 10) {
        param = param.slice(0, 10)
        param = param.replace(/\D/g, '')
        param = `${param.substr(6, 2)}${param.substr(4, 2)}${param.substr(0, 4)}`
      }
      setMaskedValue(maskValue(param))
    },
    [defaultValue, value]
  )

  function handleChange (e) {
    const { target } = e
    const { value: inputValue = 0 } = target

    const value = unmaskValue(inputValue)
    const maskedValue = maskValue(inputValue)

    setMaskedValue(maskedValue)

    if (!onChange || typeof onChange !== 'function') return false
    return onChange(e, value, maskedValue)
  }

  return (<input ref={ref} value={maskedValue} onChange={handleChange} className={className} placeholder={placeholder} {...rest} type='tel' maxLength={10} />)
})

const StyledDateInputDebounced = styled(InputDateDebounced)`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: 1.6;

  padding: 0 4px;
  background-color: white;
  border: 1px solid hsla(0, 0%, 85%, 1);
  border-radius: 4px;
  outline: 0;

  font-size: 12px;

  transition: all 300ms;
  &:hover, &:focus {
    border-color: #40a9ff !important;
    background-color: white;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  &:active {
    border-color: #096dd9 !important;
  }
`

export default StyledDateInputDebounced
