import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends Component {
    constructor(props) {
      super(props)
      this.state = { eventId: null }
    }

    static getDerivedStateFromError() {
      return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
      if (this.state.hasError) {
        if (process.env.NODE_ENV === 'production') {
          Sentry.withScope((scope) => {
            scope.setExtras(errorInfo)
            const eventId = Sentry.captureException(error)
            this.setState({ eventId })
          })
        } else {
          console.error(error)
          console.error(errorInfo)
        }
      }
    }

    render() {
      if (this.state.hasError && process.env.NODE_ENV === 'production') {
        //render fallback UI
        return (
          <div>
            <h1>Ops! Ocorreu um erro.</h1>
            {/* <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button> */}
          </div>
        )
      }

      //when there's not an error, render children untouched
      return this.props.children
    }
}

export default ErrorBoundary
