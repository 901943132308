import React from 'react'
import { useHistory } from 'react-router-dom'
import { css } from 'styled-components/macro'
import { Flex, Box } from '../../components/_new/FlexBox'
import Input from '../../components/_new/Input'
import Text from '../../components/_new/Text'
import Button from '../../components/_new/Button'
import { useAppContext } from '../../contexts/AppContext'
import BigCheckbox from '../../components/_new/BigCheckbox'

function Plans() {
  const history = useHistory()

    const [planMode, setPlanMode] = React.useState('I')
    const [basicQty, setBasicQty] = React.useState('1')
    const [plusQty, setPlusQty] = React.useState('0')
    const [proQty, setProQty] = React.useState('0')

  const { setItemState } = useAppContext()

  const choose = plan => {
    setItemState({ plans: [plan] })
    history.push('/register')
  }

  function handleChangeQty(plan, value) {
    let newValue = '1'
    try {
      const num = Number(value)
      if (num) {
        newValue = num.toString()
        if (num < 1) newValue = '1'
        if (num > 5) newValue = '5'
      }
    } catch (error) {}

    if (plan === 'BASIC') setBasicQty(newValue)
    if (plan === 'PLUS') setPlusQty(newValue)
    if (plan === 'PRO') setProQty(newValue)
  }

  const submit = () => {
    setItemState({ plans: { BASIC: Number(basicQty), PLUS: Number(plusQty), PRO: Number(proQty) } })
    history.push('/register')
  }

  return (
    <Box pt={5} pb={4}>
      <Box textAlign='center' mb={4}>
        <Text fontWeight={800} css={css`font-size: clamp(2rem, 8vw - 2.3rem, 2.3rem); color: hsl(0deg 0% 11%);`}>Planos e Preços</Text>
      </Box>

      <Flex justifyContent='center' mb={4}>
        <BigCheckbox mr={2} checked={planMode === 'I'} onClick={() => setPlanMode('I')}>Conta individual</BigCheckbox>
        <BigCheckbox ml={2} checked={planMode === 'M'} onClick={() => setPlanMode('M')}>Dois ou mais usuários</BigCheckbox>
      </Flex>

      <Flex justifyContent='center' mb={4}>

        <Box p={4} m={3} minWidth={300} css={css`background-color: hsl(220deg 14% 96%); border: 1px solid hsl(210deg 14% 92%); border-radius: 8px;`}>
          <Box mb={2} fontWeight={600} fontSize={18} css={css`color: hsl(0deg 0% 11%);`}>Basic</Box>
          <Box mb={3} fontWeight={400} fontSize={16} css={css`color: hsl(0deg 0% 11%);`}>Ilustrações rápidas</Box>
          <Flex mb={4} alignItems='baseline'>
            <Box fontWeight={800} fontSize={24} css={css`color: hsl(0deg 0% 11%);`}>R$ 59,00</Box>
            <Box>/mês</Box>
          </Flex>
          {planMode === 'M' ? (
            <Flex alignItems='center'>
              <Box mr={2}>Quantidade:</Box>
              <Box><Input width='60px' type='number' value={basicQty} onChange={e => handleChangeQty('BASIC', e.target.value)} /></Box>
            </Flex>
          ) : (
            <Box textAlign='center'>
              <Button onClick={() => choose('BASIC')}>Experimente 15 dias grátis</Button>
            </Box>
          )}
        </Box>

        <Box p={4} m={3} minWidth={300} css={css`background-color: hsl(220deg 14% 96%); border: 1px solid hsl(210deg 14% 92%); border-radius: 8px;`}>
          <Box mb={2} fontWeight={600} fontSize={18} css={css`color: hsl(0deg 0% 11%);`}>Plus</Box>
          <Box mb={3} fontWeight={400} fontSize={16} css={css`color: hsl(0deg 0% 11%);`}>Tabelas e Ilustrações</Box>
          <Flex mb={4} alignItems='baseline'>
            <Box fontWeight={800} fontSize={24} css={css`color: hsl(0deg 0% 11%);`}>R$ 99,00</Box>
            <Box>/mês</Box>
          </Flex>
          {planMode === 'M' ? (
            <Flex alignItems='center'>
              Em breve
              {/* <Box mr={2}>Quantidade:</Box>
              <Box><Input width='60px' type='number' value={plusQty} onChange={e => handleChangeQty('PLUS', e.target.value)} /></Box> */}
            </Flex>
          ) : (
            <Box textAlign='center'>
              <Button disabled onClick={() => choose('PLUS')}>Em breve</Button>
            </Box>
          )}
        </Box>

        <Box p={4} m={3} minWidth={300} css={css`background-color: hsl(220deg 14% 96%); border: 1px solid hsl(210deg 14% 92%); border-radius: 8px;`}>
          <Box mb={2} fontWeight={600} fontSize={18} css={css`color: hsl(0deg 0% 11%);`}>Pro</Box>
          <Box mb={3} fontWeight={400} fontSize={16} css={css`color: hsl(0deg 0% 11%);`}>Laudos, tabelas e ilustrações</Box>
          <Flex mb={4} alignItems='baseline'>
            <Box fontWeight={800} fontSize={24} css={css`color: hsl(0deg 0% 11%);`}>R$ 199,00</Box>
            <Box>/mês</Box>
          </Flex>
          {planMode === 'M' ? (
            <Flex alignItems='center'>
              Em breve
              {/* <Box mr={2}>Quantidade:</Box>
              <Box><Input width='60px' type='number' value={proQty} onChange={e => handleChangeQty('PRO', e.target.value)} /></Box> */}
            </Flex>
          ) : (
            <Box textAlign='center'>
              <Button disabled onClick={() => choose('PRO')}>Em breve</Button>
            </Box>
          )}
        </Box>

      </Flex>

      {planMode === 'M' && (
        <Box textAlign='center'>
          <Button onClick={() => submit()}>Continuar</Button>
        </Box>
      )}

    </Box>
  )
}

export default Plans
