import styled, { css } from 'styled-components/macro'
import { Box } from '@alobato/flex-box'

export default styled(Box)`
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  ${props => props.uppercase && css`text-transform: uppercase;`}
  ${props => props.small && css`font-size: 13px;`}
  & > span {
    color: hsla(216, 40%, 70%, 1);
  }
`
