import React from 'react'
import { CompositeDecorator, Entity } from 'draft-js'

const Field = props => (
  <span style={{color: 'hsla(240, 90%, 50%, 1)'}} data-offset-key={props.offsetKey}>{props.children}</span>
)

const After = props => (
  <span style={{color: 'lightseagreen'}} data-offset-key={props.offsetKey}>{props.children}</span>
)

const Dictation = props => (
  <span style={{color: 'hsla(18, 90%, 50%, 1)'}} data-offset-key={props.offsetKey}>{props.children}</span>
)

const Nulo = props => (
  <span data-offset-key={props.offsetKey} role='img' aria-label='blank'>❌</span>
)

const Blank = props => (
  <span data-offset-key={props.offsetKey} role='img' aria-label='blank'>❗</span>
)

// https://draftjs.org/docs/advanced-topics-decorators/
// const AsteriskBold = props => {
//   return (
//     <span style={{ fontWeight: 'bold' }} data-offset-key={props.offsetKey}>
//       {props.children[0].props.text.replace(/\*\*/g, '')}
//     </span>
//   )
// }

// function findWithRegex(regex, contentBlock, callback) {
//   const text = contentBlock.getText();
//   let matchArr, start;
//   while ((matchArr = regex.exec(text)) !== null) {
//     start = matchArr.index;
//     callback(start, start + matchArr[0].length);
//   }
// }

// function findAsteriskBold(contentBlock, callback, contentState) {
//   findWithRegex(/\*\*.+?\*\*/g, contentBlock, callback)
// }

function findDictations(contentBlock, callback) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (entityKey !== null && Entity.get(entityKey).getType() === 'DICTATION')
  }, callback)
}

function findFields(contentBlock, callback) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (entityKey !== null && Entity.get(entityKey).getType() === 'FIELD')
  }, callback)
}

function findNulos(contentBlock, callback) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (entityKey !== null && Entity.get(entityKey).getType() === 'NULO')
  }, callback)
}

function findBlanks(contentBlock, callback) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (entityKey !== null && Entity.get(entityKey).getType() === 'BLANK')
  }, callback)
}

function findAfters(contentBlock, callback) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (entityKey !== null && Entity.get(entityKey).getType() === 'AFTER')
  }, callback)
}

const decorator = new CompositeDecorator([
  { strategy: findDictations, component: Dictation },
  { strategy: findFields, component: Field },
  { strategy: findNulos, component: Nulo },
  { strategy: findBlanks, component: Blank },
  { strategy: findAfters, component: After },
  // { strategy: findAsteriskBold, component: AsteriskBold }
])

export default decorator
