import React, { memo, useState, useCallback } from 'react'
import { css } from 'styled-components/macro'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { EditorState, convertFromRaw, convertToRaw, ContentBlock, ContentState } from 'draft-js'
import { Map } from 'immutable'
// import { EditorState, ContentState, ContentBlock, SelectionState, Modifier } from 'draft-js'

import Spin from '../../components/Spin'
import Wrapper from '../../components/Wrapper'
import Card from '../../components/Card'
import Title from '../../components/Title'
import { LockOutlineIcon } from '../../components/Icons'
import ButtonIcon from '../../components/ButtonIcon'
import CircularProgressInline from '../../components/CircularProgressInline'
import Modal from '../../components/StyledModal'
import { formatDateFromIsoString } from '../../utils/date'
import { getCurrentBlock } from '../../utils/draft'

import Editor from '../../components/EditorExtended'

import { useTranslation } from 'react-i18next'
import useDebounce from '@alobato/use-debounce'
import useLocalStorage from '../../hooks/useLocalStorage'
import useDidMount from '../../hooks/useDidMount'
import useIgnoreMount from '../../hooks/useIgnoreMount'

import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { GET, UPDATE, SIGN, UNSIGN, CORRECT } from './Gqls'

import CleanHtml from './CleanHtml'
import CleanHtml2 from './CleanHtml2'
import Illustrations from '../Step3/Illustrations'
// import PreviewPdf from './PreviewPdf'

import decorator from './decorator'

import mount from './mount'


const genderText = gender => {
  if (!gender) return ''
  if (gender.toUpperCase() === 'F') return 'Feminino'
  if (gender.toUpperCase() === 'M') return 'Masculino'
  return ''
}

// const clearDisplay = text => {
//   text = text.replace(/#.+?#/, '')
//   text = text.replace(/<.+?>/, '')
//   return text
// }

const defaultInitialValues = {
  'TÍTULO': {
    active: true,
    title: 'Título',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 0,
  },
  'INDICAÇÃO CLÍNICA': {
    active: true,
    title: 'Indicação Clínica:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 1,
  },
  'INTRODUÇÃO': {
    active: true,
    title: 'Introdução:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 2,
  },
  'TABELAS E ILUSTRAÇÕES': {
    active: true,
    title: 'Tabelas e Ilustrações',
    titleIsBold: true,
    formatting: 'WITHBREAK',
    order: 3,
  },
  'ANÁLISE': {
    active: true,
    title: 'Análise',
    titleIsBold: true,
    formatting: 'WITHBREAK',
    order: 4,
  },
  'IMPRESSÃO': {
    active: true,
    title: 'Impressão:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 5,
  },
  'OBSERVAÇÃO': {
    active: true,
    title: 'Achados adicionais:',
    titleIsBold: true,
    formatting: 'SAMELINE',
    order: 6,
  },
  'NOTA': {
    active: true,
    title: '',
    titleIsBold: false,
    formatting: 'WITHBREAK',
    order: 7,
  }
}




const Home = memo(({ data: { examination: initialExamination }, code, notifier, me }) => {

  const substitutions = me.preferences && me.preferences.speechRecognition && me.preferences.speechRecognition.substitutions ? me.preferences.speechRecognition.substitutions : []

  const { t } = useTranslation()

  const groupsSettings = initialExamination.clinic.groupsSettings || defaultInitialValues
  const docSettings = initialExamination.clinic.docSettings

  const defaultIllustrationSettings = {
    titleBackgroundColor: '#6b6b6b',
    titleTextColor: '#ffffff',
    titleBorderColor: '#878787'
  }

  const titleBackgroundColor = initialExamination.clinic && initialExamination.clinic.illustrationSettings && initialExamination.clinic.illustrationSettings.titleBackgroundColor ? initialExamination.clinic.illustrationSettings.titleBackgroundColor : defaultIllustrationSettings.titleBackgroundColor
  const titleTextColor = initialExamination.clinic && initialExamination.clinic.illustrationSettings && initialExamination.clinic.illustrationSettings.titleTextColor ? initialExamination.clinic.illustrationSettings.titleTextColor : defaultIllustrationSettings.titleTextColor
  const titleBorderColor = initialExamination.clinic && initialExamination.clinic.illustrationSettings && initialExamination.clinic.illustrationSettings.titleBorderColor ? initialExamination.clinic.illustrationSettings.titleBorderColor : defaultIllustrationSettings.titleBorderColor
  const zoom = initialExamination.clinic && initialExamination.clinic.illustrationSettings && initialExamination.clinic.illustrationSettings.zoom ? Number(initialExamination.clinic.illustrationSettings.zoom) : 2

  const [documentTheme] = useState({
    paper: docSettings.paper,

    color: docSettings.color,

    fontSize: docSettings.fontSize || 12,
    fontFamily: docSettings.fontFamily || 'Arial',
    lineHeight: docSettings.lineHeight || 1.2,

    topMargin: docSettings.topMargin,
    bottomMargin: docSettings.bottomMargin,
    leftMargin: docSettings.leftMargin,
    rightMargin: docSettings.rightMargin,

    showPageNumber: docSettings.showPageNumber,
    pageNumberDistanceFromBottom: docSettings.pageNumberDistanceFromBottom,
    pageNumberAlign: 'right',
    pageNumberColor: 'black',
    pageNumberFontSize: docSettings.fontSize || 12,
    pageNumberFontFamily: docSettings.fontFamily || 'Arial',
    pageNumberFontWeight: 700,

    showSignature: docSettings.showSignature,
    signatureText: docSettings.signatureText,
    signatureSettings: docSettings.signatureSettings,
    signatureAlign: docSettings.signatureAlign,
    signatureAdditionalText: docSettings.signatureAdditionalText,
    signatureAdditionalImage: docSettings.signatureAdditionalImage,
    signatureMarginTop: docSettings.signatureMarginTop,

    showTop: docSettings.showTop,
    topHeight: docSettings.topHeight,
    topImageUrl: docSettings.topImageUrl,

    showBottom: docSettings.showBottom,
    bottomHeight: docSettings.bottomHeight,
    bottomImageUrl: docSettings.bottomImageUrl,

    showData: docSettings.showData,
    dataHeight: docSettings.dataHeight,
    dataBgColor: docSettings.dataBgColor,
    dataBorderColor: docSettings.dataBorderColor,
    dataBorderWidth: docSettings.dataBorderWidth,
    dataColor: docSettings.dataColor,
    dataFontFamily: docSettings.dataFontFamily,
    dataFontSize: docSettings.dataFontSize,
    dataHasBg: docSettings.dataHasBg,
    dataHasBorder: docSettings.dataHasBorder,
    dataLineHeight: docSettings.dataLineHeight,

    // titleIsBold: docSettings.titleIsBold,
    // titleFontSize: docSettings.titleFontSize,
    // titleAlign: docSettings.titleAlign,

    // pageDistanceFromBottom: 30,
    // pageAlign: 'right',
    // pageColor: 'black',
    // pageFontSize: 12,

    // headerHeight: 50,
    // headerFontSize: 12,
    // headerLineHeight: 1.5,
    // headerFontFamily: 'Arial',
    // headerColor: 'black',
    // headerBackgroundColor: '#E4E4E4',
    // headerBorderStyle: 'solid',
    // headerBorderColor: 'black',
    // headerBorderWidth: 2,
  })

  const [examination, setExamination] = useState(initialExamination)
  const client = useApolloClient()

  let examinationSigned = examination.signedAt ? true : false
  const [signed, setSigned] = useState(examinationSigned)
  // if (!examination.correctedAt && examination.underCorrectionAt) signed = false

  const [updateExamination] = useCallback(useMutation(UPDATE), [UPDATE])
  const [signExamination] = useCallback(useMutation(SIGN), [SIGN])
  const [unsignExamination] = useCallback(useMutation(UNSIGN), [UNSIGN])
  const [correctExamination] = useCallback(useMutation(CORRECT), [CORRECT])

  // const [uploadDoc] = useCallback(useMutation(UPLOAD_DOC), [UPLOAD_DOC])

  const save = useCallback(async ({ variables }) => {
    if (signed || !me.isDoctor) return false
    const steps = (examination.status && examination.status.steps) ? examination.status.steps : []
    const newSteps = steps.includes(3) ? steps : [...steps, 3]
    const newStatus = { ...(examination.status || {}), steps: newSteps }
    try {
      await updateExamination({ variables: { ...variables, status: newStatus }})
    } catch(err) {
      console.error(err)
    }
  }, [updateExamination, examination.status, signed, me.isDoctor])

  let { contentState } = examination

  const [loading, setLoading] = useState(false)

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(decorator))
  const debouncedEditorState = useDebounce(editorState, 100)

  const [cleanHtmlModalOpen, setCleanHtmlModalOpen] = useState(false)
  const [cleanHtml, setCleanHtml] = useState('')
  const [cleanHtml2ModalOpen, setCleanHtml2ModalOpen] = useState(false)

  const [illustrationModalOpen, setIllustrationModalOpen] = useState(false)

  const [previewOpen, setPreviewOpen] = useLocalStorage('previewOpen', true)

  // const blobRef = useRef(null)

  const mountContent = useCallback(mount, [])

  useDidMount(() => {
    let newEditorState
    if (contentState) {
      newEditorState = EditorState.createWithContent(convertFromRaw(contentState), decorator)

      const newContentState = newEditorState.getCurrentContent()
      let blocks = newContentState.getBlocksAsArray()
      console.log('blocks1', blocks)
      blocks = blocks.map(block => ({ key: block.getKey(), text: block.getText(), type: block.getType(), data: block.getData().toObject() })).filter(block => block.type === 'image')
      blocks = [...blocks, { key: Math.random().toString(36).substr(2, 6), text: '', type: 'image', data: { src: '123' } }]
      console.log('blocks', blocks)

    } else {
      newEditorState = mountContent(decorator, examination, docSettings, groupsSettings, zoom)

      const newContentState = newEditorState.getCurrentContent()
      let blocks = newContentState.getBlocksAsArray()
      console.log('blocks1', blocks)
      blocks = blocks.map(block => ({ key: block.getKey(), text: block.getText(), type: block.getType(), data: block.getData().toObject() })).filter(block => block.type === 'image')
      blocks = [...blocks, { key: Math.random().toString(36).substr(2, 6), text: '', type: 'image', data: { src: '123' } }]
      console.log('blocks', blocks)

      // acc = [...acc, new ContentBlock({ key: `${key}--T${index}`, type: 'image', data: Map({ src: `https://p.telerison.com/pup?c=.left%20%7B%0A%20%20text-align%3A%20left%3B%0A%7D%0A.right%20%7B%0A%20%20text-align%3A%20right%3B%0A%7D%0A.justify%20%7B%0A%20%20text-align%3A%20justify%3B%0A%7D%0A.center%20%7B%0A%20%20text-align%3A%20center%3B%0A%7D%0A.small%20%7B%0A%20%20font-size%3A%209px%3B%20text-align%3A%20justify%3B%0A%7D%0Atable%20%7B%0A%20%20color%3A%20black%3B%0A%20%20font-family%3A%20Arial%2C%20sans-serif%3B%0A%20%20font-size%3A%2011px%3B%0A%20%20width%3A%20648px%3B%0A%20%20border-collapse%3A%20collapse%3B%0A%20%20border-spacing%3A%200%3B%0A%7D%0Atd%2C%20th%20%7B%0A%20%20padding%3A%204px%3B%0A%20%20border%3A%201px%20solid%20black%3B%0A%20%20background%3A%20white%3B%0A%7D%0Ath%20%7B%0A%20%20text-align%3A%20center%3B%0A%20%20font-weight%3A%20bold%3B%0A%7D%0Atr%3Anth-child(even)%20td%20%7B%0A%20%20background%3A%20white%3B%0A%7D%0A.bmk%20%7B%0A%20%20padding%3A%203px%3B%0A%20%20background-color%3A%20%23476A91%3B%0A%20%20border-top%3A%204px%20solid%20%23D1783D%3B%0A%20%20border-bottom%3A%204px%20solid%20%23D1783D%3B%0A%20%20color%3A%20white%3B%0A%20%20border-right%3A%201px%20solid%20%23476A91%3B%0A%20%20border-left%3A%201px%20solid%20%23476A91%3B%0A%20%20text-align%3A%20center%3B%0A%7D&h=${encodeURIComponent(item)}` }) })]

      // getCurrentContent()
      // getBlocksAsArray
      // let blocks = editorState.getCurrentContent().getBlocksAsArray().map(block => ({ text: block.getText(), type: block.getType(), data: block.getData().toObject() }))
      // acc = [...acc, new ContentBlock({ key: `${item.key}--T`, type: 'unstyled', text: item.title, data: Map({}) })]
      // let newContentState = ContentState.createFromBlockArray(formatedBlockArray)
      // EditorState.createWithContent(newContentState, decorator)
      // acc = [...acc, ...newBlocks, new ContentBlock({ key: `${shortKey('ANÁLISE')}--B`, type: 'unstyled', text: '', data: Map({}) })]

    }
    return setEditorState(newEditorState)
  })

  useIgnoreMount(debouncedEditorState, async newDebouncedEditorState => {
    const contentState = convertToRaw(newDebouncedEditorState.getCurrentContent())
    await save({ variables: { code, contentState } })
  })



  // #region handles
  const handleCopyCleanHtml = () => {
    let html = document.getElementsByClassName('DraftEditor-editorContainer')[0].innerHTML
    html = html.replace(/\s?❌/g, '')

    setCleanHtml(html)
    setCleanHtmlModalOpen(true)
  }

  const handleRemount = async () => {
    if (window.confirm('Você perderá as modificações realizadas no editor. Tem certeza que deseja remontar?')) {
      setLoading(true)
      client.query({ query: GET, fetchPolicy: 'network-only', variables: { code } } ).then(result => {
        setExamination(result.data.examination)
        const newContentState = mountContent(decorator, result.data.examination, docSettings, groupsSettings, zoom)
        setEditorState(newContentState)
        setLoading(false)
      })
    }
  }

  const handleSign = async () => {

    // OpenPendenciesQty
    // ClosedPendenciesQty

    // const file = new File([blobRef.current], `${code}.pdf`)

    // try {
    //   const data = await uploadDoc({ variables: { file } })
    //   notifier.success('Laudo assinado com sucesso.')
    // } catch(err) {
    //   console.error(err)
    // }

    if (!!initialExamination.OpenPendenciesQty) {
      notifier.error('Você não pode assinar este laudo pois existe pendência.')
      return false
    }

    try {
      const result = await signExamination({ variables: { code } })
      if (result) {
        setExamination(currentExamination => ({ ...currentExamination, signedAt: result.data.signExamination.signedAt }))
        notifier.success(t('Laudo assinado com sucesso!'))
        // setSigned(true)
        setTimeout(() => { window.location.reload() }, 1000)
      }

    } catch(err) {
      console.error(err)
      notifier.error('Erro ao tentar assinar')
    }

  }

  const handleCorrect = async () => {
    try {
      const result = await correctExamination({ variables: { code } })
      if (result) {
        setExamination(currentExamination => ({ ...currentExamination, correctedAt: result.data.correctExamination.correctedAt }))
        notifier.success(t('Laudo salvo com sucesso!'))
      }
    } catch(err) {
      console.error(err)
      notifier.error('Erro ao tentar salvar')
    }
  }

  const handleUnsign = async () => {

    try {
      const result = await unsignExamination({ variables: { code } })
      if (result) {
        setExamination(currentExamination => ({ ...currentExamination, signedAt: null }))
        // setSigned(false)
        notifier.success(t('Laudo desassinado com sucesso!'))
        setTimeout(() => { window.location.reload() }, 1000)
      }
    } catch(err) {
      console.error(err)
      notifier.error('Erro ao tentar desassinar')
    }

  }

  const handleChangeEditor = React.useCallback(value => {
    if (signed) return false
    setEditorState(value)
  }, [signed, setEditorState])

  const handleAddIllustration = src => {
    // const selectionState = editorState.getSelection()
    // if (!selectionState.isCollapsed()) return null

    // let contentState = editorState.getCurrentContent()
    // const key = selectionState.getStartKey()
    // const blockMap = contentState.getBlockMap()
    // const currentBlock = getCurrentBlock(editorState)

    // if (!currentBlock) return null
    // if (currentBlock.getLength() === 0) {
    //   if (currentBlock.getType() === 'image') return null

    //   const newBlock = currentBlock.merge({type: 'image', data: {src}})
    //   const newContentState = contentState.merge({blockMap: blockMap.set(key, newBlock), selectionAfter: selectionState})
    //   setEditorState(EditorState.push(editorState, newContentState, 'change-block-type'))
    // }


    const contentState = editorState.getCurrentContent()
    let blocks = contentState.getBlocksAsArray()
    const key = Math.random().toString(36).substr(2, 6)
    const newBlock = new ContentBlock({ key, type: 'image', data: Map({ src }) })
    blocks = [newBlock, ...blocks]
    let newContentState = ContentState.createFromBlockArray(blocks)
    const newEditorState = EditorState.createWithContent(newContentState, decorator)
    return setEditorState(newEditorState)

  }
  // #endregion


  let headerContent = docSettings.dataHtml

  headerContent = headerContent.replace('[NOME_UNIDADE]', examination.order && examination.order.unit ? examination.order.unit.name : examination.clinicName)
  headerContent = headerContent.replace('[NOME_PACIENTE]', examination.PatientName)
  headerContent = headerContent.replace('[IDADE]', examination.PatientAge ? examination.PatientAge : '')
  headerContent = headerContent.replace('[SEXO]', genderText(examination.PatientGender))
  headerContent = headerContent.replace('[ACCESSION_NUMBER]', examination.accessionNumber ? examination.accessionNumber : '')
  headerContent = headerContent.replace('[PATIENT_ID]', examination.PatientIdTag ? examination.PatientIdTag : '')
  headerContent = headerContent.replace('[MEDICO_SOLICITANTE]', examination.PhysicianName ? examination.PhysicianName : '')
  headerContent = headerContent.replace('[DATA_EXAME]', formatDateFromIsoString(examination.examinedAt))

  const handlePreviewOpen = React.useCallback(() => {
    setPreviewOpen(currentPreviewOpen => !currentPreviewOpen)
  }, [setPreviewOpen])

  const handleIllustrationModalOpen = React.useCallback(() => {
    setIllustrationModalOpen(currentIllustrationModalOpen => !currentIllustrationModalOpen)
  }, [setIllustrationModalOpen])

  const handleTextFactorChange = async textFactor => {
    await save({ variables: { code, textFactor } })
    setExamination(currentExamination => ({ ...currentExamination, textFactor }))
  }

  let values = Object.values(JSON.parse(JSON.stringify(editorState._immutable.currentContent.blockMap)))
  values = values.filter(item => !!item?.data?.src).map(item => item.data.src)

  if (!me.isDoctor) {
    return (
      <Wrapper>
        <Card>

          <Box mb={4}>
            <Title>{t('Etapa 3')}</Title>
          </Box>

          <Box>
            Somente usuários médicos podem acessar a Etapa 3.
          </Box>
        </Card>
      </Wrapper>
    )
  }

  return (
    <>
      <Box>
        {signed &&
          <Box boxShadow='0px 1px 6px 0px hsla(0, 0%, 20%, 0.1)' h={32} ta='center' bg='hsla(54, 77%, 94%, 1)' p={1} zIndex={2} position='fixed' width={1}><Text display='inline-block' lineHeight='16px' verticalAlign='-2px' mr={1}><LockOutlineIcon height={16} /></Text><Text display='inline-block' lineHeight='16px'>Este Laudo está assinado e não pode ser editado.</Text></Box>
        }

        <Box px={3} pt={signed ? 5 : 3}>
          <Box>

            <Flex mb={3} ai='center' jc='space-between'>
              <Flex>
                <Box>
                  <Title>{t('Tabela - Etapa 3')}</Title>
                </Box>
                <Flex ai='center' ml={3}>{loading && <CircularProgressInline />}</Flex>
              </Flex>
              <Box lh={0}>
                <Flex ai='center'>
                  <Box mx={1}><ButtonIcon title='Texto Limpo' onClick={handleCopyCleanHtml} iconPath='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' /></Box>
                  {/*<Box mx={1}><ButtonIcon title='Texto Limpo2' onClick={() => setCleanHtml2ModalOpen(true)} iconPath='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' /></Box>*/}
                  <Box mx={1}><ButtonIcon iconProps={{ height: 28 }} title='Remontar' onClick={handleRemount} iconPath='M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z' /></Box>
                  <Box mr={2}><ButtonIcon title='Adicionar Ilustração' onClick={() => handleIllustrationModalOpen()} selected={illustrationModalOpen} iconPath='M19,19H5V5H19M19,3H5C3.9,3 3,3.9 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.9 20.1,3 19,3M13.96,12.29L11.21,15.83L9.25,13.47L6.5,17H17.5L13.96,12.29Z' /></Box>

                  <Box mx={1}>
                    {examination.orderAvailableAt && !examination.underCorrectionAt && !examination.correctedAt && <>Este exame já foi liberado. Para correção, solicite a operação.</>}
                    {!examination.underCorrectionAt && examination.correctedAt && <>Exame corrigido. Para corrigir novamente, solicite a operação.</>}
                    {examination.signedAt && examination.orderAvailableAt && examination.underCorrectionAt && <Text underline pointer onClick={() => { window.confirm('Você tem certeza que deseja desassinar este laudo?') && handleUnsign() }}>Desassinar</Text>}
                    {/*examination.signedAt && examination.orderAvailableAt && examination.underCorrectionAt && !examination.correctedAt && <ButtonIcon iconProps={{ viewBox: '0 0 24 24', height: 30 }} title='Salvar' onClick={() => { window.confirm('Você tem certeza que deseja confirmar a correção deste laudo?') && handleCorrect() }} iconPath='M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z' />*/}
                  </Box>

                </Flex>
              </Box>
            </Flex>

            <Flex>
              <Box css={css`& .DraftEditor-root { height: ${signed ? `calc(100vh - 169px)` : `calc(100vh - 180px)`} !important; }`} flex={1} mr={3} style={{border: '1px solid hsla(216, 20%, 85%, 1)'}}>
                <div className='DraftEditor-editorContainer'>
                  {values.map(src => (
                    <div>
                      <img src={src} alt='' />
                    </div>
                  ))}
                  {/* <Editor
                    pasteImageEnabled={!illustrationModalOpen}
                    disabled={signed}
                    editorState={editorState}
                    onChange={handleChangeEditor}
                    disableFontSize
                    onAddIllustrationClick={handleIllustrationModalOpen}
                    onPreviewClick={handlePreviewOpen}
                    illustrationSelected={illustrationModalOpen}
                    previewSelected={previewOpen}
                    substitutions={substitutions}
                    onTextFactorChange={handleTextFactorChange}
                    textFactor={examination.textFactor}
                  /> */}
                </div>
              </Box>

              {/*previewOpen &&
                <Box width={420}>
                  <PreviewPdf
                    me={me}
                    notifier={notifier}
                    contentState={debouncedEditorState.getCurrentContent()}
                    examination={examination}
                    headerContent={headerContent}
                    documentTheme={documentTheme}
                  />
                </Box>
              */}

            </Flex>

          </Box>
        </Box>
      </Box>

      {cleanHtmlModalOpen &&
        <Modal
          onCloseCompleted={() => setCleanHtmlModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <CleanHtml
                onRequestClose={onRequestClose}
                html={cleanHtml}
                examination={examination}
              />
            )
          }
        />
      }

      {cleanHtml2ModalOpen &&
        <Modal
          onCloseCompleted={() => setCleanHtml2ModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <CleanHtml2
                onRequestClose={onRequestClose}
                examination={examination}
              />
            )
          }
        />
      }

      {illustrationModalOpen &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => setIllustrationModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <Illustrations
                gallery={initialExamination.gallery}
                images={initialExamination.images}
                onRequestClose={onRequestClose}
                onCompleted={src => { handleAddIllustration(src); setIllustrationModalOpen(false) }}
                titleBackgroundColor={titleBackgroundColor}
                titleTextColor={titleTextColor}
                titleBorderColor={titleBorderColor}
                zoom={zoom}
              />
            )
          }
        />
      }

    </>
  )
})

const HomeQuery = ({ code, me, notifier }) => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET, { fetchPolicy: 'network-only', variables: { code } })
  if (loading) return <Spin />
  if (error) return error.message
  if (!data.examination) return t('Não encontrado')
  return <Home me={me} notifier={notifier} code={code} data={data} />
}

export default memo(HomeQuery)
