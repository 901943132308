import React, { memo } from 'react'
import CircularProgress from '@alobato/circular-progress'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import { CloseIcon } from '../../components/Icons'

import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { GET } from './Gqls'

const Show = memo(({ user: item, onRequestClose }) => {

  const { t } = useTranslation()

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Detalhes')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={3}>
          <Box>{t('Nome')}</Box>
          <Box>{item.name}</Box>
        </Box>

        <Box mb={3}>
          <Box>{t('Email')}</Box>
          <Box>{item.email}</Box>
        </Box>

        <Box mb={3}>
          <Box>{t('Celular')}</Box>
          <Box>{item.cellPhoneNumber}</Box>
        </Box>

        <Box mb={3}>
          <Box>{t('Permissão')}</Box>
          <Box>{item.role}</Box>
        </Box>

        <Box mb={3}>
          <Box>{t('Médico?')}</Box>
          <Box>{item.isDoctor ? t('Sim') : t('Não')}</Box>
        </Box>

        {item.isDoctor &&
          <Box>
            <Box mb={3}>
              <Box>{t('Especialidade')}</Box>
              <Box>{item.specialty}</Box>
            </Box>

            <Box mb={3}>
              <Box>{t('CRM')}</Box>
              <Box>{item.crm}</Box>
            </Box>

            <Box mb={3}>
              <Box>{t('CRM UF')}</Box>
              <Box>{item.crmUF}</Box>
            </Box>
          </Box>
        }

      </Box>
    </Flex>
  )
})

const ShowQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <CircularProgress />
  if (error) return error.message
  return <Show user={data.user} onRequestClose={onRequestClose} />
}

export default memo(ShowQuery)
