import React from 'react'

export const IconPdf = (props) => (
  <svg width='100' viewBox="0 0 56 56" {...props}>
<g>
	<path style={{fill: '#E9E9E0'}} d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
		c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z" />
	<polygon style={{fill: '#D9D7CA'}} points="37.5,0.151 37.5,12 49.349,12 	"/>
	<path style={{fill: '#CC4B4C'}} d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
		c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
		c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
		c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
		c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
		c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
		c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
		c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
		 M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
		c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
		c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
		 M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"/>
	<path style={{fill: '#CC4B4C'}} d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"/>
	<g>
		<path style={{fill: '#FFFFFF'}} d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
			c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
			c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
			s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
			c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
			c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
			c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"/>
		<path style={{fill: '#FFFFFF'}} d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
			s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
			c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
			 M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
			c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"/>
		<path style={{fill: '#FFFFFF'}} d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"/>
	</g>
  </g>
  </svg>
)

export const GroupIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z' />
  </svg>
)

export const ContentDuplicateIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M11,17H4A2,2 0 0,1 2,15V3A2,2 0 0,1 4,1H16V3H4V15H11V13L15,16L11,19V17M19,21V7H8V13H6V7A2,2 0 0,1 8,5H19A2,2 0 0,1 21,7V21A2,2 0 0,1 19,23H8A2,2 0 0,1 6,21V19H8V21H19Z' />
  </svg>
)

export const PdfIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M12,10.5H13V13.5H12V10.5M7,11.5H8V10.5H7V11.5M20,6V18A2,2 0 0,1 18,20H6A2,2 0 0,1 4,18V6A2,2 0 0,1 6,4H18A2,2 0 0,1 20,6M9.5,10.5A1.5,1.5 0 0,0 8,9H5.5V15H7V13H8A1.5,1.5 0 0,0 9.5,11.5V10.5M14.5,10.5A1.5,1.5 0 0,0 13,9H10.5V15H13A1.5,1.5 0 0,0 14.5,13.5V10.5M18.5,9H15.5V15H17V13H18.5V11.5H17V10.5H18.5V9Z' />
  </svg>
)

export const HistoryIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3' />
  </svg>
)

export const FileEyeOutlineIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M17,18C17.56,18 18,18.44 18,19C18,19.56 17.56,20 17,20C16.44,20 16,19.56 16,19C16,18.44 16.44,18 17,18M17,15C14.27,15 11.94,16.66 11,19C11.94,21.34 14.27,23 17,23C19.73,23 22.06,21.34 23,19C22.06,16.66 19.73,15 17,15M17,21.5A2.5,2.5 0 0,1 14.5,19A2.5,2.5 0 0,1 17,16.5A2.5,2.5 0 0,1 19.5,19A2.5,2.5 0 0,1 17,21.5M9.27,20H6V4H13V9H18V13.07C18.7,13.15 19.36,13.32 20,13.56V8L14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H10.5C10,21.41 9.59,20.73 9.27,20Z' />
  </svg>
)

export const DragIndicatorIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' />
  </svg>
)

export const DragVerticalIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M9,3H11V5H9V3M13,3H15V5H13V3M9,7H11V9H9V7M13,7H15V9H13V7M9,11H11V13H9V11M13,11H15V13H13V11M9,15H11V17H9V15M13,15H15V17H13V15M9,19H11V21H9V19M13,19H15V21H13V19Z' />
  </svg>
)

export const AttachFileIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z' />
  </svg>
)

export const NoteTextOutline = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M15 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V9L15 3M19 19H5V5H14V10H19M17 14H7V12H17M14 17H7V15H14' />
  </svg>
)

export const GenericIcon = ({ iconPath, ...rest }) => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...rest}>
    <path d={iconPath} />
  </svg>
)

export const MenuIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z' />
  </svg>
)

export const AvailableIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M13 9h5.5L13 3.5V9M6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m9 16v-2H6v2h9m3-4v-2H6v2h12z' />
  </svg>
)

export const SignIcon = props => (
  <svg viewBox='0 0 1024 1024' height={24} fill='currentColor' {...props}>
    <path d='M799.773 119.293l-36.759-13.788-117.78 314.080 36.759 13.785 117.78-314.077zM467.314 822.453c-0.788 0.278-1.572 0.57-2.346 0.882 0.281-1.406 0.54-2.823 0.771-4.253h-39.93c-5.392 22.869-19.927 39.26-37.017 39.26-17.097 0-31.628-16.39-37.018-39.26h-2.242v-104.614c-0.234 0.169-0.471 0.33-0.702 0.501 0.237-0.491 0.466-0.991 0.702-1.483v-12.186h-37.158c-43.805 91.353-105.302 147.284-153.869 132.24-61.975-19.202-79.424-146.7-38.974-284.781 40.45-138.082 123.485-234.458 185.465-215.26 37.514 11.621 58.707 62.929 60.627 132.24h37.674c-3.43-87.567-34.365-154.388-87.035-170.703-82.635-25.598-187.463 82.807-234.14 242.132-46.673 159.325-17.522 309.236 65.113 334.834 52.901 16.387 114.893-22.158 164.903-93.812-1.211 6.848-1.868 13.956-1.868 21.26 0 54.206 35.154 98.15 78.521 98.15 14.442 0 27.967-4.884 39.593-13.388 3.118 29.588 28.14 52.649 58.555 52.649 25.638 0 47.442-16.39 55.53-39.26h-75.156v-75.15zM933.429 96.501l-34.11-12.78c-28.252-10.585-58.050 0.334-66.558 24.388l-82.128 232.283c-8.5 24.054 7.507 52.133 35.762 62.722l34.105 12.775c28.256 10.588 58.054-0.33 66.558-24.385l82.128-232.283c8.509-24.053-7.506-52.132-35.758-62.721zM805.398 426.586l-36.893-13.427c-30.564-11.126-62.796 0.346-71.995 25.621l-111.415 301.78c-9.202 25.276-36.806 182.803 0 196.301 30.121 13.561 116.414-82.003 130.885-121.765l128.098-322.605c9.201-25.277-8.121-54.785-38.679-65.907z' />
  </svg>
)

export const LockOutlineIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z' />
  </svg>
)

export const PlaylistEditIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M2,6V8H14V6H2M2,10V12H14V10H2M20.04,10.13C19.9,10.13 19.76,10.19 19.65,10.3L18.65,11.3L20.7,13.35L21.7,12.35C21.92,12.14 21.92,11.79 21.7,11.58L20.42,10.3C20.31,10.19 20.18,10.13 20.04,10.13M18.07,11.88L12,17.94V20H14.06L20.12,13.93L18.07,11.88M2,14V16H10V14H2Z' />
  </svg>
)

export const ReorderIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M3 15h18v-2H3v2zm0 4h18v-2H3v2zm0-8h18V9H3v2zm0-6v2h18V5H3z' />
  </svg>
)

export const DoneIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' />
  </svg>
)

export const DoneAllIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z' />
  </svg>
)

export const HourglassFullIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6z' />
  </svg>
)

export const FolderOutlineIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z' />
  </svg>
)

export const FolderSyncOutlineIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M10,4L12,6H20A2,2 0 0,1 22,8V11.5C21.38,11.24 20.7,11.07 20,11V8H4V18H13C13.07,18.7 13.24,19.38 13.5,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10M19,12V13.5A4,4 0 0,1 23,17.5C23,18.32 22.75,19.08 22.33,19.71L21.24,18.62C21.41,18.28 21.5,17.9 21.5,17.5A2.5,2.5 0 0,0 19,15V16.5L16.75,14.25L16.72,14.22C16.78,14.17 16.85,14.13 19,12M19,23V21.5A4,4 0 0,1 15,17.5C15,16.68 15.25,15.92 15.67,15.29L16.76,16.38C16.59,16.72 16.5,17.1 16.5,17.5A2.5,2.5 0 0,0 19,20V18.5L21.25,20.75L21.28,20.78C21.22,20.83 21.15,20.87 19,23Z' />
  </svg>
)

export const CloseIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
  </svg>
)

export const DeleteIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z' />
  </svg>
)

export const MoreIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' />
  </svg>
)

export const SettingsIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z' />
  </svg>
)

export const ProfileIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z' />
  </svg>
)

export const ExpandMoreIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
  </svg>
)

export const ArrowDownIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z' />
  </svg>
)

export const ArrowUpIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z' />
  </svg>
)

export const CheckBoldIcon = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z' />
  </svg>
)

export const CloseIcon2 = props => (
  <svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}>
    <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
  </svg>
)
