import { gql } from '@apollo/client'

export const GET = gql`
  query Examination($code: ID!) {
    examination(code: $code) {
      code

      status

      indication
      # uid
      tree
      selectedProtocol
      filters
      selectedModel

      groupsCollapse
      hiddenGroups
      selectedBlocks
      selectedPhrases
      selectedComplementPhrases
      groupsIgnoringSelections

      afterGroup
      fields
      complementFields
      vars
      # contentState

      PatientName
      PatientAge
      PatientWeight
      PatientHeight
      PatientGender

      # UnitName

      examinedAt
      previousExam1At
      previousExam2At
      signedAt
      underCorrectionAt
      correctedAt

      # createdAt

      clinic {
        name
        defaultEquipment
      }

      list {
        content
      }
    }
  }
`

export const UPDATE = gql`
  mutation UpdateExamination($code: ID!, $selectedModel: String, $groupsCollapse: JSON, $vars: JSON, $hiddenGroups: JSON, $groupsIgnoringSelections: JSON, $selectedBlocks: JSON, $selectedPhrases: JSON, $selectedComplementPhrases: JSON, $fields: JSON, $complementFields: JSON, $afterGroup: JSON, $status: JSON) {
    updateExamination(code: $code, selectedModel: $selectedModel, groupsCollapse: $groupsCollapse, vars: $vars, hiddenGroups: $hiddenGroups, groupsIgnoringSelections: $groupsIgnoringSelections, selectedBlocks: $selectedBlocks, selectedPhrases: $selectedPhrases, selectedComplementPhrases: $selectedComplementPhrases, fields: $fields, complementFields: $complementFields, afterGroup: $afterGroup, status: $status) {
      code
    }
  }
`
