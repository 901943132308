import { useState, useEffect } from 'react'
import EXIF from 'exif-js'

function clipboardDataToFile(e, allowedFormats) {
  const files = Array.prototype.slice.call(e.clipboardData.files)
  if (!files || !!!files.length || !allowedFormats.includes(files[0].type)) return null
  return files[0]
}

function readFileAsImage(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = () => {
      reader.abort()
      reject(new DOMException("Problem parsing input file."))
    }
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  })
}

function base64ToArrayBuffer(base64) {
  base64 = base64.replace(/^data:([^;]+);base64,/gmi, '')
  const binaryString = atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) { bytes[i] = binaryString.charCodeAt(i) }
  return bytes.buffer
}

function processImage(dataURL, exif) {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.onload = () => {
      const MAX_WIDTH = 1296
      const MAX_HEIGHT = 1296
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      switch(exif.Orientation) {
        case 8:
          canvas.width = height
          canvas.height = width
          ctx.setTransform(0, -1, 1, 0, 0, height)
          ctx.drawImage(img, 0, 0, height, width)
          break
        case 6:
          canvas.width = height
          canvas.height = width
          ctx.setTransform(0, 1, -1, 0, height, 0)
          ctx.drawImage(img, 0, 0, width, height)
          break
        default:
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0, width, height)
          break
      }
      resolve(canvas.toDataURL('image/jpeg', 0.8))
    }
    img.src = dataURL
  })
}

const usePasteImage = () => {
  const [src, setSrc] = useState(null)

  const handlePaste = async e => {

    const activeElement = document.activeElement
    if (activeElement && ['input', 'textarea'].indexOf(activeElement.tagName.toLowerCase()) !== -1) return false

    e.preventDefault()

    const file = clipboardDataToFile(e, ['image/png', 'image/jpeg'])
    if (!file) return

    const base64Image = await readFileAsImage(file)
    const exif = EXIF.readFromBinaryFile(base64ToArrayBuffer(base64Image))
    const newSrc = await processImage(base64Image, exif)
    setSrc(newSrc)

  }

  useEffect(() => {
    window.addEventListener('paste', handlePaste)
    return () => {
      window.removeEventListener('paste', handlePaste)
    }
  }, [])

  return src
}

export default usePasteImage
