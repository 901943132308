import React from 'react'
import { css } from 'styled-components/macro'
import { Box } from '@alobato/flex-box'
import isEqual from 'lodash.isequal'
// import Spin from '../../../../components/Spin'
import Label from '../../../../components/Label'
// import { gql, useQuery } from '@apollo/client'
import FieldInput from '../../../../components/_new/FieldInput'
import FieldSelect from '../../../../components/_new/FieldSelect'
import Button from '../../../../components/_new/Button'
import { replaceFields, replaceMultiples } from '../../../../utils/variables'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import Tippy from '@tippy.js/react'
import ButtonIcon from '../../../../components/ButtonIcon'
import useDidMount from '../../../../hooks/useDidMount'

import safeEval from 'safe-eval'
import memoize from 'lodash.memoize'
import omitBy from 'lodash.omitby'

import uniq from 'lodash.uniq'

// function getFirstGroup(regexp, str) {
//   const array = [...str.matchAll(regexp)]
//   return array.map(m => m[1])
// }

// function sortVariablesData(items, orderedCodes) {
//   const newItems = [...items]
//   console.log('sortVariablesData newItems', newItems)
//   let orderedItems = []
//   for (const code of orderedCodes) {
//     const foundIndex = items.findIndex((obj) => obj.code === code)
//     if (foundIndex > -1) {
//       const itemToAdd = newItems.splice(foundIndex, 1)
//       orderedItems.push(itemToAdd[0])
//     }
//   }
//   orderedItems.push(...newItems)
//   orderedItems = orderedItems.filter((item) => !!item)
//   console.log('orderedItems', orderedItems)
//   return orderedItems
// }

function isNumeric(str) {
  if (typeof str != 'string') return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function isoDateToBr(isoDate) {
  const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z/
  const [_, year, month, day] = isoDate.match(regex)
  return `${day}/${month}/${year}`
}

function stringifyValues(obj) {
  const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z/
  const newObj = {}
  obj = omitBy(obj, (value) => (value === null || value === undefined))
  Object.keys(obj).forEach((key) => {
    if (key !== 'dif_data_exame_data_exame_anterior_1') {
      if (obj[key] && (typeof obj[key] === 'number' || isNumeric(obj[key]))) {
        newObj[key] = Number(obj[key]).toFixed(2).toString()
      } else if (obj[key] && typeof obj[key] !== 'string') {
        try {
          newObj[key] = obj[key].toString()//.replace(/\s/g, '')
        } catch (e) {
          newObj[key] = ''
        }
      } else {
        try {
          newObj[key] = obj[key].trim()
          if (regex.test(newObj[key])) {
            newObj[key] = isoDateToBr(newObj[key])
          }
        } catch (e) {
          newObj[key] = ''
        }
      }
    }
  })
  return newObj
}

const systemVariables = {
  s65321: 'accessionNumber',
  s82450: 'studyInstanceUID',
  s22584: 'anamnesis',
  s63654: 'indication',
  s08695: 'PhysicianName',
  s49494: 'PhysicianCrm',
  s80168: 'PatientName',
  s01916: 'PatientGender',
  s19738: 'PatientAge',
  s15658: 'examinedAt',
  s52888: 'previousExam1At',
  s88301: 'previousExam2At',
  s10760: 'PatientHeight',
  s34153: 'PatientWeight'
}

function diffDatesInDays(d1, d2) {
  const date1 = new Date(d1)
  const date2 = new Date(d2)
  const diffTime = Math.abs(date2 - date1)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

function formatUtcDate(date) {
  // replace(/(\d{4})-(\d{2})-(\d{2})T00:00:00,000Z/, '$3/$2/$1')
  if (!date) return ''
  const [year, month, day] = date.replace(',', '.').replace('T00:00:00.000Z', '').split('-')
  return `${day}/${month}/${year}`
}

// const TEXT = `<table><tbody class="version2"><tr><th colspan="12" class="bmk">Título</th></tr><tr><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td><td colspan="1"></td></tr><tr><td colspan="6">ff1</td><td colspan="6"><@ f76503 * 2 @></td></tr><tr><td colspan="12" class="small"><@ f76503 @></td></tr></tbody></table>`
// const TEXT = `<table><tbody class="version2"><tr><th colspan="6" class="bmk">Análise do bulbo carotídeo direito</th></tr><tr><td colspan="2">Posição da bifurcação carotídea: <@ o12863 @></td><td colspan="2" class="center">Extensão da lesão = <@ v59725 @> mm</td><td colspan="2" class="center">Estenose</td></tr><tr><td colspan="2">Composição: <@ o06345 @></td><td colspan="2" class="center">Excentricidade: <@ o24916 @></td><td colspan="1" class="center">NASCET</td><td colspan="1" class="center"><@ f37834 @>%</td></tr><tr><td colspan="2">Superfície: <@ o37194 @></td><td colspan="2" class="center">Remodelamento: <@ o29827 @></td><td colspan="1" class="center">ECST</td><td colspan="1" class="center"><@ f39771 @>%</td></tr><tr><td colspan="1" class="center">ØCCD = <@ v30524 @> mm</td><td colspan="1" class="center">ØBCD = <@ v08237 @> mm </td><td colspan="1" class="center">ØECD = <@ v14742 @> mm</td><td colspan="1" class="center">ØCID = <@ v22915 @> mm</td><td colspan="1" class="center">CSI</td><td colspan="1" class="center"><@ f83677 @>%</td></tr><tr><td colspan="6" class="small">CCD = carótida comum direita; BCD = bulbo carotídeos direito; ECD = estenose de carótida direita; CID = carótida interna direita; NASCET = North American Syntomatic Carotid Endarterectomy Trial; ECST = European Carotid Surgery Trial; CSI = Carotid Stenosis Index; Ø = diâmetro</td></tr></tbody></table>`
// const TEXT = `Teste <@ f76503 @> .`

const memoSafeEval = memoize(safeEval, (...args) => JSON.stringify(args))

function getVariablesAndCalculated(variables, variablesData, examination) {
  let newVariables = Object.keys(variables).reduce((acc, key) => {
    let newValue = variables[key]
    if (/\d+,\d+/.test(newValue)) newValue = newValue.replace(',', '.')
    if (newValue && !isNaN(newValue)) newValue = Number(newValue)
    acc = {...acc, [key]: newValue}
    return acc
  }, {})

  const systemVariablesData = Object.keys(systemVariables).reduce((acc, key) => {
    let newValue = ''
    if (examination && examination[systemVariables[key]]) {
      newValue = examination[systemVariables[key]]
    }
    acc = {...acc, [key]: newValue}
    return acc
  }, {})

  newVariables = { ...newVariables, ...systemVariablesData }

  console.log('!variablesData', variablesData)
  for (const v of variablesData) {
    if (v && v.mode && v.mode.toUpperCase() === 'S' && v.source) {
      if (v.source === 'examinedAt') {
        try { newVariables[v.code] = formatUtcDate(examination.examinedAt) } catch(err) {}
      } else if (v.source === 'previousExam1At') {
        try { newVariables[v.code] = formatUtcDate(examination.previousExam1At) } catch(err) {}
      } else if (v.source === 'previousExam2At') {
        try { newVariables[v.code] = formatUtcDate(examination.previousExam2At) } catch(err) {}
      } else if (v.source === 'diffExaminedAtPreviousExam1At') {
        try { newVariables[v.code] = diffDatesInDays(examination.previousExam1At, examination.examinedAt) } catch(err) {}
      } else if (v.source === 'diffExaminedAtPreviousExam2At') {
        try { newVariables[v.code] = diffDatesInDays(examination.previousExam2At, examination.examinedAt) } catch(err) {}
      } else if (v.source === 'diffPreviousExam1AtPreviousExam2At') {
        try { newVariables[v.code] = diffDatesInDays(examination.previousExam2At, examination.previousExam1At) } catch(err) {}
      } else {
        try { newVariables[v.code] = examination[v.source] } catch(err) {}
      }
    }

    // if (v.mode.toUpperCase() === 'S' && v.source.trim()) {
    //   try {
    //     newVariables[v.code] = examination[v.source.trim()]
    //   } catch(error) {}
    // }

    if (v && v.mode && ['F', 'C'].includes(v.mode.toUpperCase())) {
      const vars = Object.keys(newVariables).join(', ')
      const varsStr = `({ ${vars} })`
      let formula = v.source.trim()
      // if (formula === `(if (f21464 > 0 && f28732 < 1825) {return f28732 + ' dias'} else if (f28732 > 1825){return ' acima de 5 anos'} else if (f21464 < 0) {return ' lesão em involução'}else if(f21464 === 0){return ' lesão estável'})`) {
      //   formula = `if (f21464 > 0 && f28732 < 1825) {return f28732 + ' dias'} else if (f28732 > 1825){return ' acima de 5 anos'} else if (f21464 < 0) {return ' lesão em involução'}else if(f21464 === 0){return ' lesão estável'}`
      // }
      if (!formula.includes('return')) formula = `return (${formula})`
      const evalStr = `(${varsStr} => { ${formula} })${varsStr}`
      let result = ''
      try {
        result = memoSafeEval(evalStr, newVariables)

        if (v.valueType && v.valueType.toUpperCase() === 'DECIMAL') {
          if (result && !isNaN(result)) {
            result = Number(result).toFixed(2)
            result = Number(result)
          } else {
            result = 0.00
          }
        } else if (v.valueType && v.valueType.toUpperCase() === 'INTEIRO') {
          if (result && !isNaN(result)) {
            result = Math.trunc(Number(result))
          } else {
            result = 0
          }
        } else {
          if (typeof result === 'undefined') {
            result = ''
          }
        }

        newVariables = {...newVariables, [v.code]: result}
      } catch(error) {}
    }
  }

  return newVariables
}

function getTableVariablesCodes(text) {
  let variables = []

  if (text.includes('$$')) {
    const matches1 = text.split('$$')[0].matchAll(/(\d{5})/g)
    for (const match of matches1) {
      variables.push(match[1])
    }
  }

  const matches = text.matchAll(/[^\d\w]([cfmvonurs]\d{5}?)\D/g)
  for (const match of matches) {
    variables.push(match[1])
  }

  variables = variables.map(code => code.replace(/[cfmvonurs]/, ''))
  variables = uniq(variables)

  return variables
}

function getInitialValues(variablesData, variables) {
  const result = variablesData.reduce((acc, { code, name, unit, valueType, options, range, mode }) => {
    let value = ''
    if (variables[code]) {
      if (valueType === 'DECIMAL') {
        const typeOfVariable = typeof variables[code]
        if (typeOfVariable === 'number') {
          value = variables[code].toString().replace('.', ',')
        } else if (typeOfVariable === 'string') {
          if (variables[code].includes(',') && !isNaN(variables[code].replace(',', '.'))) {
            value = variables[code]
          }
          if (variables[code].includes('.') && !isNaN(variables[code])) {
            value = variables[code].replace('.', ',')
          }
        }
      } else if (valueType === 'INTEIRO') {
        if (!isNaN(variables[code])) {
          value = parseInt(variables[code]).toString()
        }
      } else {
        value = variables[code].toString()
      }
    }
    acc[code] = value
    return acc
  }, {})

  return result
}

function getValidationSchema(variablesData) {

  const shapeObj = variablesData.reduce((acc, { code, valueType, mode, range }) => {
    if (mode && ['V', 'O'].includes(mode.toUpperCase())) {
      if (valueType === 'DECIMAL') {
        // acc[code] = yup.number().typeError('O valor deve ser um número decimal').required('Campo obrigatório')
        acc[code] = yup.string().test('decimal', 'Deve ser um número decimal', (value) => {
          if (!value) return false
          if (/\d,\d/.test(value.toString())) return true
          return false
        })

      } else if (valueType === 'INTEIRO') {
        acc[code] = yup.number().typeError('Deve ser um número inteiro').positive('Deve ser maior que zero').integer('Deve ser um número inteiro').required('Campo obrigatório')
      } else if (valueType === 'TEXTO') {
        acc[code] = yup.string().required('Campo obrigatório')
      }
    }
    return acc
  }, {})

  return yup.object().shape(shapeObj)
}

function FormField({ label, code, options = [], title = '', errors, touched }) {
  if (options && options.length > 0) {
    return (
      <Box>
        <Label htmlFor={label} title={title} css={css`text-transform: none;`}>{label}</Label>
        <Field withError={touched[code] && errors[code]} width='100%' name={code} placeholder='Selecione...' component={FieldSelect} options={options.map(item => ({ label: item, value: item }))} />
      </Box>
    )
  }

  return (
    <Box>
      <Label htmlFor={label} title={title} css={css`text-transform: none;`}>{label}</Label>
      <Field withError={touched[code] && errors[code]} width='100%' autoComplete='off' name={code} component={FieldInput} />
    </Box>
  )
}

function TableGroup2Form({ phrase, variables, variablesData, onSubmit }) {
  console.log('variablesData', variablesData)
  console.log('variables', variables)
  const initialValues = getInitialValues(variablesData, variables)
  const validationSchema = getValidationSchema(variablesData)

  const variablesObj = variablesData.reduce((acc, { code, name, unit, valueType, options, range, mode }) => {
    if (mode && mode.toUpperCase() === 'M') {
      const count = (phrase.text.match(new RegExp(code, 'g')) || []).length
      Array.from(Array(count)).forEach((_, i) => {
        acc[`${code}_${phrase.id}_${i + 1}`] = { name, unit, valueType, options, range }
      })
    }
    if (!acc[code] && mode && ['V', 'O'].includes(mode.toUpperCase())) {
      acc[code] = { name, unit, valueType, options, range }
    }
    return acc
  }, {})
  console.log('variablesObj', variablesObj)

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ errors, touched, isSubmitting }) => (
        <Form>
          {Object.entries(variablesObj).map(([key, { name, unit, options }]) => {
            const label = `${name} ${unit ? `(${unit})` : ''}`
            return (
              <FormField errors={errors} touched={touched} key={key} label={label} code={key} options={options} title={name} />
            )
          })}
          <Box css={css`text-align: center;`}>
            <Button type='submit'>Salvar</Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const TableText = ({ text }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: text.replace(/.*\^/, '') }} className='tbl' />
  )
}

function TableGroup2({ phrase, variables, examination, onSubmit, variablesData }) {

  const instanceRef = React.useRef()

  variablesData = variablesData.map(({ code, mode, ...rest }) => ({
    code: `${mode.toLowerCase()}${code}`,
    mode,
    ...rest
  }))
  // const textCodes = getFirstGroup(/<@.*?(v\d{5}).*?@>/g, phrase.text)
  // variablesData = sortVariablesData(variablesData, textCodes)

  const newVariables = getVariablesAndCalculated(variables, variablesData, examination)

  let text = phrase.text.replace('v2!', '')

  if (text.includes('$$')) {
    text = text.split('$$')[1]
  }

  text = replaceMultiples(text, phrase.id)

  text = replaceFields(text, newVariables)

  useDidMount(() => {
    console.log('---TableGroup2 useDidMount')
    let vars = { ...variables, ...newVariables }

    vars = Object.keys(vars).reduce((acc, code) => {
      acc[code] = vars[code]

      const [baseCode] = code.split('_')
      const codeWithoutLetter = code.replace(/[mvofcs]/, '')

      const variable = variablesData.find((item) => item.code === baseCode)

      if (variable && variable.unit) acc[`u${codeWithoutLetter}`] = variable.unit
      if (variable && variable.name) acc[`n${codeWithoutLetter}`] = variable.name
      if (variable && variable.reference) acc[`r${codeWithoutLetter}`] = variable.reference

      return acc
    }, {})

    const equal = isEqual(stringifyValues(variables), stringifyValues(vars))

    if (!equal && vars && onSubmit && typeof onSubmit === 'function') {
      onSubmit(vars)
    }
  })

  React.useEffect(() => {
    const newVariables = getVariablesAndCalculated(variables, variablesData, examination)

    let vars = { ...variables, ...newVariables }

    vars = Object.keys(vars).reduce((acc, code) => {
      acc[code] = vars[code]

      const [baseCode] = code.split('_')
      const codeWithoutLetter = code.replace(/[mvofcs]/, '')

      const variable = variablesData.find((item) => item.code === baseCode)

      if (variable && variable.unit) acc[`u${codeWithoutLetter}`] = variable.unit
      if (variable && variable.name) acc[`n${codeWithoutLetter}`] = variable.name
      if (variable && variable.reference) acc[`r${codeWithoutLetter}`] = variable.reference

      return acc
    }, {})

    const equal = isEqual(stringifyValues(variables), stringifyValues(vars))

    if (!equal && vars && onSubmit && typeof onSubmit === 'function') {
      onSubmit(vars)
    }

    // eslint-disable-next-line
  }, [variables])

  const handleTableGroup2FormSubmit = (vars) => {
    vars = { ...vars, ...getVariablesAndCalculated(vars, variablesData) }
    vars = Object.keys(vars).reduce((acc, code) => {
      acc[code] = vars[code]

      const [baseCode] = code.split('_')
      const codeWithoutLetter = code.replace(/[mvos]/, '')

      const variable = variablesData.find((item) => item.code === baseCode)

      if (variable && variable.unit) acc[`u${codeWithoutLetter}`] = variable.unit
      if (variable && variable.name) acc[`n${codeWithoutLetter}`] = variable.name
      if (variable && variable.reference) acc[`r${codeWithoutLetter}`] = variable.reference

      return acc
    }, {})

    if (vars && onSubmit && typeof onSubmit === 'function') {
      onSubmit(vars)
    }

    instanceRef.current.hide()
  }

  console.log('-variables', variables)
  console.log('-variablesData', variablesData)

  return (
    <>
      {variablesData && variablesData.length > 0 &&
        <Box>
          <Tippy
            content={<TableGroup2Form phrase={phrase} variables={variables} variablesData={variablesData} onSubmit={handleTableGroup2FormSubmit} />}
            placement='bottom'
            trigger='click'
            theme='light'
            interactive={true}
            inertia={true}
            arrow={false}
            duration={[350, 200]}
            onCreate={instance => instanceRef.current = instance}
          >
            <span>
              <ButtonIcon iconPath='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z' />
            </span>
          </Tippy>
        </Box>
      }
      <Box mt={1}><TableText text={text} /></Box>
    </>
  )
}

const TableGroup2Query = ({ phrase, variables, examination, onSubmit }) => {
  console.log('phrase.text', phrase.text)
  const codes = getTableVariablesCodes(phrase.text)
  console.log('codes', codes)
  // const { loading, error, data } = useQuery(gql`query ($codes: [ID]) { variables(codes: $codes) }`, { variables: { codes }, fetchPolicy: 'network-only' })
  // if (loading) return <Spin />
  // if (error) return error.message

  let variablesData = []
  let sortedVariablesData = []
  if (codes.length > 0 && examination.list && examination.list.contentVariables && examination.list.contentVariables.length > 0) {
    variablesData = examination.list.contentVariables.filter((item) => codes.includes(item.code))
    for (const code of codes) {
      const found = variablesData.find((item) => item.code === code)
      if (found) {
        sortedVariablesData.push(found)
      }
    }
  }

  // return <TableGroup2 data={data} phrase={phrase} variables={variables} examination={examination} onSubmit={onSubmit} />
  return <TableGroup2 variablesData={sortedVariablesData} phrase={phrase} variables={variables} examination={examination} onSubmit={onSubmit} />
}

export default TableGroup2Query
