import React, { memo } from 'react'
import { Box } from '@alobato/flex-box'
import Fab from '@alobato/fab'

import Title from '../../components/Title'
import Modal from '../../components/StyledModal'

import { useTranslation } from 'react-i18next'
import { useHotkeys } from 'react-hotkeys-hook'

import List from './List'
import Show from './Show'
import New from './New'
import Edit from './Edit'

const Home = ({ id, path, navigate, notifier }) => {

  const { t } = useTranslation()

  useHotkeys('n', () => navigate('novo'))

  return (
    <>
      <Box mb={5} p={[3, 4]}>

      <Box>

          <Box mb={4}>
            <Title>{t('Planos')}</Title>
          </Box>

          <Fab onClick={() => navigate('novo')} />

          <List navigate={navigate} notifier={notifier} />

        </Box>

      </Box>

      {path === '/:id' && id &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../')}
          render={
            ({onRequestClose}) => (
              <Show
                id={id}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      }

      {path === '/:id/editar' &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../../')}
          render={
            ({onRequestClose}) => (
              <Edit
                id={id}
                notifier={notifier}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
              />
            )
          }
        />
      }

      {path === '/novo' &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../')}
          render={
            ({onRequestClose}) => (
              <New
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
              />
            )
          }
        />
      }
    </>
  )
}

export default memo(Home)
