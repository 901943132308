import { gql } from '@apollo/client'

export const PASSWORD_RESET_SEND = gql`
  mutation($email: String!) {
    passwordResetSend(email: $email)
  }
`

export const PASSWORD_RESET = gql`
  mutation($resetPasswordToken: String!, $newPassword: String!) {
    passwordReset(resetPasswordToken: $resetPasswordToken, newPassword: $newPassword)
  }
`
