import React, { useMemo, useCallback } from 'react'

import { Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Select from '../../components/Select'
import MultiSelect from '../../components/MultiSelect'
// import Switch from '../../components/Switch'

import useScrollToError from '@alobato/use-scroll-to-error'
import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

import FormErrorBox from '../../components/FormErrorBox'
import Label from '../../components/Label'

// TODO
const modalities = ['TOMOGRAFIA', 'RESSONÂNCIA MAGNÉTICA', 'ULTRASSONOGRAFIA', 'MAMOGRAFIA', 'PROTOCOLOS', 'MODALIDADE DE TESTE', 'DOPPLER']

const Form = ({ plans, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {

  const { t } = useTranslation()

  console.log('values', values)

  useFocusOnLoad('name')
  useScrollToError({ errors, isValid, isSubmitting })

  // TODO: obter modalidades do banco
  const modalitiesOptions = useMemo(() => (modalities.map(item => ({ value: item, label: item }))), [])

  const changeModalitiesMultiSelect = useCallback(options => {
    options ? setFieldValue('modalities', options) : setFieldValue('modalities', null)
  }, [setFieldValue])

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>{t('Nome')}<span>*</span></Label>
        <Input width='100%' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} withError={touched.name && errors.name} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Modalidades')}<span>(deixe em branco para todas)</span></Label>
        <MultiSelect
          onChange={changeModalitiesMultiSelect}
          value={values.modalities}
          options={modalitiesOptions}
        />
        <FormErrorBox fieldName='modalities' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Plano')}<span>*</span></Label>
        <Select width='100%' placeholder='Selecione...' name='PlanId' value={values.PlanId} onChange={handleChange} onBlur={handleBlur} withError={touched.PlanId && errors.PlanId}>
          {plans.map(item => (
            <option key={item.id} value={item.id}>{item.name}</option>
          ))}
        </Select>
        <FormErrorBox fieldName='PlanId' errors={errors} touched={touched} />
      </Box>

      {/*isCreating &&
        <Box mb={3}>
          <Label>{t('Usar frases do TelerisOn')}</Label>
          <Switch checked={values.useMaster} onChange={checked => setFieldValue('useMaster', checked)} />
        </Box>
      */}

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
}

export default Form

