import { gql } from '@apollo/client'

export const LIST = gql`
  query {
    plans {
      id
      name
      categories
      useMaster
      modalities
      features
      usersLimit
      trialDays
      price
      createdAt
    }
  }
`

export const GET = gql`
  query ($id: ID!) {
    plan(id: $id) {
      id
      name
      categories
      useMaster
      modalities
      features
      usersLimit
      trialDays
      price
      createdAt
    }
  }
`

export const CREATE = gql`
  mutation ($name: String!, $categories: JSON, $useMaster: Boolean, $features: JSON, $usersLimit: Int, $trialDays: Int, $price: Currency) {
    createPlan(name: $name, categories: $categories, useMaster: $useMaster, features: $features, usersLimit: $usersLimit, trialDays: $trialDays, price: $price) {
      id
      name
      categories
      useMaster
      modalities
      features
      usersLimit
      trialDays
      price
      createdAt
    }
  }
`

export const UPDATE = gql`
  mutation ($id: ID!, $name: String!) {
    updatePlan(id: $id, name: $name) {
      id
      name
      categories
      useMaster
      modalities
      features
      usersLimit
      trialDays
      price
      createdAt
    }
  }
`

export const DELETE = gql`
  mutation ($id: ID!) {
    deletePlan(id: $id)
  }
`
