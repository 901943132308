import { useState, useEffect } from 'react'

import convertContentStateToHtml from '../utils/convertContentStateToHtml'

const useConvertPdf = contentState => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [html, setHtml] = useState(null)

  // const { blocks } = rawContentState

  // const anAsyncFunction = async item => {
  //   if (item.type === 'image' && item.data.src.includes('https://p.telerison.com/pup')) {
  //     const result = await (await fetch(item.data.src.replace('https://p.telerison.com/pup?c=', 'https://p.telerison.com/pup?t=b64&c='))).json()
  //     return Promise.resolve({ ...item, data: { ...item.data, src: result.base64 } })
  //   }
  //   return Promise.resolve(item)
  // }

  useEffect(() => {
    try {
      setLoading(true)
      const tempHtml = convertContentStateToHtml(contentState)
      setHtml(tempHtml)

      // const tempDocument = convertHtmlToDocument(tempHtml)
      // setDocument(tempDocument)
      setLoading(false)

      // const getData = async () => {
        // const newBlocks = await Promise.all(blocks.map(item => anAsyncFunction(item)))
        // setLoading(false)
        // setBlockList(newBlocks)
      // }

      // getData()

    } catch(err) {
      console.error(err.message)
      setError(err)
      setLoading(false)
    }

  }, [contentState])


  return { loading, error, html }
}

export default useConvertPdf
