import React, { memo } from 'react'
import { Flex, Box } from '@alobato/flex-box'

import Wrapper from '../../components/Wrapper'
import Card from '../../components/Card'
import Title from '../../components/Title'
import Modal from '../../components/StyledModal'
import Button from '../../components/_new/Button'

import { useTranslation } from 'react-i18next'
import { useHotkeys } from 'react-hotkeys-hook'

import List from './List'
import Show from './Show'
import New from './New'
import Edit from './Edit'
import License from './License'

const Home = ({ id, path, navigate, notifier }) => {

  useHotkeys('n', () => navigate('novo'))

  const { t } = useTranslation()

  return (
    <>
      <Wrapper>

        <Card>

          <Flex justifyContent='space-between' alignItems='center'>
            <Box mb={4}>
              <Title>{t('Usuários')}</Title>
            </Box>
            <Box>
              <Button onClick={() => navigate('novo')}>Novo</Button>
            </Box>
          </Flex>

          <List navigate={navigate} notifier={notifier} />

        </Card>

      </Wrapper>

      {path === '/:id' && id &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../')}
          render={
            ({onRequestClose}) => (
              <Show
                id={id}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      }

      {path === '/:id/editar' &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../../')}
          render={
            ({onRequestClose}) => (
              <Edit
                id={id}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
              />
            )
          }
        />
      }

      {path === '/:id/license' &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../../')}
          render={
            ({onRequestClose}) => (
              <License
                id={id}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
              />
            )
          }
        />
      }

      {path === '/novo' &&
        <Modal
          clickOutsideDisabled={false}
          onCloseCompleted={() => navigate('../')}
          render={
            ({onRequestClose}) => (
              <New
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
              />
            )
          }
        />
      }
    </>
  )
}

export default memo(Home)
