import React, { useState, useCallback, useRef, useEffect, forwardRef, memo } from 'react'
import styled from 'styled-components'
import useDebounce from '@alobato/use-debounce'
import AutosizeInput from './AutosizeInput'

// const InputWithAutoSize = memo((props) => {
const InputWithAutoSize = memo(forwardRef((props, ref) => {

  const { onChange, className, defaultValue, placeholder, ...rest } = props

  const onChangeCallBack = useCallback(onChange, [])

  const [value, setValue] = useState(defaultValue)

  const debouncedValue = useDebounce(value, 600)

	const didMountRef = useRef(false)
	useEffect(() => {
    if (didMountRef.current)
		  onChangeCallBack(debouncedValue)
    else
      didMountRef.current = true
  }, [debouncedValue, onChangeCallBack])

	// useEffect(() => {
  //   if (didMountRef.current)
	// 	  setValue(defaultValue)
  //   else
  //     didMountRef.current = true
  // }, [defaultValue])

	const handleChange = e => {
    // if (!e.isTrusted) return // autoComplete Off
		setValue(e.target.value)
	}

  const handleBlur = () => {
    onChange(value)
  }

  return (
    <AutosizeInput
      type='text'
      minWidth={217}
      value={value}
      className={className}
      style={{display: 'inline-block'}}
      onChange={handleChange}
      placeholder={placeholder}
      onBlur={handleBlur}
      autoComplete='nope'
      ref={ref}
      {...rest}
    />
  )

}))

export default styled(InputWithAutoSize)`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: 2;

  padding: 0 6px;
  background-color: white;
  border: 1px solid hsla(0, 0%, 85%, 1);
  border-radius: 4px;
  outline: 0;

  transition: all 300ms;
  &:hover, &:focus {
    border-color: #40a9ff !important;
    background-color: white;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  &:active {
    border-color: #096dd9 !important;
  }
`
