import React, { memo } from 'react'
import { components } from 'react-select'
import Creatable from 'react-select/creatable'

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <svg  style={{transform: `rotate(${props.selectProps.menuIsOpen ? -180 : 0 }deg)`, transition: 'all 300ms'}} viewBox='0 0 24 24' height={24} fill='currentColor'>
      <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
    </svg>
  </components.DropdownIndicator>
)

const selectCustomStyles = {
  valueContainer: (base, state) => ({
    ...base,
    padding: '0 6px 0 4px',
    lineHeight: 1,
    flexDirection: 'row-reverse'
  }),
  option: (base, state) => ({
    ...base,
    padding: '5px 12px',
    fontSize: 14,
    color: state.isSelected ? 'rgba(0,0,0,.65)' : 'inherit',
    fontWeight: state.isSelected ? '600' : 'inherit',
    backgroundColor: state.isFocused ? 'hsla(199, 100%, 95%, 1)' : 'inherit',
    whiteSpace: 'nowrap'
  }),
  container: (base, state) => ({
    ...base,
    display: 'inline-block'
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 26,
    padding: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    transition: 'all 300ms',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(24, 144, 255, .2)' : null,
    borderColor: state.isFocused ? 'hsla(207, 100%, 63%, 1)' : 'hsla(0, 0%, 85%, 1)',
    backgroundColor: state.isFocused ? 'hsla(0, 0%, 100%, 1)' : 'hsla(0, 0%, 100%, 1)',
    '&:hover': {
      borderColor: 'hsla(207, 100%, 63%, 1)'
    }
  }),
  singleValue: (base, state) => ({
    ...base,
    color: 'hsla(0, 0%, 0%, 0.65)',
    position: 'inherit',
    textOverflow: 'inherit',
    maxWidth: 'inherit',
    transform: 'initial',
    marginLeft: 0,
    marginRight: 0,
    minWidth: 180,
    overflow: 'hidden'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 4,
    fontSize: 11,
    color: 'hsla(0, 0%, 0%, 0.25)'
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    marginBottom: 6,
    marginTop: 6
  }),
  menu: (base, state) => ({
    ...base,
    width: 'auto'
  })
}

const getLabelValue = item => {
  if (!item) return { value: '', label: '\u00A0' }
  let value = item
  let label = item
  if (/^.+?<.+?>$/.test(item)) {
    const found = item.match(/^(.+?)<(.+?)>$/)
    if (found && found[1]) label = found[1]
    value = item
    if (value && !isNaN(value)) value = Number(value)
  } else if (/^<.+?>.+?$/.test(item)) {
    const found = item.match(/^<(.+?)>(.+?)$/)
    if (found && found[2]) label = found[2]
    value = item
    if (value && !isNaN(value)) value = Number(value)
  }

  return { value, label }
}

const findOptionByValue = (options, value) => {
  const found = options.filter(item => item.value === value)
  if (found.length > 0) return found[0]
  return null
}

const InputWithOptions = memo(({ disabled = false, options, value, onChange }) => {

  options = options.map(item => getLabelValue(item))
  options = [{ value: '', label: '\u00A0' }, ...options]

  const handleChange = selected => {
    onChange(selected.value)
  }

  const componentValue = findOptionByValue(options, value)

  // value ? findOptionByValue(options, value) : {value: '', label: '\u00A0'}

  return (
    <Creatable
      isDisabled={disabled}
      styles={selectCustomStyles}
      components={{DropdownIndicator}}
      isSearchable={true}
      value={componentValue}
      onChange={handleChange}
      options={options}
      menuPlacement='auto'
      formatCreateLabel={inputValue => 'Criar'}
      onKeyDown={e => {}}
    />
  )
})

export default InputWithOptions
