import React from 'react'
import { Router } from '@reach/router'

import MainLayout from '../../layouts/MainLayout'
import Home from './Home'

const PlansIndex = ({ me, notifier }) => {
  return (
    <Router>
      <MainLayout me={me} path='/'>
        <Home notifier={notifier} me={me} path='/novo' />
        <Home notifier={notifier} me={me} path='/:id/editar' />
        <Home notifier={notifier} me={me} path='/:id' />
        <Home notifier={notifier} me={me} path='/' />
      </MainLayout>
    </Router>
  )
}

export default PlansIndex
