import React from 'react'
import { css } from 'styled-components/macro'
import { Box } from '@alobato/flex-box'
import Spin from '../../components/Spin'
import Label from '../../components/Label'
import { gql, useQuery } from '@apollo/client'
import FieldInput from '../../components/_new/FieldInput'
import FieldSelect from '../../components/_new/FieldSelect'
import Button from '../../components/_new/Button'
import { sanitize, replaceFields, replaceMultiples } from '../../utils/variables'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import Tippy from '@tippy.js/react'
import ButtonIcon from '../../components/ButtonIcon'

import safeEval from 'safe-eval'
import memoize from 'lodash.memoize'

import uniq from 'lodash.uniq'

const memoSafeEval = memoize(safeEval, (...args) => JSON.stringify(args))

function getVariablesAndCalculated(variables, variablesData) {
  let newVariables = Object.keys(variables).reduce((acc, key) => {
    let newValue = variables[key]
    if (/\d+,\d+/.test(newValue)) newValue = newValue.replace(',', '.')
    if (newValue && !isNaN(newValue)) newValue = Number(newValue)
    acc = {...acc, [key]: newValue}
    return acc
  }, {})

  for (const v of variablesData) {
    if (['F', 'C'].includes(v.mode.toUpperCase())) {
      const vars = Object.keys(newVariables).join(', ')
      const varsStr = `({ ${vars} })`
      let formula = v.source.trim()
      if (/^\(.+\)$/.test(formula)) {
        formula = formula.replace(/^\(/, '').replace(/\)$/, '')
      }
      if (!formula.includes('return')) formula = `return (${formula})`
      const evalStr = `(${varsStr} => { ${formula} })${varsStr}`
      let result = ''
      try {
        result = memoSafeEval(evalStr, newVariables)

        if (v.valueType && v.valueType.toUpperCase() === 'DECIMAL') {
          if (result && !isNaN(result)) {
            result = Number(result).toFixed(2)
            result = Number(result)
          } else {
            result = 0.00
          }
        } else if (v.valueType && v.valueType.toUpperCase() === 'INTEIRO') {
          if (result && !isNaN(result)) {
            result = Math.trunc(Number(result))
          } else {
            result = 0
          }
        }

        newVariables = {...newVariables, [v.code]: result}
      } catch(error) {}
    }
  }

  return newVariables
}

function getVariablesCodes(text) {
  let variables = []
  // TODO correct regex
  const regex = /[^\d\w]([cfmvonurs]\d{5}?)\D/g
  const matches = text.matchAll(regex)

  for (const match of matches) {
    variables.push(match[1])
  }

  variables = variables.map(code => code.replace(/[cfmvonurs]/, ''))
  variables = uniq(variables)

  return variables
}

function getInitialValues(variablesData, variables) {
  const result = variablesData.reduce((acc, { code, name, unit, valueType, options, range, mode }) => {
    let value = ''

    const found = Object.keys(variables).find((key) => { return (key.includes('_') && key.includes(code)) })

    const newCode = found || code

    if (variables[newCode]) {
      if (valueType === 'DECIMAL') {
        const typeOfVariable = typeof variables[newCode]
        if (typeOfVariable === 'number') {
          value = variables[newCode].toString().replace('.', ',')
        } else if (typeOfVariable === 'string') {
          if (variables[newCode].includes(',') && !isNaN(variables[newCode].replace(',', '.'))) {
            value = variables[newCode]
          }
          if (variables[newCode].includes('.') && !isNaN(variables[newCode])) {
            value = variables[newCode].replace('.', ',')
          }
        }
      } else if (valueType === 'INTEIRO') {
        if (!isNaN(variables[newCode])) {
          value = parseInt(variables[newCode]).toString()
        }
      } else {
        value = variables[newCode].toString()
      }
    }
    acc[newCode] = value
    return acc
  }, {})

  return result
}

function getValidationSchema(variablesData) {

  const shapeObj = variablesData.reduce((acc, { code, valueType, mode, range }) => {
    if (mode && ['V', 'O'].includes(mode.toUpperCase())) {
      if (valueType === 'DECIMAL') {
        // acc[code] = yup.number().typeError('O valor deve ser um número decimal').required('Campo obrigatório')
        acc[code] = yup.string().test('decimal', 'Deve ser um número decimal', (value) => {
          if (!value) return false
          if (/\d,\d/.test(value.toString())) return true
          return false
        })

      } else if (valueType === 'INTEIRO') {
        acc[code] = yup.number().typeError('Deve ser um número inteiro').positive('Deve ser maior que zero').integer('Deve ser um número inteiro').required('Campo obrigatório')
      } else if (valueType === 'TEXTO') {
        acc[code] = yup.string().required('Campo obrigatório')
      }
    }
    return acc
  }, {})

  return yup.object().shape(shapeObj)
}

function FormField({ label, code, options = [], title = '', errors, touched }) {
  if (options && options.length > 0) {
    return (
      <Box>
        <Label htmlFor={label} title={title} css={css`text-transform: none;`}>{label}</Label>
        <Field withError={touched[code] && errors[code]} width='100%' name={code} placeholder='Selecione...' component={FieldSelect} options={options.map(item => ({ label: item, value: item }))} />
      </Box>
    )
  }

  return (
    <Box>
      <Label htmlFor={label} title={title} css={css`text-transform: none;`}>{label}</Label>
      <Field withError={touched[code] && errors[code]} width='100%' autoComplete='off' name={code} component={FieldInput} />
    </Box>
  )
}

function TableGroup2Form({ phrase, variables, variablesData, onSubmit }) {
  const initialValues = getInitialValues(variablesData, variables)
  const validationSchema = getValidationSchema(variablesData)

  const variablesObj = variablesData.reduce((acc, { code, name, unit, valueType, options, range, mode }) => {
    if (mode && mode.toUpperCase() === 'M') {
      const count = (phrase.text.match(new RegExp(code, 'g')) || []).length
      Array.from(Array(count)).forEach((_, i) => {
        acc[`${code}_${phrase.id}_${i + 1}`] = { name, unit, valueType, options, range }
      })
    }
    if (!acc[code] && mode && ['V', 'O'].includes(mode.toUpperCase())) {
      acc[code] = { name, unit, valueType, options, range }
    }
    return acc
  }, {})

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ errors, touched, isSubmitting }) => (
        <Form>
          {Object.entries(variablesObj).map(([key, { name, unit, options }]) => {
            const label = `${name} ${unit ? `(${unit})` : ''}`
            return (
              <FormField errors={errors} touched={touched} key={key} label={label} code={key} options={options} title={name} />
            )
          })}
          <Box css={css`text-align: center;`}>
            <Button type='submit'>Salvar</Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const ResultText = ({ text }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: sanitize(text.replace(/.*\^/, '').replace(/\*\*(.+?)\*\*/g, `<strong>$1</strong>`).replace(/__(.+?)__/g, `<em>$1</em>`)) }} />
  )
}

function Structured({ phrase, variables, onSubmit, data: { variables: variablesData } }) {

  const instanceRef = React.useRef()

  variablesData = variablesData.map(({ code, mode, ...rest }) => ({
    code: `${mode.toLowerCase()}${code}`,
    mode,
    ...rest
  }))

  const newVariables = getVariablesAndCalculated(variables, variablesData)

  let text = phrase.text.replace('v2!', '')

  text = replaceMultiples(text, phrase.id)

  text = replaceFields(text, newVariables)

  const handleTextGroup2FormSubmit = (vars) => {
    vars = { ...vars, ...getVariablesAndCalculated(vars, variablesData) }
    vars = Object.keys(vars).reduce((acc, code) => {
      acc[code] = vars[code]

      const [baseCode] = code.split('_')
      const codeWithoutLetter = code.replace(/[mvos]/, '')

      const variable = variablesData.find((item) => item.code === baseCode)

      if (variable && variable.unit) acc[`u${codeWithoutLetter}`] = variable.unit
      if (variable && variable.name) acc[`n${codeWithoutLetter}`] = variable.name
      if (variable && variable.reference) acc[`r${codeWithoutLetter}`] = variable.reference

      return acc
    }, {})

    if (vars && onSubmit && typeof onSubmit === 'function') {
      onSubmit(vars)
    }

    instanceRef.current.hide()
  }

  return (
    <>
      {variablesData && variablesData.length > 0 &&
        <Box>
          <Tippy
            content={<TableGroup2Form phrase={phrase} variables={variables} variablesData={variablesData} onSubmit={handleTextGroup2FormSubmit} />}
            placement='bottom'
            trigger='click'
            theme='light'
            interactive={true}
            inertia={true}
            arrow={false}
            duration={[350, 200]}
            onCreate={instance => instanceRef.current = instance}
          >
            <span>
              <ButtonIcon iconPath='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z' />
            </span>
          </Tippy>
        </Box>
      }
      <ResultText text={text} />
    </>
  )
}

const StructuredQuery = ({ phrase, variables = {}, onSubmit }) => {
  const codes = getVariablesCodes(phrase.text)
  const { loading, error, data } = useQuery(gql`query ($codes: [ID]) { variables(codes: $codes) }`, { variables: { codes }, fetchPolicy: 'network-only' })
  if (loading) return <Spin />
  if (error) return error.message
  return <Structured data={data} phrase={phrase} variables={variables} onSubmit={onSubmit} />
}

export default StructuredQuery
