import React from 'react'
import styled from 'styled-components'
import { ButtonRaw } from '../components/Button'
import { GenericIcon } from '../components/Icons'

const FormatButtonUnstyled = ({ iconPath, iconProps = {}, className, selected, ...rest }) => (
  <ButtonRaw className={selected ? `${className} selected` : `${className}`} {...rest}>
    <GenericIcon iconPath={iconPath} {...iconProps} />
  </ButtonRaw>
)

export default styled(FormatButtonUnstyled)`
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  color: ${props => props.theme.colors.grey700};
  &.selected {
    background-color: ${props => props.theme.colors.grey200};
  }
`
