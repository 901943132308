import React from 'react'
import Input from './Input'

function InputSubdomain({ onChange, value, ...props }) {

  const [inputValue, setInputValue] = React.useState(value)

  React.useEffect(() => {
    setInputValue(value)
  }, [value])

  function handleChange(e) {
    const newValue = e.target.value ? e.target.value.toLowerCase() : ''
    if (newValue && !/^[a-z0-9]+$/.test(newValue)) return false
    // if (/[a-z0-9]{0,20}/.test(newValue)) return false
    setInputValue(newValue)
    e.target.value = newValue
    onChange(e)
  }

  return (
    <Input {...props} value={inputValue} onChange={handleChange} />
  )
}

export default InputSubdomain
