import { gql } from '@apollo/client'

export const GET = gql`
  query Examination($code: ID!) {
    # account: myAccount {
    #   template
    #   groupsSettings
    #   docSettings
    # }
    examination(code: $code, withContentState: true) {
      code

      status

      indication
      # uid
      tree
      selectedProtocol
      filters
      selectedModel

      groupsCollapse
      hiddenGroups
      selectedBlocks
      selectedPhrases
      selectedComplementPhrases
      groupsIgnoringSelections

      afterGroup
      fields
      complementFields
      vars
      contentState
      textFactor

      PatientIdTag
      PatientName
      PatientAge
      PatientWeight
      PatientHeight
      PatientGender
      PhysicianName
      PhysicianCrm
      accessionNumber

      OpenPendenciesQty
      ClosedPendenciesQty

      clinic {
        id
        name
        uf
        city
        docSettings
        groupsSettings
        defaultEquipment
        illustrationSettings
      }

      # UnitName

      examinedAt
      previousExam1At
      previousExam2At

      signedAt
      underCorrectionAt
      correctedAt
      orderAvailableAt

      # createdAt

      user {
        id
        name
        signatureImage
        crm
        crmUF
        gender
        city
      }

      order {
        PhysicianName
        unit {
          id
          name
          city
          uf
        }
      }

      list {
        content
      }

      gallery {
        imageUrl
        thumbUrl
      }

      images {
        imageUrl
        thumbUrl
      }
    }
  }
`

export const UPDATE = gql`
  mutation UpdateExamination($code: ID!, $contentState: JSON, $status: JSON, $textFactor: Int) {
    updateExamination(code: $code, contentState: $contentState, status: $status, textFactor: $textFactor) {
      code
    }
  }
`

export const SIGN = gql`
  mutation SignExamination($code: ID!) {
    signExamination(code: $code) {
      signedAt
    }
  }
`

export const UNSIGN = gql`
  mutation UnsignExamination($code: ID!) {
    unsignExamination(code: $code) {
      signedAt
    }
  }
`

export const CORRECT = gql`
  mutation ($code: ID!) {
    correctExamination(code: $code) {
      correctedAt
    }
  }
`

export const UPLOAD_DOC = gql`
  mutation ($file: Upload!) {
    uploadDoc(file: $file) {
      id
      path
      filename
      mimetype
      encoding
    }
  }
`
