import React, { memo } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Checkbox from '@alobato/checkbox'

import Label from '../../components/Label'
import FormErrorBox from '../../components/FormErrorBox'

import useScrollToError from '@alobato/use-scroll-to-error'
import useFocusOnLoad from '@alobato/use-focus-on-load'
import { useTranslation } from 'react-i18next'

const Form = ({ isCreating, doctors, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {

  const { t } = useTranslation()

  useFocusOnLoad('name')
  useScrollToError({ errors, isValid, isSubmitting })

  const selectedIds = values.users.map(u => u.id)

  const handleCheckboxChange = (id, checked) => {
    const doctor = doctors.find(item => item.id === id)
    if (checked) {
      setFieldValue('users', [...values.users, { id: doctor.id, name: doctor.name } ])
    } else {
      setFieldValue('users', [...values.users.filter(item => item.id !== doctor.id) ])
    }
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>{t('Nome')}<span>*</span></Label>
        <Input width='100%' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} withError={touched.name && errors.name} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>

      {doctors.map(item => (
        <Flex ai='center' key={item.id}>
          <Box mr={2}><Checkbox checked={selectedIds.includes(item.id)} onChange={checked => handleCheckboxChange(item.id, checked)} /></Box>
          <Box>{item.name}</Box>
        </Flex>
      ))}

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>{t('Salvar')}</Button>
      </Box>

    </form>
  )
}

export default Form

