import React from 'react'
import { css } from 'styled-components/macro'
import { Flex, Box } from '@alobato/flex-box'
import { ArrowUpIcon, ArrowDownIcon } from './Icons'

const SortLabel = ({ headerField, currentField, label, currentAscOrDesc, setFunction }) => {
  if (headerField === currentField) {
    return (
      <Flex css={css`display: inline-flex; text-decoration: underline; text-underline-position: under;`} ai='center' cursor='pointer' onClick={() => setFunction(`${headerField} ${currentAscOrDesc === 'asc' ? 'desc' : 'asc'}`)}>
        <Box css={css`user-select: none;`}>{label}</Box>
        <Box>{(currentAscOrDesc.toLowerCase() === 'desc') ? (<ArrowUpIcon height={16} />) : (<ArrowDownIcon height={16} />)}</Box>
      </Flex>
    )
  }
  return <Flex css={css`cursor: pointer; display: inline-flex; text-decoration: underline; text-underline-position: under;`} onClick={() => setFunction(`${headerField} asc`)}>{label}</Flex>
}

export default SortLabel
