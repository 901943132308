import React from 'react'
import InputDebounced from '../../components/InputDebounced'
import InputDateDebounced from '../../components/InputDateDebounced'

function Experiment() {

  function handleChange(e, value, maskedValue) {
    if (value) {
      console.log('change', value, maskedValue)
    }
  }

  return (
    <div>
      <InputDebounced defaultValue={33} onChange={handleChange} />

      <InputDateDebounced defaultValue={'2021-01-03'} onChange={handleChange} />
    </div>
  )

}

export default Experiment
