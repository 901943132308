import React, { useState, useEffect } from 'react'
import { css } from 'styled-components/macro'
import  { DateTime } from 'luxon'
import { Flex, Box } from '@alobato/flex-box'
import useDebounce from '@alobato/use-debounce'
import Fab from '@alobato/fab'

import Accordion from '../../components/Accordion'
import Spin from '../../components/Spin'
import Select from '../../components/Select'
import Title from '../../components/Title'
import Modal from '../../components/StyledModal'
import WelcomeModal from '../../components/StyledWelcomeModal'
import Input from '../../components/Input'
import Button from '../../components/Button'
import DateRangePicker from '../../components/DateRangePicker'

import { useTranslation } from 'react-i18next'
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'
import { useHotkeys } from 'react-hotkeys-hook'

import useIgnoreMount from '../../hooks/useIgnoreMount'
import useLocalStorage from '../../hooks/useLocalStorage'

import { mobileDetect } from '../../utils'
import { today, lastXDays, lastWeek } from '../../utils/date'

import List from './List'
import New from './New'
import Edit from './Edit'
import Show from './Show'
import WelcomeA from './WelcomeA'
import WelcomeB from './WelcomeB'
import WelcomeC from './WelcomeC'
import Secondary from './Secondary'
import NewPendency from './NewPendency'
import ListPendencies from './ListPendencies'
import Messages from './Messages'

import NewTable from './NewTable'
import NewIllustration from './NewIllustration'

import Telemetry from './Telemetry'

import { useQuery, useMutation } from '@apollo/client'
import { LIST_USERS, REGISTER_FIRST_ACCESS } from './Gqls'

const statusMap = {
  CREATED: 'Trial',
  EXPIRED: 'Trial expirado',
  ACTIVE: 'Ativa',
  UNPAID: 'Aguardando pagamento',
}

const ExaminationsHome = ({ data: { myAccountUsers, me, clinics, stripe }, code, path, notifier, navigate }) => {

console.log('stripe1', stripe)

  const { t, i18n } = useTranslation()

  const [regitsterFirstAccess] = useMutation(REGISTER_FIRST_ACCESS)

  const users = [
    { id: '0', name: t('Todos') },
    ...myAccountUsers
  ]

  const { isMobile, isDesktop } = mobileDetect(navigator.userAgent)

  useHotkeys('n', () => {
    navigate(`novo${window.location.search}`)
  })

  // useEffect(() => {
  //   async function updateFirstAccess() {
  //     try {
  //       await regitsterFirstAccess()
  //     } catch(err) {
  //       console.error(err)
  //     }
  //   }
  //   if (me.firstAccessAt === null) updateFirstAccess()
  // }, [me.firstAccessAt, regitsterFirstAccess])

  const [dateRangeName, setDateRangeName] = useLocalStorage('examinationsDateRangeName', 'today')

  const [statusStorage, setStatusStorage] = useLocalStorage('examinationsStatus', '')

  useEffect(() => {
    if (dateRangeName) {
      setQuery({ d: dateRangeName, from: undefined, to: undefined })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (statusStorage) {
      setQuery({ status: statusStorage })
    }
    // eslint-disable-next-line
  }, [statusStorage])

  const defaultRange = dateRangeName ? dateRangeName : 'today'

  const [query, setQuery] = useQueryParams({
    from: StringParam,
    to: StringParam,
    d: withDefault(StringParam, defaultRange),
    page: NumberParam,
    search: StringParam,
    status: StringParam,
    createdby: NumberParam
  })

  const createdBy = (query.createdby || query.createdby === 0) ? Number(query.createdby) : Number(me.id)

  const status = query.status ? query.status : ''

  let from
  let to
  let fromISO
  let toISO

  if (query.from && query.to) {
    from = DateTime.fromISO(query.from).toJSDate()
    to = DateTime.fromISO(query.to).toJSDate()
    fromISO = query.from // || DateTime.fromJSDate(today()).toISODate()
    toISO = query.to // || DateTime.fromJSDate(today()).toISODate()
  } else if (query.d) {
    if (query.d === 'today') {
      from = today()
      to = today()
      fromISO = DateTime.fromJSDate(today()).toISODate()
      toISO = DateTime.fromJSDate(today()).toISODate()
    } else if (query.d === 'last-3-days') {
      from = lastXDays(3)
      to = today()
      fromISO = DateTime.fromJSDate(lastXDays(3)).toISODate()
      toISO = DateTime.fromJSDate(today()).toISODate()
    } else if (query.d === 'last-week') {
      from = lastWeek()
      to = today()
      fromISO = DateTime.fromJSDate(lastWeek()).toISODate()
      toISO = DateTime.fromJSDate(today()).toISODate()
    }

  }

  // const [welcomeOpen, setWelcomeOpen] = useState(me.firstAccessAt === null)
  const [welcomeOpen, setWelcomeOpen] = useState(true)

  const [name, setName] = useState(query.search || '')
  const debouncedName = useDebounce(name, 500)

  useIgnoreMount(debouncedName, newDebouncedName => {
    setQuery({ page: null, search: newDebouncedName })
  })

  const handleChangeRange = ({ name, from, to }) => {
    if (name) {
      setDateRangeName(name)
      return setQuery({ d: name, from: undefined, to: undefined })
    }
    const fromDate = DateTime.fromJSDate(from)
    const toDate = DateTime.fromJSDate(to)
    setQuery({ d: undefined, from: fromDate.toISODate(), to: toDate.toISODate() })
  }

  const handleChangeStatus = e => {
    const statusToUpdate = e.target.value ? e.target.value : ''
    setStatusStorage(statusToUpdate)
    setQuery({ status: e.target.value ? e.target.value : undefined })
  }

  const hideButtons = stripe && stripe.account && stripe.account.status && ['UNPAID', 'EXPIRED'].includes(stripe.account.status)
  console.log('hideButtons', hideButtons)

  return (
    <>
      <Box mb={5} p={[3, 4]}>

        <Box>

          <Flex jc='space-between' mb={3} ai='center'>
            <Box>
              <Title>{t('Lista de trabalho')}</Title>
            </Box>
            {hideButtons && (
              <Box>
                Status da assinatura: {statusMap[stripe.account.status]}
              </Box>
            )}
            <Flex>
              {(!hideButtons && (me && me.account && me.account.name !== 'BMK')) && (
                <>
                  {isDesktop() && ['I-IR', 'I-I', 'I-T', 'I-L1', 'I-L2', 'M-IR', 'M-I', 'M-T', 'M-L1', 'M-L2', 'A-IR', 'A-I', 'A-T', 'A-L1', 'A-L2'].includes(me.license) && <Box mr={2} display={['none', 'block']}><Button css={css`height:40px;`} onClick={() => navigate(`/ilustracao`)}>{t('Ilustração Rápida')}</Button></Box>}
                  {isDesktop() && ['I-T', 'I-L1', 'I-L2', 'M-T', 'M-L1', 'M-L2', 'A-T', 'A-L1', 'A-L2'].includes(me.license) && <Box mr={2} display={['none', 'block']}><Button css={css`height:40px;`} onClick={() => navigate(`nova-tabela${window.location.search}`)}>{t('Nova Tabela/Ilustração')}</Button></Box>}
                  {/*isDesktop() && ['I-I', 'I-T', 'I-L1', 'I-L2', 'M-I', 'M-T', 'M-L1', 'M-L2', 'A-I', 'A-T', 'A-L1', 'A-L2'].includes(me.license) && <Box mr={2} display={['none', 'block']}><Button css={css`height:40px;`} onClick={() => navigate(`nova-ilustracao${window.location.search}`)}>{t('Nova Ilustração')}</Button></Box>*/}
                  {isDesktop() && ['I-L1', 'I-L2', 'M-L1', 'M-L2', 'A-L1', 'A-L2'].includes(me.license) && <Box display={['none', 'block']}><Button css={css`height:40px;`} onClick={() => navigate(`novo${window.location.search}`)}>{t('Novo Laudo')}</Button></Box>}
                </>
              )}
            </Flex>
          </Flex>

          {isMobile() && <Fab onClick={() => navigate(`novo${window.location.search}`)} />}

          {me && me.account && me.account.name === 'BMK' && (
            <Box mb={3}>
              <Accordion title='Telemetria'>
                <Telemetry />
              </Accordion>
            </Box>
          )}

          <Box mb={3} p={2} bg='grey100'>
            <Flex flexWrap='wrap' ai='center'>
              <Box m={1}><DateRangePicker locale={i18n.language} from={from} to={to} onChange={handleChangeRange} /></Box>
              <Box m={1} minWidth={270}><Input width='100%' type='search' placeholder='Buscar por paciente' value={name} onChange={e => setName(e.target.value)} /></Box>
              {myAccountUsers.length > 1 && me.role.includes('ADMIN') &&
                <Box m={1}>
                  <Select value={createdBy} onChange={e => setQuery({ createdby: e.target.value })}>
                    {users.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                  </Select>
                </Box>
              }
              <Box m={1}>
                <Select value={status} onChange={handleChangeStatus}>
                  <option value={''}>Assinados e não assinados</option>
                  <option value={'not-signed'}>Não assinados</option>
                  <option value={'signed'}>Assinados</option>
                </Select>
              </Box>
            </Flex>
          </Box>

          <List
            notifier={notifier}
            page={query.page}
            search={debouncedName}
            createdBy={createdBy}
            from={fromISO}
            to={toISO}
            status={status}
            me={me}
          />

        </Box>

      </Box>

      {me && me.firstAccessAt === null && me.license && me.license.includes('I-') && (
        <WelcomeModal
          maxWidth={840}
          backdropOpacity={0.3}
          clickOutsideDisabled={true}
          onCloseCompleted={() => setWelcomeOpen(false)}
          render={
            ({onRequestClose}) => (
              <WelcomeB
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      )}

      {me && !me.isOwner && me.firstAccessAt === null && me.license && (me.license.includes('A') || me.license.includes('M')) && (
        <WelcomeModal
          maxWidth={840}
          backdropOpacity={0.3}
          clickOutsideDisabled={true}
          onCloseCompleted={() => setWelcomeOpen(false)}
          render={
            ({onRequestClose}) => (
              <WelcomeC
                onRequestClose={onRequestClose}
                clinics={clinics}
              />
            )
          }
        />
      )}

      {me && me.isOwner && me.firstAccessAt === null && me.license && me.license.includes('A') && (
        <WelcomeModal
          maxWidth={840}
          backdropOpacity={0.3}
          clickOutsideDisabled={true}
          onCloseCompleted={() => setWelcomeOpen(false)}
          render={
            ({onRequestClose}) => (
              <WelcomeA
                me={me}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      )}

      {false && welcomeOpen &&
        <WelcomeModal
          maxWidth={840}
          backdropOpacity={0.3}
          clickOutsideDisabled={true}
          onCloseCompleted={() => setWelcomeOpen(false)}
          render={
            ({onRequestClose}) => (
              <WelcomeA
                me={me}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      }

      {path === '/novo' &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate(`../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <New
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Criado com sucesso!')) }}
                onError={errorMessage => notifier.error(errorMessage)}
                me={me}
              />
            )
          }
        />
      }

      {path === '/nova-tabela' &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate(`../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <NewTable
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Criado com sucesso!')) }}
                onError={errorMessage => notifier.error(errorMessage)}
                me={me}
              />
            )
          }
        />
      }

      {path === '/nova-ilustracao' &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate(`../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <NewIllustration
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Criado com sucesso!')) }}
                onError={errorMessage => notifier.error(errorMessage)}
                me={me}
              />
            )
          }
        />
      }

      {path === '/:code/editar' && code &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate(`../../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <Edit
                code={code}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
                onError={errorMessage => notifier.error(errorMessage)}
                me={me}
                notifier={notifier}
              />
            )
          }
        />
      }

      {path === '/:code/anexar' && code &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate(`../../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <Secondary
                code={code}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
                onError={errorMessage => notifier.error(errorMessage)}
                me={me}
                notifier={notifier}
              />
            )
          }
        />
      }

      {path === '/:code/nova-pendencia' && code &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate(`../../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <NewPendency
                code={code}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
                onError={errorMessage => notifier.error(errorMessage)}
                me={me}
                notifier={notifier}
              />
            )
          }
        />
      }

      {path === '/:code/pendencias' && code &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate(`../../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <ListPendencies
                code={code}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
                onError={errorMessage => notifier.error(errorMessage)}
                me={me}
                notifier={notifier}
              />
            )
          }
        />
      }

      {path === '/:code' && code &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate(`../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <Show
                code={code}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      }

      {path === '/:code/mensagens' && code &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate(`../../${window.location.search}`)}
          render={
            ({onRequestClose}) => (
              <Messages
                code={code}
                onRequestClose={onRequestClose}
                onCompleted={() => { onRequestClose(); notifier.success(t('Salvo com sucesso!')) }}
                onError={errorMessage => notifier.error(errorMessage)}
                me={me}
                notifier={notifier}
              />
            )
          }
        />
      }

    </>
  )
}

const ExaminationsHomeQuery = ({ me, code, path, notifier, navigate }) => {
  const { loading, error, data } = useQuery(LIST_USERS, { fetchPolicy: 'network-only' })
  if (loading) return <Spin />
  if (error) return error.message
  return <ExaminationsHome data={data} me={me} code={code} path={path} notifier={notifier} navigate={navigate} />
}

export default ExaminationsHomeQuery
