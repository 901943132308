import { useEffect } from 'react'

function tawkTo(propertyId, key, user = null) {
  if (!window) throw new Error('DOM is unavailable')

  window.Tawk_API = window.Tawk_API || {}
  window.Tawk_LoadStart = new Date()

  if (user?.name && user?.email) {
    window.Tawk_API.visitor = user
  }

  const tawk = document.getElementById('tawkId')
  if (tawk) return window.Tawk_API // Prevent TawkTo to create root script if it already exists

  if (!key) throw new Error('Key not provided. Get key from tawk dashboard - Direct Chat Link')

  const script = document.createElement('script')
  script.id = 'tawkId'
  script.async = true
  script.src = `https://embed.tawk.to/${propertyId}/${key}`
  script.charset = 'UTF-8'
  script.setAttribute('crossorigin', '*')

  const firstScriptTag = document.getElementsByTagName('script')[0]
  if (!firstScriptTag || !firstScriptTag.parentNode) throw new Error('DOM is unavailable')
  firstScriptTag.parentNode.insertBefore(script, firstScriptTag)
}

function useTawkTo(tawkToPropertyId, tawkToKey, user) {
  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey, user)
  }, [tawkToKey, tawkToPropertyId, user])
}

export default useTawkTo
