import React, { memo, useState, useRef, useLayoutEffect } from 'react'

import { Flex, Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/Input'

import Title from '../../components/Title'
import Card from '../../components/Card'

import { useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/client'
import { PASSWORD_RESET_SEND } from './Gqls'

import LoginLayout from '../../layouts/LoginLayout'

import themes from '../../themes'

const PasswordResetSend = ({ notifier }) => {

  useLayoutEffect(() => {
    document.body.style.backgroundColor = themes.default.colors.grey100
    return () => document.body.removeAttribute('style')
  }, [])

  const { t } = useTranslation()

  const emailInput = useRef(null)

  const [passwordResetSend] = useMutation(PASSWORD_RESET_SEND)

  const [loading, setLoading] = useState(false)

  const handleSend = () => {
    if (!emailInput.current.value) return notifier.error(t('Preencha o email'))

    const variables = { email: emailInput.current.value }

    setLoading(true)

    passwordResetSend({ variables })
      .then(result => {
        setLoading(false)
        if (result.data.passwordResetSend)
          notifier.success(t('Se você estiver cadastrado, instruções serão enviadas para o seu email.'))
      })
      .catch(error => {
        setLoading(false)
        notifier.error(error.message.replace('GraphQL error: ', ''))
      })
  }

  return (
    <LoginLayout>
      <Flex jc='center' minWidth={['100%', 448]} maxWidth={448} mx='auto'>

        <Box width={1} p={[3, 4]}>

          <Card p={[3, 4]} mx='auto'>

            <Box ta='center' mb={4}>
              <Title>{t('Esqueci a senha')}</Title>
            </Box>

            <Box mx='auto' maxWidth={300} mb={4}>
              <Input width='100%' ref={emailInput} autoFocus type='email' placeholder={t('Digite seu email')} />
            </Box>
            <Box ta='center'>
              <Button onClick={handleSend} loading={loading}>{t('Enviar instruções para o email')}</Button>
            </Box>

          </Card>

        </Box>

      </Flex>
    </LoginLayout>
  )
}

export default memo(PasswordResetSend)
