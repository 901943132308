import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    // we init with resources
    resources: {
      pt: {
        translations: {
          // 'Criado em': 'Created at',
          // 'Descrição': 'Description',
          // 'PacienteID': 'PatientId',
          // 'Idade': 'Age',
          // 'Estudo(s)': 'Studies',
          // 'Arquivo(s)': 'Attachments',
          // 'Médicos': 'Doctors',
          // 'Exames': 'Exams',
          // 'Solicitações': 'Orders',
          // 'Selecionados': 'Selected',
          // 'Exame': 'Exam',
          // 'Unidades': 'Units',
          // 'Acordos': 'Agreements',
          // 'Pacientes': 'Patients',
          // 'Sair': 'Logout',
          // 'Preço Portfolio': 'Portfolio Price',
          // 'Modalidade': 'Modality',
          // 'Especialidade': 'Specialty',
          // 'Carregando': 'Loading',
          // 'Erro': 'Error',
          // 'Nome': 'Name',
          // 'Preço': 'Price',
          // 'Detalhes': 'Details',
          // 'Editar': 'Edit',
          // 'Excluir': 'Delete',
          // 'Novo': 'New',
          // 'Salvar': 'Save',
          // 'Email': 'Email',
          // 'Celular': 'Cell Phone',
          // 'Permissão': 'Role',
          // 'Usuários': 'Users',
          // 'Este usuário é um médico?': 'This user is a doctor?',
          // 'Médico?': 'Doctor?',
          // 'Sim': 'Yes',
          // 'Não': 'No',
          // 'Selecione...': 'Select...',
          // 'Salvo com sucesso!': 'Success save!',
          // 'Excluído com sucesso!': 'Success delete!',
          // 'Você tem certeza que deseja excluir?': 'Are you sure?',
          // 'Nenhum registro encontrado': 'Zero items',
          // 'Total': 'Total',
          // 'Voltar para Unidades': 'Back to Units',
          // 'Usuários da Unidade': 'Unit Users',
          // 'Estudos': 'Studies',
          // 'Nome do Paciente': 'Patient Name',
          // 'Sexo do Paciente': 'Patient Gender',
          // 'Nome da Unidade': 'Unit Name',
          // 'ID do Paciente': 'Patient ID',
          // 'Nova Solicitação': 'New Order',
          // 'Não encontrado': 'Not found',
          // 'Etapa 1': 'Step 1',
          // 'Etapa 2': 'Step 2',
          // 'Etapa 3': 'Step 3',
        }
      },
      en: {
        translations: {
          // TODO: Password Reset Send
          'Preencha o email': 'Type your email',
          'Se você estiver cadastrado, instruções serão enviadas para o seu email.': 'Email sent',
          'Esqueci a senha': 'Forgot password',
          'Digite seu email': 'Type your email',
          'Enviar instruções para o email': 'Send instructions to email',

          // Password Reset
          'Redefinir senha': 'Reset password',
          'Digite sua nova senha': 'Type your new password',
          'Digite novamente sua nova senha': 'Type your new password again',
          'Senha nova atualizada com sucesso. Acesse sua conta com o novo email e a nova senha.': 'Success. Login with new credentials',
          'Preencha a nova senha': 'New password is required',
          'A nova senha deve ter pelo menos 6 caracteres': 'New password: min 6 chars',
          'A confirmação da senha está diferente': 'Password confirmation is different',

          // Login
          // TODO: Translate yup messages
          'Login': 'Login',
          'Email': 'Email',
          'Senha': 'Password',
          'Entrar': 'Login',

          // Profile
          'Minha conta': 'Profile',
          'Senha alterada com sucesso!': 'Success save!',
          'Editar Conta': 'Edit Account',
          'Trocar Senha': 'Change Password',
          'Nome': 'Name',
          'Senha atual': 'Current password',
          'Nova senha': 'New password',

          // Exams
          'Exames': 'Exams',
          'Salvo com sucesso!': 'Success save!',
          'Novo': 'New',
          'Modalidade': 'Modality',
          'Especialidade': 'Specialty',
          'Salvar': 'Save',
          'Editar': 'Edit',
          'Detalhes': 'Details',
          'Excluir': 'Delete',
          'Você tem certeza que deseja excluir?': 'Are you sure?',
          'Excluído com sucesso!': 'Success delete!',
          'Nenhum registro encontrado': 'Zero items',
          'Total': 'Total',
          'Criado em': 'Created at',

          // Units
          'Unidades': 'Units',
          'Token': 'Token',
          'Usuários': 'Users',

          // Users and UnitUsers
          'Celular': 'Cell Phone',
          'Permissão': 'Role',
          'Este usuário é um médico?': 'This user is a doctor?',
          'Médico?': 'Doctor?',
          'Sim': 'Yes',
          'Não': 'No',
          'Selecione...': 'Select...',
          'CRM': 'CRM',
          'CRM UF': 'CRM UF',
          'Será enviado um email com a senha inicial para o usuário': 'An email with the initial password will be sent to the user',
          'É médico': 'Is doctor',
          'Unidade': 'Unit',
          'Voltar para Unidades': 'Back to Units',
          'Usuários da Unidade': 'Unit Users',

          // Studies
          'Estudos': 'Studies',
          'Solicitação criada com sucesso!': 'Order created successfully!',
          'Descrição': 'Description',
          'Nova Solicitação': 'New Order',
          'selecionado(s)': 'selected',
          'Solic.': 'Orders',
          'Nome do Paciente': 'Patient Name',
          'Sexo': 'Gender',
          'Sexo do Paciente': 'Patient Gender',
          'Nome da Unidade': 'Unit Name',
          'ID do Paciente': 'Patient ID',
          'PacienteID': 'PatientId',
          'Idade': 'Age',
          'Médico Solicitante': 'Requesting Physician',
          'Dt. Nasc. do Paciente': 'Patient Birthdate',
          'Dt. Nasc.': 'Birthdate',

          'Anamnese': 'Anamnesis',

          'Médico': 'Doctor',

          'Solicitações': 'Orders',

          'Etapa 1': 'Step 1',


          'Etapa 2': 'Step 2',


          'Etapa 3': 'Step 3',

          'Sair': 'Logout',

          // 'Estudo(s)': 'Studies',
          // 'Arquivo(s)': 'Attachments',
          // 'Médicos': 'Doctors',
          // 'Solicitações': 'Orders',
          // 'Selecionados': 'Selected',
          // 'Exame': 'Exam',
          // 'Acordos': 'Agreements',
          // 'Pacientes': 'Patients',
          // 'Sair': 'Logout',
          // 'Preço Portfolio': 'Portfolio Price',
          // 'Carregando': 'Loading',
          // 'Erro': 'Error',
          // 'Preço': 'Price',







          // 'Não encontrado': 'Not found',
          // 'Etapa 1': 'Step 1',
          // 'Etapa 2': 'Step 2',
          // 'Etapa 3': 'Step 3',




          "To get started, edit <1>src/App.js</1> and save to reload.":
            "To get started, edit <1>src/App.js</1> and save to reload.",
          "Welcome to React": "Welcome to React and react-i18next",
          welcome: "Hello <br/> <strong>World</strong>"
        }
      },
      de: {
        translations: {
          "To get started, edit <1>src/App.js</1> and save to reload.":
            "Starte in dem du, <1>src/App.js</1> editierst und speicherst.",
          "Welcome to React": "Willkommen bei React und react-i18next",
          welcome: "HH"
        }
      }
    },
    fallbackLng: "pt",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  })

export default i18n
