import React from 'react'
import { Field } from 'formik'

import FieldInput from '../../components/_new/FieldInput'
import FieldSelect from '../../components/_new/FieldSelect'
import Text from '../../components/_new/Text'
import Label from '../../components/_new/Label'
import Button from '../../components/_new/ButtonWithLoading'
import { Flex, Box } from '../../components/_new/FlexBox'
import Checkbox from '../../components/_new/Checkbox'

const states = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']

function MultiStepForm({ activeStep, values, setFieldValue, isLastStep, onBack, isSubmitting, handleSubmit, students, isCreating = false, clinics }) {

  const handleClinicsCheckboxChange = (value, checked) => {
    if (checked) {
      if (!values.clinics.includes(value)) {
        setFieldValue('clinics', [...values.clinics, value])
      }
    } else {
      if (values.clinics.includes(value)) {
        setFieldValue('clinics', [...values.clinics.filter(item => item !== value) ])
      }
    }
  }

  const handleModalityCheckboxChange = (value, checked) => {
    if (checked) {

      if (!values.modalities.includes(value)) {
        setFieldValue('modalities', [...values.modalities, value])
      }

    } else {
      if (values.modalities.includes(value)) {
        setFieldValue('modalities', [...values.modalities.filter(item => item !== value) ])
      }
    }
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>
      <div>
        {activeStep === 0 && (
          <Box>
            <Box textAlign='center' mb={3}>
              <Text large>Seja bem-vindo!</Text>
            </Box>

            <Box textAlign='center' mb={5}>
              <Text>Vamos completar algumas informações importantes para o funcionamento do TelerisOn:</Text>
            </Box>

            <Flex>
              <Box mr={3}>
                <Label required>Seu CRM</Label>
                <Field width='100%' name='crm' autoComplete='nope' component={FieldInput} />
              </Box>
              <Box>
                <Label required>UF</Label>
                <Field width='100%' name='crmUF' autoComplete='nope' component={FieldSelect} options={states.map(item => ({ label: item, value: item }))} />
              </Box>
            </Flex>

            <Box>
              <Label required>Sua especialidade</Label>
              <Field width='100%' name='specialty' autoComplete='nope' list='specialties' component={FieldInput} />
              <datalist id='specialties'>
                <option value='Cabeça e Pescoço' />
                <option value='Cardiologia' />
                <option value='Coluna' />
                <option value='Medicina Interna' />
                <option value='Músculo Esquelético' />
                <option value='Neuro' />
                <option value='Saúde da Mulher' />
                <option value='Radiologia Dentária' />
                <option value='Vascular' />
              </datalist>
            </Box>

            <Box>
              <Label required>Crie uma nova senha</Label>
              <Field width='100%' type='password' name='password' autoComplete='new-password' component={FieldInput} />
            </Box>

          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <Box textAlign='center' mb={3}>
              <Text large>Você trabalha com quais modalidades?</Text>
            </Box>

            <Box textAlign='center' mb={5}>
              <Text>Esta informação será importante para melhorar a sua experiência de navegação</Text>
            </Box>

            <Flex mb={6}>
              <Box mr={4}><Checkbox checked={values.modalities.includes('TC')} onChange={checked => handleModalityCheckboxChange('TC', checked)} label='TOMOGRAFIA' /></Box>
              <Box mr={4}><Checkbox checked={values.modalities.includes('RM')} onChange={checked => handleModalityCheckboxChange('RM', checked)} label='RESSONÂNCIA' /></Box>
              <Box mr={4}><Checkbox checked={values.modalities.includes('US')} onChange={checked => handleModalityCheckboxChange('US', checked)} label='ULTRASSONOGRAFIA' /></Box>
              <Box mr={4}><Checkbox checked={values.modalities.includes('MG')} onChange={checked => handleModalityCheckboxChange('MG', checked)} label='MAMOGRAFIA' /></Box>
            </Flex>
          </Box>
        )}

        {activeStep === 2 && (
          <Box>
            <Box textAlign='center' mb={5}>
              <Text large>Selecione as clínicas que você trabalha</Text>
            </Box>

            <Flex mb={6}>
              {clinics.map(item => (
                <Box mr={4} key={item.id}>
                  <Checkbox checked={values.clinics.includes(item.id)} onChange={checked => handleClinicsCheckboxChange(item.id, checked)} label={item.name} />
                </Box>
              ))}
            </Flex>

          </Box>
        )}


      </div>

      <Flex alignItems='center' mt={4}>
        {activeStep !== 0 && (<Box cursor='pointer' onClick={onBack} mr={4}>Voltar</Box>)}
        <Box><Button type='submit' loading={isSubmitting}>{isLastStep ? (isCreating ? 'Concluir' : 'Salvar') : 'Próximo'}</Button></Box>
      </Flex>

    </form>
  )
}

export default MultiStepForm
