import React from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import { CloseIcon } from '../../components/Icons'

import { useTranslation } from 'react-i18next'

import Form from './Form'
import validationSchema from './validationSchema'

import { useMutation } from '@apollo/client'
import { LIST, CREATE } from './Gqls'



const New = ({ onRequestClose, onCompleted }) => {

  const { t } = useTranslation()

  const [createPlan] = useMutation(CREATE)

  const initialValues = { name: '', categories: [], features: [], usersLimit: 1, trialDays: '', useMaster: false }

  const handleSubmit = async variables => {

    const update = (cache, { data: { createPlan } }) => {
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, plans: [createPlan, ...cacheList.plans] } })
    }

    try {
      await createPlan({ variables: {
        name: variables.name,
        categories: variables.categories,
        features: variables.features,
        usersLimit: variables.usersLimit,
        trialDays: variables.trialDays ? variables.trialDays : null,
        useMaster: variables.useMaster
      }, update })
      onCompleted()
    } catch(error) {
      console.error(error)
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Novo Plano')}</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default New
