import React from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import { CloseIcon } from '../../components/Icons'
import Spin from '../../components/Spin'

import { useTranslation } from 'react-i18next'

import Form from './Form'
import validationSchema from './validationSchema'

import { useQuery, useMutation } from '@apollo/client'
import { CREATE, PLANS } from './Gqls'



const New = ({ data: { plans }, onRequestClose, onCompleted, notifier }) => {

  const { t } = useTranslation()

  const [createUnit] = useMutation(CREATE)

  const initialValues = { name: '', PlanId: '', modalities: null }

  const handleSubmit = async variables => {

    try {
      await createUnit({ variables })
      onCompleted()
    } catch(error) {
      console.error(error)
      notifier.error(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Box position='relative' h={64} bg='grey100' css={{flexShrink: 0, borderBottom: '1px solid hsla(216, 40%, 90%, 1)'}}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text medium>{t('Nova Conta')}</Text></Box>
      </Box>
      <Box p={4}>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} plans={plans} />}
        </Formik>

      </Box>
    </Flex>
  )
}



const NewQuery = ({ onRequestClose, onCompleted, notifier }) => {
  const { loading, error, data } = useQuery(PLANS)
  if (loading) return <Spin />
  if (error) return error.message
  return <New data={data} onRequestClose={onRequestClose} onCompleted={onCompleted} notifier={notifier} />
}

export default NewQuery
