import React, { memo, useState, useEffect, useCallback } from 'react'
import { css } from 'styled-components/macro'
import { EditorState, convertFromRaw } from 'draft-js'
import { Flex, Box } from '@alobato/flex-box'

import Portal from '../../components/Portal'

import Select from '../../components/Select'
import ButtonIcon from '../../components/ButtonIcon'

import { getCurrentBlock, insertTextToCursor } from '../../utils/draft'

import useSpeechRecognition from '../../hooks/useSpeechRecognition'
import usePasteImage from '../../hooks/usePasteImage'

import Editor from './Editor'
import { ExtendedRichUtils, getCurrentlySelectedBlockData } from './ExtendedRichUtils'

const INLINE_STYLES = [
  { iconPath: 'M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z', style: 'BOLD' },
	{ iconPath: 'M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z', style: 'ITALIC' },
  { iconPath: 'M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z', style: 'UNDERLINE' },
]

const ALIGNMENTS = [
  { iconPath: 'M3,3H21V5H3V3M3,7H15V9H3V7M3,11H21V13H3V11M3,15H15V17H3V15M3,19H21V21H3V19Z', alignment: 'left' },
  { iconPath: 'M3,3H21V5H3V3M7,7H17V9H7V7M3,11H21V13H3V11M7,15H17V17H7V15M3,19H21V21H3V19Z', alignment: 'center' },
  { iconPath: 'M3,3H21V5H3V3M9,7H21V9H9V7M3,11H21V13H3V11M9,15H21V17H9V15M3,19H21V21H3V19Z', alignment: 'right' },
  { iciconPathon: 'M3,3H21V5H3V3M3,7H21V9H3V7M3,11H21V13H3V11M3,15H21V17H3V15M3,19H21V21H3V19Z', alignment: 'justify' },
]

const EditorExtended = memo(({ editorState, onChange, stopAction = () => {}, substitutions = [], defaultRawContentState, pasteImageEnabled = true, disabled = false, disableFontSize = false, onAddIllustrationClick = () => {}, onPreviewClick = () => {}, textFactor = 0, onTextFactorChange = () => {}, previewSelected = false, illustrationSelected = false }) => {
  const onChangeCallBack = useCallback(onChange, [])

  const src = usePasteImage()
  const [prevSrc, setPrevSrc] = useState('')

  const [isRecording, interim, finalTranscript, toggle, stop] = useSpeechRecognition({})
  const [prevFinalTranscript, setPrevFinalTranscript] = useState('')

  useEffect(() => {
    if (stopAction) stopAction(() => stop())
    return () => stop()
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (defaultRawContentState) {
      const contentState = convertFromRaw(defaultRawContentState)
      onChangeCallBack(EditorState.createWithContent(contentState))
    }
  // eslint-disable-next-line
  }, [])

  const currentStyle = editorState.getCurrentInlineStyle()
  const currentBlockAlign = getCurrentlySelectedBlockData(editorState, 'textAlign')

  const currentBlockFontSize = getCurrentlySelectedBlockData(editorState, 'fontSize')
  let fontSizeSelectValue = {}
  if (currentBlockFontSize) fontSizeSelectValue = { value: currentBlockFontSize, label: `${currentBlockFontSize}pt` }

	const toggleInlineStyle = style => {
    const editorStateFocused = EditorState.forceSelection(editorState, editorState.getSelection())
    onChangeCallBack(ExtendedRichUtils.toggleInlineStyle(editorStateFocused, style))
  }

	const toggleAlignment = alignment => {
    const editorStateFocused = EditorState.forceSelection(editorState, editorState.getSelection())
    const newEditorState = ExtendedRichUtils.toggleAlignment(editorStateFocused, alignment)
    onChangeCallBack(newEditorState)
  }

  const toggleFontSize = element => {
    const value = element.target.value
    const editorStateFocused = EditorState.forceSelection(editorState, editorState.getSelection())
    const newEditorState = ExtendedRichUtils.toggleFontSize(editorStateFocused, value.toString())
    onChangeCallBack(newEditorState)
  }

  const addImage = src => {
    console.log('addImage')
    const selectionState = editorState.getSelection()

    const focusKey = selectionState.getFocusKey()
    console.log('focusKey', focusKey)
    const focusOffset = selectionState.getFocusOffset()
    console.log('focusOffset', focusOffset)
    const anchorKey = selectionState.getAnchorKey()
    console.log('anchorKey', anchorKey)
    const anchorOffset = selectionState.getAnchorOffset()
    console.log('anchorOffset', anchorOffset)

    if (focusKey !== anchorKey) {
      return alert('Verifique se o cursor está posicionado em uma linha onde a ilustração será adicionada.')
    }

    if (focusOffset !== 0 || anchorOffset !== 0) {
      return alert('Verifique se o cursor está posicionado em uma linha em branco.')
    }

    if (!selectionState.isCollapsed()) {
      return alert('O cursor não pode estar selecionando nenhum conteúdo.')
    }

    let contentState = editorState.getCurrentContent()
    const key = selectionState.getStartKey()
    const blockMap = contentState.getBlockMap()
    const currentBlock = getCurrentBlock(editorState)

    if (!currentBlock) return null
    if (currentBlock.getLength() === 0) {
      if (currentBlock.getType() === 'image') return null
      const newBlock = currentBlock.merge({type: 'image', data: {src}})
      const newContentState = contentState.merge({blockMap: blockMap.set(key, newBlock), selectionAfter: selectionState})
      onChangeCallBack(EditorState.push(editorState, newContentState, 'change-block-type'))
    }
  }

  const handleChangeTextFactor = (factor) => {
    if ((textFactor + factor) >= -1 && (textFactor + factor) <= 1)
      onTextFactorChange(textFactor + factor)
  }

  // if (finalTranscript && prevFinalTranscript !== finalTranscript) {
  //   let textToInsert = finalTranscript
  //   if (!prevFinalTranscript)
  //     textToInsert = textToInsert.trim().charAt(0).toUpperCase() + textToInsert.slice(1)
  //   if (prevFinalTranscript.substr(-1) === '.')
  //     textToInsert = ' ' + textToInsert.trim().charAt(0).toUpperCase() + textToInsert.slice(2)
  //   onChangeCallBack(insertTextToCursor(editorState, `${textToInsert}`, 'DICTATION'))
  //   setPrevFinalTranscript(finalTranscript)
  // }

  if (finalTranscript && prevFinalTranscript !== finalTranscript) {

    let textToInsert = finalTranscript.toLowerCase()

    textToInsert = textToInsert.replace(/(^|\. *)([a-z])/g, function(_, separator, char) {
      return separator + char.toUpperCase()
    })

    const fixedSubstitutions = [
      { from: 'may thurner', to: 'May-Thurner' },
      { from: 'cockett', to: 'Cockett' },
      { from: 'texto', to: 'terço' },
      { from: 'reabilitação', to: 'reabitação' },
      { from: 'bosniak', to: 'Bosniak' },
      { from: 'doppler', to: 'Doppler' },
      { from: 'dopler', to: 'Doppler' },
      { from: 'convite', to: 'Covid-19' },
      { from: 'convide', to: 'Covid-19' },
      { from: 'covid', to: 'Covid-19' },
      { from: 'covide', to: 'Covid-19' },
      { from: 'covid-19', to: 'Covid-19' },
      { from: 'covid 19', to: 'Covid-19' },
      { from: 'Covid-19-19', to: 'Covid-19' },
      { from: 'avc', to: 'AVC' },
      { from: 'actinicas', to: 'actínicas' },
      { from: 'c1', to: 'C1' },
      { from: 'c2', to: 'C2' },
      { from: 'c3', to: 'C3' },
      { from: 'c4', to: 'C4' },
      { from: 'c5', to: 'C5' },
      { from: 'c6', to: 'C6' },
      { from: 'c7', to: 'C7' },
      { from: 't1', to: 'T1' },
      { from: 't2', to: 'T2' },
      { from: 't3', to: 'T3' },
      { from: 't4', to: 'T4' },
      { from: 't5', to: 'T5' },
      { from: 't6', to: 'T6' },
      { from: 't7', to: 'T7' },
      { from: 't8', to: 'T8' },
      { from: 't9', to: 'T9' },
      { from: 't10', to: 'T10' },
      { from: 't11', to: 'T11' },
      { from: 't12', to: 'T12' },
      { from: 'l1', to: 'L1' },
      { from: 'l2', to: 'L2' },
      { from: 'l3', to: 'L3' },
      { from: 'l4', to: 'L4' },
      { from: 'l5', to: 'L5' },
      { from: 's1', to: 'S1' },
      { from: 'L1 L2', to: 'L1-L2' },
      { from: 'L2 L3', to: 'L2-L3' },
      { from: 'L3 L4', to: 'L3-L4' },
      { from: 'L4 L5', to: 'L4-L5' },
      { from: 'L5 S1', to: 'L5-S1' },
      { from: 'ipmn', to: 'IPMN' },
      { from: 'C1 C2', to: 'C1-C2' },
      { from: 'C2 C3', to: 'C2-C3' },
      { from: 'C3 C4', to: 'C3-C4' },
      { from: 'C4 C5', to: 'C4-C5' },
      { from: 'C5 C6', to: 'C5-C6' },
      { from: 'C6 C7', to: 'C6-C7' },
      { from: 'lefort', to: 'Lefort' },
      { from: 'tep', to: 'TEP' },
      { from: 'sindrome', to: 'síndrome' },
      { from: 'bronquicas', to: 'brônquicas' },
      { from: 'horta', to: 'aorta' },
      { from: 'leriche', to: 'Leriche' },
      { from: 'endolique', to: 'endoleak' },
      { from: 'infra renal', to: 'infrarrenal' },
      { from: 'supra aorticos', to: 'supra-aórticos' },
      { from: 'aórtico mesentérica', to: 'aórtico-mesentérica' },
      { from: 'a, também,', to: 'há, também,' },
      { from: 'zap', to: 'ápice' },
      { from: 'na estampa grafias', to: 'nas topografias' },
      { from: 'bronquios fontes', to: 'brônquios-fonte' },
      { from: 'ausencia', to: 'ausência' },
      { from: 'virugla', to: ',' },
      { from: 'corretora', to: 'curvatura' },
      { from: 'lâminas aperas e cremosas e integrais', to: 'Lâminas papiráceas e crivosas integras' },
      { from: 'canais eróticos', to: 'Canais ópticos' },
      { from: 'bicicleta', to: 'discreta' },
      { from: 'leriche', to: 'Leriche' },
      { from: 'takayasu', to: 'Takayasu' },
      { from: 'tvp', to: 'TVP' },
      { from: 'duplo j', to: 'duplo J' },
    ]

    for (const substitution of [...fixedSubstitutions, ...substitutions]) {
      textToInsert = textToInsert.replace(new RegExp(substitution.from, 'g'), substitution.to)
    }

    textToInsert = textToInsert.replace(/(\S+?) em maiúscul[ao]/ig, function (x) {
      return x.replace(/\sem maiúscul[ao]/ig, '').toUpperCase()
    })

    const breakLine = `
`

    textToInsert = textToInsert.replace('↵', breakLine)

    // if (textareaRef.current.selectionEnd === 0) {
    //   textToInsert = textToInsert.trim().charAt(0).toUpperCase() + textToInsert.trim().slice(1)
    // } else if (textareaRef.current.value.substring(textareaRef.current.selectionEnd - 2, textareaRef.current.selectionEnd - 1) === '.') {
    //   textToInsert = textToInsert.trim().charAt(0).toUpperCase() + textToInsert.trim().slice(1)
    // } else if (textareaRef.current.value.substring(textareaRef.current.selectionEnd - 1, textareaRef.current.selectionEnd) === '.') {
    //   textToInsert = ' ' + textToInsert.trim().charAt(0).toUpperCase() + textToInsert.trim().slice(1)
    // } else if (textareaRef.current.value.substring(textareaRef.current.selectionEnd - 2, textareaRef.current.selectionEnd - 1) === '↵') {
    //   textToInsert = textToInsert.trim().charAt(0).toUpperCase() + textToInsert.trim().slice(1)
    // } else if (textareaRef.current.value.substring(textareaRef.current.selectionEnd - 1, textareaRef.current.selectionEnd) === '↵') {
    //   textToInsert = ' ' + textToInsert.trim().charAt(0).toUpperCase() + textToInsert.trim().slice(1)
    // }

    // const startPos = textareaRef.current.selectionStart
    // const endPos = textareaRef.current.selectionEnd
    // setValue(value => `${value}${textToInsert}`)
    // setValue(currentValue => `${currentValue.substring(0, startPos)}${textToInsert}${currentValue.substring(endPos, currentValue.length)}`)

    // setTimeout(() => {
    //   textareaRef.current.selectionStart = startPos + textToInsert.length
    //   textareaRef.current.selectionEnd = startPos + textToInsert.length
    // }, 1)

    if (!prevFinalTranscript)
      textToInsert = textToInsert.trim().charAt(0).toUpperCase() + textToInsert.trim().slice(1)
    if (prevFinalTranscript.substr(-1) === '.')
      textToInsert = ' ' + textToInsert.trim().charAt(0).toUpperCase() + textToInsert.trim().slice(1)

    onChangeCallBack(insertTextToCursor(editorState, `${textToInsert}`, 'DICTATION'))

		setPrevFinalTranscript(finalTranscript)
		// autosize.update(textareaRef.current)
  }

  if (pasteImageEnabled && src && prevSrc !== src) {
    addImage(src)
    setPrevSrc(src)
  }

  return (
    <Box cursor={disabled ? 'not-allowed' : 'auto'}>

      {interim && <Portal><Box css={css`position: fixed; bottom: 16px; left: 16px; right: 16px; background: hsla(0, 0%, 0%, 0.4); color: white; font-size: 42px; padding: 16px; margin: 0 auto; text-align: center; border-radius: 16px; font-weight: 600;`}>{interim}</Box></Portal>}

      <Box p={1} bg='grey100'>
        <Flex ai='center' jc='space-between' lh={0}>
          <Flex ai='center'>
            {!disabled &&
              <>
                {INLINE_STYLES.map(item => (
                  <Box key={item.style} lh={0}><ButtonIcon selected={currentStyle.has(item.style)} iconPath={item.iconPath} onClick={() => toggleInlineStyle(item.style)} /></Box>
                ))}
                {ALIGNMENTS.map(item => (
                  <Box key={item.alignment} lh={0}><ButtonIcon selected={(currentBlockAlign === item.alignment)} iconPath={item.iconPath} onClick={() => toggleAlignment(item.alignment)} /></Box>
                ))}
                <ButtonIcon onClick={() => toggle()} selected={false} className={isRecording ? 'recording' : ''} iconPath='M 12 2 C 10.343 2 9 3.343 9 5 L 9 11 C 9 12.657 10.343 14 12 14 C 13.657 14 15 12.657 15 11 L 15 5 C 15 3.343 13.657 2 12 2 z M 5 11 C 5 14.525296 7.6093644 17.433226 11 17.919922 L 11 21 L 13 21 L 13 17.919922 C 16.390636 17.433226 19 14.525296 19 11 L 17 11 C 17 13.761 14.761 16 12 16 C 9.239 16 7 13.761 7 11 L 5 11 z' />
                {!disableFontSize && <Box w={100} lh={0}>
                  <Select disabled={disabled} placeholder='Fonte' value={fontSizeSelectValue.value} onChange={toggleFontSize}>
                    {[10, 11, 12, 14, 18, 24, 32].map(item => <option value={item} key={item}>{`${item}pt`}</option>)}
                  </Select>
                </Box>}


                <Flex ai='center'>
                  <Box>
                    <ButtonIcon onClick={() => { handleChangeTextFactor(-1) }} iconPath='M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,17L23,11.93L21.59,10.5L19,13.1V7H17V13.1L14.41,10.5L13,11.93L18,17Z' />
                  </Box>
                  <Box>{textFactor}</Box>
                  <Box>
                    <ButtonIcon onClick={() => { handleChangeTextFactor(1) }} iconPath='M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,7L13,12.07L14.41,13.5L17,10.9V17H19V10.9L21.59,13.5L23,12.07L18,7Z' />
                  </Box>
                </Flex>

                <Box flex={1} w={100}></Box>
              </>
            }
          </Flex>
          <Box>
            <Flex>
              <Box mr={2}><ButtonIcon title='Adicionar Ilustração' onClick={() => onAddIllustrationClick()} selected={illustrationSelected} iconPath='M19,19H5V5H19M19,3H5C3.9,3 3,3.9 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.9 20.1,3 19,3M13.96,12.29L11.21,15.83L9.25,13.47L6.5,17H17.5L13.96,12.29Z' /></Box>
              <Box><ButtonIcon title='Visualizar Impressão' onClick={() => onPreviewClick()} selected={previewSelected} iconPath='M6,2C4.9,2 4,2.9 4,4V20C4,21.1 4.9,22 6,22H18C19.1,22 20,21.1 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z' /></Box>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box>
        <Editor
          editorState={editorState}
          onChange={onChangeCallBack}
        />
      </Box>
    </Box>
  )
})

export default EditorExtended
