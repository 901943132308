import React, { memo } from 'react'
import { navigate } from '@reach/router'
import { Flex, Box, css } from '@alobato/flex-box'
import Text from '@alobato/text'
import { TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '@alobato/table'
import { Link } from '@reach/router'

import Spin from '../../components/Spin'
import PopOver from '../../components/PopOver'
import { Menu, MenuItem } from '../../components/Menu'

import { MoreIcon } from '../../components/Icons'
import Pagination from '../../components/Pagination'
import { FormatUtcDate, FormatDateTime } from '../../components/Format'

import { useTranslation } from 'react-i18next'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'

import { useQuery, useMutation } from '@apollo/client'
import { LIST, DELETE } from './Gqls'

const PAGE_SIZE = 100
const DEFAULT_ORDER_FIELD = 'id'



const List = memo(({ data: { accounts: { results: items, count } }, page, setPage, order, notifier, refetch }) => {

  const { t, i18n } = useTranslation()

  const [deleteAccount] = useMutation(DELETE)

  const columns = [
    { name: 'name', label: 'Nome', nowrap: true },
  ]

  const tds = {
    createdAt: (item, config) => <TableCell key='createdAt' data-title={t('Realizado')} style={{ width: 100, whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}><FormatUtcDate>{item.createdAt}</FormatUtcDate></TableCell>,
    name: (item, config) => <TableCell key='name' data-title={t('Criado por')} style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.name}</TableCell>,
  }

  const pageCount = Math.ceil(count > 0 ? count / PAGE_SIZE : 0)

  let [field, ascOrDesc] = order ? order.split(' ') : ['', '']
  if (!field) field = DEFAULT_ORDER_FIELD
  if (!ascOrDesc) ascOrDesc = 'asc'

  const handleDelete = async id => {
    if (!window.confirm(t('Você tem certeza que deseja excluir?'))) return false

    try {
      await deleteAccount({ variables: { id } })
      refetch()
      notifier.success(t('Excluído com sucesso!'))
    } catch(error) {
      console.error(error)
      notifier.error(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Box mb={5}>

      {items.length > 0 &&
        <>
          <Flex jc='space-between'>
            <Box>{`${t('Total')}: ${count}`}</Box>
          </Flex>

          <TableContainer css={css`overflow-x: scroll;`}>
            <Table>
              <TableHeaderRow>
                <TableHeaderCell style={{ whiteSpace: 'nowrap', width: 140 }}>{t('Criado em')}</TableHeaderCell>
                {columns.map(item => (
                  <TableHeaderCell key={item.label} width={item.width ? item.width : null} style={{whiteSpace: item.nowrap ? 'nowrap' : null, paddingRight: item.paddingRight ? item.paddingRight : null }}>{t(item.label)}</TableHeaderCell>
                ))}
                <TableHeaderCell>{t('Usuários')}</TableHeaderCell>
                <TableHeaderCell>{t('Licenças')}</TableHeaderCell>
                <TableHeaderCell>{t('Grupos')}</TableHeaderCell>
                <TableHeaderCell>{t('Modalidades')}</TableHeaderCell>
                {/* <TableHeaderCell>{t('Plano')}</TableHeaderCell> */}
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {items.map(item => (
                <TableRow key={item.id}>
                  <TableCell data-title='Criado em' style={{ whiteSpace: 'nowrap'}}><FormatDateTime locale={i18n.language}>{item.createdAt}</FormatDateTime></TableCell>
                  {columns.map(td => tds[td.name](item, td))}
                  <TableCell data-title={t('Usuários')}><Link to={`${item.id}/usuarios`}>{item.users.length}</Link></TableCell>
                  <TableCell data-title={t('Licenças')}><Link to={`${item.id}/licencas`}>{item.licenses.length}</Link></TableCell>
                  <TableCell data-title={t('Grupos')}><Link to={`${item.id}/grupos`}>{item.groups.length}</Link></TableCell>
                  <TableCell data-title={t('Modalidades')}>{item.modalities && item.modalities.map(item => item.label).join(', ')}</TableCell>
                  {/* <TableCell data-title={t('Plano')}>{item.plan && item.plan.name}</TableCell> */}
                  <TableCell width={40} lineHeight={0}>
                    <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                      <Menu>
                        <MenuItem onClick={() => navigate(`/contas/${item.id}${window.location.search}`)}>{t('Detalhes')}</MenuItem>
                        <MenuItem onClick={() => navigate(`/contas/${item.id}/editar`)}>{t('Editar')}</MenuItem>
                        <MenuItem onClick={() => handleDelete(item.id)}>{t('Excluir')}</MenuItem>
                      </Menu>
                    )}>
                      <MoreIcon style={{cursor: 'pointer'}} />
                    </PopOver>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </>
      }

      {items.length === 0 &&
        <Box my={5} ta='center' css={css`@keyframes fadein { 0% { opacity: 0 } 66% { opacity: 0 } 100% { opacity: 1 } } & { animation: 0.5s ease 0s normal forwards 1 fadein; }`}>
          <Text medium fw={300} color='grey400'>{t('Nenhum registro encontrado')}</Text>
        </Box>
      }

      <Box ta='center'>
        <Pagination onPageChange={({ selected }) => { if (!selected) return setPage(null); setPage(selected + 1) }} currentPage={page} pageCount={pageCount} />
      </Box>

    </Box>
  )
})

const ListQuery = props => {
  const [search, setSearch] = useQueryParam('search', StringParam)

  const [order] = useQueryParam('order', StringParam)
  const [page, setPage] = useQueryParam('page', NumberParam)

  const offset = page ? ((page - 1) * PAGE_SIZE) : 0

  const { loading, error, data, refetch } = useQuery(LIST, { fetchPolicy: 'cache-and-network', variables: { limit: PAGE_SIZE, offset, search, orderBy: (order || 'id desc') } })
  if (loading) return <Spin />
  if (error) return error.message
  return <List {...props} refetch={refetch} data={data} page={page} setPage={setPage} offset={offset} search={search} setSearch={setSearch} order={order} />
}

export default memo(ListQuery)
