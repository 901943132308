import React, { memo, useRef } from 'react'
import Tippy from '@tippy.js/react'
import { Box } from '@alobato/flex-box'

import ButtonIcon from '../../../../components/ButtonIcon'

import CaptureVars from './CaptureVars'

// import 'tippy.js/dist/tippy.css'
// import 'tippy.js/themes/light.css'
// import 'tippy.js/animations/perspective.css'

const TableText = memo(({ text }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: text }} className='tbl' />
  )
})

const TableGroup = memo(({ text, capturedVars, vars, onChangeVars }) => {

  const instanceRef = useRef()
  // const buttonRef = useRef() // buttonRef.current.focus()

  // animation='perspective'

  return (
    <>
      {capturedVars && capturedVars.length > 0 &&
        <Box>
        <Tippy
          content={<CaptureVars capturedVars={capturedVars} vars={vars} onSubmit={vars => { onChangeVars(vars); instanceRef.current.hide() }} />}
          placement='bottom'
          trigger='click'
          theme='light'
          interactive={true}
          inertia={true}
          arrow={false}
          duration={[350, 200]}
          onCreate={instance => instanceRef.current = instance}
        >
          <span>
            <ButtonIcon iconPath='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z' />
          </span>
        </Tippy>
        </Box>
      }
      <Box mt={1}><TableText text={text} /></Box>
    </>
  )
})

export default TableGroup
