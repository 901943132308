import React, { memo, useState, useRef  } from 'react'
import styled from 'styled-components'

export const Accordion = styled.div`
  outline: 0;
  /*&:focus {
    border: 1px solid hsla(207, 100%, 63%, 1);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
    background-color: hsla(200, 77%, 94%, 1);
  };*/
  & > .pills {
    padding-bottom: 16px;
  }
`

export const Pill = styled.div`
  cursor: pointer;
  user-select: none;
  padding: 6px 10px;
  margin: 8px 8px 0 10px;
  font-size: 13px;
  font-weight: 500;
  color: hsla(0, 0%, 0%, 0.6);
  outline: 0;
`

export const Blocks = ({ options, containerIsFocused, focused, handleClick, value, open }) => {

  return (
    <div className='pills'>
      {options.map((option, i) => {
        let style = {}
        const isFocused = containerIsFocused && option.props.value._id === focused.props.value._id
        const isSelected = (value && option.props.value._id === value.id)
        if (isFocused) style = {...style, border: '1px solid hsla(207, 100%, 63%, 1)', boxShadow: '0 0 0 3px rgba(24, 144, 255, 0.2)'}
        if (isSelected) style = {...style, color: 'white', backgroundColor: 'hsla(216, 20%, 50%, 1)'}
        
        
        return (
          <Pill
            key={option.props.value._id}
            style={style}
            onClick={() => handleClick(option, i)}
            tabIndex={-1}
          >
            {option.props.children}
          </Pill>
        )
      })}
    </div>
  )
}

export const Option = ({children}) => <div>{children}</div>

export default memo(function SelectPhrases({children, disabled = false, onChange, value, title, onFocus, onBlur }) {
  const [focused, setFocused] = useState(children.find(option => value && value.id === option.props.value._id) || children[0])
  const [containerIsFocused, setContainerIsFocused] = useState(false)

  const container = useRef()

  const handleClick = (option, index) => {
    if (disabled) return false
    setFocused(children[index])
    onChange(container.current, option.props.value)
  }

  const handleFocus = () => {
    if (disabled) return false
    setContainerIsFocused(true)
    onFocus(title)
    setFocused(children[0])
  }

  const handleBlur = () => {
    // onCollapse(false)
    setContainerIsFocused(false)
    onBlur(title)
  }

  const handleKeyDown = e => {
    if (disabled) return false
    if (e.which === 38 || e.which === 40)
      e.preventDefault()
  }

  const handleKeyUp = e => {
    if (disabled) return false
    e.preventDefault()

    const itemFocused = children.find(option => option.props.value._id === focused.props.value._id)
    const index = children.indexOf(itemFocused)

    if (e.which === 32 || e.which === 13) {
      const selectedItem = children[index]
      setFocused(selectedItem)
      onChange(container.current, selectedItem.props.value)
    }

    if (e.which === 37 || e.which === 38) {
      if (index > 0) setFocused(children[index - 1])
    }
    if (e.which === 39 || e.which === 40) {
      if (index < children.length - 1) setFocused(children[index + 1])
    }
  }

  return (
    <Accordion ref={container} tabIndex={value ? -1 : 0} onFocus={handleFocus} onBlur={handleBlur} onKeyUp={handleKeyUp} onKeyDown={handleKeyDown}>
      <Blocks open={false} options={children} containerIsFocused={containerIsFocused} focused={focused} handleClick={handleClick} value={value} />
    </Accordion>
  )

})
