import React, { memo, useState } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'

import { useMutation, gql } from '@apollo/client'

import Modal from '../../../components/StyledModal'

import { CloseIcon } from '../../../components/Icons'

import Home from './Home'

export default memo(({ onRequestClose, onCompleted, gallery, captions, d1 = null, d2 = null, images, hideHeader = false, hideAdd = false, titleBackgroundColor, titleTextColor, titleBorderColor, zoom = 2, pasteImageEnabled = true, vars, examination }) => {

  const [resultModalOpen, setResultModalOpen] = useState(false)
  const [resultUrl, setResultUrl] = useState(false)

  const [log] = useMutation(gql`mutation ($name: String) { log(name: $name) }`)

  const handleAdd = async base64Image => {
    try { log({ variables: { name: 'illustration.add' } }) } catch(err) { console.error(err) }
    onCompleted(base64Image)
  }

  const handleNew = () => {
    try { log({ variables: { name: 'illustration.new' } }) } catch(err) { console.error(err) }
  }

  const handlePartial = () => {
    try { log({ variables: { name: 'illustration.partial' } }) } catch(err) { console.error(err) }
  }

  const handlePreview = () => {
    try { log({ variables: { name: 'illustration.generate' } }) } catch(err) { console.error(err) }
  }

  const handleGenerate = async url => {
    // try { log({ variables: { name: 'illustration.generate' } }) } catch(err) { console.error(err) }
    setResultModalOpen(true)
    setResultUrl(url)
  }

  return (
    <>
      <Flex flexDirection='column' height='100%'>
        {!hideHeader && (
          <Flex bg='grey100' minHeight={64} ai='center'>
            <Box w={64} />
            <Box flex={1} ta='center' css='white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'><Text medium>Ilustrações</Text></Box>
            <Box w={64} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
          </Flex>
        )}
        <Box p={4} overflow='auto'>

          <Box minHeight={200}>

            <Home
              onGenerate={handleGenerate}
              onNew={handleNew}
              onPartial={handlePartial}
              onPreview={handlePreview}
              onAdd={handleAdd}
              gallery={gallery}
              d1={d1}
              d2={d2}
              captions={captions}
              images={images}
              hideAdd={hideAdd}
              titleBackgroundColor={titleBackgroundColor}
              titleTextColor={titleTextColor}
              titleBorderColor={titleBorderColor}
              zoom={zoom}
              pasteImageEnabled={pasteImageEnabled}
              vars={vars}
              examination={examination}
            />
            {/* <IllustrationDrop
                baseUrl={baseUrl}
                token={token()}
                finalWidth={finalWidth}
                zoom={zoom}
                quality={quality}
                format={format}
                titleBackgroundColor={titleBackgroundColor}
                titleTextColor={titleTextColor}
                titleBorderColor={titleBorderColor}
                id='ilustracao'
                onGenerate={this.handleGenerate}
                arrows={arrows} /> */}

          </Box>

        </Box>

      </Flex>

      {resultModalOpen &&

        <Modal
          zIndex={1003}
          onCloseCompleted={() => setResultModalOpen(false)}
          backdropStyle={{zIndex: '1002'}}
          render={
            ({onRequestClose}) => (

              <Flex flexDirection='column' height='100%'>
              <Flex bg='grey100' minHeight={64} ai='center'>
                <Box w={64} />
                <Box flex={1} ta='center' css='white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'><Text medium>Prever</Text></Box>
                <Box w={64} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
              </Flex>
              <Box p={4} overflow='auto'>

                <Box minHeight={200} minWidth={800}>
                  {resultUrl &&
                    <div style={{width: '648px', margin: '1rem auto 5rem auto'}}><img src={resultUrl} alt='result' style={{width: `648px`, height: 'auto'}} /></div>
                  }
                </Box>

              </Box>

            </Flex>

          )}
        />

      }

    </>
  )

})
